import { connect } from "react-redux";

import PrivacyPoliciesComponent from "./component";

const PrivacyPolicies = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {},
)(PrivacyPoliciesComponent);

export default PrivacyPolicies;
