import { Menu } from "antd";
import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import profileFeatureContext from "../../../context/profile-access";

const AccessType = {
  NoAccess: false,
  ReadWrite: true,
  menuItem: "menuItem",
  notAccessible: "not visible",
  accessible: "visible",
};

const AccessControl = ({ ...props }) => {
  const { navigate } = useNavigate();
  const { code, children, page, handleTableAction } = props;
  const profileFeatures = useContext(profileFeatureContext);
  if (!profileFeatures) return null;
  let profileFeature = profileFeatures?.map((pf) => pf).find((f) => f?.name === page)?.actions;
  if (code === "menuItem") {
    profileFeature = { ...profileFeature, menuItem: "menuItem", view_all_clips: true };
  }
  const menuItems = [];
  switch (profileFeature?.[code]) {
    case AccessType.ReadWrite:
      return children;
    case AccessType.menuItem:
      handleTableAction(profileFeature, menuItems);
      return React.createElement(Menu, { items: menuItems });
    case AccessType.NoAccess:
      return null;
    case AccessType.notAccessible:
      return <Navigate to="/dashboard" />;
    case AccessType.accessible:
      return children;
  }
};

export default AccessControl;
