import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" fill="none" viewBox="0 0 20 17" {...props}>
    <path
      fill="currentColor"
      d="M19.803.501H5.49a.195.195 0 00-.138.06.203.203 0 00-.058.14v1.4c0 .11.088.2.197.2h14.312a.195.195 0 00.14-.058.202.202 0 00.057-.142v-1.4a.203.203 0 00-.058-.141.195.195 0 00-.139-.059zm0 7.1H5.49a.195.195 0 00-.138.059.203.203 0 00-.058.141v1.4c0 .11.088.2.197.2h14.312a.195.195 0 00.14-.059A.202.202 0 0020 9.2V7.801a.202.202 0 00-.058-.142.195.195 0 00-.139-.058zm0 7.1H5.49a.195.195 0 00-.138.059.202.202 0 00-.058.14v1.4c0 .11.088.2.197.2h14.312a.195.195 0 00.14-.058A.202.202 0 0020 16.3v-1.4a.202.202 0 00-.058-.141.195.195 0 00-.139-.06v.002zM0 1.399c0 .372.145.728.402.99.257.263.606.41.97.41s.714-.147.971-.41c.257-.262.402-.618.402-.99 0-.37-.145-.727-.402-.99A1.358 1.358 0 001.373 0C1.009 0 .66.147.403.41A1.414 1.414 0 000 1.4H0zm.001 7.102c0 .371.145.727.402.99.257.262.607.41.97.41.365 0 .714-.148.971-.41.257-.263.402-.619.402-.99s-.145-.728-.402-.99a1.359 1.359 0 00-.97-.41c-.364 0-.714.147-.971.41a1.414 1.414 0 00-.402.99zm0 7.1c0 .37.145.726.402.989.257.262.607.41.97.41.365 0 .714-.148.971-.41.257-.263.402-.619.402-.99s-.145-.727-.402-.99a1.358 1.358 0 00-.97-.41c-.364 0-.713.148-.97.41a1.413 1.413 0 00-.402.99H.001z"
    ></path>
  </svg>
);

export default SvgComponent;
