import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47" fill="none" viewBox="0 0 46 47">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.124 16.954c-3.68 0-6.663 3.015-6.663 6.733 0 3.718 2.983 6.732 6.663 6.732s6.663-3.014 6.663-6.732c0-3.718-2.983-6.733-6.663-6.733zm-9.015 6.733c0-5.03 4.036-9.109 9.015-9.109 4.979 0 9.015 4.078 9.015 9.109 0 5.03-4.036 9.109-9.015 9.109-4.979 0-9.015-4.078-9.015-9.11zM23.123 6.843c.65 0 1.176.532 1.176 1.188v4.215c0 .656-.526 1.188-1.176 1.188-.65 0-1.176-.532-1.176-1.188V8.03c0-.656.527-1.188 1.176-1.188zM23.123 33.94c.65 0 1.176.531 1.176 1.188v4.215c0 .656-.526 1.188-1.176 1.188-.65 0-1.176-.532-1.176-1.188v-4.216c0-.656.527-1.188 1.176-1.188zM39.797 23.686c0 .656-.527 1.188-1.176 1.188h-4.172c-.649 0-1.175-.532-1.175-1.188 0-.656.526-1.188 1.175-1.188h4.172c.65 0 1.176.532 1.176 1.188zM12.98 23.686c0 .656-.526 1.188-1.175 1.188H7.633c-.65 0-1.176-.532-1.176-1.188 0-.656.527-1.188 1.176-1.188h4.172c.649 0 1.175.532 1.175 1.188zM31.46 9.1c.562.329.755 1.056.43 1.624l-2.086 3.65a1.17 1.17 0 01-1.606.435 1.195 1.195 0 01-.43-1.623l2.085-3.65A1.17 1.17 0 0131.46 9.1zM18.053 32.567c.563.328.756 1.054.43 1.623l-2.085 3.65a1.17 1.17 0 01-1.606.435 1.195 1.195 0 01-.43-1.623l2.085-3.65a1.17 1.17 0 011.607-.435zM37.56 32.109a1.17 1.17 0 01-1.606.435l-3.612-2.108a1.195 1.195 0 01-.43-1.623 1.17 1.17 0 011.605-.434l3.613 2.107c.563.328.755 1.055.43 1.623zM14.338 18.561a1.17 1.17 0 01-1.606.435l-3.613-2.107a1.195 1.195 0 01-.43-1.623 1.17 1.17 0 011.606-.435l3.613 2.107c.562.328.755 1.055.43 1.623zM37.56 15.264a1.195 1.195 0 01-.43 1.623l-3.612 2.108a1.17 1.17 0 01-1.607-.435 1.195 1.195 0 01.43-1.623l3.613-2.108a1.17 1.17 0 011.607.435zM14.338 28.812a1.195 1.195 0 01-.43 1.623l-3.613 2.108a1.17 1.17 0 01-1.606-.435 1.195 1.195 0 01.43-1.623l3.613-2.108a1.17 1.17 0 011.606.435zM31.462 38.274a1.17 1.17 0 01-1.606-.435l-2.086-3.65a1.195 1.195 0 01.43-1.623 1.17 1.17 0 011.607.434l2.085 3.65a1.195 1.195 0 01-.43 1.624zM18.054 14.808a1.17 1.17 0 01-1.606-.435l-2.086-3.65a1.195 1.195 0 01.43-1.623 1.17 1.17 0 011.607.435l2.085 3.65a1.195 1.195 0 01-.43 1.623z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SvgComponent;
