import React from "react";

function SoundMute() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.25 5.75l-3.5 4.5m0-4.5l3.5 4.5m-12.5-4.5v4.5h2.5l4 3V2.75l-4 3h-2.5z"
      ></path>
    </svg>
  );
}

export default SoundMute;
