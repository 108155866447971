import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M28.961 1.015A3.407 3.407 0 0026.525 0h-9.54a4.273 4.273 0 00-2.882 1.258L1.478 13.884c-1.908 1.948-1.99 4.993-.122 6.86l7.916 7.916c.893.893 2.111 1.34 3.288 1.34a5.013 5.013 0 003.532-1.461l12.625-12.626a4.29 4.29 0 001.259-2.922V3.45c0-.934-.366-1.786-1.015-2.436zm-4.668 10.717c-.812.853-1.909 1.259-3.005 1.259-1.096 0-2.192-.407-3.004-1.259-1.664-1.624-1.664-4.344 0-6.008 1.624-1.664 4.344-1.664 6.008 0 1.665 1.624 1.665 4.344 0 6.008z"
    ></path>
  </svg>
);

export default SvgComponent;
