import React from "react";
import { useNavigate } from "react-router-dom";

import Back from "../../components/icons/Back";

import "./styles.scss";

export default function PrivacyPoliciesComponent(props) {
  const navigate = useNavigate();
  return (
    <>
      <main className="global-main pt-2">
        <div className="title-container mt-4 mb-3">
          <div className="container-fluid page-rl-p">
            <div className="row align-items-center border-back-container">
              <div className="col-md-8">
                <div className="page-title-items back-icon-block">
                  <h1>
                    <button className="pageBack" onClick={() => navigate(-1)}>
                      <Back />
                    </button>
                    Back
                  </h1>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-title">
                  <h1>Privacy Policy</h1>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="text-content-block">
                  <p>
                    The use of the website is usually possible without providing personal information. Magnifi does not
                    collect any private user information, unless specifically stated or required. In some areas of this
                    website, in which interaction will be expected with the user, it is necessary to provide certain
                    personal information. By providing this information you agree that Magnifi may use the data given in
                    the narrow confines of the German data protection law. This data may not be disclosed to third
                    parties without your express consent.
                  </p>

                  <p>
                    Magnifi points out that data transmission over the internet can be subject to security gaps.
                    Complete protection of data against access by third parties is not possible. For more information on
                    privacy, please see the privacy policy. Through the use of the imprint obligation framework for
                    published contact data by third parties, the sending of unsolicited advertisements and information
                    materials is hereby expressly excluded. The operators of the pages will take all required legal
                    steps in case of unsolicited promotional information, such as spam emails.
                  </p>

                  <h4>Contact & Communication</h4>
                  <p>
                    Users contacting this website and/or its owners do so at their own discretion and provide any such
                    personal details requested at their own risk. Your personal information is kept private and stored
                    securely until a time it is no longer required or has no use, as detailed in the Data Protection Act
                    1998. Every effort has been made to ensure a safe and secure form to email submission process but
                    advise users using such form to email processes that they do so at their own risk.
                  </p>
                  <p>
                    This website and its owners use any information submitted to provide you with further information
                    about the products/services they offer or to assist you in answering any questions or queries you
                    may have submitted. This includes using your details to subscribe you to any email newsletter
                    program the website operates but only if this was made clear to you and your express permission was
                    granted when submitting any form to the email process. Or whereby you the consumer have previously
                    purchased from or enquired about purchasing from the company a product or service that the email
                    newsletter relates to. This is by no means an entire list of your user rights in regard to receiving
                    email marketing material. Your details are not passed on to any third parties.
                  </p>

                  <h4>Privacy Statement for the Use of Google Analytics</h4>
                  <p>
                    This website uses Google Analytics, a web analytics service provided by Google Inc. (“Google”).
                    Google Analytics uses “cookies “, text files that are stored on your computer, to help analyze how
                    users use the website. The information generated by the cookie about your use of this website is
                    usually transferred to a server of Google in the USA and stored there. Through the activation of the
                    IP anonymous function on this website, your IP address will be truncated within Member states of
                    India or other parties which are bound to the Agreement on the IndiaEconomic Area.
                  </p>

                  <p>
                    Only in exceptional cases, the complete IP address will be transmitted to a Google server in the USA
                    and shortened there. On behalf of the operator of this website, Google will use this information to
                    evaluate your use of the website, compiling reports on website activity and providing other
                    internet-related services to the website operator. It is understood that your IP address is not
                    associated with any other data held by Google Analytics.
                  </p>

                  <p>
                    You may refuse the use of cookies by selecting the appropriate settings on your browser; we would
                    point out, however, that you may then be unable to use all the features on our website. You can also
                    prevent the collection of data generated by the cookie, concerning your use of the website
                    (including your IP address) to Google and the processing of this data by Google, by downloading and
                    installing the available plug-in below: http://tools.google.com/dlpage/gaoptout?hl=de.
                  </p>

                  <h4>Privacy Policy for Usage of Facebook – Plug-ins (Like-Button)</h4>
                  <p>
                    Our pages integrate Facebook plug-ins; the company is located at 1601 South California Avenue, Palo
                    Alto, CA 94304, USA. By using the Facebook plug-ins, you agree to the presence of the Facebook logo
                    or the “Like-Button” (“Like”) on our side. You can find an overview of Facebook – Plug-Ins here:
                    http://developers.facebook.com/docs/plugins/.
                  </p>

                  <p>
                    When you visit our pages, the plug-in establishes a connection between your browser and the Facebook
                    server on which it is established. Facebook thereby obtains the information that you have on your IP
                    Address when visiting our website. If you click on the Facebook “Like” button while you are logged
                    into your Facebook account, you can link the content of our pages on your Facebook profile. Thus,
                    Facebook can correlate your visit to our site with your user account. We point out that as providers
                    of the sites we will share no knowledge of the content of the transmitted data and use them through
                    Facebook. For more information, please refer to the privacy statement of Facebook under
                    http://dede.facebook.com/policy.php.
                  </p>

                  <p>
                    If you do not wish Facebook to assign our pages to your Facebook – account, please log out from your
                    Facebook account before visiting us.
                  </p>

                  <h4>Privacy Statement for using Google</h4>

                  <p>Information collection and dissemination:</p>

                  <p>
                    The Google +1 button is used to publish information worldwide. The Google +1 button lets you and
                    other users get personalized content from Google and our partners. Google will store both the
                    information that you have tagged as +1, as well as information about the page that you viewed when
                    you click on +1. Your +1′s can be shown as evidence along with your name and profile photo in Google
                    services, such as in search results or in your Google profile, or elsewhere on websites and internet
                    ads.
                  </p>

                  <p>
                    Google records information about your +1- activities to improve Google’s services for you and
                    others. In order to use the Google +1 button, you need a globally visible, public Google profile,
                    which must contain at least the chosen name for the profile. This name is used in all Google
                    services. In some cases, this name can also substitute any other name that you used when sharing
                    content under your Google account. The identity of your Google profile can be shown to users who
                    know your email address or other identifying information about you.
                  </p>

                  <h4>Using the collected information:</h4>

                  <p>
                    In addition to the aforementioned uses, the information you provide is in accordance with the
                    applicable Google privacy policies. Google may release aggregate statistics on the activities of the
                    users of +1 service to partners such as publishers, advertisers or linked sites.
                  </p>

                  <h4>Privacy Statement for Twitter usage:</h4>
                  <p>
                    Functions of Twitter as a service are integrated on our website. These functions are offered by
                    Twitter Inc., Twitter, Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA. Through the
                    use of Twitter and its “re-tweet” function, the web pages you visit are linked to your Twitter
                    account and made known to other users. This data is also transmitted to Twitter.
                  </p>

                  <p>
                    We point out that as providers of the sites, we have no knowledge of the content of the transmitted
                    data and how Twitter uses it. For more information, please refer to the privacy statement of Twitter
                    at http://twitter.com/privacy.
                  </p>

                  <p>
                    Your privacy preferences at Twitter can be changed by accessing:
                    http://twitter.com/account/settings.
                  </p>

                  <h4>Privacy Statement for YouTube/ Vimeo usage:</h4>
                  <p>
                    Uploading of Video will be done from Video Link provided from YouTube, YouTube, LLC 901 Cherry Ave.
                    San Bruno, CA 94066 USA or Vimeo, Vimeo LLC New York NY, 10011. We are not having any Relation to
                    the videos, Data is coming from YouTube/Vimeo and we are not having knowledge of the content they
                    are providing. If someone has posted your personal information or uploaded a video of you without
                    your consent, start by contacting the uploader(YouTube or Vimeo) and asking them to remove the
                    content.
                  </p>

                  <p>
                    For more information, please refer to the privacy statement of YouTube at
                    https://www.youtube.com/static?template=privacy_guidelines and Vimeo at https://vimeo.com/privacy.
                  </p>

                  <p>
                    To change privacy preference at YouTube visit https://www.youtube.com/account_privacy and at Vimeo
                    visit https://vimeo.com/help/faq/managing-your-videos/privacy-settings.
                  </p>

                  <h4>Contact & Communication</h4>
                  <p>
                    Users contacting this website and/or its owners do so at their own discretion and provide any such
                    personal details requested at their own risk. Your personal information is kept private and stored
                    securely until a time it is no longer required or has no use, as detailed in the Data Protection Act
                    1998. Every effort has been made to ensure a safe and secure form to email submission process but
                    advise users using such form to email processes that they do so at their own risk.
                  </p>

                  <p>
                    This website and its owners use any information submitted to provide you with further information
                    about the products/services they offer or to assist you in answering any questions or queries you
                    may have submitted. This includes using your details to subscribe you to any email newsletter
                    program the website operates but only if this was made clear to you and your express permission was
                    granted when submitting any form to the email process. Or whereby you the consumer have previously
                    purchased from or enquired about purchasing from the company a product or service that the email
                    newsletter relates to. This is by no means an entire list of your user rights in regard to receiving
                    email marketing material. Your details are not passed on to any third parties.
                  </p>

                  <h4>Email Newsletter</h4>
                  <p>
                    This website operates an email newsletter program, used to inform subscribers about products and
                    services supplied by this website. Users can subscribe through an online automated process should
                    they wish to do so but do so at their own discretion. Some subscriptions may be manually processed
                    through prior written agreements with the user.
                  </p>

                  <p>
                    Subscriptions are taken in compliance with India Spam Laws detailed in the Privacy and Electronic
                    Communications Regulations 2003. All personal details relating to subscriptions are held securely
                    and in accordance with the Data Protection Act 1998. No personal details are passed on to third
                    parties nor shared with companies/people outside of the company that operates this website. Under
                    the Data Protection Act 1998, you may request a copy of personal information held about you by this
                    website’s email newsletter program. A small fee will be payable. If you would like a copy of the
                    information held on you please write to the business address at the bottom of this policy.
                  </p>

                  <p>
                    Email marketing campaigns published by this website or its owners may contain tracking facilities
                    within the actual email. Subscriber activity is tracked and stored in a database for future analysis
                    and evaluation. Such tracked activity may include; the opening of emails, forwarding of emails, the
                    clicking of links within the email content, times, dates and frequency of activity [this is by no
                    far a comprehensive list].
                  </p>

                  <p>
                    This information is used to refine future email campaigns and supply the user with more relevant
                    content based on their activity.
                  </p>

                  <p>
                    In compliance with India Spam Laws and the Privacy and Electronic Communications Regulations 2003
                    subscribers are given the opportunity to unsubscribe at any time through an automated system. This
                    process is detailed at the footer of each email campaign. If an automated un-subscription system is
                    unavailable clear instructions on how to unsubscribe will be detailed instead.
                  </p>

                  <h4>External Links</h4>
                  <p>
                    Although this website only looks to include quality, safe and relevant external links users should
                    always adopt a policy of caution before clicking any external web links mentioned throughout this
                    website.
                  </p>

                  <p>
                    The owners of this website cannot guarantee or verify the contents of any externally linked website
                    despite their best efforts. Users should therefore note they click on external links at their own
                    risk and this website and its owners cannot be held liable for any damages or implications caused by
                    visiting any external links mentioned. Adverts and Sponsored Links This website may contain
                    sponsored links and adverts. These will typically be served through our advertising partners, who
                    may have detailed privacy policies relating directly to the adverts they serve.
                  </p>

                  <p>
                    Clicking on any such adverts will send you to the advertisers’ website through a referral program
                    that may use cookies and will track the number of referrals sent from this website. This may include
                    the use of cookies which may, in turn, be saved on your computer’s hard drive. Users should
                    therefore note they click on sponsored external links at their own risk and this website and its
                    owners cannot be held liable for any damages or implications caused by visiting any external links
                    mentioned. Social Media Platforms Communication, engagement and actions taken through external
                    social media platforms that this website and its owners participate in are customs to the terms and
                    conditions as well as the privacy policies held with each social media platform respectively.
                  </p>

                  <p>
                    Users are advised to use social media platforms wisely and communicate/engage upon them with due
                    care and caution in regard to their own privacy and personal details. This website nor its owners
                    will ever ask for personal or sensitive information through social media platforms and encourage
                    users wishing to discuss sensitive details to contact them through primary communication channels
                    such as by telephone or email.
                  </p>

                  <p>
                    This website may use social sharing buttons that help share web content directly from web pages to
                    the social media platform in question. Users are advised before using such social sharing buttons
                    that they do so at their own discretion and note that the social media platform may track and save
                    your request to share a web page respectively through your social media platform account.
                  </p>

                  <h4>Shortened Links in Social Media</h4>
                  <p>
                    This website and its owners through their social media platform accounts may share web links to
                    relevant web pages. By default, some social media platforms shorten lengthy URLs [web addresses]
                    (this is an example: http://bit.ly/zyVUBo).
                  </p>

                  <p>
                    Users are advised to take caution and good judgment before clicking any shortened URLs published on
                    social media platforms by this website and its owners. Despite the best efforts to ensure only
                    genuine URLs are published, many social media platforms are prone to spam and hacking and therefore
                    this website and its owners cannot be held liable for any damages or implications caused by visiting
                    any shortened links.
                  </p>

                  <h4>Resources & Further Information</h4>
                  <ul>
                    <li>Data Protection Act 1998</li>
                    <li>Privacy and Electronic Communications Regulations 2003</li>
                    <li>Privacy and Electronic Communications Regulations 2003 – The Guide</li>
                    <li>Twitter Privacy Policy</li>
                    <li>You Tube</li>
                    <li>Vimeo</li>
                    <li>Facebook Privacy Policy</li>
                    <li>Google Privacy Policy</li>
                    <li>Linkedin Privacy Policy</li>
                    <li>Mailchimp Privacy Policy</li>
                    <li>By: Magnifi</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content body end */}
      </main>
    </>
  );
}
