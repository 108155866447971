import React from "react";

function Info() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_667_2)">
        <path
          fill="#000"
          d="M10 20a10 10 0 100-20 10 10 0 000 20zm1.162-11.765l-1.25 5.881c-.087.425.037.667.38.667.243 0 .61-.088.858-.308l-.11.52c-.359.433-1.15.748-1.831.748-.879 0-1.253-.528-1.01-1.65L9.12 9.76c.08-.367.008-.499-.359-.588L8.2 9.07l.102-.476 2.863-.359h-.002zM10 6.875a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_667_2">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Info;
