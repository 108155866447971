import React from "react";
import Modal from "react-modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { trimClipValidation } from "../../../utils/validations";
import moment from "moment";
import CloseModal from "../../../components/icons/CloseModal";
import { get } from "lodash";

const TrimClip = (props) => {
  const { trimOpen, setTrimOpen, clip, loder, streamDetails, trimClip } = props;
  const initialState = {
    start_time: new Date((clip?.start_time || "") * 1000).toISOString().substring(11, 23).replace(".", ":"),
    end_time: new Date((clip?.end_time || "") * 1000).toISOString().substring(11, 23).replace(".", ":"),
    // playback_speed: 1,
    title: "",
  };
  const getTimeInSeconds = (timeString) => {
    // convert time into seconds
    let [hours, minutes, seconds, milliseconds] = timeString.split(":");
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds) + parseInt(milliseconds) / 1000;
  };
  const handleSubmit = async ({ start_time, end_time, title, playback_speed }) => {
    let startTime = "";
    let endTime = "";
    startTime = getTimeInSeconds(start_time);
    endTime = getTimeInSeconds(end_time);
    const payload = {
      end_time: endTime,
      env: "production",
      isMediaLive: "false",
      server_address: streamDetails.server_address,
      start_time: startTime,
      streamId: clip.streamId,
      // playback_speed: playback_speed,
      title: title,
      video_url: clip.videoUrl,
      zee: false,
      filter: clip?.filter || "",
      clipData: clip?.clipData || {},
    };
    if (get(streamDetails, "glacierRecoveredStream", false) || get(streamDetails, "status", 0) === 1) {
      payload.sourceUrl = true;
      payload.videoUrl = get(streamDetails, "s3RecordedUrl", "");
    }

    const value = await trimClip(payload);
    closeModal();
  };

  const closeModal = () => {
    setTrimOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={trimOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Trim Clip"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Trim Clip</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={trimClipValidation}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container row">
                    <div className="form-group col-md-12">
                      <label className="form-label">
                        Title <span className="required">*</span>
                      </label>
                      <Field className="form-control" name="title" type="text" placeholder="Enter title" />
                      {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Start Time <span className="required">*</span>
                      </label>
                      <Field className="form-control" name="start_time" type="text" placeholder="HH:mm:ss" />
                      {touched.start_time && errors.start_time && (
                        <div className="invalid-feedback">{errors.start_time}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        End Time <span className="required">*</span>
                      </label>
                      <Field className="form-control" name="end_time" type="text" placeholder="HH:mm:ss" />
                      {touched.end_time && errors.end_time && <div className="invalid-feedback">{errors.end_time}</div>}
                    </div>
                    {/* <div className="form-group col-md-6">
                      <label className="form-label">
                        End Time <span className="required">*</span>
                      </label>
                      <Field className="form-control" as="select" name="playback_speed">
                        <option value={1}>1x</option>
                        <option value={0.75}>0.75x</option>
                        <option value={0.5}>0.5x</option>
                      </Field>
                    </div> */}
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Save
                    {loder && (
                      <Spin
                        size="large"
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: "#000", marginLeft: 50 }} spin />}
                        className="loader__full"
                      ></Spin>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default TrimClip;
