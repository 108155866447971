import * as React from "react";

const ArrowDownWComponent = (props) => (
  <svg width={14} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m1.666 1 5.333 5.333L12.333 1"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowDownWComponent;
