import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
      <path
        fill="#4676ED"
        d="M47.245 23.619C47.245 10.574 36.671 0 23.627 0 10.582 0 .006 10.574.006 23.619c0 11.788 8.636 21.56 19.928 23.331V30.445h-5.997V23.62h5.997v-5.203c0-5.92 3.527-9.189 8.923-9.189 2.585 0 5.287.462 5.287.462V15.5h-2.978c-2.936 0-3.849 1.82-3.849 3.688v4.43h6.55l-1.046 6.826h-5.504V46.95c11.288-1.77 19.927-11.543 19.927-23.331z"
      ></path>
      <path
        fill="#fff"
        d="M32.818 30.445l1.046-6.826h-6.55v-4.43c0-1.869.913-3.688 3.849-3.688h2.978V9.689s-2.702-.462-5.287-.462c-5.396 0-8.923 3.268-8.923 9.189v5.203h-5.997v6.826h5.997V46.95a23.786 23.786 0 007.383 0V30.445h5.504z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
