import { Record } from "immutable";
import { assign } from "lodash";
// import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";

import { INIT } from "../../constants/phase";
import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const LOGIN_USER = "magnifi/user/LOGIN_USER";
export const OPS_LOGIN_USER = "magnifi/user/OPS_LOGIN_USER";
export const ME_DETAILS = "magnifi/user/ME_DETAILS";
export const FETCH_USER_DETAILS = "magnifi/user/FETCH_USER_DETAILS";
export const FETCH_FILTER_USER = "magnifi/user/FETCH_FILTER_USER";
export const ADD_USERS = "magnifi/user/ADD_USERS";
export const DELETE_USERS = "magnifi/user/DELETE_USERS";
export const UPDATE_USERS = "magnifi/user/UPDATE_USERS";
export const CREATE_ACCOUNT = "magnifi/user/CREATE_ACCOUNT";
export const GET_USER = "magnifi/user/GET_USER";
export const FETCH_USER_DATA = "magnifi/user/FETCH_USER_DATA";
export const GET_APPLIED_FILTER = "magnifi/user/GET_APPLIED_FILTER";
export const CREATE_USER_PROFILE = "magnifi/user/CREATE_USER_PROFILE";
export const ADD_SOCIALMEDIA_PLATFORMS = "magnifi/user/ADD_SOCIALMEDIA_PLATFORMS";
export const CREATE_STORAGE = "magnifi/user/CREATE_STORAGE";
export const EDIT_STORAGE = "magnifi/user/EDIT_STORAGE";
export const GET_STORAGE = "magnifi/user/GET_STORAGE";
export const GET_STORAGE_LIST = "magnifi/user/GET_STORAGE_LIST";
export const GET_USER_BY_STORAGE_SUBTITLE = "magnifi/user/GET_USER_BY_STORAGE_SUBTITLE";
export const DELETE_STORAGE = "magnifi/user/DELETE_STORAGE";
export const FETCH_LOGGER_DETAILS = "mangnifi/user/FETCH_LOGGER_DETAILS";
export const UPDATE_LOGGER_DETAILS = "mangnifi/user/UPDATE_LOGGER_DETAILS";
export const GET_CLIENT_SETTING = "mangnifi/user/GET_CLIENT_SETTING";
export const UPDATE_CLIENT_SETTING = "mangnifi/user/UPDATE_CLIENT_SETTING";
export const UPDATE_TOKEN = "magnifi/user/UPDATE_TOKEN";
export const REMOVE_IP = "magnifi/user/REMOVE_IP";
export const GET_USER_PROCESSING_LIMIT = "mangnifi/user/GET_USER_PROCESSING_LIMIT";
export const CLIENT_WEBHOOK_PUBLISH = "mangnifi/user/CLIENT_WEBHOOK_PUBLISH";
export const GET_PACKAGES = "mangnifi/user/GET_PACKAGES";
export const GET_USERS_BY_USER_IDS = "mangnifi/user/GET_USERS_BY_USER_IDS";
export const FETCH_ACCESSCONTROL = "mangnifi/user/FETCH_ACCESSCONTROL";
export const CREATE_STORAGE_ADMIN = "magnifi/user/CREATE_STORAGE_ADMIN";
export const DELETE_STORAGE_ADMIN = "magnifi/user/DELETE_STORAGE_ADMIN";
export const EDIT_STORAGE_ADMIN = "magnifi/user/EDIT_STORAGE_ADMIN";
export const GET_SSO_URL = "mangnifi/user/GET_SSO_URL";

export const GET_FOLDER = "mangnifi/user/GET_FOLDER";
export const STORAGE_VALIDATION = "mangnifi/user/STORAGE_VALIDATION";
export const UPDATE_STORAGE_MULTIPLE_FOLDER_PATH = "mangnifi/user/UPDATE_STORAGE_MULTIPLE_FOLDER_PATH";
export const PROCESS_LIST = "mangnifi/user/PROCESS_LIST";
export const CANCEL_PROCESS = "mangnifi/user/CANCEL_PROCESS";
export const IMPORT_S3_GLACIER_STREAM = "mangnifi/user/IMPORT_S3_GLACIER_STREAM";
export const YOUTUBE_DOWNLOAD_CSV = "mangnifi/user/YOUTUBE_DOWNLOAD_CSV";
export const ACTIVATE_USER = "mangnifi/user/ACTIVATE_USER";
export const UPDATE_USER = "mangnifi/user/UPDATE_USER";
export const GET_PUBLISH_HISTORY = "mangnifi/user/GET_PUBLISH_HISTORY";
export const ADD_PUBLISH_NOTIFICATION = "mangnifi/user/ADD_PUBLISH_NOTIFICATION";
export const DELETE_USER_PROFILE = "mangnifi/user/DELETE_USER_PROFILE";
export const EDIT_USER_PROFILE = "mangnifi/user/EDIT_USER_PROFILE";
export const CREATE_ILLUSTO_USER = "magnifi/user/CREATE_ILLUSTO_USER";
export const RESYNC_ILLUSTO_RESOURCE = "magnifi/user/RESYNC_ILLUSTO_RESOURCE";
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  error: null,
  user: {},
  isSuccess: false,
  message: "",
  loginError: "",
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    // const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    const token = localStorage.getItem("authToken");
    super(assign({ token }, desiredValues));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const loginUser = (payload) => {
  return {
    type: LOGIN_USER,
    payload: api.loginUser(payload),
  };
};

export const opsloginUser = (payload) => {
  return {
    type: OPS_LOGIN_USER,
    payload: api.opsloginUser(payload),
  };
};

export const createStorage = (payload) => {
  return {
    type: CREATE_STORAGE,
    payload: api.createStorage(payload),
  };
};
export const createStorageAdmin = (payload) => {
  return {
    type: CREATE_STORAGE_ADMIN,
    payload: api.createStorageAdmin(payload),
  };
};
export const getStorage = (payload) => {
  return {
    type: GET_STORAGE,
    payload: api.getStorage(payload),
  };
};
export const getStorageList = (payload) => {
  return {
    type: GET_STORAGE_LIST,
    payload: api.getStorageList(payload),
  };
};
export const getAssignedUserOfStorage = (payload) => {
  return {
    type: GET_USER_BY_STORAGE_SUBTITLE,
    payload: api.getUserOfStorage(payload),
  };
};

export const deleteStorage = (payload) => {
  return {
    type: DELETE_STORAGE,
    payload: api.deleteStorage(payload),
  };
};
export const deleteStorageAdmin = (payload) => {
  return {
    type: DELETE_STORAGE_ADMIN,
    payload: api.deleteStorageAdmin(payload),
  };
};
export const editStorageDetails = (payload) => {
  return {
    type: EDIT_STORAGE_ADMIN,
    payload: api.editStorageDetails(payload),
  };
};
export const editStorageDetailsAdmin = (payload) => {
  return {
    type: EDIT_STORAGE,
    payload: api.editStorageDetailsAdmin(payload),
  };
};
export const fetchMe = (payload) => {
  return {
    type: ME_DETAILS,
    payload: api.me(payload),
  };
};

export const getAppliedFilter = (payload) => {
  return {
    type: GET_APPLIED_FILTER,
    payload: api.getAppliedFilter(payload),
  };
};
export const createAccount = (payload) => {
  return {
    type: CREATE_ACCOUNT,
    payload: api.createAccount(payload),
  };
};

export const fetchUserDetails = (credentials) => {
  return {
    type: FETCH_USER_DETAILS,
    payload: api.getUserDetails(credentials),
  };
};
export const fetchFilterUser = (credentials) => {
  return {
    type: FETCH_FILTER_USER,
    payload: api.getFilterUser(credentials),
  };
};

export const addUsers = (credentials) => {
  return {
    type: ADD_USERS,
    payload: api.addUsers(credentials),
  };
};

export const deleteUsers = (userId) => {
  return {
    type: DELETE_USERS,
    payload: api.deleteUsers(userId),
  };
};

export const updateUsers = (credentials) => {
  return {
    type: UPDATE_USERS,
    payload: api.updateUsers(credentials),
  };
};

export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    payload: api.updateUser(payload),
  };
};

export const getUser = (userId) => {
  return {
    type: GET_USER,
    payload: api.getUser(userId),
  };
};

export const createIllustoUser = (payload) => {
  return {
    type: CREATE_ILLUSTO_USER,
    payload: api.createIllustoUser(payload),
  };
};

export const resyncIllustoResource = (payload) => {
  return {
    type: RESYNC_ILLUSTO_RESOURCE,
    payload: api.resyncIllustoResource(payload),
  };
};
export const createUserProfile = (userId) => {
  return {
    type: CREATE_USER_PROFILE,
    payload: api.createUserProfile(userId),
  };
};

export const editUserProfile = (userId) => {
  return {
    type: EDIT_USER_PROFILE,
    payload: api.editUserProfile(userId),
  };
};
export const deleteUserProfile = (userId) => {
  return {
    type: DELETE_USER_PROFILE,
    payload: api.deleteUserProfile(userId),
  };
};
export const addSocialMediaPlatforms = (userId) => {
  return {
    type: ADD_SOCIALMEDIA_PLATFORMS,
    payload: api.addSocialMediaPlatforms(userId),
  };
};

export const fetchLoggerDetails = (payload) => {
  return {
    type: FETCH_LOGGER_DETAILS,
    payload: api.fetchLoggerDetails(payload),
  };
};
export const updateLoggerDetails = (payload) => {
  return {
    type: UPDATE_LOGGER_DETAILS,
    payload: api.updateLoggerDetails(payload),
  };
};
export const getClientSetting = (payload) => {
  return {
    type: GET_CLIENT_SETTING,
    payload: api.getClientSetting(payload),
  };
};
export const updateClientSetting = (payload) => {
  return {
    type: UPDATE_CLIENT_SETTING,
    payload: api.updateClientSetting(payload),
  };
};
// function for updating token for login limit functionality
export const updateToken = (credentials) => {
  return {
    type: UPDATE_TOKEN,
    payload: api.updateToken(credentials),
  };
};
// function for removing token for login limit functionality
export const removeIp = (credentials) => {
  return {
    type: REMOVE_IP,
    payload: api.removeIp(credentials),
  };
};

// To get the status of added streams by Admin and its users

export const getUserProcessingLimit = (payload) => {
  return {
    type: GET_USER_PROCESSING_LIMIT,
    payload: api.getUserProcessingLimit(payload),
  };
};
export const clientWebHookPublish = (payload) => {
  return {
    type: CLIENT_WEBHOOK_PUBLISH,
    payload: api.clientWebHookPublish(payload),
  };
};

export const getPackages = (payload) => {
  return {
    type: GET_PACKAGES,
    payload: api.getPackages(payload),
  };
};

export const getUsersByUserIds = (payload) => {
  return {
    type: GET_USERS_BY_USER_IDS,
    payload: api.getUsersByUserIds(payload),
  };
};
export const getFolder = (payload) => {
  return {
    type: GET_FOLDER,
    payload: api.getFolder(payload),
  };
};

export const fetchAccessControl = (payload) => {
  return {
    type: FETCH_ACCESSCONTROL,
    payload: api.fetchAccessControl(payload),
  };
};
export const storageValidation = (payload) => {
  return {
    type: STORAGE_VALIDATION,
    payload: api.storageValidation(payload),
  };
};
export const updateStorageMultipleFolderPath = (payload) => {
  return {
    type: UPDATE_STORAGE_MULTIPLE_FOLDER_PATH,
    payload: api.updateStorageMultipleFolderPath(payload),
  };
};
export const getProcessList = (payload) => {
  return {
    type: PROCESS_LIST,
    payload: api.getProcessList(payload),
  };
};

export const cancelProcess = (payload) => {
  return {
    type: CANCEL_PROCESS,
    payload: api.cancelProcess(payload),
  };
};

export const getSSOUrl = (payload) => {
  return {
    type: GET_SSO_URL,
    payload: api.getSSOUrl(payload),
  };
};

export const importS3GlacierStreams = (payload) => {
  return {
    type: IMPORT_S3_GLACIER_STREAM,
    payload: api.importS3GlacierStreams(payload),
  };
};

export const getPublishHistory = (payload) => {
  return {
    type: GET_PUBLISH_HISTORY,
    payload: api.getPublishHistory(payload),
  };
};
export const addPublishNotification = (payload) => {
  return {
    type: ADD_PUBLISH_NOTIFICATION,
    payload: api.addPublishNotification(payload),
  };
};
export const getDownloadVideosCSV = (payload) => {
  return {
    type: YOUTUBE_DOWNLOAD_CSV,
    payload: api.getDownloadVideosCSV(payload),
  };
};

export const getYoutubeDownloadVideosData = (payload) => {
  return {
    type: YOUTUBE_DOWNLOAD_CSV,
    payload: api.getYoutubeDownloadVideosData(payload),
  };
};

/***********************************
 * Epics
 ***********************************/

const loginUserEpic = (action$) =>
  action$.ofType(LOGIN_USER).mergeMap((action) => {
    return true;
  });

export const userEpic = combineEpics(loginUserEpic);
