import * as React from "react";

const SvgComponent = (props) => (
  <svg width={22} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 0a1 1 0 0 1 1 1v4.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L16 10.8V15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14ZM7 4v3H4v2h2.999L7 12h2l-.001-3H12V7H9V4H7Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
