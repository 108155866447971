import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseModal from "../../../components/icons/CloseModal";
import { Formik, Form, Field } from "formik";
import { notification, Spin, Radio, Select, Checkbox, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { addFolderValidation } from "../../../utils/validations";
import { aspect_ratio, autoFlipCategories } from "../../../config";
import moment from "moment";
import AlertDialog from "../../../components/common/Modals/AlertDialog";
import { useOutletContext } from "react-router-dom";
import { FancodeUsers } from "../../../constants/phase";

const CreatePromo = (props) => {
  const {
    getAspectRatios,
    streamDetails,
    generateAutoFlip,
    promoId,
    folderArray,
    setFolderArray,
    createPreview,
    closePromoModal,
    promoIsOpen,
    setPromoIsOpen,
    id,
    createFolder,
    setNotify,
    notify,
    seteventDisable,
    isAlertOpen,
    setIsAlertOpen,
    alertMessage,
    setAlertMessage,
  } = props;
  const NewHost = process.env.REACT_APP_WEBHOOK_SERVER;
  const { clientSetting, user } = useOutletContext();

  const [loder, setLoder] = useState(false);
  const [promoRatio, setPromoRatio] = useState("16:9");
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [transition, setTransition] = useState(false);
  const [disablePromoBtn, setDisablePromoBtn] = useState(false);

  const [initialState] = useState({
    title: "",
    aspectRatio: "16:9",
  });
  //DO NOT REMOVE
  // useEffect(() => {
  //   const { unSyncClips } = checkClipSync();
  //   const OverwriteClips = unSyncClips.filter(({ aspectRatio }) => aspectRatio !== "16:9");
  //   setDisablePromoBtn(false);
  //   if (OverwriteClips.length) {
  //     setDisablePromoBtn(true);
  //     notification["error"]({ message: "ARs other than 16:9 can't be synced" });
  //   }
  // }, [promoRatio]);

  useEffect(() => {
    if (promoId == "") {
      seteventDisable(true);
    } else {
      seteventDisable(false);
    }
  }, [promoId]);

  const generateAspectRatio = async (aspectRatio, folderId, unSyncClips, title) => {
    let coordinate = aspect_ratio.find((o) => o?.value === aspectRatio)?.coordinate;
    await Promise.all(
      unSyncClips.map(async (clip) => {
        if (
          autoFlipCategories.includes(streamDetails?.category) &&
          (FancodeUsers.includes(user?.userId) || FancodeUsers.includes(user?.isBillingAdmin)
            ? streamDetails?.category !== "cricket"
            : true)
        ) {
          const { value } = await generateAutoFlip({
            v_url: clip.videoUrl,
            id: clip._id,
            streamId: clip.streamId,
            video_type: streamDetails?.category,
            vertical_ar: aspectRatio,
            sId: clip.streamId,
            webhook_url: `${NewHost}/sony_get_ai_slate_result`,
            clipTitle: clip?.title || clip?.episodeTitle || clip?.id || "",
            webhook_param: {
              id: clip._id,
              clipType: "",
              event: "autoFlip",
              aspect_ratio: aspectRatio,
              streamId: clip.streamId,
              promoParams: {
                streamId: id,
                folderId,
                isTransition: transition,
                aspectRatio,
                id: clip._id,
                isPromoVideo: true,
                title,
                type: "promo",
                createdAt: moment().utc(),
              },
              isEntertainment: clientSetting?.appAdditionalPermissions?.isZeeEntertainmentAutoflip || false,
              isCreatePromo: true,
            },
          });
        } else {
          const { value } = await getAspectRatios({
            aspect_ratio: aspectRatio,
            size: coordinate,
            streamId: clip.streamId,
            userId: localStorage.getItem("__uid"),
            v_url: clip.videoUrl,
            clipTitle: clip?.title || clip?.episodeTitle || clip?.id || "",
            webhook_param: {
              id: clip._id,
              clipType: "",
              event: "croppedVideos",
              aspect_ratio: aspectRatio,
              streamId: clip.streamId,
              promoParams: {
                streamId: id,
                folderId,
                isTransition: transition,
                aspectRatio,
                id: clip._id,
                isPromoVideo: true,
                title,
                type: "promo",
                createdAt: moment().utc(),
              },
              isCreatePromo: true,
            },
            webhook_url: `${NewHost}/sony_get_ai_slate_result`,
          });
        }
      }),
    );
    notification["success"]({
      message: `Process initiated successfully. ${unSyncClips.length} clip${
        unSyncClips.length > 1 ? "s" : ""
      } are not synced in given aspect ratio so it may take some more time`,
    });
  };

  const createPromoPreview = async (id, clips, title, aspectRatio, syncClips, promoDuration) => {
    let data = {
      folderId: id,
      clips: syncClips,
      clipIdArr: clips.map(({ id }) => id),
      isTransition: transition,
      type: "promo",
      webhook_param: { title: title, aspectRatio, isPromoVideo: true },
      totalDuration: promoDuration,
    };
    const { value } = await createPreview(data);
    if (value.status) {
      notification["success"]({ message: value.message });
    }
    if (!value.status) {
      notification["error"]({ message: value.message });
    }
  };

  const handleSubmit = async ({ title, aspectRatio }) => {
    const { promoDuration } = checkClipSync();
    let isLimitExceeded = promoDuration > clientSetting?.limitation?.maxVideoProcessDuration;
    if (isLimitExceeded && clientSetting?.isProcessingLimit) {
      closePromoModal();
      setIsAlertOpen(true);
      setAlertMessage(
        `Video duration should be less than or equal to ${
          clientSetting?.limitation?.maxVideoProcessDuration || 0
        } second(s).`,
      );
    } else {
      let isAllHaveSameAspectRatio = folderArray.every((clip) => {
        const mainAspectMatch = clip.aspectRatio === aspectRatio;
        const editedVideoMatch = clip.editedVideos.some((editedVideo) => editedVideo.aspect_ratio === aspectRatio);

        return mainAspectMatch || editedVideoMatch;
      });
      if (!isAllHaveSameAspectRatio) {
        notification.warning({ message: `Select all clips with the same aspect ratio as the selected one.` });
        return;
      } else {
        setLoder(true);
        const { value } = await createFolder({
          streamId: id,
          title: title,
          type: "promo",
          aspectRatio,
          clips: folderArray.map(({ _id }) => _id),
        });
        if (value.status) {
          const { unSyncClips, syncClips, isAllClipSynced } = checkClipSync();
          if (unSyncClips.length) {
            await generateAspectRatio(aspectRatio, value.data._id, unSyncClips, title);
          } else {
            await createPromoPreview(value.data._id, folderArray, title, aspectRatio, syncClips, promoDuration);
          }
          closePromoModal();
          setNotify(false);
          setLoder(false);
          setPromoIsOpen(false);
          setFolderArray([]);
        } else if (value.message === "Title already exists") {
          setLoder(false);
          setNotify(true);
        } else {
          notification["error"]({ message: value.message, duration: 6 });
        }
      }
    }
  };
  const handlChange = (setFieldValue, e) => {
    if (e?.target?.name) {
      setFieldValue(e.target.name, e.target.value);
    } else {
      setFieldValue("aspectRatio", e);
    }
    setNotify(false);
  };

  const applyTransition = (e) => {
    setTransition(!transition);
  };

  const checkClipSync = () => {
    let unSyncClips = [];
    let syncClips = [];
    let promoDuration = 0;
    let isAllClipSynced = true;
    folderArray.map((clip) => {
      let clipData = clip?.editedVideos?.find(({ aspect_ratio }) => aspect_ratio === promoRatio);
      if (clip.aspectRatio == promoRatio) {
        syncClips.push(clip.videoUrl);
        promoDuration += clip.duration || 0;
      } else if (clipData) {
        syncClips.push(clipData.video_url);
        promoDuration += clipData.duration || 0;
      } else {
        unSyncClips.push(clip);
        isAllClipSynced = false;
      }
    });
    return { unSyncClips, syncClips, isAllClipSynced, promoDuration };
  };

  const changePromoRation = (value) => {
    setPromoRatio(value);
  };

  return (
    <>
      <Modal
        isOpen={promoIsOpen}
        onRequestClose={closePromoModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        portalClassName="react-modal"
        overlayClassName="modal"
        contentLabel="Create Promo"
        className="modal-dialog modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Create Promo</h5>
            <button type="button" onClick={closePromoModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={addFolderValidation} onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-group">
                    <label className="form-label">
                      Title <span className="required">*</span>
                    </label>
                    <Field
                      className="form-control"
                      name="title"
                      type="text"
                      value={values.title}
                      onChange={(e) => handlChange(setFieldValue, e)}
                    />
                    {notify && <div className="invalid-feedback">Promo Already Exists!</div>}
                    {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Aspect Ratio <span className="required">*</span>
                    </label>
                    <select
                      className="custom-select form-control"
                      name="aspectRatio"
                      value={values.aspectRatio}
                      onChange={(e) => {
                        handlChange(setFieldValue, e.target.value);
                        changePromoRation(e.target.value);
                      }}
                    >
                      {aspect_ratio &&
                        aspect_ratio.map((ratio) => {
                          return (
                            <option value={ratio.value} key={ratio.key}>
                              {ratio.value}
                            </option>
                          );
                        })}
                    </select>
                    {touched.aspectRatio && errors.aspectRatio && (
                      <div className="invalid-feedback">{errors.aspectRatio}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <Tooltip
                      title="Transition should be added for more than 2 clips"
                      visible={folderArray.length < 3}
                      placement="bottomLeft"
                    >
                      <Checkbox
                        value={""}
                        checked={transition}
                        disabled={folderArray.length < 3}
                        className="text-capitalize"
                        onChange={applyTransition}
                      >
                        Apply Transition
                      </Checkbox>
                    </Tooltip>
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closePromoModal}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    {loder ? <Spin className="lightLoader-spin" indicator={antIcon} /> : "Create Promo"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default CreatePromo;
