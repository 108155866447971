import { Record } from "immutable";
import { assign } from "lodash";

import { combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const LTWEEKLY_DATA = "home/LTWEEKLY_DATA";
export const LTWEEKLY_DATA_BY_DATE = "home/LTWEEKLY_DATA_BY_DATE";
export const LTWEEKLY_ARCHIVES_DATA = "home/LTWEEKLY_ARCHIVES_DATA";
export const GET_DASH_REPORTS = "home/GET_DASH_REPORTS";
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const ltweeklyData = (value) => {
  return {
    type: LTWEEKLY_DATA,
    payload: api.LTWeeklyData(value),
  };
};

export const getltWeeklyByDate = (value) => {
  return {
    type: LTWEEKLY_DATA_BY_DATE,
    payload: api.getltWeeklyByDate(value),
  };
};

export const getLTWeeklyArchiveList = (value) => {
  return {
    type: LTWEEKLY_ARCHIVES_DATA,
    payload: api.GetLTWeeklyArchiveList(value),
  };
};

export const getDashReports = (value) => {
  return {
    type: GET_DASH_REPORTS,
    payload: api.getDashReports(value),
  };
};

/***********************************
 * Epics
 ***********************************/

export const ltweeklyEpic = combineEpics();
