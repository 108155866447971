class LocalStorage {
  constructor() {
    this.cache = {};
    this.storageContext = window.localStorage;
  }

  getItem(key) {
    if (this.cache[key]) {
      return this.cache[key];
    }

    this.cache[key] = this.storageContext.getItem(key);
    return this.cache[key];
  }

  setItem(key, value) {
    this.cache[key] = value;
    this.storageContext.setItem(key, value);
  }

  removeItem(key) {
    this.cache[key] = null;
    this.storageContext.removeItem(key);
  }

  clear() {
    this.cache = {};
    this.storageContext.clear();
  }
}

export default new LocalStorage();
