import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="21" fill="none" viewBox="0 0 15 21">
      <path
        fill="currentColor"
        d="M14.224 19.42a.77.77 0 01.549.231.794.794 0 010 1.114.77.77 0 01-.549.23L.776 21a.77.77 0 01-.549-.23.794.794 0 010-1.114.77.77 0 01.549-.231l13.448-.004zM7.394.008L7.5 0a.77.77 0 01.51.194.791.791 0 01.259.487l.007.107-.001 14.37 3.85-3.907a.77.77 0 011.01-.076l.088.076a.793.793 0 01.074 1.026l-.075.088-5.17 5.246a.77.77 0 01-1.01.077l-.087-.075-5.176-5.249a.793.793 0 01-.04-1.072.768.768 0 011.05-.118l.087.076 3.847 3.9L6.724.787c0-.4.292-.728.67-.78z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
