import moment from "moment";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  MSTelegram,
  MSLinkedin,
  MSTiktok,
  MSYoutube,
  YoutubeColor,
  TiktokColor,
  LinkedinColor,
  TelegramColor,
} from "../components/icons";

export const INIT = "init";
export const LOADING = "loading";
export const SUCCESS = "success";
export const ERROR = "error";
export const SAVED = "saved";

export const ZEE_PROCESS_VIDEO_THRESHOLD = process.env.ZEE_PROCESS_VIDEO_THRESHOLD || 100;

export const socialPlatforms = [
  {
    name: "Facebook",
    icon: <FacebookIcon />,
  },
  {
    name: "Twitter",
    icon: <TwitterIcon />,
  },
  {
    name: "Instagram",
    icon: <InstagramIcon />,
  },
  {
    name: "Youtube",
    icon: <YoutubeColor />,
  },
  {
    name: "TiKTok",
    icon: <TiktokColor />,
  },
  {
    name: "Linkedin",
    icon: <LinkedinColor />,
  },
  {
    name: "Telegram",
    icon: <TelegramColor />,
  },
];

export const ASPECT_RATIO = {
  "16_9": "16:9",
  "9_16": "9:16",
  "4_3": "4:3",
  "1_1": "1:1",
  "4_5": "4:5",
};

export const SERVER = "dummy:3004";

export const ZEE_EPISODE = "zee_episode";

export const SYCNING_STATUS = {
  PROCESSING: "processing",
  COMPLETED: "completed",
};

export const EVENT_NAME = {
  DYNAMIC: "dynamicCropped",
  STATIC: "croppedVideos",
  AUTO_FLIP: "autoFlip",
};
export const ZEE_CLIENT = "ZEE_AUTO_SHOW";
export const ZEE_EPISODE_CAT = "zee_episode";

export const milliConversion = 1000;

export const secondsToString = (time) => {
  const absoluteTime = Math.abs(time);
  return moment()
    .startOf("day")
    .seconds(absoluteTime || 0)
    .format("HH:mm:ss");
};

export const milliSecondsToString = (time, format) => {
  return moment()
    .startOf("day")
    .milliseconds(time || 0)
    .format(format);
};

export const millisecondsToString = (time) => {
  return moment()
    .startOf("day")
    .milliseconds(time || 0)
    .format("HH:mm:ss");
};
export const formatTime = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const timeStr = moment.utc(duration.asMilliseconds()).format("HH:mm:ss:SSS");
  return timeStr;
};

export const stringToMilli = (timeString) => {
  const segments = timeString.split(":");
  const hasMilliseconds = segments.length === 4;
  const format = hasMilliseconds ? "HH:mm:ss:SSS" : "HH:mm:ss";
  const momentTime = moment(timeString, format);
  const seconds = momentTime.diff(moment().startOf("day"), "milliseconds");
  return seconds;
};

export const stringToSeconds = (timeString) => {
  const momentDuration = moment.duration(timeString);
  return momentDuration.asSeconds();
};

export const convertToMillisecondsOrFormat = (timeString) => {
  const segments = timeString.split(":");
  const hasMilliseconds = segments.length === 4;
  const originalFormat = hasMilliseconds ? "HH:mm:ss:SSS" : "HH:mm:ss";
  const parsedTime = moment(timeString, originalFormat);
  const formattedTime = parsedTime.format("HH:mm:ss:SSS");
  return formattedTime;
};

export const getTimeInSeconds = (timeString) => {
  // convert time into seconds
  let [hours, minutes, seconds, milliseconds] = timeString.split(":");
  return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds) + parseInt(milliseconds) / 1000;
};
export const calculateActualDuration = (stream, clip) => {
  if (stream?.streamStartTime && stream.fireOn && stream.isLive) {
    const fireOnTimestamp = stream.initialMatchStartTimeOnServer;
    const matchStartTime = stream.config.match_start_time;
    // Calculate the difference in seconds
    const resultInSeconds = matchStartTime - fireOnTimestamp;
    const clipStartTime = secondsToString(clip.initialStartTime - resultInSeconds);
    return clipStartTime;
  } else {
    const clipStartTime = secondsToString(clip.initialStartTime);
    return clipStartTime;
  }
};

export const nameValidator = (strName) => {
  if (strName !== "") {
    strName = strName
      .replace(/ +/g, "")
      .replace(/[{()}]/g, "")
      .replace(/[^a-zA-Z0-9_.]/g, "");
  }
  return strName;
};

export const multiAudioOptions = [
  {
    English: "Afar",
    French: "afar",
    alpha2: "aa",
    "alpha3-b": "aar",
    "alpha3-t": null,
  },
  {
    English: "Abkhazian",
    French: "abkhaze",
    alpha2: "ab",
    "alpha3-b": "abk",
    "alpha3-t": null,
  },
  {
    English: "Achinese",
    French: "aceh",
    alpha2: null,
    "alpha3-b": "ace",
    "alpha3-t": null,
  },
  {
    English: "Acoli",
    French: "acoli",
    alpha2: null,
    "alpha3-b": "ach",
    "alpha3-t": null,
  },
  {
    English: "Adangme",
    French: "adangme",
    alpha2: null,
    "alpha3-b": "ada",
    "alpha3-t": null,
  },
  {
    English: "Adyghe; Adygei",
    French: "adyghé",
    alpha2: null,
    "alpha3-b": "ady",
    "alpha3-t": null,
  },
  {
    English: "Afro-Asiatic languages",
    French: "afro-asiatiques, langues",
    alpha2: null,
    "alpha3-b": "afa",
    "alpha3-t": null,
  },
  {
    English: "Afrihili",
    French: "afrihili",
    alpha2: null,
    "alpha3-b": "afh",
    "alpha3-t": null,
  },
  {
    English: "Afrikaans",
    French: "afrikaans",
    alpha2: "af",
    "alpha3-b": "afr",
    "alpha3-t": null,
  },
  {
    English: "Ainu",
    French: "aïnou",
    alpha2: null,
    "alpha3-b": "ain",
    "alpha3-t": null,
  },
  {
    English: "Akan",
    French: "akan",
    alpha2: "ak",
    "alpha3-b": "aka",
    "alpha3-t": null,
  },
  {
    English: "Akkadian",
    French: "akkadien",
    alpha2: null,
    "alpha3-b": "akk",
    "alpha3-t": null,
  },
  {
    English: "Albanian",
    French: "albanais",
    alpha2: "sq",
    "alpha3-b": "alb",
    "alpha3-t": "sqi",
  },
  {
    English: "Aleut",
    French: "aléoute",
    alpha2: null,
    "alpha3-b": "ale",
    "alpha3-t": null,
  },
  {
    English: "Algonquian languages",
    French: "algonquines, langues",
    alpha2: null,
    "alpha3-b": "alg",
    "alpha3-t": null,
  },
  {
    English: "Southern Altai",
    French: "altai du Sud",
    alpha2: null,
    "alpha3-b": "alt",
    "alpha3-t": null,
  },
  {
    English: "Amharic",
    French: "amharique",
    alpha2: "am",
    "alpha3-b": "amh",
    "alpha3-t": null,
  },
  {
    English: "English, Old (ca.450-1100)",
    French: "anglo-saxon (ca.450-1100)",
    alpha2: null,
    "alpha3-b": "ang",
    "alpha3-t": null,
  },
  {
    English: "Angika",
    French: "angika",
    alpha2: null,
    "alpha3-b": "anp",
    "alpha3-t": null,
  },
  {
    English: "Apache languages",
    French: "apaches, langues",
    alpha2: null,
    "alpha3-b": "apa",
    "alpha3-t": null,
  },
  {
    English: "Arabic",
    French: "arabe",
    alpha2: "ar",
    "alpha3-b": "ara",
    "alpha3-t": null,
  },
  {
    English: "Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)",
    French: "araméen d'empire (700-300 BCE)",
    alpha2: null,
    "alpha3-b": "arc",
    "alpha3-t": null,
  },
  {
    English: "Aragonese",
    French: "aragonais",
    alpha2: "an",
    "alpha3-b": "arg",
    "alpha3-t": null,
  },
  {
    English: "Armenian",
    French: "arménien",
    alpha2: "hy",
    "alpha3-b": "arm",
    "alpha3-t": "hye",
  },
  {
    English: "Mapudungun; Mapuche",
    French: "mapudungun; mapuche; mapuce",
    alpha2: null,
    "alpha3-b": "arn",
    "alpha3-t": null,
  },
  {
    English: "Arapaho",
    French: "arapaho",
    alpha2: null,
    "alpha3-b": "arp",
    "alpha3-t": null,
  },
  {
    English: "Artificial languages",
    French: "artificielles, langues",
    alpha2: null,
    "alpha3-b": "art",
    "alpha3-t": null,
  },
  {
    English: "Arawak",
    French: "arawak",
    alpha2: null,
    "alpha3-b": "arw",
    "alpha3-t": null,
  },
  {
    English: "Assamese",
    French: "assamais",
    alpha2: "as",
    "alpha3-b": "asm",
    "alpha3-t": null,
  },
  {
    English: "Asturian; Bable; Leonese; Asturleonese",
    French: "asturien; bable; léonais; asturoléonais",
    alpha2: null,
    "alpha3-b": "ast",
    "alpha3-t": null,
  },
  {
    English: "Athapascan languages",
    French: "athapascanes, langues",
    alpha2: null,
    "alpha3-b": "ath",
    "alpha3-t": null,
  },
  {
    English: "Australian languages",
    French: "australiennes, langues",
    alpha2: null,
    "alpha3-b": "aus",
    "alpha3-t": null,
  },
  {
    English: "Avaric",
    French: "avar",
    alpha2: "av",
    "alpha3-b": "ava",
    "alpha3-t": null,
  },
  {
    English: "Avestan",
    French: "avestique",
    alpha2: "ae",
    "alpha3-b": "ave",
    "alpha3-t": null,
  },
  {
    English: "Awadhi",
    French: "awadhi",
    alpha2: null,
    "alpha3-b": "awa",
    "alpha3-t": null,
  },
  {
    English: "Aymara",
    French: "aymara",
    alpha2: "ay",
    "alpha3-b": "aym",
    "alpha3-t": null,
  },
  {
    English: "Azerbaijani",
    French: "azéri",
    alpha2: "az",
    "alpha3-b": "aze",
    "alpha3-t": null,
  },
  {
    English: "Banda languages",
    French: "banda, langues",
    alpha2: null,
    "alpha3-b": "bad",
    "alpha3-t": null,
  },
  {
    English: "Bamileke languages",
    French: "bamiléké, langues",
    alpha2: null,
    "alpha3-b": "bai",
    "alpha3-t": null,
  },
  {
    English: "Bashkir",
    French: "bachkir",
    alpha2: "ba",
    "alpha3-b": "bak",
    "alpha3-t": null,
  },
  {
    English: "Baluchi",
    French: "baloutchi",
    alpha2: null,
    "alpha3-b": "bal",
    "alpha3-t": null,
  },
  {
    English: "Bambara",
    French: "bambara",
    alpha2: "bm",
    "alpha3-b": "bam",
    "alpha3-t": null,
  },
  {
    English: "Balinese",
    French: "balinais",
    alpha2: null,
    "alpha3-b": "ban",
    "alpha3-t": null,
  },
  {
    English: "Basque",
    French: "basque",
    alpha2: "eu",
    "alpha3-b": "baq",
    "alpha3-t": "eus",
  },
  {
    English: "Basa",
    French: "basa",
    alpha2: null,
    "alpha3-b": "bas",
    "alpha3-t": null,
  },
  {
    English: "Baltic languages",
    French: "baltes, langues",
    alpha2: null,
    "alpha3-b": "bat",
    "alpha3-t": null,
  },
  {
    English: "Beja; Bedawiyet",
    French: "bedja",
    alpha2: null,
    "alpha3-b": "bej",
    "alpha3-t": null,
  },
  {
    English: "Belarusian",
    French: "biélorusse",
    alpha2: "be",
    "alpha3-b": "bel",
    "alpha3-t": null,
  },
  {
    English: "Bemba",
    French: "bemba",
    alpha2: null,
    "alpha3-b": "bem",
    "alpha3-t": null,
  },
  {
    English: "Bengali",
    French: "bengali",
    alpha2: "bn",
    "alpha3-b": "ben",
    "alpha3-t": null,
  },
  {
    English: "Berber languages",
    French: "berbères, langues",
    alpha2: null,
    "alpha3-b": "ber",
    "alpha3-t": null,
  },
  {
    English: "Bhojpuri",
    French: "bhojpuri",
    alpha2: null,
    "alpha3-b": "bho",
    "alpha3-t": null,
  },
  {
    English: "Bihari languages",
    French: "langues biharis",
    alpha2: "bh",
    "alpha3-b": "bih",
    "alpha3-t": null,
  },
  {
    English: "Bikol",
    French: "bikol",
    alpha2: null,
    "alpha3-b": "bik",
    "alpha3-t": null,
  },
  {
    English: "Bini; Edo",
    French: "bini; edo",
    alpha2: null,
    "alpha3-b": "bin",
    "alpha3-t": null,
  },
  {
    English: "Bislama",
    French: "bichlamar",
    alpha2: "bi",
    "alpha3-b": "bis",
    "alpha3-t": null,
  },
  {
    English: "Siksika",
    French: "blackfoot",
    alpha2: null,
    "alpha3-b": "bla",
    "alpha3-t": null,
  },
  {
    English: "Bantu languages",
    French: "bantou, langues",
    alpha2: null,
    "alpha3-b": "bnt",
    "alpha3-t": null,
  },
  {
    English: "Bosnian",
    French: "bosniaque",
    alpha2: "bs",
    "alpha3-b": "bos",
    "alpha3-t": null,
  },
  {
    English: "Braj",
    French: "braj",
    alpha2: null,
    "alpha3-b": "bra",
    "alpha3-t": null,
  },
  {
    English: "Breton",
    French: "breton",
    alpha2: "br",
    "alpha3-b": "bre",
    "alpha3-t": null,
  },
  {
    English: "Batak languages",
    French: "batak, langues",
    alpha2: null,
    "alpha3-b": "btk",
    "alpha3-t": null,
  },
  {
    English: "Buriat",
    French: "bouriate",
    alpha2: null,
    "alpha3-b": "bua",
    "alpha3-t": null,
  },
  {
    English: "Buginese",
    French: "bugi",
    alpha2: null,
    "alpha3-b": "bug",
    "alpha3-t": null,
  },
  {
    English: "Bulgarian",
    French: "bulgare",
    alpha2: "bg",
    "alpha3-b": "bul",
    "alpha3-t": null,
  },
  {
    English: "Burmese",
    French: "birman",
    alpha2: "my",
    "alpha3-b": "bur",
    "alpha3-t": "mya",
  },
  {
    English: "Blin; Bilin",
    French: "blin; bilen",
    alpha2: null,
    "alpha3-b": "byn",
    "alpha3-t": null,
  },
  {
    English: "Caddo",
    French: "caddo",
    alpha2: null,
    "alpha3-b": "cad",
    "alpha3-t": null,
  },
  {
    English: "Central American Indian languages",
    French: "amérindiennes de L'Amérique centrale, langues",
    alpha2: null,
    "alpha3-b": "cai",
    "alpha3-t": null,
  },
  {
    English: "Galibi Carib",
    French: "karib; galibi; carib",
    alpha2: null,
    "alpha3-b": "car",
    "alpha3-t": null,
  },
  {
    English: "Catalan; Valencian",
    French: "catalan; valencien",
    alpha2: "ca",
    "alpha3-b": "cat",
    "alpha3-t": null,
  },
  {
    English: "Caucasian languages",
    French: "caucasiennes, langues",
    alpha2: null,
    "alpha3-b": "cau",
    "alpha3-t": null,
  },
  {
    English: "Cebuano",
    French: "cebuano",
    alpha2: null,
    "alpha3-b": "ceb",
    "alpha3-t": null,
  },
  {
    English: "Celtic languages",
    French: "celtiques, langues; celtes, langues",
    alpha2: null,
    "alpha3-b": "cel",
    "alpha3-t": null,
  },
  {
    English: "Chamorro",
    French: "chamorro",
    alpha2: "ch",
    "alpha3-b": "cha",
    "alpha3-t": null,
  },
  {
    English: "Chibcha",
    French: "chibcha",
    alpha2: null,
    "alpha3-b": "chb",
    "alpha3-t": null,
  },
  {
    English: "Chechen",
    French: "tchétchène",
    alpha2: "ce",
    "alpha3-b": "che",
    "alpha3-t": null,
  },
  {
    English: "Chagatai",
    French: "djaghataï",
    alpha2: null,
    "alpha3-b": "chg",
    "alpha3-t": null,
  },
  {
    English: "Chinese",
    French: "chinois",
    alpha2: "zh",
    "alpha3-b": "chi",
    "alpha3-t": "zho",
  },
  {
    English: "Chuukese",
    French: "chuuk",
    alpha2: null,
    "alpha3-b": "chk",
    "alpha3-t": null,
  },
  {
    English: "Mari",
    French: "mari",
    alpha2: null,
    "alpha3-b": "chm",
    "alpha3-t": null,
  },
  {
    English: "Chinook jargon",
    French: "chinook, jargon",
    alpha2: null,
    "alpha3-b": "chn",
    "alpha3-t": null,
  },
  {
    English: "Choctaw",
    French: "choctaw",
    alpha2: null,
    "alpha3-b": "cho",
    "alpha3-t": null,
  },
  {
    English: "Chipewyan; Dene Suline",
    French: "chipewyan",
    alpha2: null,
    "alpha3-b": "chp",
    "alpha3-t": null,
  },
  {
    English: "Cherokee",
    French: "cherokee",
    alpha2: null,
    "alpha3-b": "chr",
    "alpha3-t": null,
  },
  {
    English: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    French: "slavon d'église; vieux slave; slavon liturgique; vieux bulgare",
    alpha2: "cu",
    "alpha3-b": "chu",
    "alpha3-t": null,
  },
  {
    English: "Chuvash",
    French: "tchouvache",
    alpha2: "cv",
    "alpha3-b": "chv",
    "alpha3-t": null,
  },
  {
    English: "Cheyenne",
    French: "cheyenne",
    alpha2: null,
    "alpha3-b": "chy",
    "alpha3-t": null,
  },
  {
    English: "Chamic languages",
    French: "chames, langues",
    alpha2: null,
    "alpha3-b": "cmc",
    "alpha3-t": null,
  },
  {
    English: "Montenegrin",
    French: "monténégrin",
    alpha2: null,
    "alpha3-b": "cnr",
    "alpha3-t": null,
  },
  {
    English: "Coptic",
    French: "copte",
    alpha2: null,
    "alpha3-b": "cop",
    "alpha3-t": null,
  },
  {
    English: "Cornish",
    French: "cornique",
    alpha2: "kw",
    "alpha3-b": "cor",
    "alpha3-t": null,
  },
  {
    English: "Corsican",
    French: "corse",
    alpha2: "co",
    "alpha3-b": "cos",
    "alpha3-t": null,
  },
  {
    English: "Creoles and pidgins, English based",
    French: "créoles et pidgins basés sur l'anglais",
    alpha2: null,
    "alpha3-b": "cpe",
    "alpha3-t": null,
  },
  {
    English: "Creoles and pidgins, French-based",
    French: "créoles et pidgins basés sur le français",
    alpha2: null,
    "alpha3-b": "cpf",
    "alpha3-t": null,
  },
  {
    English: "Creoles and pidgins, Portuguese-based",
    French: "créoles et pidgins basés sur le portugais",
    alpha2: null,
    "alpha3-b": "cpp",
    "alpha3-t": null,
  },
  {
    English: "Cree",
    French: "cree",
    alpha2: "cr",
    "alpha3-b": "cre",
    "alpha3-t": null,
  },
  {
    English: "Crimean Tatar; Crimean Turkish",
    French: "tatar de Crimé",
    alpha2: null,
    "alpha3-b": "crh",
    "alpha3-t": null,
  },
  {
    English: "Creoles and pidgins",
    French: "créoles et pidgins",
    alpha2: null,
    "alpha3-b": "crp",
    "alpha3-t": null,
  },
  {
    English: "Kashubian",
    French: "kachoube",
    alpha2: null,
    "alpha3-b": "csb",
    "alpha3-t": null,
  },
  {
    English: "Cushitic languages",
    French: "couchitiques, langues",
    alpha2: null,
    "alpha3-b": "cus",
    "alpha3-t": null,
  },
  {
    English: "Czech",
    French: "tchèque",
    alpha2: "cs",
    "alpha3-b": "cze",
    "alpha3-t": "ces",
  },
  {
    English: "Dakota",
    French: "dakota",
    alpha2: null,
    "alpha3-b": "dak",
    "alpha3-t": null,
  },
  {
    English: "Danish",
    French: "danois",
    alpha2: "da",
    "alpha3-b": "dan",
    "alpha3-t": null,
  },
  {
    English: "Dargwa",
    French: "dargwa",
    alpha2: null,
    "alpha3-b": "dar",
    "alpha3-t": null,
  },
  {
    English: "Land Dayak languages",
    French: "dayak, langues",
    alpha2: null,
    "alpha3-b": "day",
    "alpha3-t": null,
  },
  {
    English: "Delaware",
    French: "delaware",
    alpha2: null,
    "alpha3-b": "del",
    "alpha3-t": null,
  },
  {
    English: "Slave (Athapascan)",
    French: "esclave (athapascan)",
    alpha2: null,
    "alpha3-b": "den",
    "alpha3-t": null,
  },
  {
    English: "Dogrib",
    French: "dogrib",
    alpha2: null,
    "alpha3-b": "dgr",
    "alpha3-t": null,
  },
  {
    English: "Dinka",
    French: "dinka",
    alpha2: null,
    "alpha3-b": "din",
    "alpha3-t": null,
  },
  {
    English: "Divehi; Dhivehi; Maldivian",
    French: "maldivien",
    alpha2: "dv",
    "alpha3-b": "div",
    "alpha3-t": null,
  },
  {
    English: "Dogri",
    French: "dogri",
    alpha2: null,
    "alpha3-b": "doi",
    "alpha3-t": null,
  },
  {
    English: "Dravidian languages",
    French: "dravidiennes, langues",
    alpha2: null,
    "alpha3-b": "dra",
    "alpha3-t": null,
  },
  {
    English: "Lower Sorbian",
    French: "bas-sorabe",
    alpha2: null,
    "alpha3-b": "dsb",
    "alpha3-t": null,
  },
  {
    English: "Duala",
    French: "douala",
    alpha2: null,
    "alpha3-b": "dua",
    "alpha3-t": null,
  },
  {
    English: "Dutch, Middle (ca.1050-1350)",
    French: "néerlandais moyen (ca. 1050-1350)",
    alpha2: null,
    "alpha3-b": "dum",
    "alpha3-t": null,
  },
  {
    English: "Dutch; Flemish",
    French: "néerlandais; flamand",
    alpha2: "nl",
    "alpha3-b": "dut",
    "alpha3-t": "nld",
  },
  {
    English: "Dyula",
    French: "dioula",
    alpha2: null,
    "alpha3-b": "dyu",
    "alpha3-t": null,
  },
  {
    English: "Dzongkha",
    French: "dzongkha",
    alpha2: "dz",
    "alpha3-b": "dzo",
    "alpha3-t": null,
  },
  {
    English: "Efik",
    French: "efik",
    alpha2: null,
    "alpha3-b": "efi",
    "alpha3-t": null,
  },
  {
    English: "Egyptian (Ancient)",
    French: "égyptien",
    alpha2: null,
    "alpha3-b": "egy",
    "alpha3-t": null,
  },
  {
    English: "Ekajuk",
    French: "ekajuk",
    alpha2: null,
    "alpha3-b": "eka",
    "alpha3-t": null,
  },
  {
    English: "Elamite",
    French: "élamite",
    alpha2: null,
    "alpha3-b": "elx",
    "alpha3-t": null,
  },
  {
    English: "English",
    French: "anglais",
    alpha2: "en",
    "alpha3-b": "eng",
    "alpha3-t": null,
  },
  {
    English: "English, Middle (1100-1500)",
    French: "anglais moyen (1100-1500)",
    alpha2: null,
    "alpha3-b": "enm",
    "alpha3-t": null,
  },
  {
    English: "Esperanto",
    French: "espéranto",
    alpha2: "eo",
    "alpha3-b": "epo",
    "alpha3-t": null,
  },
  {
    English: "Estonian",
    French: "estonien",
    alpha2: "et",
    "alpha3-b": "est",
    "alpha3-t": null,
  },
  {
    English: "Ewe",
    French: "éwé",
    alpha2: "ee",
    "alpha3-b": "ewe",
    "alpha3-t": null,
  },
  {
    English: "Ewondo",
    French: "éwondo",
    alpha2: null,
    "alpha3-b": "ewo",
    "alpha3-t": null,
  },
  {
    English: "Fang",
    French: "fang",
    alpha2: null,
    "alpha3-b": "fan",
    "alpha3-t": null,
  },
  {
    English: "Faroese",
    French: "féroïen",
    alpha2: "fo",
    "alpha3-b": "fao",
    "alpha3-t": null,
  },
  {
    English: "Fanti",
    French: "fanti",
    alpha2: null,
    "alpha3-b": "fat",
    "alpha3-t": null,
  },
  {
    English: "Fijian",
    French: "fidjien",
    alpha2: "fj",
    "alpha3-b": "fij",
    "alpha3-t": null,
  },
  {
    English: "Filipino; Pilipino",
    French: "filipino; pilipino",
    alpha2: null,
    "alpha3-b": "fil",
    "alpha3-t": null,
  },
  {
    English: "Finnish",
    French: "finnois",
    alpha2: "fi",
    "alpha3-b": "fin",
    "alpha3-t": null,
  },
  {
    English: "Finno-Ugrian languages",
    French: "finno-ougriennes, langues",
    alpha2: null,
    "alpha3-b": "fiu",
    "alpha3-t": null,
  },
  {
    English: "Fon",
    French: "fon",
    alpha2: null,
    "alpha3-b": "fon",
    "alpha3-t": null,
  },
  {
    English: "French",
    French: "français",
    alpha2: "fr",
    "alpha3-b": "fre",
    "alpha3-t": "fra",
  },
  {
    English: "French, Middle (ca.1400-1600)",
    French: "français moyen (1400-1600)",
    alpha2: null,
    "alpha3-b": "frm",
    "alpha3-t": null,
  },
  {
    English: "French, Old (842-ca.1400)",
    French: "français ancien (842-ca.1400)",
    alpha2: null,
    "alpha3-b": "fro",
    "alpha3-t": null,
  },
  {
    English: "Northern Frisian",
    French: "frison septentrional",
    alpha2: null,
    "alpha3-b": "frr",
    "alpha3-t": null,
  },
  {
    English: "Eastern Frisian",
    French: "frison oriental",
    alpha2: null,
    "alpha3-b": "frs",
    "alpha3-t": null,
  },
  {
    English: "Western Frisian",
    French: "frison occidental",
    alpha2: "fy",
    "alpha3-b": "fry",
    "alpha3-t": null,
  },
  {
    English: "Fulah",
    French: "peul",
    alpha2: "ff",
    "alpha3-b": "ful",
    "alpha3-t": null,
  },
  {
    English: "Friulian",
    French: "frioulan",
    alpha2: null,
    "alpha3-b": "fur",
    "alpha3-t": null,
  },
  {
    English: "Ga",
    French: "ga",
    alpha2: null,
    "alpha3-b": "gaa",
    "alpha3-t": null,
  },
  {
    English: "Gayo",
    French: "gayo",
    alpha2: null,
    "alpha3-b": "gay",
    "alpha3-t": null,
  },
  {
    English: "Gbaya",
    French: "gbaya",
    alpha2: null,
    "alpha3-b": "gba",
    "alpha3-t": null,
  },
  {
    English: "Germanic languages",
    French: "germaniques, langues",
    alpha2: null,
    "alpha3-b": "gem",
    "alpha3-t": null,
  },
  {
    English: "Georgian",
    French: "géorgien",
    alpha2: "ka",
    "alpha3-b": "geo",
    "alpha3-t": "kat",
  },
  {
    English: "German",
    French: "allemand",
    alpha2: "de",
    "alpha3-b": "ger",
    "alpha3-t": "deu",
  },
  {
    English: "Geez",
    French: "guèze",
    alpha2: null,
    "alpha3-b": "gez",
    "alpha3-t": null,
  },
  {
    English: "Gilbertese",
    French: "kiribati",
    alpha2: null,
    "alpha3-b": "gil",
    "alpha3-t": null,
  },
  {
    English: "Gaelic; Scottish Gaelic",
    French: "gaélique; gaélique écossais",
    alpha2: "gd",
    "alpha3-b": "gla",
    "alpha3-t": null,
  },
  {
    English: "Irish",
    French: "irlandais",
    alpha2: "ga",
    "alpha3-b": "gle",
    "alpha3-t": null,
  },
  {
    English: "Galician",
    French: "galicien",
    alpha2: "gl",
    "alpha3-b": "glg",
    "alpha3-t": null,
  },
  {
    English: "Manx",
    French: "manx; mannois",
    alpha2: "gv",
    "alpha3-b": "glv",
    "alpha3-t": null,
  },
  {
    English: "German, Middle High (ca.1050-1500)",
    French: "allemand, moyen haut (ca. 1050-1500)",
    alpha2: null,
    "alpha3-b": "gmh",
    "alpha3-t": null,
  },
  {
    English: "German, Old High (ca.750-1050)",
    French: "allemand, vieux haut (ca. 750-1050)",
    alpha2: null,
    "alpha3-b": "goh",
    "alpha3-t": null,
  },
  {
    English: "Gondi",
    French: "gond",
    alpha2: null,
    "alpha3-b": "gon",
    "alpha3-t": null,
  },
  {
    English: "Gorontalo",
    French: "gorontalo",
    alpha2: null,
    "alpha3-b": "gor",
    "alpha3-t": null,
  },
  {
    English: "Gothic",
    French: "gothique",
    alpha2: null,
    "alpha3-b": "got",
    "alpha3-t": null,
  },
  {
    English: "Grebo",
    French: "grebo",
    alpha2: null,
    "alpha3-b": "grb",
    "alpha3-t": null,
  },
  {
    English: "Greek, Ancient (to 1453)",
    French: "grec ancien (jusqu'à 1453)",
    alpha2: null,
    "alpha3-b": "grc",
    "alpha3-t": null,
  },
  {
    English: "Greek, Modern (1453-)",
    French: "grec moderne (après 1453)",
    alpha2: "el",
    "alpha3-b": "gre",
    "alpha3-t": "ell",
  },
  {
    English: "Guarani",
    French: "guarani",
    alpha2: "gn",
    "alpha3-b": "grn",
    "alpha3-t": null,
  },
  {
    English: "Swiss German; Alemannic; Alsatian",
    French: "suisse alémanique; alémanique; alsacien",
    alpha2: null,
    "alpha3-b": "gsw",
    "alpha3-t": null,
  },
  {
    English: "Gujarati",
    French: "goudjrati",
    alpha2: "gu",
    "alpha3-b": "guj",
    "alpha3-t": null,
  },
  {
    English: "Gwich'in",
    French: "gwich'in",
    alpha2: null,
    "alpha3-b": "gwi",
    "alpha3-t": null,
  },
  {
    English: "Haida",
    French: "haida",
    alpha2: null,
    "alpha3-b": "hai",
    "alpha3-t": null,
  },
  {
    English: "Haitian; Haitian Creole",
    French: "haïtien; créole haïtien",
    alpha2: "ht",
    "alpha3-b": "hat",
    "alpha3-t": null,
  },
  {
    English: "Hausa",
    French: "haoussa",
    alpha2: "ha",
    "alpha3-b": "hau",
    "alpha3-t": null,
  },
  {
    English: "Hawaiian",
    French: "hawaïen",
    alpha2: null,
    "alpha3-b": "haw",
    "alpha3-t": null,
  },
  {
    English: "Hebrew",
    French: "hébreu",
    alpha2: "he",
    "alpha3-b": "heb",
    "alpha3-t": null,
  },
  {
    English: "Herero",
    French: "herero",
    alpha2: "hz",
    "alpha3-b": "her",
    "alpha3-t": null,
  },
  {
    English: "Hiligaynon",
    French: "hiligaynon",
    alpha2: null,
    "alpha3-b": "hil",
    "alpha3-t": null,
  },
  {
    English: "Himachali languages; Western Pahari languages",
    French: "langues himachalis; langues paharis occidentales",
    alpha2: null,
    "alpha3-b": "him",
    "alpha3-t": null,
  },
  {
    English: "Hindi",
    French: "hindi",
    alpha2: "hi",
    "alpha3-b": "hin",
    "alpha3-t": null,
  },
  {
    English: "Hittite",
    French: "hittite",
    alpha2: null,
    "alpha3-b": "hit",
    "alpha3-t": null,
  },
  {
    English: "Hmong; Mong",
    French: "hmong",
    alpha2: null,
    "alpha3-b": "hmn",
    "alpha3-t": null,
  },
  {
    English: "Hiri Motu",
    French: "hiri motu",
    alpha2: "ho",
    "alpha3-b": "hmo",
    "alpha3-t": null,
  },
  {
    English: "Croatian",
    French: "croate",
    alpha2: "hr",
    "alpha3-b": "hrv",
    "alpha3-t": null,
  },
  {
    English: "Upper Sorbian",
    French: "haut-sorabe",
    alpha2: null,
    "alpha3-b": "hsb",
    "alpha3-t": null,
  },
  {
    English: "Hungarian",
    French: "hongrois",
    alpha2: "hu",
    "alpha3-b": "hun",
    "alpha3-t": null,
  },
  {
    English: "Hupa",
    French: "hupa",
    alpha2: null,
    "alpha3-b": "hup",
    "alpha3-t": null,
  },
  {
    English: "Iban",
    French: "iban",
    alpha2: null,
    "alpha3-b": "iba",
    "alpha3-t": null,
  },
  {
    English: "Igbo",
    French: "igbo",
    alpha2: "ig",
    "alpha3-b": "ibo",
    "alpha3-t": null,
  },
  {
    English: "Icelandic",
    French: "islandais",
    alpha2: "is",
    "alpha3-b": "ice",
    "alpha3-t": "isl",
  },
  {
    English: "Ido",
    French: "ido",
    alpha2: "io",
    "alpha3-b": "ido",
    "alpha3-t": null,
  },
  {
    English: "Sichuan Yi; Nuosu",
    French: "yi de Sichuan",
    alpha2: "ii",
    "alpha3-b": "iii",
    "alpha3-t": null,
  },
  {
    English: "Ijo languages",
    French: "ijo, langues",
    alpha2: null,
    "alpha3-b": "ijo",
    "alpha3-t": null,
  },
  {
    English: "Inuktitut",
    French: "inuktitut",
    alpha2: "iu",
    "alpha3-b": "iku",
    "alpha3-t": null,
  },
  {
    English: "Interlingue; Occidental",
    French: "interlingue",
    alpha2: "ie",
    "alpha3-b": "ile",
    "alpha3-t": null,
  },
  {
    English: "Iloko",
    French: "ilocano",
    alpha2: null,
    "alpha3-b": "ilo",
    "alpha3-t": null,
  },
  {
    English: "Interlingua (International Auxiliary Language Association)",
    French: "interlingua (langue auxiliaire internationale)",
    alpha2: "ia",
    "alpha3-b": "ina",
    "alpha3-t": null,
  },
  {
    English: "Indic languages",
    French: "indo-aryennes, langues",
    alpha2: null,
    "alpha3-b": "inc",
    "alpha3-t": null,
  },
  {
    English: "Indonesian",
    French: "indonésien",
    alpha2: "id",
    "alpha3-b": "ind",
    "alpha3-t": null,
  },
  {
    English: "Indo-European languages",
    French: "indo-européennes, langues",
    alpha2: null,
    "alpha3-b": "ine",
    "alpha3-t": null,
  },
  {
    English: "Ingush",
    French: "ingouche",
    alpha2: null,
    "alpha3-b": "inh",
    "alpha3-t": null,
  },
  {
    English: "Inupiaq",
    French: "inupiaq",
    alpha2: "ik",
    "alpha3-b": "ipk",
    "alpha3-t": null,
  },
  {
    English: "Iranian languages",
    French: "iraniennes, langues",
    alpha2: null,
    "alpha3-b": "ira",
    "alpha3-t": null,
  },
  {
    English: "Iroquoian languages",
    French: "iroquoises, langues",
    alpha2: null,
    "alpha3-b": "iro",
    "alpha3-t": null,
  },
  {
    English: "Italian",
    French: "italien",
    alpha2: "it",
    "alpha3-b": "ita",
    "alpha3-t": null,
  },
  {
    English: "Javanese",
    French: "javanais",
    alpha2: "jv",
    "alpha3-b": "jav",
    "alpha3-t": null,
  },
  {
    English: "Lojban",
    French: "lojban",
    alpha2: null,
    "alpha3-b": "jbo",
    "alpha3-t": null,
  },
  {
    English: "Japanese",
    French: "japonais",
    alpha2: "ja",
    "alpha3-b": "jpn",
    "alpha3-t": null,
  },
  {
    English: "Judeo-Persian",
    French: "judéo-persan",
    alpha2: null,
    "alpha3-b": "jpr",
    "alpha3-t": null,
  },
  {
    English: "Judeo-Arabic",
    French: "judéo-arabe",
    alpha2: null,
    "alpha3-b": "jrb",
    "alpha3-t": null,
  },
  {
    English: "Kara-Kalpak",
    French: "karakalpak",
    alpha2: null,
    "alpha3-b": "kaa",
    "alpha3-t": null,
  },
  {
    English: "Kabyle",
    French: "kabyle",
    alpha2: null,
    "alpha3-b": "kab",
    "alpha3-t": null,
  },
  {
    English: "Kachin; Jingpho",
    French: "kachin; jingpho",
    alpha2: null,
    "alpha3-b": "kac",
    "alpha3-t": null,
  },
  {
    English: "Kalaallisut; Greenlandic",
    French: "groenlandais",
    alpha2: "kl",
    "alpha3-b": "kal",
    "alpha3-t": null,
  },
  {
    English: "Kamba",
    French: "kamba",
    alpha2: null,
    "alpha3-b": "kam",
    "alpha3-t": null,
  },
  {
    English: "Kannada",
    French: "kannada",
    alpha2: "kn",
    "alpha3-b": "kan",
    "alpha3-t": null,
  },
  {
    English: "Karen languages",
    French: "karen, langues",
    alpha2: null,
    "alpha3-b": "kar",
    "alpha3-t": null,
  },
  {
    English: "Kashmiri",
    French: "kashmiri",
    alpha2: "ks",
    "alpha3-b": "kas",
    "alpha3-t": null,
  },
  {
    English: "Kanuri",
    French: "kanouri",
    alpha2: "kr",
    "alpha3-b": "kau",
    "alpha3-t": null,
  },
  {
    English: "Kawi",
    French: "kawi",
    alpha2: null,
    "alpha3-b": "kaw",
    "alpha3-t": null,
  },
  {
    English: "Kazakh",
    French: "kazakh",
    alpha2: "kk",
    "alpha3-b": "kaz",
    "alpha3-t": null,
  },
  {
    English: "Kabardian",
    French: "kabardien",
    alpha2: null,
    "alpha3-b": "kbd",
    "alpha3-t": null,
  },
  {
    English: "Khasi",
    French: "khasi",
    alpha2: null,
    "alpha3-b": "kha",
    "alpha3-t": null,
  },
  {
    English: "Khoisan languages",
    French: "khoïsan, langues",
    alpha2: null,
    "alpha3-b": "khi",
    "alpha3-t": null,
  },
  {
    English: "Central Khmer",
    French: "khmer central",
    alpha2: "km",
    "alpha3-b": "khm",
    "alpha3-t": null,
  },
  {
    English: "Khotanese; Sakan",
    French: "khotanais; sakan",
    alpha2: null,
    "alpha3-b": "kho",
    "alpha3-t": null,
  },
  {
    English: "Kikuyu; Gikuyu",
    French: "kikuyu",
    alpha2: "ki",
    "alpha3-b": "kik",
    "alpha3-t": null,
  },
  {
    English: "Kinyarwanda",
    French: "rwanda",
    alpha2: "rw",
    "alpha3-b": "kin",
    "alpha3-t": null,
  },
  {
    English: "Kirghiz; Kyrgyz",
    French: "kirghiz",
    alpha2: "ky",
    "alpha3-b": "kir",
    "alpha3-t": null,
  },
  {
    English: "Kimbundu",
    French: "kimbundu",
    alpha2: null,
    "alpha3-b": "kmb",
    "alpha3-t": null,
  },
  {
    English: "Konkani",
    French: "konkani",
    alpha2: null,
    "alpha3-b": "kok",
    "alpha3-t": null,
  },
  {
    English: "Komi",
    French: "kom",
    alpha2: "kv",
    "alpha3-b": "kom",
    "alpha3-t": null,
  },
  {
    English: "Kongo",
    French: "kongo",
    alpha2: "kg",
    "alpha3-b": "kon",
    "alpha3-t": null,
  },
  {
    English: "Korean",
    French: "coréen",
    alpha2: "ko",
    "alpha3-b": "kor",
    "alpha3-t": null,
  },
  {
    English: "Kosraean",
    French: "kosrae",
    alpha2: null,
    "alpha3-b": "kos",
    "alpha3-t": null,
  },
  {
    English: "Kpelle",
    French: "kpellé",
    alpha2: null,
    "alpha3-b": "kpe",
    "alpha3-t": null,
  },
  {
    English: "Karachay-Balkar",
    French: "karatchai balkar",
    alpha2: null,
    "alpha3-b": "krc",
    "alpha3-t": null,
  },
  {
    English: "Karelian",
    French: "carélien",
    alpha2: null,
    "alpha3-b": "krl",
    "alpha3-t": null,
  },
  {
    English: "Kru languages",
    French: "krou, langues",
    alpha2: null,
    "alpha3-b": "kro",
    "alpha3-t": null,
  },
  {
    English: "Kurukh",
    French: "kurukh",
    alpha2: null,
    "alpha3-b": "kru",
    "alpha3-t": null,
  },
  {
    English: "Kuanyama; Kwanyama",
    French: "kuanyama; kwanyama",
    alpha2: "kj",
    "alpha3-b": "kua",
    "alpha3-t": null,
  },
  {
    English: "Kumyk",
    French: "koumyk",
    alpha2: null,
    "alpha3-b": "kum",
    "alpha3-t": null,
  },
  {
    English: "Kurdish",
    French: "kurde",
    alpha2: "ku",
    "alpha3-b": "kur",
    "alpha3-t": null,
  },
  {
    English: "Kutenai",
    French: "kutenai",
    alpha2: null,
    "alpha3-b": "kut",
    "alpha3-t": null,
  },
  {
    English: "Ladino",
    French: "judéo-espagnol",
    alpha2: null,
    "alpha3-b": "lad",
    "alpha3-t": null,
  },
  {
    English: "Lahnda",
    French: "lahnda",
    alpha2: null,
    "alpha3-b": "lah",
    "alpha3-t": null,
  },
  {
    English: "Lamba",
    French: "lamba",
    alpha2: null,
    "alpha3-b": "lam",
    "alpha3-t": null,
  },
  {
    English: "Lao",
    French: "lao",
    alpha2: "lo",
    "alpha3-b": "lao",
    "alpha3-t": null,
  },
  {
    English: "Latin",
    French: "latin",
    alpha2: "la",
    "alpha3-b": "lat",
    "alpha3-t": null,
  },
  {
    English: "Latvian",
    French: "letton",
    alpha2: "lv",
    "alpha3-b": "lav",
    "alpha3-t": null,
  },
  {
    English: "Lezghian",
    French: "lezghien",
    alpha2: null,
    "alpha3-b": "lez",
    "alpha3-t": null,
  },
  {
    English: "Limburgan; Limburger; Limburgish",
    French: "limbourgeois",
    alpha2: "li",
    "alpha3-b": "lim",
    "alpha3-t": null,
  },
  {
    English: "Lingala",
    French: "lingala",
    alpha2: "ln",
    "alpha3-b": "lin",
    "alpha3-t": null,
  },
  {
    English: "Lithuanian",
    French: "lituanien",
    alpha2: "lt",
    "alpha3-b": "lit",
    "alpha3-t": null,
  },
  {
    English: "Mongo",
    French: "mongo",
    alpha2: null,
    "alpha3-b": "lol",
    "alpha3-t": null,
  },
  {
    English: "Lozi",
    French: "lozi",
    alpha2: null,
    "alpha3-b": "loz",
    "alpha3-t": null,
  },
  {
    English: "Luxembourgish; Letzeburgesch",
    French: "luxembourgeois",
    alpha2: "lb",
    "alpha3-b": "ltz",
    "alpha3-t": null,
  },
  {
    English: "Luba-Lulua",
    French: "luba-lulua",
    alpha2: null,
    "alpha3-b": "lua",
    "alpha3-t": null,
  },
  {
    English: "Luba-Katanga",
    French: "luba-katanga",
    alpha2: "lu",
    "alpha3-b": "lub",
    "alpha3-t": null,
  },
  {
    English: "Ganda",
    French: "ganda",
    alpha2: "lg",
    "alpha3-b": "lug",
    "alpha3-t": null,
  },
  {
    English: "Luiseno",
    French: "luiseno",
    alpha2: null,
    "alpha3-b": "lui",
    "alpha3-t": null,
  },
  {
    English: "Lunda",
    French: "lunda",
    alpha2: null,
    "alpha3-b": "lun",
    "alpha3-t": null,
  },
  {
    English: "Luo (Kenya and Tanzania)",
    French: "luo (Kenya et Tanzanie)",
    alpha2: null,
    "alpha3-b": "luo",
    "alpha3-t": null,
  },
  {
    English: "Lushai",
    French: "lushai",
    alpha2: null,
    "alpha3-b": "lus",
    "alpha3-t": null,
  },
  {
    English: "Macedonian",
    French: "macédonien",
    alpha2: "mk",
    "alpha3-b": "mac",
    "alpha3-t": "mkd",
  },
  {
    English: "Madurese",
    French: "madourais",
    alpha2: null,
    "alpha3-b": "mad",
    "alpha3-t": null,
  },
  {
    English: "Magahi",
    French: "magahi",
    alpha2: null,
    "alpha3-b": "mag",
    "alpha3-t": null,
  },
  {
    English: "Marshallese",
    French: "marshall",
    alpha2: "mh",
    "alpha3-b": "mah",
    "alpha3-t": null,
  },
  {
    English: "Maithili",
    French: "maithili",
    alpha2: null,
    "alpha3-b": "mai",
    "alpha3-t": null,
  },
  {
    English: "Makasar",
    French: "makassar",
    alpha2: null,
    "alpha3-b": "mak",
    "alpha3-t": null,
  },
  {
    English: "Malayalam",
    French: "malayalam",
    alpha2: "ml",
    "alpha3-b": "mal",
    "alpha3-t": null,
  },
  {
    English: "Mandingo",
    French: "mandingue",
    alpha2: null,
    "alpha3-b": "man",
    "alpha3-t": null,
  },
  {
    English: "Maori",
    French: "maori",
    alpha2: "mi",
    "alpha3-b": "mao",
    "alpha3-t": "mri",
  },
  {
    English: "Austronesian languages",
    French: "austronésiennes, langues",
    alpha2: null,
    "alpha3-b": "map",
    "alpha3-t": null,
  },
  {
    English: "Marathi",
    French: "marathe",
    alpha2: "mr",
    "alpha3-b": "mar",
    "alpha3-t": null,
  },
  {
    English: "Masai",
    French: "massaï",
    alpha2: null,
    "alpha3-b": "mas",
    "alpha3-t": null,
  },
  {
    English: "Malay",
    French: "malais",
    alpha2: "ms",
    "alpha3-b": "may",
    "alpha3-t": "msa",
  },
  {
    English: "Moksha",
    French: "moksa",
    alpha2: null,
    "alpha3-b": "mdf",
    "alpha3-t": null,
  },
  {
    English: "Mandar",
    French: "mandar",
    alpha2: null,
    "alpha3-b": "mdr",
    "alpha3-t": null,
  },
  {
    English: "Mende",
    French: "mendé",
    alpha2: null,
    "alpha3-b": "men",
    "alpha3-t": null,
  },
  {
    English: "Irish, Middle (900-1200)",
    French: "irlandais moyen (900-1200)",
    alpha2: null,
    "alpha3-b": "mga",
    "alpha3-t": null,
  },
  {
    English: "Mi'kmaq; Micmac",
    French: "mi'kmaq; micmac",
    alpha2: null,
    "alpha3-b": "mic",
    "alpha3-t": null,
  },
  {
    English: "Minangkabau",
    French: "minangkabau",
    alpha2: null,
    "alpha3-b": "min",
    "alpha3-t": null,
  },
  {
    English: "Uncoded languages",
    French: "langues non codées",
    alpha2: null,
    "alpha3-b": "mis",
    "alpha3-t": null,
  },
  {
    English: "Mon-Khmer languages",
    French: "môn-khmer, langues",
    alpha2: null,
    "alpha3-b": "mkh",
    "alpha3-t": null,
  },
  {
    English: "Malagasy",
    French: "malgache",
    alpha2: "mg",
    "alpha3-b": "mlg",
    "alpha3-t": null,
  },
  {
    English: "Maltese",
    French: "maltais",
    alpha2: "mt",
    "alpha3-b": "mlt",
    "alpha3-t": null,
  },
  {
    English: "Manchu",
    French: "mandchou",
    alpha2: null,
    "alpha3-b": "mnc",
    "alpha3-t": null,
  },
  {
    English: "Manipuri",
    French: "manipuri",
    alpha2: null,
    "alpha3-b": "mni",
    "alpha3-t": null,
  },
  {
    English: "Manobo languages",
    French: "manobo, langues",
    alpha2: null,
    "alpha3-b": "mno",
    "alpha3-t": null,
  },
  {
    English: "Mohawk",
    French: "mohawk",
    alpha2: null,
    "alpha3-b": "moh",
    "alpha3-t": null,
  },
  {
    English: "Mongolian",
    French: "mongol",
    alpha2: "mn",
    "alpha3-b": "mon",
    "alpha3-t": null,
  },
  {
    English: "Mossi",
    French: "moré",
    alpha2: null,
    "alpha3-b": "mos",
    "alpha3-t": null,
  },
  {
    English: "Multiple languages",
    French: "multilingue",
    alpha2: null,
    "alpha3-b": "mul",
    "alpha3-t": null,
  },
  {
    English: "Munda languages",
    French: "mounda, langues",
    alpha2: null,
    "alpha3-b": "mun",
    "alpha3-t": null,
  },
  {
    English: "Creek",
    French: "muskogee",
    alpha2: null,
    "alpha3-b": "mus",
    "alpha3-t": null,
  },
  {
    English: "Mirandese",
    French: "mirandais",
    alpha2: null,
    "alpha3-b": "mwl",
    "alpha3-t": null,
  },
  {
    English: "Marwari",
    French: "marvari",
    alpha2: null,
    "alpha3-b": "mwr",
    "alpha3-t": null,
  },
  {
    English: "Mayan languages",
    French: "maya, langues",
    alpha2: null,
    "alpha3-b": "myn",
    "alpha3-t": null,
  },
  {
    English: "Erzya",
    French: "erza",
    alpha2: null,
    "alpha3-b": "myv",
    "alpha3-t": null,
  },
  {
    English: "Nahuatl languages",
    French: "nahuatl, langues",
    alpha2: null,
    "alpha3-b": "nah",
    "alpha3-t": null,
  },
  {
    English: "North American Indian languages",
    French: "nord-amérindiennes, langues",
    alpha2: null,
    "alpha3-b": "nai",
    "alpha3-t": null,
  },
  {
    English: "Neapolitan",
    French: "napolitain",
    alpha2: null,
    "alpha3-b": "nap",
    "alpha3-t": null,
  },
  {
    English: "Nauru",
    French: "nauruan",
    alpha2: "na",
    "alpha3-b": "nau",
    "alpha3-t": null,
  },
  {
    English: "Navajo; Navaho",
    French: "navaho",
    alpha2: "nv",
    "alpha3-b": "nav",
    "alpha3-t": null,
  },
  {
    English: "Ndebele, South; South Ndebele",
    French: "ndébélé du Sud",
    alpha2: "nr",
    "alpha3-b": "nbl",
    "alpha3-t": null,
  },
  {
    English: "Ndebele, North; North Ndebele",
    French: "ndébélé du Nord",
    alpha2: "nd",
    "alpha3-b": "nde",
    "alpha3-t": null,
  },
  {
    English: "Ndonga",
    French: "ndonga",
    alpha2: "ng",
    "alpha3-b": "ndo",
    "alpha3-t": null,
  },
  {
    English: "Low German; Low Saxon; German, Low; Saxon, Low",
    French: "bas allemand; bas saxon; allemand, bas; saxon, bas",
    alpha2: null,
    "alpha3-b": "nds",
    "alpha3-t": null,
  },
  {
    English: "Nepali",
    French: "népalais",
    alpha2: "ne",
    "alpha3-b": "nep",
    "alpha3-t": null,
  },
  {
    English: "Nepal Bhasa; Newari",
    French: "nepal bhasa; newari",
    alpha2: null,
    "alpha3-b": "new",
    "alpha3-t": null,
  },
  {
    English: "Nias",
    French: "nias",
    alpha2: null,
    "alpha3-b": "nia",
    "alpha3-t": null,
  },
  {
    English: "Niger-Kordofanian languages",
    French: "nigéro-kordofaniennes, langues",
    alpha2: null,
    "alpha3-b": "nic",
    "alpha3-t": null,
  },
  {
    English: "Niuean",
    French: "niué",
    alpha2: null,
    "alpha3-b": "niu",
    "alpha3-t": null,
  },
  {
    English: "Norwegian Nynorsk; Nynorsk, Norwegian",
    French: "norvégien nynorsk; nynorsk, norvégien",
    alpha2: "nn",
    "alpha3-b": "nno",
    "alpha3-t": null,
  },
  {
    English: "Bokmål, Norwegian; Norwegian Bokmål",
    French: "norvégien bokmål",
    alpha2: "nb",
    "alpha3-b": "nob",
    "alpha3-t": null,
  },
  {
    English: "Nogai",
    French: "nogaï; nogay",
    alpha2: null,
    "alpha3-b": "nog",
    "alpha3-t": null,
  },
  {
    English: "Norse, Old",
    French: "norrois, vieux",
    alpha2: null,
    "alpha3-b": "non",
    "alpha3-t": null,
  },
  {
    English: "Norwegian",
    French: "norvégien",
    alpha2: "no",
    "alpha3-b": "nor",
    "alpha3-t": null,
  },
  {
    English: "N'Ko",
    French: "n'ko",
    alpha2: null,
    "alpha3-b": "nqo",
    "alpha3-t": null,
  },
  {
    English: "Pedi; Sepedi; Northern Sotho",
    French: "pedi; sepedi; sotho du Nord",
    alpha2: null,
    "alpha3-b": "nso",
    "alpha3-t": null,
  },
  {
    English: "Nubian languages",
    French: "nubiennes, langues",
    alpha2: null,
    "alpha3-b": "nub",
    "alpha3-t": null,
  },
  {
    English: "Classical Newari; Old Newari; Classical Nepal Bhasa",
    French: "newari classique",
    alpha2: null,
    "alpha3-b": "nwc",
    "alpha3-t": null,
  },
  {
    English: "Chichewa; Chewa; Nyanja",
    French: "chichewa; chewa; nyanja",
    alpha2: "ny",
    "alpha3-b": "nya",
    "alpha3-t": null,
  },
  {
    English: "Nyamwezi",
    French: "nyamwezi",
    alpha2: null,
    "alpha3-b": "nym",
    "alpha3-t": null,
  },
  {
    English: "Nyankole",
    French: "nyankolé",
    alpha2: null,
    "alpha3-b": "nyn",
    "alpha3-t": null,
  },
  {
    English: "Nyoro",
    French: "nyoro",
    alpha2: null,
    "alpha3-b": "nyo",
    "alpha3-t": null,
  },
  {
    English: "Nzima",
    French: "nzema",
    alpha2: null,
    "alpha3-b": "nzi",
    "alpha3-t": null,
  },
  {
    English: "Occitan (post 1500)",
    French: "occitan (après 1500)",
    alpha2: "oc",
    "alpha3-b": "oci",
    "alpha3-t": null,
  },
  {
    English: "Ojibwa",
    French: "ojibwa",
    alpha2: "oj",
    "alpha3-b": "oji",
    "alpha3-t": null,
  },
  {
    English: "Oriya",
    French: "oriya",
    alpha2: "or",
    "alpha3-b": "ori",
    "alpha3-t": null,
  },
  {
    English: "Oromo",
    French: "galla",
    alpha2: "om",
    "alpha3-b": "orm",
    "alpha3-t": null,
  },
  {
    English: "Osage",
    French: "osage",
    alpha2: null,
    "alpha3-b": "osa",
    "alpha3-t": null,
  },
  {
    English: "Ossetian; Ossetic",
    French: "ossète",
    alpha2: "os",
    "alpha3-b": "oss",
    "alpha3-t": null,
  },
  {
    English: "Turkish, Ottoman (1500-1928)",
    French: "turc ottoman (1500-1928)",
    alpha2: null,
    "alpha3-b": "ota",
    "alpha3-t": null,
  },
  {
    English: "Otomian languages",
    French: "otomi, langues",
    alpha2: null,
    "alpha3-b": "oto",
    "alpha3-t": null,
  },
  {
    English: "Papuan languages",
    French: "papoues, langues",
    alpha2: null,
    "alpha3-b": "paa",
    "alpha3-t": null,
  },
  {
    English: "Pangasinan",
    French: "pangasinan",
    alpha2: null,
    "alpha3-b": "pag",
    "alpha3-t": null,
  },
  {
    English: "Pahlavi",
    French: "pahlavi",
    alpha2: null,
    "alpha3-b": "pal",
    "alpha3-t": null,
  },
  {
    English: "Pampanga; Kapampangan",
    French: "pampangan",
    alpha2: null,
    "alpha3-b": "pam",
    "alpha3-t": null,
  },
  {
    English: "Panjabi; Punjabi",
    French: "pendjabi",
    alpha2: "pa",
    "alpha3-b": "pan",
    "alpha3-t": null,
  },
  {
    English: "Papiamento",
    French: "papiamento",
    alpha2: null,
    "alpha3-b": "pap",
    "alpha3-t": null,
  },
  {
    English: "Palauan",
    French: "palau",
    alpha2: null,
    "alpha3-b": "pau",
    "alpha3-t": null,
  },
  {
    English: "Persian, Old (ca.600-400 B.C.)",
    French: "perse, vieux (ca. 600-400 av. J.-C.)",
    alpha2: null,
    "alpha3-b": "peo",
    "alpha3-t": null,
  },
  {
    English: "Persian",
    French: "persan",
    alpha2: "fa",
    "alpha3-b": "per",
    "alpha3-t": "fas",
  },
  {
    English: "Philippine languages",
    French: "philippines, langues",
    alpha2: null,
    "alpha3-b": "phi",
    "alpha3-t": null,
  },
  {
    English: "Phoenician",
    French: "phénicien",
    alpha2: null,
    "alpha3-b": "phn",
    "alpha3-t": null,
  },
  {
    English: "Pali",
    French: "pali",
    alpha2: "pi",
    "alpha3-b": "pli",
    "alpha3-t": null,
  },
  {
    English: "Polish",
    French: "polonais",
    alpha2: "pl",
    "alpha3-b": "pol",
    "alpha3-t": null,
  },
  {
    English: "Pohnpeian",
    French: "pohnpei",
    alpha2: null,
    "alpha3-b": "pon",
    "alpha3-t": null,
  },
  {
    English: "Portuguese",
    French: "portugais",
    alpha2: "pt",
    "alpha3-b": "por",
    "alpha3-t": null,
  },
  {
    English: "Prakrit languages",
    French: "prâkrit, langues",
    alpha2: null,
    "alpha3-b": "pra",
    "alpha3-t": null,
  },
  {
    English: "Provençal, Old (to 1500); Occitan, Old (to 1500)",
    French: "provençal ancien (jusqu'à 1500); occitan ancien (jusqu'à 1500)",
    alpha2: null,
    "alpha3-b": "pro",
    "alpha3-t": null,
  },
  {
    English: "Pushto; Pashto",
    French: "pachto",
    alpha2: "ps",
    "alpha3-b": "pus",
    "alpha3-t": null,
  },
  {
    English: "Reserved for local use",
    French: "réservée à l'usage local",
    alpha2: null,
    "alpha3-b": "qaa-qtz",
    "alpha3-t": null,
  },
  {
    English: "Quechua",
    French: "quechua",
    alpha2: "qu",
    "alpha3-b": "que",
    "alpha3-t": null,
  },
  {
    English: "Rajasthani",
    French: "rajasthani",
    alpha2: null,
    "alpha3-b": "raj",
    "alpha3-t": null,
  },
  {
    English: "Rapanui",
    French: "rapanui",
    alpha2: null,
    "alpha3-b": "rap",
    "alpha3-t": null,
  },
  {
    English: "Rarotongan; Cook Islands Maori",
    French: "rarotonga; maori des îles Cook",
    alpha2: null,
    "alpha3-b": "rar",
    "alpha3-t": null,
  },
  {
    English: "Romance languages",
    French: "romanes, langues",
    alpha2: null,
    "alpha3-b": "roa",
    "alpha3-t": null,
  },
  {
    English: "Romansh",
    French: "romanche",
    alpha2: "rm",
    "alpha3-b": "roh",
    "alpha3-t": null,
  },
  {
    English: "Romany",
    French: "tsigane",
    alpha2: null,
    "alpha3-b": "rom",
    "alpha3-t": null,
  },
  {
    English: "Romanian; Moldavian; Moldovan",
    French: "roumain; moldave",
    alpha2: "ro",
    "alpha3-b": "rum",
    "alpha3-t": "ron",
  },
  {
    English: "Rundi",
    French: "rundi",
    alpha2: "rn",
    "alpha3-b": "run",
    "alpha3-t": null,
  },
  {
    English: "Aromanian; Arumanian; Macedo-Romanian",
    French: "aroumain; macédo-roumain",
    alpha2: null,
    "alpha3-b": "rup",
    "alpha3-t": null,
  },
  {
    English: "Russian",
    French: "russe",
    alpha2: "ru",
    "alpha3-b": "rus",
    "alpha3-t": null,
  },
  {
    English: "Sandawe",
    French: "sandawe",
    alpha2: null,
    "alpha3-b": "sad",
    "alpha3-t": null,
  },
  {
    English: "Sango",
    French: "sango",
    alpha2: "sg",
    "alpha3-b": "sag",
    "alpha3-t": null,
  },
  {
    English: "Yakut",
    French: "iakoute",
    alpha2: null,
    "alpha3-b": "sah",
    "alpha3-t": null,
  },
  {
    English: "South American Indian languages",
    French: "sud-amérindiennes, langues",
    alpha2: null,
    "alpha3-b": "sai",
    "alpha3-t": null,
  },
  {
    English: "Salishan languages",
    French: "salishennes, langues",
    alpha2: null,
    "alpha3-b": "sal",
    "alpha3-t": null,
  },
  {
    English: "Samaritan Aramaic",
    French: "samaritain",
    alpha2: null,
    "alpha3-b": "sam",
    "alpha3-t": null,
  },
  {
    English: "Sanskrit",
    French: "sanskrit",
    alpha2: "sa",
    "alpha3-b": "san",
    "alpha3-t": null,
  },
  {
    English: "Sasak",
    French: "sasak",
    alpha2: null,
    "alpha3-b": "sas",
    "alpha3-t": null,
  },
  {
    English: "Santali",
    French: "santal",
    alpha2: null,
    "alpha3-b": "sat",
    "alpha3-t": null,
  },
  {
    English: "Sicilian",
    French: "sicilien",
    alpha2: null,
    "alpha3-b": "scn",
    "alpha3-t": null,
  },
  {
    English: "Scots",
    French: "écossais",
    alpha2: null,
    "alpha3-b": "sco",
    "alpha3-t": null,
  },
  {
    English: "Selkup",
    French: "selkoupe",
    alpha2: null,
    "alpha3-b": "sel",
    "alpha3-t": null,
  },
  {
    English: "Semitic languages",
    French: "sémitiques, langues",
    alpha2: null,
    "alpha3-b": "sem",
    "alpha3-t": null,
  },
  {
    English: "Irish, Old (to 900)",
    French: "irlandais ancien (jusqu'à 900)",
    alpha2: null,
    "alpha3-b": "sga",
    "alpha3-t": null,
  },
  {
    English: "Sign Languages",
    French: "langues des signes",
    alpha2: null,
    "alpha3-b": "sgn",
    "alpha3-t": null,
  },
  {
    English: "Shan",
    French: "chan",
    alpha2: null,
    "alpha3-b": "shn",
    "alpha3-t": null,
  },
  {
    English: "Sidamo",
    French: "sidamo",
    alpha2: null,
    "alpha3-b": "sid",
    "alpha3-t": null,
  },
  {
    English: "Sinhala; Sinhalese",
    French: "singhalais",
    alpha2: "si",
    "alpha3-b": "sin",
    "alpha3-t": null,
  },
  {
    English: "Siouan languages",
    French: "sioux, langues",
    alpha2: null,
    "alpha3-b": "sio",
    "alpha3-t": null,
  },
  {
    English: "Sino-Tibetan languages",
    French: "sino-tibétaines, langues",
    alpha2: null,
    "alpha3-b": "sit",
    "alpha3-t": null,
  },
  {
    English: "Slavic languages",
    French: "slaves, langues",
    alpha2: null,
    "alpha3-b": "sla",
    "alpha3-t": null,
  },
  {
    English: "Slovak",
    French: "slovaque",
    alpha2: "sk",
    "alpha3-b": "slo",
    "alpha3-t": "slk",
  },
  {
    English: "Slovenian",
    French: "slovène",
    alpha2: "sl",
    "alpha3-b": "slv",
    "alpha3-t": null,
  },
  {
    English: "Southern Sami",
    French: "sami du Sud",
    alpha2: null,
    "alpha3-b": "sma",
    "alpha3-t": null,
  },
  {
    English: "Northern Sami",
    French: "sami du Nord",
    alpha2: "se",
    "alpha3-b": "sme",
    "alpha3-t": null,
  },
  {
    English: "Sami languages",
    French: "sames, langues",
    alpha2: null,
    "alpha3-b": "smi",
    "alpha3-t": null,
  },
  {
    English: "Lule Sami",
    French: "sami de Lule",
    alpha2: null,
    "alpha3-b": "smj",
    "alpha3-t": null,
  },
  {
    English: "Inari Sami",
    French: "sami d'Inari",
    alpha2: null,
    "alpha3-b": "smn",
    "alpha3-t": null,
  },
  {
    English: "Samoan",
    French: "samoan",
    alpha2: "sm",
    "alpha3-b": "smo",
    "alpha3-t": null,
  },
  {
    English: "Skolt Sami",
    French: "sami skolt",
    alpha2: null,
    "alpha3-b": "sms",
    "alpha3-t": null,
  },
  {
    English: "Shona",
    French: "shona",
    alpha2: "sn",
    "alpha3-b": "sna",
    "alpha3-t": null,
  },
  {
    English: "Sindhi",
    French: "sindhi",
    alpha2: "sd",
    "alpha3-b": "snd",
    "alpha3-t": null,
  },
  {
    English: "Soninke",
    French: "soninké",
    alpha2: null,
    "alpha3-b": "snk",
    "alpha3-t": null,
  },
  {
    English: "Sogdian",
    French: "sogdien",
    alpha2: null,
    "alpha3-b": "sog",
    "alpha3-t": null,
  },
  {
    English: "Somali",
    French: "somali",
    alpha2: "so",
    "alpha3-b": "som",
    "alpha3-t": null,
  },
  {
    English: "Songhai languages",
    French: "songhai, langues",
    alpha2: null,
    "alpha3-b": "son",
    "alpha3-t": null,
  },
  {
    English: "Sotho, Southern",
    French: "sotho du Sud",
    alpha2: "st",
    "alpha3-b": "sot",
    "alpha3-t": null,
  },
  {
    English: "Spanish; Castilian",
    French: "espagnol; castillan",
    alpha2: "es",
    "alpha3-b": "spa",
    "alpha3-t": null,
  },
  {
    English: "Sardinian",
    French: "sarde",
    alpha2: "sc",
    "alpha3-b": "srd",
    "alpha3-t": null,
  },
  {
    English: "Sranan Tongo",
    French: "sranan tongo",
    alpha2: null,
    "alpha3-b": "srn",
    "alpha3-t": null,
  },
  {
    English: "Serbian",
    French: "serbe",
    alpha2: "sr",
    "alpha3-b": "srp",
    "alpha3-t": null,
  },
  {
    English: "Serer",
    French: "sérère",
    alpha2: null,
    "alpha3-b": "srr",
    "alpha3-t": null,
  },
  {
    English: "Nilo-Saharan languages",
    French: "nilo-sahariennes, langues",
    alpha2: null,
    "alpha3-b": "ssa",
    "alpha3-t": null,
  },
  {
    English: "Swati",
    French: "swati",
    alpha2: "ss",
    "alpha3-b": "ssw",
    "alpha3-t": null,
  },
  {
    English: "Sukuma",
    French: "sukuma",
    alpha2: null,
    "alpha3-b": "suk",
    "alpha3-t": null,
  },
  {
    English: "Sundanese",
    French: "soundanais",
    alpha2: "su",
    "alpha3-b": "sun",
    "alpha3-t": null,
  },
  {
    English: "Susu",
    French: "soussou",
    alpha2: null,
    "alpha3-b": "sus",
    "alpha3-t": null,
  },
  {
    English: "Sumerian",
    French: "sumérien",
    alpha2: null,
    "alpha3-b": "sux",
    "alpha3-t": null,
  },
  {
    English: "Swahili",
    French: "swahili",
    alpha2: "sw",
    "alpha3-b": "swa",
    "alpha3-t": null,
  },
  {
    English: "Swedish",
    French: "suédois",
    alpha2: "sv",
    "alpha3-b": "swe",
    "alpha3-t": null,
  },
  {
    English: "Classical Syriac",
    French: "syriaque classique",
    alpha2: null,
    "alpha3-b": "syc",
    "alpha3-t": null,
  },
  {
    English: "Syriac",
    French: "syriaque",
    alpha2: null,
    "alpha3-b": "syr",
    "alpha3-t": null,
  },
  {
    English: "Tahitian",
    French: "tahitien",
    alpha2: "ty",
    "alpha3-b": "tah",
    "alpha3-t": null,
  },
  {
    English: "Tai languages",
    French: "tai, langues",
    alpha2: null,
    "alpha3-b": "tai",
    "alpha3-t": null,
  },
  {
    English: "Tamil",
    French: "tamoul",
    alpha2: "ta",
    "alpha3-b": "tam",
    "alpha3-t": null,
  },
  {
    English: "Tatar",
    French: "tatar",
    alpha2: "tt",
    "alpha3-b": "tat",
    "alpha3-t": null,
  },
  {
    English: "Telugu",
    French: "télougou",
    alpha2: "te",
    "alpha3-b": "tel",
    "alpha3-t": null,
  },
  {
    English: "Timne",
    French: "temne",
    alpha2: null,
    "alpha3-b": "tem",
    "alpha3-t": null,
  },
  {
    English: "Tereno",
    French: "tereno",
    alpha2: null,
    "alpha3-b": "ter",
    "alpha3-t": null,
  },
  {
    English: "Tetum",
    French: "tetum",
    alpha2: null,
    "alpha3-b": "tet",
    "alpha3-t": null,
  },
  {
    English: "Tajik",
    French: "tadjik",
    alpha2: "tg",
    "alpha3-b": "tgk",
    "alpha3-t": null,
  },
  {
    English: "Tagalog",
    French: "tagalog",
    alpha2: "tl",
    "alpha3-b": "tgl",
    "alpha3-t": null,
  },
  {
    English: "Thai",
    French: "thaï",
    alpha2: "th",
    "alpha3-b": "tha",
    "alpha3-t": null,
  },
  {
    English: "Tibetan",
    French: "tibétain",
    alpha2: "bo",
    "alpha3-b": "tib",
    "alpha3-t": "bod",
  },
  {
    English: "Tigre",
    French: "tigré",
    alpha2: null,
    "alpha3-b": "tig",
    "alpha3-t": null,
  },
  {
    English: "Tigrinya",
    French: "tigrigna",
    alpha2: "ti",
    "alpha3-b": "tir",
    "alpha3-t": null,
  },
  {
    English: "Tiv",
    French: "tiv",
    alpha2: null,
    "alpha3-b": "tiv",
    "alpha3-t": null,
  },
  {
    English: "Tokelau",
    French: "tokelau",
    alpha2: null,
    "alpha3-b": "tkl",
    "alpha3-t": null,
  },
  {
    English: "Klingon; tlhIngan-Hol",
    French: "klingon",
    alpha2: null,
    "alpha3-b": "tlh",
    "alpha3-t": null,
  },
  {
    English: "Tlingit",
    French: "tlingit",
    alpha2: null,
    "alpha3-b": "tli",
    "alpha3-t": null,
  },
  {
    English: "Tamashek",
    French: "tamacheq",
    alpha2: null,
    "alpha3-b": "tmh",
    "alpha3-t": null,
  },
  {
    English: "Tonga (Nyasa)",
    French: "tonga (Nyasa)",
    alpha2: null,
    "alpha3-b": "tog",
    "alpha3-t": null,
  },
  {
    English: "Tonga (Tonga Islands)",
    French: "tongan (Îles Tonga)",
    alpha2: "to",
    "alpha3-b": "ton",
    "alpha3-t": null,
  },
  {
    English: "Tok Pisin",
    French: "tok pisin",
    alpha2: null,
    "alpha3-b": "tpi",
    "alpha3-t": null,
  },
  {
    English: "Tsimshian",
    French: "tsimshian",
    alpha2: null,
    "alpha3-b": "tsi",
    "alpha3-t": null,
  },
  {
    English: "Tswana",
    French: "tswana",
    alpha2: "tn",
    "alpha3-b": "tsn",
    "alpha3-t": null,
  },
  {
    English: "Tsonga",
    French: "tsonga",
    alpha2: "ts",
    "alpha3-b": "tso",
    "alpha3-t": null,
  },
  {
    English: "Turkmen",
    French: "turkmène",
    alpha2: "tk",
    "alpha3-b": "tuk",
    "alpha3-t": null,
  },
  {
    English: "Tumbuka",
    French: "tumbuka",
    alpha2: null,
    "alpha3-b": "tum",
    "alpha3-t": null,
  },
  {
    English: "Tupi languages",
    French: "tupi, langues",
    alpha2: null,
    "alpha3-b": "tup",
    "alpha3-t": null,
  },
  {
    English: "Turkish",
    French: "turc",
    alpha2: "tr",
    "alpha3-b": "tur",
    "alpha3-t": null,
  },
  {
    English: "Altaic languages",
    French: "altaïques, langues",
    alpha2: null,
    "alpha3-b": "tut",
    "alpha3-t": null,
  },
  {
    English: "Tuvalu",
    French: "tuvalu",
    alpha2: null,
    "alpha3-b": "tvl",
    "alpha3-t": null,
  },
  {
    English: "Twi",
    French: "twi",
    alpha2: "tw",
    "alpha3-b": "twi",
    "alpha3-t": null,
  },
  {
    English: "Tuvinian",
    French: "touva",
    alpha2: null,
    "alpha3-b": "tyv",
    "alpha3-t": null,
  },
  {
    English: "Udmurt",
    French: "oudmourte",
    alpha2: null,
    "alpha3-b": "udm",
    "alpha3-t": null,
  },
  {
    English: "Ugaritic",
    French: "ougaritique",
    alpha2: null,
    "alpha3-b": "uga",
    "alpha3-t": null,
  },
  {
    English: "Uighur; Uyghur",
    French: "ouïgour",
    alpha2: "ug",
    "alpha3-b": "uig",
    "alpha3-t": null,
  },
  {
    English: "Ukrainian",
    French: "ukrainien",
    alpha2: "uk",
    "alpha3-b": "ukr",
    "alpha3-t": null,
  },
  {
    English: "Umbundu",
    French: "umbundu",
    alpha2: null,
    "alpha3-b": "umb",
    "alpha3-t": null,
  },
  {
    English: "Undetermined",
    French: "indéterminée",
    alpha2: null,
    "alpha3-b": "und",
    "alpha3-t": null,
  },
  {
    English: "Urdu",
    French: "ourdou",
    alpha2: "ur",
    "alpha3-b": "urd",
    "alpha3-t": null,
  },
  {
    English: "Uzbek",
    French: "ouszbek",
    alpha2: "uz",
    "alpha3-b": "uzb",
    "alpha3-t": null,
  },
  {
    English: "Vai",
    French: "vaï",
    alpha2: null,
    "alpha3-b": "vai",
    "alpha3-t": null,
  },
  {
    English: "Venda",
    French: "venda",
    alpha2: "ve",
    "alpha3-b": "ven",
    "alpha3-t": null,
  },
  {
    English: "Vietnamese",
    French: "vietnamien",
    alpha2: "vi",
    "alpha3-b": "vie",
    "alpha3-t": null,
  },
  {
    English: "Volapük",
    French: "volapük",
    alpha2: "vo",
    "alpha3-b": "vol",
    "alpha3-t": null,
  },
  {
    English: "Votic",
    French: "vote",
    alpha2: null,
    "alpha3-b": "vot",
    "alpha3-t": null,
  },
  {
    English: "Wakashan languages",
    French: "wakashanes, langues",
    alpha2: null,
    "alpha3-b": "wak",
    "alpha3-t": null,
  },
  {
    English: "Wolaitta; Wolaytta",
    French: "wolaitta; wolaytta",
    alpha2: null,
    "alpha3-b": "wal",
    "alpha3-t": null,
  },
  {
    English: "Waray",
    French: "waray",
    alpha2: null,
    "alpha3-b": "war",
    "alpha3-t": null,
  },
  {
    English: "Washo",
    French: "washo",
    alpha2: null,
    "alpha3-b": "was",
    "alpha3-t": null,
  },
  {
    English: "Welsh",
    French: "gallois",
    alpha2: "cy",
    "alpha3-b": "wel",
    "alpha3-t": "cym",
  },
  {
    English: "Sorbian languages",
    French: "sorabes, langues",
    alpha2: null,
    "alpha3-b": "wen",
    "alpha3-t": null,
  },
  {
    English: "Walloon",
    French: "wallon",
    alpha2: "wa",
    "alpha3-b": "wln",
    "alpha3-t": null,
  },
  {
    English: "Wolof",
    French: "wolof",
    alpha2: "wo",
    "alpha3-b": "wol",
    "alpha3-t": null,
  },
  {
    English: "Kalmyk; Oirat",
    French: "kalmouk; oïrat",
    alpha2: null,
    "alpha3-b": "xal",
    "alpha3-t": null,
  },
  {
    English: "Xhosa",
    French: "xhosa",
    alpha2: "xh",
    "alpha3-b": "xho",
    "alpha3-t": null,
  },
  {
    English: "Yao",
    French: "yao",
    alpha2: null,
    "alpha3-b": "yao",
    "alpha3-t": null,
  },
  {
    English: "Yapese",
    French: "yapois",
    alpha2: null,
    "alpha3-b": "yap",
    "alpha3-t": null,
  },
  {
    English: "Yiddish",
    French: "yiddish",
    alpha2: "yi",
    "alpha3-b": "yid",
    "alpha3-t": null,
  },
  {
    English: "Yoruba",
    French: "yoruba",
    alpha2: "yo",
    "alpha3-b": "yor",
    "alpha3-t": null,
  },
  {
    English: "Yupik languages",
    French: "yupik, langues",
    alpha2: null,
    "alpha3-b": "ypk",
    "alpha3-t": null,
  },
  {
    English: "Zapotec",
    French: "zapotèque",
    alpha2: null,
    "alpha3-b": "zap",
    "alpha3-t": null,
  },
  {
    English: "Blissymbols; Blissymbolics; Bliss",
    French: "symboles Bliss; Bliss",
    alpha2: null,
    "alpha3-b": "zbl",
    "alpha3-t": null,
  },
  {
    English: "Zenaga",
    French: "zenaga",
    alpha2: null,
    "alpha3-b": "zen",
    "alpha3-t": null,
  },
  {
    English: "Standard Moroccan Tamazight",
    French: "amazighe standard marocain",
    alpha2: null,
    "alpha3-b": "zgh",
    "alpha3-t": null,
  },
  {
    English: "Zhuang; Chuang",
    French: "zhuang; chuang",
    alpha2: "za",
    "alpha3-b": "zha",
    "alpha3-t": null,
  },
  {
    English: "Zande languages",
    French: "zandé, langues",
    alpha2: null,
    "alpha3-b": "znd",
    "alpha3-t": null,
  },
  {
    English: "Zulu",
    French: "zoulou",
    alpha2: "zu",
    "alpha3-b": "zul",
    "alpha3-t": null,
  },
  {
    English: "Zuni",
    French: "zuni",
    alpha2: null,
    "alpha3-b": "zun",
    "alpha3-t": null,
  },
  {
    English: "No linguistic content; Not applicable",
    French: "pas de contenu linguistique; non applicable",
    alpha2: null,
    "alpha3-b": "zxx",
    "alpha3-t": null,
  },
  {
    English: "Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki",
    French: "zaza; dimili; dimli; kirdki; kirmanjki; zazaki",
    alpha2: null,
    "alpha3-b": "zza",
    "alpha3-t": null,
  },
];

export const STREAM_STATUS = {
  ALL: "all",
  COMPLETED: 1,
  PROCESSING: 2,
  PENDING: 3,
  FAILED: 4,
  CANCELLED: 5,
  DELAYED: 6,
};

// Allow Fandcode User for Cricket InputCrop
export const FancodeUsers = ["WzLdx1SU6"];

export const ROLE = {
  SUPER_ADMIN: "superadmin",
  ADMIN: "admin",
  USER: "user",
};
export const ZEE_CATEGORY_TYPES = {
  WEBISODE: "webisode",
  BESTSCENE: "best_scene",
  PERFORMANCE: "performance",
};
export const ZEE_VERTICAL = "zee_vertical";

export const SHOW_PROGRESS_TEXT = (value) => {
  let text = "";
  if (1 >= value && value < 20) {
    text = "Process Started";
  } else if (20 >= value && value < 30) {
    text = "Audio convert and Read Done";
  } else if (30 >= value && value < 40) {
    text = "Scene Split Done";
  } else if (40 >= value && value < 50) {
    text = "Get Scene Match Values";
  } else if (50 >= value && value < 75) {
    text = "Scene Matching Done";
  } else if (75 >= value && value < 85) {
    text = "Create New Short Scenes";
  } else if (85 >= value && value < 98) {
    text = "Face Detection Applied";
  } else if (98 <= value) {
    text = "All Task Done";
  }
  return text;
};
