import * as React from "react";

const ThreeDotsIconComponent = (props) => (
  <svg width={4} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.5 18a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM.5 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM.5 2a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill="#2E2E2E"
      stroke="#2E2E2E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThreeDotsIconComponent;
