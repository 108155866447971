import * as React from "react";

const InfoIcon = (props) => (
  <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.002.98C5.638.98.48 6.138.48 12.5a11.52 11.52 0 0 0 11.522 11.522c6.36 0 11.52-5.158 11.52-11.521S18.362.98 12.002.98Zm1.075 4.16c1.123 0 1.453.651 1.453 1.396 0 .93-.744 1.79-2.015 1.79-1.063 0-1.57-.533-1.538-1.418 0-.745.623-1.768 2.1-1.768ZM10.199 19.4c-.768 0-1.328-.466-.792-2.512l.88-3.63c.152-.581.177-.814 0-.814-.23 0-1.227.4-1.815.797l-.382-.628c1.866-1.558 4.011-2.473 4.93-2.473.767 0 .895.907.512 2.304l-1.008 3.816c-.18.674-.102.907.077.907.23 0 .983-.278 1.725-.863l.434.584c-1.815 1.814-3.794 2.512-4.56 2.512Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoIcon;
