import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" fill="none" viewBox="0 0 24 19" {...props}>
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M17.786 6.943l4.68-2.367a.708.708 0 01.695.032c.21.132.34.364.339.615v8.666a.728.728 0 01-.245.55.707.707 0 01-.79.1l-4.68-2.37"
    ></path>
    <path
      stroke="currentColor"
      d="M14.5 1h-10A3.5 3.5 0 001 4.5v10A3.5 3.5 0 004.5 18h10a3.5 3.5 0 003.5-3.5v-10A3.5 3.5 0 0014.5 1z"
    ></path>
  </svg>
);

export default SvgComponent;
