import { Record } from "immutable";
import { assign } from "lodash";
// import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";

import * as api from "./api";
import { INIT } from "../../constants/phase";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const GET_DASHBOARD_STATES = "magnifi/user/GET_DASHBOARD_STATES";
export const GET_VIDEOLIST_STATES = "magnifi/user/GET_VIDEOLIST_STATES";
export const GET_FORGOTPASSWORD_STATES = "magnifi/user/GET_FORGOTPASSWORD_STATES";
export const GET_RESETPASSWORD_STATES = "magnifi/user/GET_RESETPASSWORD_STATES";
export const FETCH_CHART_DATA = "magnifi/user/FETCH_CHART_DATA";
export const GET_STREAM_STATES = "home/GET_STREAM_STATES";
export const GET_ANALYTICS_TOKEN = "home/GET_ANALYTICS_TOKEN";
export const FETCH_USER_DOWNLOAD_REPORT = "magnifi/user/FETCH_USER_DOWNLOAD_REPORT";

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  error: null,
  user: {},
  isSuccess: false,
  message: "",
  loginError: "",
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    // const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    const token = localStorage.getItem("authToken");
    super(assign({ token }, desiredValues));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getDashBoardState = (credentials) => {
  return {
    type: GET_DASHBOARD_STATES,
    payload: api.getDashBoardStates(credentials),
  };
};

/***********************************
 * Epics
 ***********************************/
export const getVideoListState = (credentials) => {
  return {
    type: GET_VIDEOLIST_STATES,
    payload: api.getVideoListStates(credentials),
  };
};

export const forgotPassword = (credentials) => {
  return {
    type: GET_FORGOTPASSWORD_STATES,
    payload: api.getForgotPasswords(credentials),
  };
};

export const resetPassword = (credentials) => {
  return {
    type: GET_RESETPASSWORD_STATES,
    payload: api.resetPassword(credentials),
  };
};

export const fetchChartData = (payload) => {
  return {
    type: FETCH_CHART_DATA,
    payload: api.getChartData(payload),
  };
};

export const getStreamStates = (value) => {
  return {
    type: GET_STREAM_STATES,
    payload: api.getStreamStates(value),
  };
};
export const analyticsToken = (value) => {
  return {
    type: GET_ANALYTICS_TOKEN,
    payload: api.analyticsToken(value),
  };
};

export const getCSVReport = (payload) => {
  return {
    type: FETCH_USER_DOWNLOAD_REPORT,
    payload: api.getCSVReport(payload),
  };
};

export const dashboardEpic = combineEpics();
export const videolistEpic = combineEpics();
