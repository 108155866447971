import React, { useEffect, useState, useContext } from "react";
import VideoGrid from "./VideoGrid";
import EmptyIcon from "../../../images/EmptyIcon.png";
import PreviewDialog from "../../../components/common/Modals/PreviewDialog";
import { SocketContext } from "../../../context/socket";

const Gridview = (props) => {
  const {
    getAspectRatios,
    clipsDetails,
    allClips,
    filters,
    allClipsForCms,
    setClipData,
    streamDetails,
    aspectRatio,
    deleteClips,
    editClip,
    publishClip,
    getTrimClip,
    folderLength,
    storage,
    id,
    updatesftp,
    step,
    generateAutoFlip,
    setToggle,
    toggle,
    clipListFolder,
    copyClip,
    s3BucketPublish,
    jsonBucketPublish,
    downloadVideo,
    setFolderClips,
    folderClips,
    folderArray,
    setFolderArray,
    setClip,
    setShowClass,
    showClass,
    checkhighLight,
    user,
    clipExportJson,
    clipExportXml,
    downloadClips,
    openPreviewModal,
    modalIsOpen,
    setIsOpen,
    clipUrl,
    clipTitle,
    clientWebHookPublish,
    dumpOnS3,
    bucketCopy,
    fetchUserDetails,
    copyClipToReviewer,
    getPublicUrls,
    uploadClipInBrightCove,
    fetchBrightCoveToken,
    getCustomFields,
    fetchUsersByUserIds,
    allUsers,
    deleteEditedClip,
    clientSetting,
    addThumbnail,
    getUploadPicture,
    updateClipDetails,
    GenerateVideoTitle,
    singleClipDetail,
    AccessControl,
    genrateNewClip,
    overWriteClip,
    setSelectedClipIDS,
    selectedClipIDS,
    setClipCounts,
    s3Open,
    setS3Open,
    bulkS3BucketPublish,
    setSelectedFileUrls,
    selectedFileUrls,
    clipIdsArray,
    setClipIdsArray,
    bytesToMB,
    hoveredVideo,
    setHoveredVideo,
    shareOnSocialMedias,
    getVideoMetaData,
    getS3PresignedURl,
    filterClipsByTag,
    clipPreviewData,
    clipDetail,
    addPublishNotification,
    hlsToMp4Data,
    ruleTitle,
    refreshClips,
    handleSteps,
    setLiveVideoUrl,
    getStreamDetail,
  } = props;

  const { webhookSocket } = useContext(SocketContext);
  const [clipDetails, setClipDetails] = useState({});
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    const userId = localStorage.getItem("__uid");
    if (userId && user?.userId && user?.userId === userId) {
      webhookSocket.on(`illustoUpdateClipResponse${userId}`, (data) => {
        refreshClips();
      });
      return () => {
        webhookSocket.off(`illustoUpdateClipResponse${userId}`);
      };
    }
  }, []);

  return (
    <>
      <PreviewDialog
        clipTitle={clipDetails?.episodeTitle}
        clipUrl={clipPreviewData.url}
        duration={clipPreviewData.duration}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        streamId={id}
        clip={clipDetails}
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
      {clipsDetails.length || hlsToMp4Data.length ? (
        <div className="vv-grid clip-grid-view" grid-size="5">
          <VideoGrid
            isRuleBasedClips={props?.isRuleBasedClips}
            filters={filters}
            bytesToMB={bytesToMB}
            clipListFolder={clipListFolder}
            singleClipDetail={singleClipDetail}
            generateAutoFlip={generateAutoFlip}
            setToggle={setToggle}
            toggle={toggle}
            step={step}
            updatesftp={updatesftp}
            genrateNewClip={genrateNewClip}
            getAspectRatios={getAspectRatios}
            allClips={allClips}
            allClipsForCms={allClipsForCms}
            id={id}
            ruleTitle={ruleTitle}
            hoveredVideo={hoveredVideo}
            setHoveredVideo={setHoveredVideo}
            deleteEditedClip={deleteEditedClip}
            setClipData={setClipData}
            folderLength={folderLength}
            streamDetails={streamDetails}
            storage={storage}
            aspectRatio={aspectRatio}
            deleteClips={deleteClips}
            copyClip={copyClip}
            editClip={editClip}
            publishClip={publishClip}
            getTrimClip={getTrimClip}
            s3BucketPublish={s3BucketPublish}
            jsonBucketPublish={jsonBucketPublish}
            downloadVideo={downloadVideo}
            setFolderClips={setFolderClips}
            folderClips={folderClips}
            folderArray={folderArray}
            setFolderArray={setFolderArray}
            setClip={setClip}
            showClass={showClass}
            setShowClass={setShowClass}
            checkhighLight={checkhighLight}
            user={user}
            clipExportJson={clipExportJson}
            clipExportXml={clipExportXml}
            downloadClips={downloadClips}
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            clipUrl={clipUrl}
            clipTitle={clipTitle}
            openPreviewModal={openPreviewModal}
            clientWebHookPublish={clientWebHookPublish}
            fetchUserDetails={fetchUserDetails}
            copyClipToReviewer={copyClipToReviewer}
            bucketCopy={bucketCopy}
            dumpOnS3={dumpOnS3}
            getPublicUrls={getPublicUrls}
            uploadClipInBrightCove={uploadClipInBrightCove}
            fetchBrightCoveToken={fetchBrightCoveToken}
            getCustomFields={getCustomFields}
            fetchUsersByUserIds={fetchUsersByUserIds}
            allUsers={allUsers}
            clientSetting={clientSetting}
            addThumbnail={addThumbnail}
            setClipDetails={setClipDetails}
            getUploadPicture={getUploadPicture}
            updateClipDetails={updateClipDetails}
            GenerateVideoTitle={GenerateVideoTitle}
            clipsDetails={clipsDetails}
            AccessControl={AccessControl}
            overWriteClip={overWriteClip}
            s3Open={s3Open}
            setS3Open={setS3Open}
            clipIdsArray={clipIdsArray}
            setClipIdsArray={setClipIdsArray}
            shareOnSocialMedias={shareOnSocialMedias}
            getVideoMetaData={getVideoMetaData}
            getS3PresignedURl={getS3PresignedURl}
            filterClipsByTag={filterClipsByTag}
            clipDetail={clipDetail}
            addPublishNotification={addPublishNotification}
            hlsToMp4Data={hlsToMp4Data}
            refreshClips={refreshClips}
            handleSteps={handleSteps}
            setLiveVideoUrl={setLiveVideoUrl}
            getStreamDetail={getStreamDetail}
            {...props}
          />
        </div>
      ) : (
        <div className="d-block pt-5 empty-screen-block text-center">
          <img src={EmptyIcon} alt="" className="empty-icon" />
          <h1>Clips not found!</h1>
        </div>
      )}
    </>
  );
};

export default Gridview;
