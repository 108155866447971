import fetch from "isomorphic-fetch";
import { get } from "lodash";

const handleHTTPErrors = (res) => {
  if (res.ok) return res;
  return res.json().then((err) => {
    throw err;
  });
};

export function jwPlayerCDNUrl() {
  const jwPlayerUrl = get(process.env, "REACT_APP_JW_PLAYER_CDN", "https://test-fancode.s3.amazonaws.com/zCI2rGRk.js");
  return jwPlayerUrl;
}

export default function fetchUrl(url, options) {
  const jwtToken = localStorage.getItem("authToken");
  if (jwtToken) {
    let authAddedOptions = options;
    if (typeof options !== "object") {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== "object") {
      authAddedOptions.headers = {};
    }
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: jwtToken,
    };
    return fetch(url, authAddedOptions).then(handleHTTPErrors);
  } else {
    return fetch(url, options).then(handleHTTPErrors);
  }
}
