import { Record } from "immutable";
import { assign } from "lodash";
// import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";

import { INIT } from "../../constants/phase";
import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const GET_VIDEOGRID_STATES = "magnifi/user/GET_VIDEOGRID_STATES";
export const GET_SPORTDETAIL_STATES = "magnifi/user/GET_SPORTDETAIL_STATES";
export const GET_USERTDETAIL_STATES = "magnifi/user/GET_USERDETAIL_STATES";
export const GET_USERSTDETAIL_STATES = "magnifi/user/GET_USERSDETAIL_STATES";
export const GET_STREAM_DETAIL = "magnifi/user/GET_STREAM_DETAIL";
export const GET_BULK_DATA = "magnifi/user/GET_BULK_DATA";
export const GET_AllCLIPS = "magnifi/user/GET_AllCLIPS";
export const ADD_SHOW = "magnifi/user/ADD_SHOW";
export const EDIT_SHOW = "magnifi/user/EDIT_SHOW";
export const DELETE_SHOWS = "magnifi/user/DELETE_SHOWS";
export const GET_SINGLESTREAM = "mangnifi/user/GET_SINGLESTREAM";
export const GET_LATESTSTREAMS = "mangnifi/user/GET_LATESTSTREAMS";
export const GET_TRIMCLIP = "mangnifi/user/GET_TRIMCLIP";
export const UPLOAD_PICTURE = "mangnifi/user/UPLOAD_PICTURE";
export const GET_IMAGES = "mangnifi/user/GET_IMAGES";
export const GET_AUDIO = "mangnifi/videoGrid/GET_AUDIO";
export const ADD_IMAGES = "mangnifi/user/ADD_IMAGES";
export const ADD_AUDIO = "mangnifi/videoGrid/ADD_AUDIO";
export const ADD_AUDIO_To_FOLDER = "mangnifi/videoGrid/ADD_AUDIO";
export const GET_ADD_IMAGES = "mangnifi/user/GET_ADD_IMAGES";
export const ADD_SLATE = "mangnifi/user/ADD_SLATE";
export const UPLOAD_SLATE = "mangnifi/user/UPLOAD_SLATE";
export const SHOW_SLATE = "mangnifi/user/SHOW_SLATE";
export const FETCH_TOURNAMENTS = "mangnifi/videoGrid/FETCH_TOURNAMENTS";
export const UPDATE_SFTP = "mangnifi/videoGrid/UPDATE_SFTP";
export const FETCH_STORAGE = "mangnifi/videoGrid/FETCH_STORAGE";
export const CREATE_STREAM = "mangnifi/videoGrid/CREATE_STREAM";
export const FETCH_EVENT = "mangnifi/videoGrid/FETCH_EVENT";
export const FETCH_BRIGHTCOVE_TOKEN = "mangnifi/videoGrid/FETCH_BRIGHTCOVE_TOKEN";
export const FETCH_BRIGHTCOVE_VIDEOS = "mangnifi/videoGrid/FETCH_BRIGHTCOVE_VIDEOS";
export const FETCH_PLAYERS = "magnifi/videoGrid/FETCH_PLAYERS";
export const FETCH_EVENTS = "magnifi/videoGrid/FETCH_EVENTS";
export const EDIT_STREAM = "mangnifi/videoGrid/EDIT_STREAM";
export const DELETE_STREAM = "mangnifi/videoGrid/DELETE_STREAM";
export const DELETE_EDITED_CLIP = "mangnifi/videoGrid/DELETE_EDITED_CLIP";
export const FETCH_FOLDERS = "mangnifi/videoGrid/FETCH_FOLDERS";
export const CREATE_FOLDERS = "mangnifi/videoGrid/CREATE_FOLDERS";
export const UPDATE_FOLDER = "mangnifi/videoGrid/UPDATE_FOLDER";
export const ADD_SPORTS = "mangnifi/videoGrid/ADD_SPORTS";
export const DELETE_CATEGORY = "mangnifi/videoGrid/DELETE_CATEGORY";
export const DELETE_ARCHIVE_CLIPS = "mangnifi/videoGrid/DELETE_ARCHIVE_CLIPS";
export const DELETE_ARCHIVE_STREAM = "mangnifi/videoGrid/DELETE_ARCHIVE_STREAM";
export const GET_ARCHIVE_STREAM = "mangnifi/videoGrid/GET_ARCHIVE_STREAM";
export const GET_ARCHIVE_CLIPS = "mangnifi/videoGrid/GET_ARCHIVE_CLIPS";
export const RESTORE_ARCHIVE_STREAM = "mangnifi/videoGrid/RESTORE_ARCHIVE_STREAM";
export const RESTORE_ARCHIVED_STREAM = "mangnifi/videoGrid/RESTORE_ARCHIVED_STREAM";
export const RESTORE_ARCHIVE_CLIPS = "mangnifi/videoGrid/RESTORE_ARCHIVE_CLIPS";
export const GET_COPIED_STREAM_COUNT = "mangnifi/videoGrid/GET_COPIED_STREAM_COUNT";
export const GET_CUSTOMFIELD = "mangnifi/videoGrid/GET_CUSTOMFIELD";
export const GET_RTMP_CHANNELS = "mangnifi/videoGrid/GET_RTMP_CHANNELS";
export const UPDATE_RTMP_STATE = "mangnifi/videoGrid/UPDATE_RTMP_STATE";
export const UPDATE_RTMP_CHANNEL = "mangnifi/videoGrid/UPDATE_RTMP_CHANNEL";
export const GET_CHANNEL_DETAILS = "mangnifi/videoGrid/GET_CHANNEL_DETAILS";
export const CREATE_CUSTOM_FIELDS = "mangnifi/videoGrid/CREATE_CUSTOM_FIELDS";
export const DELETE_FIELDS = "mangnifi/videoGrid/DELETE_FIELDS";
export const DELETE_AUDIO = "mangnifi/videoGrid/DELETE_AUDIO";
export const GET_SPORTS_TEAM = "mangnifi/videoGrid/GET_SPORTS_TEAM";
export const CREATE_TEAM = "mangnifi/videoGrid/CREATE_TEAM";
export const CREATE_PLAYER = "mangnifi/videoGrid/CREATE_PLAYER";
export const DELETE_TOURNAMENT = "mangnifi/videoGrid/DELETE_TOURNAMENT";
export const DELETE_KEYMOMENT = "mangnifi/videoGrid/DELETE_KEYMOMENT";
export const DELETE_TEAM = "mangnifi/videoGrid/DELETE_TEAM";
export const DELETE_PLAYERS = "mangnifi/videoGrid/DELETE_TEAM";
export const HOURS_CONSUMED = "mangnifi/videoGrid/HOURS_CONSUMED";
export const COPY_CLIP = "mangnifi/videoGrid/COPY_CLIP";
export const EDIT_CLIP = "mangnifi/videoGrid/EDIT_CLIP";
export const UPLOAD_CLIP = "mangnifi/videoGrid/UPLOAD_CLIP";
export const PUBLISH_CLIP = "mangnifi/videoGrid/PUBLISH_CLIP";
export const GET_PLAYER_BY_EVENT = "mangnifi/videoGrid/GET_PLAYER_BY_EVENT";
export const S3_BUCKET_PUBLISH = "mangnifi/videoGrid/S3_BUCKET_PUBLISH";
export const FTP_PUBLISH = "mangnifi/videoGrid/FTP_PUBLISH";
export const LIST_FTP = "mangnifi/videoGrid/LIST_FTP";
export const FTP_PUBLISHED_CLIPS = "mangnifi/videoGrid/FTP_PUBLISHED_CLIPS";
export const BULK_S3_BUCKET_PUBLISH = "mangnifi/videoGrid/BULK_S3_BUCKET_PUBLISH";
export const JSON_BUCKET_PUBLISH = "mangnifi/videoGrid/JSON_BUCKET_PUBLISH";
export const SORT_HAND_VIDEO = "mangnifi/videoGrid/SORT_HAND_VIDEO";
export const DOWNLOAD_NEW_VIDEO_URL = "mangnifi/videoGrid/DOWNLOAD_NEW_VIDEO_URL";
export const GET_CLIPS_COUNT = "mangnifi/videoGrid/GET_CLIPS_COUNT";
export const CREATE_HIGHLIGHT = "mangnifi/videoGrid/CREATE_HIGHLIGHT";
export const DELETE_CLIPS = "mangnifi/videoGrid/DELETE_CLIPS";
export const DELETE_RTMP_CHANNEL = "mangnifi/videoGrid/DELETE_RTMP_CHANNEL";
export const TAG_FILTERS = "mangnifi/videoGrid/TAG_FILTERS";
export const DELETE_FOLDER = "mangnifi/videoGrid/DELETE_FOLDER";
export const CROP_VIDEO = "mangnifi/videoGrid/CROP_VIDEO";
export const CREATE_TOURNAMENT = "mangnifi/videoGrid/CREATE_TOURNAMENT";
export const GET_VIDEO_META_DATA = "mangnifi/videoGrid/GET_VIDEO_META_DATA";
export const UPLOAD_HASHTAG = "mangnifi/videoGrid/UPLOAD_HASHTAG";
export const GET_HASHTAG = "mangnifi/videoGrid/GET_HASHTAG";
export const CREATE_PREVIEW = "mangnifi/videoGrid/CREATE_PREVIEW";
export const DELETE_SLATE = "mangnifi/videoGrid/DELETE_SLATE";
export const DELETE_IMAGES = "mangnifi/videoGrid/DELETE_IMAGES";
export const SAVE_INNING_DATA = "mangnifi/videoGrid/SAVE_INNING_DATA";
export const SHARE_ON_SOCIAL_MEDIA = "mangnifi/videoGrid/SHARE_ON_SOCIAL_MEDIA";
export const CUT_CROP_VIDEO = "mangnifi/videoGrid/CUT_CROP_VIDEO";
export const TWITTER_SHARE = "mangnifi/videoGrid/TWITTER_SHARE";
export const YOUTUBE_SHARE = "mangnifi/videoGrid/YOUTUBE_SHARE";
export const DELETEHASHTAG = "mangnifi/videoGrid/DELETEHASHTAG";
export const UPLOAD_STREAM = "mangnifi/videoGrid/UPLOAD_STREAM";
export const IS_VALIDSTREAM = "mangnifi/videoGrid/IS_VALIDSTREAM";
export const SINGLE_CLIPS = "mangnifi/videoGrid/SINGLE_CLIPS";
export const UPDATE_CLIPS = "mangnifi/videoGrid/UPDATE_CLIPS";
export const GET_PUBLIC_URLS = "mangnifi/videoGrid/GET_PUBLIC_URLS";
export const SHARE_ON_SOCIAL_MEDIAS = "mangnifi/videoGrid/SHARE_ON_SOCIAL_MEDIAS";
export const GET_SINGLE_FOLDER = "mangnifi/videoGrid/GET_SINGLE_FOLDER";
export const GET_CLIPS_BY_FOLDER = "mangnifi/videoGrid/GET_CLIPS_BY_FOLDER";
export const UPDATE_HIGHLIGHT_FOLDER = "mangnifi/videoGrid/UPDATE_HIGHLIGHT_FOLDER";
export const GET_ASPECT_RATIO = "mangnifi/videoGrid/GET_ASPECT_RATIO";
export const CLIP_LIST_FOLDER = "mangnifi/videoGrid/CLIP_LIST_FOLDER";
export const CREATE_STORY = "mangnifi/videoGrid/CREATE_STORY";
export const ADD_STORY = "mangnifi/videoGrid/ADD_STORY";
export const GET_STORY = "mangnifi/videoGrid/GET_STORY";
export const GET_STORY_DETAILS = "mangnifi/videoGrid/GET_STORY_DETAILS";
export const EDIT_WEB_STORY = "mangnifi/videoGrid/EDIT_WEB_STORY";
export const REPUBLISH_STORY = "mangnifi/videoGrid/REPUBLISH_STORY";
export const CREATE_SERVER = "mangnifi/videoGrid/CREATE_SERVER";
export const DELETE_SERVER = "mangnifi/videoGrid/DELETE_SERVER";
export const ALL_INVITE_USER = "mangnifi/videoGrid/ALL_INVITE_USER";
export const GET_SREVER_DETAILS = "mangnifi/videoGrid/GET_SREVER_DETAILS";
export const GET_MAP_STREAMS = "mangnifi/videoGrid/GET_MAP_STREAMS";
export const GET_GROPUP_MAPPING_STREAM = "mangnifi/videoGrid/GET_GROPUP_MAPPING_STREAM";
export const GET_GROPUP_MAPPING_CLIPS = "mangnifi/videoGrid/GET_GROPUP_MAPPING_CLIPS";
export const CREATE_RTMP_CHANNEL = "mangnifi/videoGrid/CREATE_RTMP_CHANNEL";
export const GET_GROPUP_STREAMS = "mangnifi/videoGrid/GET_GROPUP_STREAMS";
export const GET_SPORTS_VALUE = "mangnifi/videoGrid/GET_SPORTS_VALUE";
export const UPDATE_CUSTOM_FIELDS = "mangnifi/videoGrid/UPDATE_CUSTOM_FIELDS";
export const GET_EVENTS_BY_CATEGORY = "mangnifi/videoGrid/GET_EVENTS_BY_CATEGORY";
export const GET_TOURNAMENT_BY_ID = "mangnifi/videoGrid/GET_TOURNAMENT_BY_ID";
export const CROP_IMAGE = "mangnifi/videoGrid/CROP_IMAGE";
export const UPDATE_TOURNAMENT = "mangnifi/videoGrid/UPDATE_TOURNAMENT";
export const GENERATE_AUTOFLIP = "mangnifi/videoGrid/GENERATE_AUTOFLIP";
export const NEW_COPY_CLIPS = "mangnifi/videoGrid/ NEW_COPY_CLIPS";
export const ADD_BULK_TOURNAMENT = "mangnifi/videoGrid/ADD_BULK_TOURNAMENT";
export const ADD_BULK_PLAYER = "mangnifi/videoGrid/ADD_BULK_PLAYER";
export const ADD_BULK_EVENT = "mangnifi/videoGrid/ADD_BULK_EVENT";
export const ADD_BULK_TEAM = "mangnifi/videoGrid/ADD_BULK_TEAM";
export const FANCODEEDIT = "mangnifi/videoGrid/FANCODEEDIT";
export const GET_PUBLISHED_CLIPS = "mangnifi/videoGrid/GET_PUBLISHED_CLIPS";
export const GET_CROPPER_VIDEO_METADATA = "mangnifi/videoGrid/GET_CROPPER_VIDEO_METADATA";
export const STREAM_EXPORT_JSON = "mangnifi/videoGrid/STREAM_EXPORT_JSON";
export const EXPORT_EVENTS_JSON = "magnifi/videoGrid/EXPORT_EVENTS_JSON";
export const EXPORT_MATCH_REPORT = "mangnifi/videoGrid/EXPORT_MATCH_REPORT";
export const CLIPS_EXPORT_JSON = "mangnifi/videoGrid/CLIPS_EXPORT_JSON";
export const GET_VIDEO_ASPECT_RATIO = "mangnifi/videoGrid/GET_VIDEO_ASPECT_RATIO ";
export const DOWNLOAD_CLIPS = "mangnifi/videoGrid/DOWNLOAD_CLIPS ";
export const BUCKET_COPY = "mangnifi/videoGrid/BUCKET_COPY ";
export const DUMP_ON_S3 = "mangnifi/videoGrid/DUMP_ON_S3 ";
export const GET_PLAYERS = "mangnifi/videoGrid/GET_PLAYERS ";
export const COPY_CLIP_TO_REVIEWER = "mangnifi/videoGrid/COPY_CLIP_TO_REVIEWER ";
export const UPLOAD_CLIP_IN_BRIGHTCOVE = "mangnifi/videoGrid/UPLOAD_CLIP_IN_BRIGHTCOVE";
export const FETCH_FOLDER_IN_BRIGHTCOVE = "mangnifi/videoGrid/FETCH_FOLDER_IN_BRIGHTCOVE";
export const FETCH_VIDEO_URL_IN_BRIGHTCOVE = "mangnifi/videoGrid/FETCH_VIDEO_URL_IN_BRIGHTCOVE";
export const STREAM_LOGS = "mangnifi/videoGrid/STREAM_LOGS ";
export const FILE_UPLOAD = "mangnifi/videoGrid/FILE_UPLOAD";
export const GET_ARCHIVE_BULK_DATA = "mangnifi/videoGrid/GET_ARCHIVE_BULK_DATA";
export const GET_BULK_ARCHIVE_DATA = "mangnifi/videoGrid/GET_BULK_ARCHIVE_DATA";
export const AUDIOFADEINOUT = "mangnifi/videoGrid/AUDIOFADEINOUT";
export const UPLOAD_FILE_CHUNKS = "mangnifi/videoGrid/UPLOAD_FILE_CHUNKS";
export const GET_TIMELINE_DURATION = "mangnifi/videoGrid/GET_TIMELINE_DURATION";
export const ADD_THUMBNAIL = "mangnifi/videoGrid/ADD_THUMBNAIL";
export const GENERATE_VIDEO_TITLE = "mangnifi/videoGrid/GENERATE_VIDEO_TITLE";
export const GET_FOLDER_BY_ID = "mangnifi/videoGrid/GET_FOLDER_BY_ID";
export const UPLOAD_STORY_TEMPLATE = "mangnifi/videoGrid/UPLOAD_STORY_TEMPLATE";
export const GET_STORY_TEMPLATE = "mangnifi/videoGrid/GET_STORY_TEMPLATE";
export const TEST_STORY_TEMPLATE = "mangnifi/videoGrid/TEST_STORY_TEMPLATE";
export const MOVE_STORY_TEMPLATE = "mangnifi/videoGrid/MOVE_STORY_TEMPLATE";
export const DELETE_STORY_TEMPLATE = "mangnifi/videoGrid/DELETE_STORY_TEMPLATE";
export const GET_CATEGORY_CONFIGURATION = "mangnifi/videoGrid/GET_CATEGORY_CONFIGURATION";
export const UPDATE_CATEGORY_CONFIGURATION = "mangnifi/videoGrid/UPDATE_CATEGORY_CONFIGURATION";
export const COPY_STREAM = "mangnifi/videoGrid/COPY_STREAM";

export const APPLY_LOGO_ON_THUMBNAIL = "mangnifi/videoGrid/APPLY_LOGO_ON_THUMBNAIL";

export const CREATE_CLIP_PREVIEW = "mangnifi/videoGrid/CREATE_CLIP_PREVIEW";
export const OVER_WRITE_CLIP = "mangnifi/videoGrid/OVER_WRITE_CLIP";
export const DOWNLOAD_ALL_CLIPS = "mangnifi/videoGrid/DOWNLOAD_ALL_CLIPS";
export const TRIM_AND_CHANGE_SPEED = "mangnifi/videoGrid/TRIM_AND_CHANGE_SPEED";
export const GET_CLIPSIZE_BY_URL = "mangnifi/videoGrid/GET_CLIPSIZE_BY_URL";
export const Add_ON_SOCIAL_MEDIAS = "mangnifi/videoGrid/Add_ON_SOCIAL_MEDIAS";
export const ADD_TENANT = "mangnifi/videoGrid/ADD_TENANT";
export const GET_TENANT = "mangnifi/videoGrid/GET_TENANT";
export const UPDATE_TENANT = "mangnifi/videoGrid/UPDATE_TENANT";
export const DELETE_TENANT = "mangnifi/videoGrid/DELETE_TENANT";
export const GET_CLIPS_DATA = "mangnifi/videoGrid/GET_CLIPS_DATA";
export const DOWNLOADASZIP = "mangnifi/videoGrid/DOWNLOADASZIP";
export const STREAM_EXPORT_XML = "mangnifi/videoGrid/STREAM_EXPORT_XML";
export const GET_SHOWS = "mangnifi/videoGrid/GET_SHOWS";
export const GET_PRE_ASSIGNED_URL = "mangnifi/videoGrid/GET_PRE_ASSIGNED_URL";
export const GET_CONTENT_TYPE = "mangnifi/videoGrid/GET_CONTENT_TYPE";
export const GET_S3_PRESIGNED_URL = "mangnifi/videoGrid/GET_S3_PRESIGNED_URL";
export const CREATE_S3_PRESIGNED_URL = "mangnifi/videoGrid/CREATE_S3_PRESIGNED_URL";
export const GET_QUEUE_SETTING = "mangnifi/videoGrid/GET_QUEUE_SETTING";
export const UPDATE_QUEUE_SETTING = "mangnifi/videoGrid/UPDATE_QUEUE_SETTING";
export const GET_TRIMCLIP_V2 = "mangnifi/user/GET_TRIMCLIP_V2";
export const ADD_RULE_DATA = "mangnifi/videoGrid/ADD_RULE_DATA";
export const GET_RULE_BASED_DATA = "mangnifi/videoGrid/GET_RULE_BASED_DATA";
export const GET_RULE_BY_CATAGORY = "mangnifi/videoGrid/GET_RULE_BY_CATAGORY";
export const GET_RULE_BY_ID = "mangnifi/videoGrid/GET_RULE_BY_ID";
export const DELETE_RULE_BASED_DATA = "mangnifi/videoGrid/DELETE_RULE_BASED_DATA";
export const RULE_TRIGGER_POSTGAME = "mangnifi/videoGrid/RULE_TRIGGER_POSTGAME";
export const RE_PROCESS_VIDEO = "mangnifi/user/RE_PROCESS_VIDEO";
export const GET_COMBINE_FOLDERS_BY_RULE = "mangnifi/videoGrid/GET_COMBINE_FOLDERS_BY_RULE";
export const GET_STREAM_COUNT_BY_FILTERS = "mangnifi/videoGrid/GET_STREAM_COUNT_BY_FILTERS";
export const GET_CLIPS_BY_FILTERS = "mangnifi/videoGrid/GET_CLIPS_BY_FILTERS";
export const UPDATE_VIDEO_DATA = "mangnifi/videoGrid/UPDATE_VIDEO_DATA";
export const REQUEST_FOR_HLS_TO_MP4 = "mangnifi/videoGrid/REQUEST_FOR_HLS_TO_MP4";
export const GET_HLS_TO_MP4 = "mangnifi/videoGrid/GET_HLS_TO_MP4";
export const GET_INTERNAL_STATUS = "mangnifi/videoGridGET_INTERNAL_STATUS";
export const DELETE_HLS_TO_MP4 = "mangnifi/videoGrid/DELETE_HLS_TO_MP4";
export const ADVANCE_EDITOR_ILLUSTO = "mangnifi/videoGrid/ADVANCE_EDITOR_ILLUSTO";
export const CREATE_RESERVED_CHANNEL = "mangnifi/videoGrid/CREATE_RESERVED_CHANNEL";
export const ADD_MEDIA_TEMPLATE = "mangnifi/videoGrid/ADD_MEDIA_TEMPLATE";
export const GET_MEDIA_TEMPLATE = "mangnifi/videoGrid/GET_MEDIA_TEMPLATE";
export const EDIT_MEDIA_TEMPLATE = "mangnifi/videoGrid/EDIT_MEDIA_TEMPLATE";
export const DELETE_MEDIA_TEMPLATE = "mangnifi/videoGrid/DELETE_MEDIA_TEMPLATE";
export const GET_DOWNLOAD_STATUS = "magnifi/videoGrid/GET_DOWNLOAD_STATUS";
export const CHECK_AUTO_ASSIGN_RATING = "magnifi/videoGrid/CHECK_AUTO_ASSIGN_RATING";
export const GET_ALL_RATING_RULES = "magnifi/videoGrid/GET_ALL_RATING_RULES";
export const GENERATE_USER_PERMISSION_REPORT = "magnifi/videoGrid/GENERATE_USER_PERMISSION_REPORT";
export const GET_VERTICAL_REQUEST_DETAILS = "magnifi/videoGrid/GET_VERTICAL_REQUEST_DETAILS";
export const VERIFY_SRT = "magnifi/videoGrid/VERIFY_SRT";
export const LIST_SECRET_VALUES = "magnifi/videoGrid/LIST_SECRET_VALUES";
export const DELETE_SECRET_VALUE = "magnifi/videoGrid/DELETE_SECRET_VALUE";

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  error: null,
  user: {},
  isSuccess: false,
  message: "",
  loginError: "",
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    // const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    const token = localStorage.getItem("authToken");
    super(assign({ token }, desiredValues));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getVideoGridState = (credentials) => {
  return {
    type: GET_VIDEOGRID_STATES,
    payload: api.getVideoGridStates(credentials),
  };
};
export const getSportsDetail = (credentials) => {
  return {
    type: GET_SPORTDETAIL_STATES,
    payload: api.getSportsDetails(credentials),
  };
};

export const getUserDetail = (credentials) => {
  return {
    type: GET_USERTDETAIL_STATES,
    payload: api.getUserDetails(credentials),
  };
};
export const getUsersDetail = (credentials) => {
  return {
    type: GET_USERSTDETAIL_STATES,
    payload: api.getUsersDetails(credentials),
  };
};

export const getStreamDetails = (credentials) => {
  return {
    type: GET_STREAM_DETAIL,
    payload: api.getStreamDetail(credentials),
  };
};
export const getBulkData = (credentials) => {
  return {
    type: GET_BULK_DATA,
    payload: api.getBulkData(credentials),
  };
};

export const getAspectRatios = (credentials) => {
  return {
    type: GET_ASPECT_RATIO,
    payload: api.getAspectRatios(credentials),
  };
};

export const getAllClips = (payload) => {
  return {
    type: GET_AllCLIPS,
    payload: api.getAllClips(payload),
  };
};

export const addShow = (payload) => {
  return {
    type: ADD_SHOW,
    payload: api.addShow(payload),
  };
};
export const editShow = (payload) => {
  return {
    type: EDIT_SHOW,
    payload: api.editShow(payload),
  };
};
export const deleteShows = (payload) => {
  return {
    type: DELETE_SHOWS,
    payload: api.deleteShows(payload),
  };
};
export const editStream = (payload) => {
  return {
    type: EDIT_STREAM,
    payload: api.editStream(payload),
  };
};
export const deleteStreams = (payload) => {
  return {
    type: DELETE_STREAM,
    payload: api.deleteStreams(payload),
  };
};
export const deleteEditedClip = (payload) => {
  return {
    type: DELETE_EDITED_CLIP,
    payload: api.deleteEditedClip(payload),
  };
};

export const getHoursConsumed = (payload) => {
  return {
    type: HOURS_CONSUMED,
    payload: api.getHoursConsumed(payload),
  };
};
export const deleteAudio = (payload) => {
  return {
    type: DELETE_AUDIO,
    payload: api.deleteAudio(payload),
  };
};

export const downloadVideo = (payload) => {
  return {
    type: DOWNLOAD_NEW_VIDEO_URL,
    payload: api.downloadVideo(payload),
  };
};

export const getSingleStream = (payload) => {
  return {
    type: GET_SINGLESTREAM,
    payload: api.getSingleStream(payload),
  };
};

export const getTrimClip = (payload) => {
  return {
    type: GET_TRIMCLIP,
    payload: api.getTrimClip(payload),
  };
};

export const getUploadPicture = (payload) => {
  return {
    type: UPLOAD_PICTURE,
    payload: api.getUploadPicture(payload),
  };
};

export const getImages = (payload) => {
  return {
    type: GET_IMAGES,
    payload: api.getImages(payload),
  };
};

export const getAudio = (payload) => {
  return {
    type: GET_AUDIO,
    payload: api.getAudio(payload),
  };
};

export const addAudio = (payload) => {
  return {
    type: ADD_AUDIO,
    payload: api.addAudio(payload),
  };
};
export const addAudioToFolder = (payload) => {
  return {
    type: ADD_AUDIO_To_FOLDER,
    payload: api.addAudioToFolder(payload),
  };
};
export const addImages = (payload) => {
  return {
    type: ADD_IMAGES,
    payload: api.addImages(payload),
  };
};
export const addSport = (payload) => {
  return {
    type: ADD_SPORTS,
    payload: api.addSport(payload),
  };
};

export const getAddImages = (payload) => {
  return {
    type: GET_ADD_IMAGES,
    payload: api.getAddImages(payload),
  };
};

export const addSlate = (payload) => {
  return {
    type: ADD_SLATE,
    payload: api.addSlate(payload),
  };
};

export const uploadSlate = (payload) => {
  return {
    type: UPLOAD_SLATE,
    payload: api.uploadSlate(payload),
  };
};

export const showSlate = (payload) => {
  return {
    type: SHOW_SLATE,
    payload: api.showSlate(payload),
  };
};
export const fetchTournaments = (payload) => {
  return {
    type: FETCH_TOURNAMENTS,
    payload: api.getTournaments(payload),
  };
};
export const updatesftp = (payload) => {
  return {
    type: UPDATE_SFTP,
    payload: api.updatesftp(payload),
  };
};

export const fetchStorage = (payload) => {
  return {
    type: FETCH_STORAGE,
    payload: api.getStorage(payload),
  };
};
export const fetchTournamentsData = (payload) => {
  return {
    type: FETCH_EVENT,
    payload: api.getTournamentsData(payload),
  };
};
export const fetchCustomData = (payload) => {
  return {
    type: FETCH_EVENT,
    payload: api.getCustomData(payload),
  };
};
export const fetchBrightCoveToken = (payload) => {
  return {
    type: FETCH_BRIGHTCOVE_TOKEN,
    payload: api.getBrightCoveToken(payload),
  };
};
export const fetchBrightVideo = (payload) => {
  return {
    type: FETCH_BRIGHTCOVE_VIDEOS,
    payload: api.getBrightCoveVideos(payload),
  };
};

export const uploadClipInBrightCove = (payload) => {
  return {
    type: UPLOAD_CLIP_IN_BRIGHTCOVE,
    payload: api.UploadClipInBrightCove(payload),
  };
};

export const fetchFolderListFromBrightCove = (payload) => {
  return {
    type: FETCH_FOLDER_IN_BRIGHTCOVE,
    payload: api.fetchFolderListFromBrightCove(payload),
  };
};

export const fetchVideoUrlListFromBrightCove = (payload) => {
  return {
    type: FETCH_VIDEO_URL_IN_BRIGHTCOVE,
    payload: api.fetchVideoUrlListFromBrightCove(payload),
  };
};

export const fileUpload = (payload) => {
  return {
    type: FILE_UPLOAD,
    payload: api.fileUpload(payload),
  };
};

export const createStream = (payload) => {
  return {
    type: CREATE_STREAM,
    payload: api.createStream(payload),
  };
};

export const getPlayerStream = (payload) => {
  return {
    type: FETCH_PLAYERS,
    payload: api.getPlayersStreams(payload),
  };
};

export const getEventStream = (payload) => {
  return {
    type: FETCH_EVENTS,
    payload: api.getEventsStreams(payload),
  };
};
export const getPlayersByEvents = (payload) => {
  return {
    type: GET_PLAYER_BY_EVENT,
    payload: api.getPlayersByEvents(payload),
  };
};

export const getPlayers = (payload) => {
  return {
    type: GET_PLAYERS,
    payload: api.getPlayers(payload),
  };
};
export const copyClipToReviewer = (payload) => {
  return {
    type: COPY_CLIP_TO_REVIEWER,
    payload: api.copyClipToReviewer(payload),
  };
};

export const getFolders = (payload) => {
  return {
    type: FETCH_FOLDERS,
    payload: api.getFolders(payload),
  };
};

export const createFolder = (payload) => {
  return {
    type: CREATE_FOLDERS,
    payload: api.createFolder(payload),
  };
};

export const updateFolder = (payload) => {
  return {
    type: UPDATE_FOLDER,
    payload: api.updateFolder(payload),
  };
};
export const updateHighlightFolder = (payload) => {
  return {
    type: UPDATE_HIGHLIGHT_FOLDER,
    payload: api.updateHighlightFolder(payload),
  };
};

export const getClipsCount = (payload) => {
  return {
    type: GET_CLIPS_COUNT,
    payload: api.getClipsCount(payload),
  };
};
export const deleteCategory = (payload) => {
  return {
    type: DELETE_CATEGORY,
    payload: api.deleteCategory(payload),
  };
};
export const deleteArchiveStream = (payload) => {
  return {
    type: DELETE_ARCHIVE_STREAM,
    payload: api.deleteArchiveStream(payload),
  };
};
export const deleteArchiveClips = (payload) => {
  return {
    type: DELETE_ARCHIVE_CLIPS,
    payload: api.deleteArchiveClips(payload),
  };
};
export const getArchiveStream = (payload) => {
  return {
    type: GET_ARCHIVE_STREAM,
    payload: api.getArchiveStream(payload),
  };
};
export const getArchiveBulkData = (payload) => {
  return {
    type: GET_ARCHIVE_BULK_DATA,
    payload: api.getArchiveBulkData(payload),
  };
};
export const getBulkArchiveData = (payload) => {
  return {
    type: GET_BULK_ARCHIVE_DATA,
    payload: api.getBulkArchiveData(payload),
  };
};
export const getArchiveClips = (payload) => {
  return {
    type: GET_ARCHIVE_CLIPS,
    payload: api.getArchiveClips(payload),
  };
};
export const restoreArchiveStream = (payload) => {
  return {
    type: RESTORE_ARCHIVE_STREAM,
    payload: api.restoreArchiveStream(payload),
  };
};
export const restoreArchivedStream = (payload) => {
  return {
    type: RESTORE_ARCHIVED_STREAM,
    payload: api.restoreArchivedStream(payload),
  };
};
export const restoreArchiveClips = (payload) => {
  return {
    type: RESTORE_ARCHIVE_CLIPS,
    payload: api.restoreArchiveClips(payload),
  };
};
export const getCopiedStreamCount = (payload) => {
  return {
    type: GET_COPIED_STREAM_COUNT,
    payload: api.getCopiedStreamCount(payload),
  };
};
export const createHighlight = (payload) => {
  return {
    type: CREATE_HIGHLIGHT,
    payload: api.createHighlight(payload),
  };
};
export const getCustomFields = (payload) => {
  return {
    type: GET_CUSTOMFIELD,
    payload: api.getCustomFields(payload),
  };
};
export const getRTMPChannel = (payload) => {
  return {
    type: GET_RTMP_CHANNELS,
    payload: api.getRTMPChannel(payload),
  };
};
export const updateRTMPState = (payload) => {
  return {
    type: UPDATE_RTMP_STATE,
    payload: api.updateRTMPState(payload),
  };
};
export const updatertmpChannel = (payload) => {
  return {
    type: UPDATE_RTMP_CHANNEL,
    payload: api.updatertmpChannel(payload),
  };
};
export const getChannelDetails = (payload) => {
  return {
    type: GET_CHANNEL_DETAILS,
    payload: api.getChannelDetails(payload),
  };
};
export const deleteClips = (payload) => {
  return {
    type: DELETE_CLIPS,
    payload: api.deleteClip(payload),
  };
};
export const deleteRTMPChannel = (payload) => {
  return {
    type: DELETE_RTMP_CHANNEL,
    payload: api.deleteRTMPChannel(payload),
  };
};
export const createCustomFields = (payload) => {
  return {
    type: CREATE_CUSTOM_FIELDS,
    payload: api.createCustomFields(payload),
  };
};

export const deleteFolder = (payload) => {
  return {
    type: DELETE_FOLDER,
    payload: api.deleteFolder(payload),
  };
};

export const cropVideo = (payload) => {
  return {
    type: CROP_VIDEO,
    payload: api.cropVideo(payload),
  };
};

export const getVideoMetaData = (payload) => {
  return {
    type: GET_VIDEO_META_DATA,
    payload: api.getVideoMetaData(payload),
  };
};

export const deleteFields = (payload) => {
  return {
    type: DELETE_FIELDS,
    payload: api.deleteFields(payload),
  };
};
export const streamExportJson = (payload) => {
  return {
    type: STREAM_EXPORT_JSON,
    payload: api.streamExportJson(payload),
  };
};
export const exportEventsJson = (payload) => {
  return {
    type: EXPORT_EVENTS_JSON,
    payload: api.exportEventsJson(payload),
  };
};
export const exportStreamReport = (payload) => {
  return {
    type: EXPORT_MATCH_REPORT,
    payload: api.exportStreamReport(payload),
  };
};
export const clipExportJson = (payload) => {
  return {
    type: STREAM_EXPORT_JSON,
    payload: api.clipExportJson(payload),
  };
};
export const clipExportXml = (payload) => {
  return {
    type: STREAM_EXPORT_XML,
    payload: api.clipExportXml(payload),
  };
};
export const fetchSportsTeam = (payload) => {
  return {
    type: GET_SPORTS_TEAM,
    payload: api.getSportsTeam(payload),
  };
};
export const createTournament = (payload) => {
  return {
    type: CREATE_TOURNAMENT,
    payload: api.createTournament(payload),
  };
};
export const addEvent = (payload) => {
  return {
    type: CREATE_TOURNAMENT,
    payload: api.addEvent(payload),
  };
};
export const createTeam = (payload) => {
  return {
    type: CREATE_TEAM,
    payload: api.createTeam(payload),
  };
};
export const createPlayer = (payload) => {
  return {
    type: CREATE_PLAYER,
    payload: api.createPlayer(payload),
  };
};
export const deleteTournamentData = (payload) => {
  return {
    type: DELETE_TOURNAMENT,
    payload: api.deleteTournamentData(payload),
  };
};
export const deleteKeymoment = (payload) => {
  return {
    type: DELETE_KEYMOMENT,
    payload: api.deleteKeymoment(payload),
  };
};
export const deleteTeam = (payload) => {
  return {
    type: DELETE_TEAM,
    payload: api.deleteTeam(payload),
  };
};
export const deletePlayers = (payload) => {
  return {
    type: DELETE_PLAYERS,
    payload: api.deletePlayers(payload),
  };
};

export const uploadHashtag = (payload) => {
  return {
    type: UPLOAD_HASHTAG,
    payload: api.uploadHashtag(payload),
  };
};

export const getHashtag = (payload) => {
  return {
    type: GET_HASHTAG,
    payload: api.getHashtag(payload),
  };
};

export const getSportsTeam = (payload) => {
  return {
    type: GET_SPORTS_TEAM,
    payload: api.getSportsTeam(payload),
  };
};

export const createPreview = (payload) => {
  return {
    type: CREATE_PREVIEW,
    payload: api.createPreview(payload),
  };
};

export const deleteSlate = (payload) => {
  return {
    type: DELETE_SLATE,
    payload: api.deleteSlate(payload),
  };
};

export const copyClip = (payload) => {
  return {
    type: COPY_CLIP,
    payload: api.copyClip(payload),
  };
};
export const editClip = (payload) => {
  return {
    type: EDIT_CLIP,
    payload: api.editClip(payload),
  };
};
export const uploadClip = (payload) => {
  return {
    type: UPLOAD_CLIP,
    payload: api.uploadClip(payload),
  };
};
export const publishClip = (payload) => {
  return {
    type: PUBLISH_CLIP,
    payload: api.publishClip(payload),
  };
};
export const s3BucketPublish = (payload) => {
  return {
    type: S3_BUCKET_PUBLISH,
    payload: api.s3BucketPublish(payload),
  };
};
export const ftpPublish = (payload) => {
  return {
    type: FTP_PUBLISH,
    payload: api.ftpPublish(payload),
  };
};
export const getftplist = (payload) => {
  return {
    type: LIST_FTP,
    payload: api.getftplist(payload),
  };
};
export const getftpPublishedClips = (payload) => {
  return {
    type: FTP_PUBLISHED_CLIPS,
    payload: api.getftpPublishedClips(payload),
  };
};
export const bulkS3BucketPublish = (payload) => {
  return {
    type: BULK_S3_BUCKET_PUBLISH,
    payload: api.bulkS3BucketPublish(payload),
  };
};
export const jsonBucketPublish = (payload) => {
  return {
    type: JSON_BUCKET_PUBLISH,
    payload: api.jsonBucketPublish(payload),
  };
};
export const sortHandVideo = (payload) => {
  return {
    type: SORT_HAND_VIDEO,
    payload: api.sortHandVideo(payload),
  };
};

export const deleteImages = (payload) => {
  return {
    type: S3_BUCKET_PUBLISH,
    payload: api.deleteImages(payload),
  };
};

export const shareOnSocialMedia = (payload) => {
  return {
    type: SHARE_ON_SOCIAL_MEDIA,
    payload: api.shareOnSocialMedia(payload),
  };
};

export const cutCropVideo = (payload) => {
  return {
    type: CUT_CROP_VIDEO,
    payload: api.cutCropVideo(payload),
  };
};

export const twitterShare = (payload) => {
  return {
    type: TWITTER_SHARE,
    payload: api.twitterShare(payload),
  };
};

export const youtubeShare = (payload) => {
  return {
    type: YOUTUBE_SHARE,
    payload: api.youtubeShare(payload),
  };
};
export const deleteHashtag = (payload) => {
  return {
    type: DELETEHASHTAG,
    payload: api.deleteHashtag(payload),
  };
};
export const uploadStream = (payload) => {
  return {
    type: UPLOAD_STREAM,
    payload: api.uploadStream(payload),
  };
};
export const isValidStream = (payload) => {
  return {
    type: IS_VALIDSTREAM,
    payload: api.isValidStream(payload),
  };
};
export const singleClipDetail = (payload) => {
  return {
    type: SINGLE_CLIPS,
    payload: api.singleClipDetails(payload),
  };
};
export const updateClipDetail = (payload) => {
  return {
    type: UPDATE_CLIPS,
    payload: api.updateClipDetails(payload),
  };
};

export const getPublicUrls = (payload) => {
  return {
    type: GET_PUBLIC_URLS,
    payload: api.getPublicUrls(payload),
  };
};
export const tagFilters = (payload) => {
  return {
    type: TAG_FILTERS,
    payload: api.tagFilters(payload),
  };
};
export const saveInningData = (payload) => {
  return {
    type: SAVE_INNING_DATA,
    payload: api.saveInningData(payload),
  };
};

export const shareOnSocialMedias = (payload) => {
  return {
    type: SHARE_ON_SOCIAL_MEDIAS,
    payload: api.shareOnSocialMedias(payload),
  };
};

export const addSocialMediaData = (payload) => {
  return {
    type: Add_ON_SOCIAL_MEDIAS,
    payload: api.addSocialMediaData(payload),
  };
};

export const getSingleFolder = (payload) => {
  return {
    type: GET_SINGLE_FOLDER,
    payload: api.getSingleFolder(payload),
  };
};

export const getClipsByFolder = (payload) => {
  return {
    type: GET_CLIPS_BY_FOLDER,
    payload: api.getClipsByFolder(payload),
  };
};

export const getFoldersById = (payload) => {
  return {
    type: GET_FOLDER_BY_ID,
    payload: api.getFoldersById(payload),
  };
};

export const clipListFolder = (payload) => {
  return {
    type: CLIP_LIST_FOLDER,
    payload: api.clipListFolders(payload),
  };
};
export const createStory = (payload) => {
  return {
    type: CREATE_STORY,
    payload: api.createStory(payload),
  };
};
export const addStory = (payload) => {
  return {
    type: ADD_STORY,
    payload: api.addStory(payload),
  };
};
export const editWebStory = (payload) => {
  return {
    type: EDIT_WEB_STORY,
    payload: api.editWebStory(payload),
  };
};
export const rePublishWebStory = (payload) => {
  return {
    type: REPUBLISH_STORY,
    payload: api.rePublishWebStory(payload),
  };
};
export const getStory = (payload) => {
  return {
    type: GET_STORY,
    payload: api.getStory(payload),
  };
};
export const getStoryDetails = (payload) => {
  return {
    type: GET_STORY_DETAILS,
    payload: api.getStoryDetails(payload),
  };
};
export const createServer = (payload) => {
  return {
    type: CREATE_SERVER,
    payload: api.createServer(payload),
  };
};
export const deleteServer = (payload) => {
  return {
    type: DELETE_SERVER,
    payload: api.deleteServer(payload),
  };
};
export const getAllInviteUser = (payload) => {
  return {
    type: ALL_INVITE_USER,
    payload: api.getAllInviteUser(payload),
  };
};
export const getServerDetails = (payload) => {
  return {
    type: GET_SREVER_DETAILS,
    payload: api.getServerDetails(payload),
  };
};

export const mapStreams = (payload) => {
  return {
    type: GET_MAP_STREAMS,
    payload: api.mapStreams(payload),
  };
};

export const gropuMappingStreams = (payload) => {
  return {
    type: GET_GROPUP_MAPPING_STREAM,
    payload: api.gropuMappingStreams(payload),
  };
};

export const gropuMappingClips = (payload) => {
  return {
    type: GET_GROPUP_MAPPING_CLIPS,
    payload: api.gropuMappingClips(payload),
  };
};
export const createRTMPChannel = (payload) => {
  return {
    type: CREATE_RTMP_CHANNEL,
    payload: api.createRTMPChannel(payload),
  };
};

export const getGroupedStreams = (payload) => {
  return {
    type: GET_GROPUP_STREAMS,
    payload: api.getGroupedStreams(payload),
  };
};
export const getSportsValue = (payload) => {
  return {
    type: GET_SPORTS_VALUE,
    payload: api.getSportsValue(payload),
  };
};
export const getEventsByCategory = (payload) => {
  return {
    type: GET_EVENTS_BY_CATEGORY,
    payload: api.getEventsByCategory(payload),
  };
};

export const generateAutoFlip = (payload) => {
  return {
    type: GENERATE_AUTOFLIP,
    payload: api.generateAutoFlip(payload),
  };
};

export const updateCustomFields = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELDS,
    payload: api.updateCustomField(payload),
  };
};
export const getTournamentById = (payload) => {
  return {
    type: GET_TOURNAMENT_BY_ID,
    payload: api.getTournamentById(payload),
  };
};
export const cropImage = (payload) => {
  return {
    type: CROP_IMAGE,
    payload: api.cropImage(payload),
  };
};
export const updateTournament = (payload) => {
  return {
    type: UPDATE_TOURNAMENT,
    payload: api.updateTournament(payload),
  };
};

export const genrateNewClip = (payload) => {
  return {
    type: NEW_COPY_CLIPS,
    payload: api.genrateNewClip(payload),
  };
};

export const addBulkTournament = (payload) => {
  return {
    type: ADD_BULK_TOURNAMENT,
    payload: api.addBulkTournament(payload),
  };
};

export const addBulkPlayer = (payload) => {
  return {
    type: ADD_BULK_PLAYER,
    payload: api.addBulkPlayer(payload),
  };
};

export const addBulkEvent = (payload) => {
  return {
    type: ADD_BULK_EVENT,
    payload: api.addBulkEvent(payload),
  };
};

export const addBulkTeam = (payload) => {
  return {
    type: ADD_BULK_TEAM,
    payload: api.addBulkTeam(payload),
  };
};
export const fancodeEdit = (payload) => {
  return {
    type: FANCODEEDIT,
    payload: api.fancodeEdit(payload),
  };
};

export const getCropperVideoMetaData = (payload) => {
  return {
    type: GET_CROPPER_VIDEO_METADATA,
    payload: api.getCropperVideoMetaData(payload),
  };
};

export const getVideoAspectRatio = (payload) => {
  return {
    type: GET_VIDEO_ASPECT_RATIO,
    payload: api.getVideoAspectRatio(payload),
  };
};
export const downloadClips = (payload) => {
  return {
    type: DOWNLOAD_CLIPS,
    payload: api.downloadClips(payload),
  };
};

export const streamLogs = (payload) => {
  return {
    type: STREAM_LOGS,
    payload: api.streamLogs(payload),
  };
};
export const getPublishedClips = (payload) => {
  return {
    type: GET_PUBLISHED_CLIPS,
    payload: api.getPublishedClips(payload),
  };
};
export const bucketCopy = (payload) => {
  return {
    type: BUCKET_COPY,
    payload: api.bucketCopy(payload),
  };
};
export const dumpOnS3 = (payload) => {
  return {
    type: DUMP_ON_S3,
    payload: api.dumpOnS3(payload),
  };
};
export const audiofadeInOut = (payload) => {
  return {
    type: AUDIOFADEINOUT,
    payload: api.audiofadeInOut(payload),
  };
};

export const uploadFileChunks = (payload) => {
  return {
    type: UPLOAD_FILE_CHUNKS,
    payload: api.uploadFileChunks(payload),
  };
};
export const getTimeLineDuration = (payload) => {
  return {
    type: GET_TIMELINE_DURATION,
    payload: api.getTimeLineDuration(payload),
  };
};
export const addThumbnail = (payload) => {
  return {
    type: ADD_THUMBNAIL,
    payload: api.addThumbnail(payload),
  };
};

export const GenerateVideoTitle = (payload) => {
  return {
    type: GENERATE_VIDEO_TITLE,
    payload: api.GenerateVideoTitle(payload),
  };
};
export const uploadStoryTemplate = (payload) => {
  return {
    type: UPLOAD_STORY_TEMPLATE,
    payload: api.uploadStoryTemplate(payload),
  };
};
export const getStoryTemplates = (payload) => {
  return {
    type: GET_STORY_TEMPLATE,
    payload: api.getStoryTemplates(payload),
  };
};
export const testStoryTemplate = (payload) => {
  return {
    type: TEST_STORY_TEMPLATE,
    payload: api.testStoryTemplate(payload),
  };
};
export const moveStoryTemplate = (payload) => {
  return {
    type: MOVE_STORY_TEMPLATE,
    payload: api.moveStoryTemplate(payload),
  };
};
export const deleteStoryTemplate = (payload) => {
  return {
    type: DELETE_STORY_TEMPLATE,
    payload: api.deleteStoryTemplate(payload),
  };
};
export const getCategoryConfiguration = (payload) => {
  return {
    type: GET_CATEGORY_CONFIGURATION,
    payload: api.getCategoryConfiguration(payload),
  };
};
export const updateCategoryConfiguration = (payload) => {
  return {
    type: UPDATE_CATEGORY_CONFIGURATION,
    payload: api.updateCategoryConfiguration(payload),
  };
};
export const copyStream = (payload) => {
  return {
    type: COPY_STREAM,
    payload: api.copyStream(payload),
  };
};

export const applyLogoOnThumbnail = (payload) => {
  return {
    type: APPLY_LOGO_ON_THUMBNAIL,
    payload: api.applyLogoOnThumbnail(payload),
  };
};
export const clipCreatePreview = (payload) => {
  return {
    type: CREATE_CLIP_PREVIEW,
    payload: api.clipCreatePreview(payload),
  };
};

export const overWriteClip = (payload) => {
  return {
    type: OVER_WRITE_CLIP,
    payload: api.overWriteClip(payload),
  };
};
export const downloadAllClips = (payload) => {
  return {
    type: DOWNLOAD_ALL_CLIPS,
    payload: api.downloadAllClips(payload),
  };
};
export const getClipSizeByUrl = (payload) => {
  return {
    type: GET_CLIPSIZE_BY_URL,
    payload: api.getClipSizeByUrl(payload),
  };
};
export const trimandChangeSpeedClip = (payload) => {
  return {
    type: TRIM_AND_CHANGE_SPEED,
    payload: api.trimandChangeSpeedClip(payload),
  };
};
export const addTenant = (payload) => {
  return {
    type: ADD_TENANT,
    payload: api.addTenant(payload),
  };
};
export const getTenant = (payload) => {
  return {
    type: GET_TENANT,
    payload: api.getTenant(payload),
  };
};
export const deleteTenant = (payload) => {
  return {
    type: DELETE_TENANT,
    payload: api.deleteTenant(payload),
  };
};
export const updateTenant = (payload) => {
  return {
    type: UPDATE_TENANT,
    payload: api.updateTenant(payload),
  };
};
export const getClipsData = (payload) => {
  return {
    type: GET_CLIPS_DATA,
    payload: api.getClipsData(payload),
  };
};
export const downloadAsZip = (payload) => {
  return {
    type: DOWNLOADASZIP,
    payload: api.downloadAsZip(payload),
  };
};

export const getShows = (payload) => {
  return {
    type: GET_SHOWS,
    payload: api.getShows(payload),
  };
};
export const getPreAssignedUrl = (payload) => {
  return {
    type: GET_PRE_ASSIGNED_URL,
    payload: api.getPreAssignedUrl(payload),
  };
};
export const getContentType = (payload) => {
  return {
    type: GET_CONTENT_TYPE,
    payload: api.getContentType(payload),
  };
};
export const getS3PresignedURl = (payload) => {
  return {
    type: GET_S3_PRESIGNED_URL,
    payload: api.getS3PresignedURl(payload),
  };
};

export const createS3PresignedURl = (payload) => {
  return {
    type: CREATE_S3_PRESIGNED_URL,
    payload: api.createS3PresignedURl(payload),
  };
};

export const getQueueSetting = (payload) => {
  return {
    type: GET_QUEUE_SETTING,
    payload: api.getQueueSetting(payload),
  };
};

export const updateQueueSetting = (payload) => {
  return {
    type: UPDATE_QUEUE_SETTING,
    payload: api.updateQueueSetting(payload),
  };
};

export const getTrimClipV2 = (payload) => {
  return {
    type: GET_TRIMCLIP_V2,
    payload: api.getTrimClipV2(payload),
  };
};

export const getAddRuleData = (payload) => {
  return {
    type: ADD_RULE_DATA,
    payload: api.getAddRuleData(payload),
  };
};
export const editRulesData = (payload) => {
  return {
    type: ADD_RULE_DATA,
    payload: api.editRulesData(payload),
  };
};

export const getRuleBaseData = (payload) => {
  return {
    type: GET_RULE_BASED_DATA,
    payload: api.getRuleBaseData(payload),
  };
};

export const getRulesByStreamId = (payload) => {
  return {
    type: "GET_RULE_BY_STREAM_ID",
    payload: api.getRulesByStreamId(payload),
  };
};

export const getRuleById = (payload) => {
  return {
    type: GET_RULE_BY_ID,
    payload: api.getRuleById(payload),
  };
};

export const getRulesByCategory = (payload) => {
  return {
    type: GET_RULE_BY_CATAGORY,
    payload: api.getRulesByCategory(payload),
  };
};
export const deleteRuleBaseData = (payload) => {
  return {
    type: DELETE_RULE_BASED_DATA,
    payload: api.deleteRuleBaseData(payload),
  };
};
export const RuleTriggerPostGame = (payload) => {
  return {
    type: RULE_TRIGGER_POSTGAME,
    payload: api.RuleTriggerPostGame(payload),
  };
};
export const reProcessVideo = (payload) => {
  return {
    type: RE_PROCESS_VIDEO,
    payload: api.reProcessVideo(payload),
  };
};

export const getCombineFoldersByRule = (payload) => {
  return {
    type: GET_COMBINE_FOLDERS_BY_RULE,
    payload: api.getCombineFoldersByRule(payload),
  };
};

export const getStreamCountByFilter = (payload) => {
  return {
    type: GET_STREAM_COUNT_BY_FILTERS,
    payload: api.getStreamCountByFilter(payload),
  };
};

export const getClipsByFilter = (payload) => {
  return {
    type: GET_CLIPS_BY_FILTERS,
    payload: api.getClipsByFilter(payload),
  };
};

export const updateVideoData = (payload) => {
  return {
    type: UPDATE_VIDEO_DATA,
    payload: api.updateVideoData(payload),
  };
};
export const initialteRequestForHLSToMP4 = (payload) => {
  return {
    type: REQUEST_FOR_HLS_TO_MP4,
    payload: api.initialteRequestForHLSToMP4(payload),
  };
};

export const getHLSToMP4 = (payload) => {
  return {
    type: GET_HLS_TO_MP4,
    payload: api.getHLSToMP4(payload),
  };
};

export const getInternalStatus = (payload) => {
  return {
    type: GET_INTERNAL_STATUS,
    payload: api.getInternalStatus(payload),
  };
};

export const exportStreamCsv = (payload) => {
  return {
    type: GET_INTERNAL_STATUS,
    payload: api.exportStreamCsv(payload),
  };
};

export const getCsvData = (payload) => {
  return {
    type: GET_INTERNAL_STATUS,
    payload: api.getCsvData(payload),
  };
};

export const restartServer = (payload) => {
  return {
    type: GET_INTERNAL_STATUS,
    payload: api.restartServer(payload),
  };
};
export const deleteHlsToMp4 = (payload) => {
  return {
    type: DELETE_HLS_TO_MP4,
    payload: api.deleteHlsToMp4(payload),
  };
};
export const addMediaTemplate = (payload) => {
  return {
    type: ADD_MEDIA_TEMPLATE,
    payload: api.addMediaTemplate(payload),
  };
};
export const getMediaTemplate = (payload) => {
  return {
    type: GET_MEDIA_TEMPLATE,
    payload: api.getMediaTemplate(payload),
  };
};
export const editMediaTemplate = (payload) => {
  return {
    type: EDIT_MEDIA_TEMPLATE,
    payload: api.editMediaTemplate(payload),
  };
};
export const deleteMediaTemplate = (payload) => {
  return {
    type: DELETE_MEDIA_TEMPLATE,
    payload: api.deleteMediaTemplate(payload),
  };
};

export const getDownloadStatus = (payload) => {
  return {
    type: GET_DOWNLOAD_STATUS,
    payload: api.getDownloadStatus(payload),
  };
};
export const generateUserPermissionsReport = (payload) => {
  return {
    type: GENERATE_USER_PERMISSION_REPORT,
    payload: api.generateUserPermissionsReport(payload),
  };
};

export const checkAutoAssignRating = (payload) => {
  return {
    type: CHECK_AUTO_ASSIGN_RATING,
    payload: api.checkAutoAssignRating(payload),
  };
};
export const getAllRatingRules = (payload) => {
  return {
    type: GET_ALL_RATING_RULES,
    payload: api.getAllRatingRules(payload),
  };
};
export const advanceEditorIllusto = (payload) => {
  return {
    type: ADVANCE_EDITOR_ILLUSTO,
    payload: api.advanceEditorIllusto(payload),
  };
};
export const createReservedChannle = (payload) => {
  return {
    type: CREATE_RESERVED_CHANNEL,
    payload: api.createReservedChannle(payload),
  };
};

export const listReservedChannel = (payload) => {
  return {
    type: CREATE_RESERVED_CHANNEL,
    payload: api.listReservedChannel(payload),
  };
};

export const deleteReservedChannel = (payload) => {
  return {
    type: CREATE_RESERVED_CHANNEL,
    payload: api.deleteReservedChannel(payload),
  };
};

export const getVerticalRequestDetails = (payload) => {
  return {
    type: GET_VERTICAL_REQUEST_DETAILS,
    payload: api.getVerticalRequestDetails(payload),
  };
};
export const submitSecretValue = (payload) => {
  return {
    type: VERIFY_SRT,
    payload: api.submitSecretValue(payload),
  };
};
export const fetchSecretValues = (payload) => {
  return {
    type: LIST_SECRET_VALUES,
    payload: api.fetchSecretValues(payload),
  };
};
export const deleteSecretValue = (payload) => {
  return {
    type: DELETE_SECRET_VALUE,
    payload: api.deleteSecretValue(payload),
  };
};
/***********************************
 * Epics
 ***********************************/

export const videogridEpic = combineEpics();
export const sportDetailsEpic = combineEpics();
