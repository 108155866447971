import { connect } from "react-redux";

import {
  clientWebHookPublish,
  fetchMe,
  fetchUserDetails,
  getUsersByUserIds,
  addPublishNotification,
} from "../../store/user/duck";
import {
  GenerateVideoTitle,
  addSocialMediaData,
  addThumbnail,
  bucketCopy,
  bulkS3BucketPublish,
  clipExportJson,
  clipExportXml,
  clipListFolder,
  copyClip,
  copyClipToReviewer,
  createFolder,
  createHighlight,
  createPreview,
  createS3PresignedURl,
  deleteClips,
  deleteEditedClip,
  deleteFolder,
  downloadAllClips,
  getClipsByFolder,
  downloadAsZip,
  downloadClips,
  downloadVideo,
  dumpOnS3,
  editClip,
  fetchBrightCoveToken,
  fetchStorage,
  ftpPublish,
  generateAutoFlip,
  genrateNewClip,
  getAllClips,
  getAspectRatios,
  getCategoryConfiguration,
  getClipSizeByUrl,
  getClipsCount,
  getCustomFields,
  getEventStream,
  getFolders,
  getPlayerStream,
  getPublicUrls,
  getS3PresignedURl,
  getSingleFolder,
  getSportsDetail,
  getStreamDetails,
  getTrimClip,
  getUploadPicture,
  getVideoMetaData,
  getftplist,
  isValidStream,
  jsonBucketPublish,
  overWriteClip,
  publishClip,
  s3BucketPublish,
  shareOnSocialMedia,
  shareOnSocialMedias,
  singleClipDetail,
  twitterShare,
  updateClipDetail,
  updateFolder,
  updateHighlightFolder,
  updatesftp,
  uploadClip,
  uploadClipInBrightCove,
  youtubeShare,
  getHLSToMP4,
  deleteHlsToMp4,
  updateVideoData,
  advanceEditorIllusto,
  getCsvData,
} from "../../store/videogrid/duck";
import { getRuleById } from "../../store/videogrid/duck";

import ClipsComponent from "./component";

const ClipsContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    getSportsDetail,
    getStreamDetails,
    getAllClips,
    getPlayerStream,
    getEventStream,
    getClipsCount,
    createFolder,
    updateFolder,
    deleteClips,
    getFolders,
    deleteFolder,
    fetchStorage,
    createHighlight,
    createPreview,
    shareOnSocialMedia,
    twitterShare,
    youtubeShare,
    getPublicUrls,
    getClipsByFolder,
    shareOnSocialMedias,
    fetchMe,
    ftpPublish,
    copyClip,
    editClip,
    publishClip,
    getTrimClip,
    s3BucketPublish,
    bulkS3BucketPublish,
    jsonBucketPublish,
    downloadVideo,
    updateHighlightFolder,
    getAspectRatios,
    generateAutoFlip,
    singleClipDetail,
    clipExportJson,
    downloadClips,
    clientWebHookPublish,
    dumpOnS3,
    updatesftp,
    getftplist,
    bucketCopy,
    fetchUserDetails,
    copyClipToReviewer,
    uploadClipInBrightCove,
    fetchBrightCoveToken,
    getCustomFields,
    getUsersByUserIds,
    addThumbnail,
    getUploadPicture,
    updateClipDetail,
    GenerateVideoTitle,
    clipListFolder,
    genrateNewClip,
    uploadClip,
    downloadAllClips,
    isValidStream,
    deleteEditedClip,
    overWriteClip,
    getCategoryConfiguration,
    getClipSizeByUrl,
    addSocialMediaData,
    clipExportXml,
    downloadAsZip,
    getSingleFolder,
    getVideoMetaData,
    getS3PresignedURl,
    updatesftp,
    getRuleById,
    createS3PresignedURl,
    addPublishNotification,
    getHLSToMP4,
    deleteHlsToMp4,
    updateVideoData,
    advanceEditorIllusto,
    getCsvData,
  },
)(ClipsComponent);

export default ClipsContainer;
