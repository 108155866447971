import React from "react";

function plusIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 1v14M1 8h14"
      ></path>
    </svg>
  );
}

export default plusIcon;
