import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";
import CloseModal from "../../../components/icons/CloseModal";
import { Spin, notification, message } from "antd";
import { get } from "lodash";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import ClientSideUpload from "../../../components/common/ClientSideUpload/component";
import { uploadClipValidation } from "../../../utils/validations";
function getVideoDuration(url, callback) {
  const video = document.createElement("video");
  video.preload = "metadata";
  video.onloadedmetadata = function () {
    window.URL.revokeObjectURL(video.src);
    const duration = video.duration;
    callback(duration);
  };
  video.src = url;
}

const UploadClipModal = (props) => {
  const { uploadModelIsOpen, setUploadModelIsOpen, streamDetails, uploadClip, allClips, isValidStream } = props;
  const uploadRef = useRef(null);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [duration, setduration] = useState(0);
  const [isvalid, setIsvalid] = useState(false);
  const [isvalidShowMessage, setIsvalidShowMessage] = useState({});
  const [initialState, setInitialValues] = useState({ uploadUrl: "" });
  const [isabort, setIsAbort] = useState(false);

  useEffect(() => {
    if (initialState.uploadUrl) {
      setIsvalidShowMessage({ status: true, message: "Validating URL please wait…" });
      setIsvalid(false);
      const timer = setTimeout(() => {
        validateUrl(initialState?.uploadUrl);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [initialState.uploadUrl]);

  const validateUrl = async (url) => {
    const isValidUrl = await isValidStream({ url });
    if (isValidUrl.value.success) {
      getVideoDuration(url, function (duration) {
        setduration(duration);
        setIsvalid(true);
      });
      setIsvalidShowMessage({});
    } else {
      setIsvalidShowMessage({ message: "Invalid url" });
    }
  };

  const abortFun = (value) => {
    if (value) {
      if (uploadRef.current) {
        uploadRef.current.abort();
      }
    }
  };

  const uploadClipFun = async ({ uploadUrl }) => {
    try {
      if (isvalid) {
        let data = {
          streamId: get(streamDetails, "streamId", ""),
          userId: localStorage.getItem("__uid"),
          duration: duration,
          url: uploadUrl,
          storage_name: get(streamDetails, "storageName", ""),
        };
        setSpinEnable(true);
        const { value } = await uploadClip(data);
        setSpinEnable(false);
        if (value.success) {
          notification["success"]({ message: value.message });
        } else {
          notification["error"]({ message: value.message });
        }
        closeUploadClipModal();
        allClips(false);
      }
    } catch (err) {
      closeUploadClipModal();
      notification["error"]({ message: "Something went wrong" });
    }
  };

  const closeUploadClipModal = () => {
    abortFun(isabort);
    setduration(0);
    setSpinEnable(false);
    setDisableEdit(false);
    setUploadModelIsOpen(false);
    setInitialValues({ uploadUrl: "" });
    setIsvalidShowMessage({});
  };

  return (
    <Modal
      isOpen={uploadModelIsOpen}
      onRequestClose={closeUploadClipModal}
      shouldCloseOnOverlayClick={false}
      contentLabel="Filter Modal"
      portalClassName="react-modal"
      overlayClassName="modal"
      className="modal-dialog react-modal-dialog-sm-md modal-dialog-centered"
      ariaHideApp={false}
    >
      <div className="modal-content">
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={uploadClipValidation}
          onSubmit={uploadClipFun}
        >
          {({ touched, errors, values }) => (
            <Form>
              <div className="react-modal-header">
                <h5 className="react-modal-title">Upload clips</h5>
                <button type="button" onClick={closeUploadClipModal} className="btn btn__default">
                  <CloseModal />
                </button>
              </div>
              <div className="react-modal-body pt-0">
                <Spin tip="Uploading..." indicator={<LoadingOutlined />} spinning={spinEnable}>
                  <ClientSideUpload
                    storageCred={get(streamDetails, "config.storage.credentials", {})}
                    setUploadUrl={(url) => {
                      setInitialValues({ uploadUrl: url });
                      setIsAbort(false);
                    }}
                    acceptFileType={"video/mp4,video/x-m4v,video/*"}
                    callBackFun={"onChange"}
                    setDisableEdit={setDisableEdit}
                    uploadRef={uploadRef}
                    setIsAbort={setIsAbort}
                  />
                </Spin>
                <div className="text-center ">OR</div>
                <label className="form-label">
                  URL <span className="required">*</span>{" "}
                  <span className="uploadClip-subtitle">(Please add video URL, If file size is more than 200 MB)</span>
                </label>
                <Field
                  className="form-control"
                  name="uploadUrl"
                  type="text"
                  value={values?.uploadUrl}
                  disabled={disableEdit}
                  onChange={(e) => {
                    setInitialValues({ uploadUrl: e.target.value });
                  }}
                />
                {touched.uploadUrl && errors.uploadUrl ? (
                  <div className="invalid-feedback">{errors.uploadUrl}</div>
                ) : values.uploadUrl.length && !_.isEmpty(isvalidShowMessage) ? (
                  <div className="invalid-feedback" style={isvalidShowMessage.status && { color: "orange" }}>
                    {isvalidShowMessage.message}
                  </div>
                ) : null}
                {/* {values.uploadUrl.length && !_.isEmpty(isvalidShowMessage) ? (
                  <div className="invalid-feedback" style={isvalidShowMessage.status && { color: "orange" }}>
                    {isvalidShowMessage.message}
                  </div>
                ) : null} */}
              </div>
              <div className="react-modal-footer ">
                <button type="submit" className="btn btn-primary btn-primary-uploadclip" disabled={spinEnable}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UploadClipModal;
