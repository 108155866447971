import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { SocketContext } from "../../context/socket";
import { Pagination, Tooltip } from "antd";

const FTPcomponent = (props) => {
  let { id } = useParams();
  const limit = 20;
  const { webhookSocket } = useContext(SocketContext);
  const { getftpPublishedClips } = props;
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState([]);
  const [ftpList, setftpList] = useState([]);

  const fetchftpList = async () => {
    const value = await getftpPublishedClips({
      id,
      page_no: activePage,
      limit: limit,
    });
    if (value?.value?.status) {
      setTotalCount(value?.value?.total);
      setftpList(value?.value?.data);
    }
  };
  useEffect(() => {
    fetchftpList();
  }, [activePage]);

  const handlePageChange = (activePage) => {
    setActivePage(activePage);
  };

  useEffect(() => {
    fetchftpList();
  }, []);

  useEffect(() => {
    webhookSocket.on(`ftpUpload-${id}`, (data) => {
      fetchftpList();
    });
  }, [webhookSocket, id]);

  return (
    <>
      <main className="main global-main">
        <div className="page-toolbar">
          <div className="page-breadcrumbs-styled">
            <div className="page-breadcrumbs-styled-inline">
              <div className="page-title">FTP Clips</div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="border-block mt-3">
            <div className="row">
              <div className="col-md-12">
                <div className="table-resposive table-container normal-table-block">
                  <table className="table">
                    <>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Type</th>
                          <th>FTP Name</th>
                          <th>FTP URL</th>
                          <th>Folder Path</th>
                          <th>File Name</th>
                          <th>Status</th>
                          <th>Published At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ftpList?.length ? (
                          ftpList?.map((data) => {
                            return (
                              <tr key={data?._id}>
                                <td>{data?.id}</td>
                                <td>{data?.type}</td>
                                <td>{data?.ftp_name}</td>
                                <td className="word-break-two">
                                  {" "}
                                  <Tooltip title={data?.ftp_url}>{data?.ftp_url}</Tooltip>
                                </td>
                                <td>
                                  <Tooltip title={data?.folderPath} placement={"bottomLeft"} className="word-break-two">
                                    {data?.folderPath}
                                  </Tooltip>
                                </td>
                                <td>
                                  <Tooltip
                                    title={data?.fileName}
                                    placement={"bottomLeft"}
                                    className="word-break-two title_Pascal"
                                  >
                                    {data?.fileName}
                                  </Tooltip>
                                </td>
                                <td>{data?.status}</td>
                                <td>
                                  {moment(data.createdAt).format("MMM DD, YYYY")},{" "}
                                  {moment(data.createdAt).format("h:mm A")}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan="7">
                                <div className="text-center  pt-5 pb-5">
                                  <h3>No Clips found</h3>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Pagination
        showSizeChanger={false}
        current={activePage}
        pageSize={limit}
        onChange={handlePageChange}
        total={totalCount}
        className="text-center mt-3"
      />
    </>
  );
};

export default FTPcomponent;
