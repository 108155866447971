import React from "react";
import Modal from "react-modal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = <LoadingOutlined style={{ fontSize: 24, align: "center" }} spin />;
const ConfirmationDialog = ({ header, modalIsOpen, loder, content, deleteComanModel, setDeleteModalOpen }) => {
  const onDelete = () => {
    deleteComanModel();
  };
  const onClose = () => {
    setDeleteModalOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={onClose}
        contentLabel="Delete Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">{header}</h5>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-body-text">{content}</p>
          </div>
          <div className="react-modal-footer text-center">
            <div className="react-foo-container">
              <button type="button" onClick={onClose} className="btn btn-white">
                Close{" "}
              </button>
              <button type="button" onClick={() => onDelete()} className="btn btn-primary">
                Delete
                {loder ? <Spin indicator={antIcon}></Spin> : ""}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmationDialog;
