import { CloudUploadOutlined, LoadingOutlined, ReloadOutlined, StarFilled } from "@ant-design/icons";
import { Dropdown, Pagination, Spin, Tooltip, notification } from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import ClipsFilter from "../../components/common/ClipsFilter/container";
import AlertDialog from "../../components/common/Modals/AlertDialog";
import { Download } from "../../components/icons";
import { SocketContext } from "../../context/socket";
import AddClipsEmptyState from "./partials/AddClipsEmptyState";
import AddNewStories from "./partials/AddNewStories";
import CreateNewFolder from "./partials/CreateNewFolder";
import Gridview from "./partials/Gridview";
import Listview from "./partials/Listview";
import UploadToS3 from "./partials/UploadToS3";
import { EyeOutlined } from "@ant-design/icons";
import "./styles.scss";
import { CheckboxBorder, Close, CopyClipboard, DeleteBorder, PlusBorder } from "../../components/icons";
import AccessControl from "../../components/common/AccessControl/accessControl";
import { UploadOutlined } from "@ant-design/icons";
import _ from "lodash";
import DeleteConfirmation from "../../components/common/ConfirmationModal/DeleteConfirmation";
import { ASPECT_RATIO, STREAM_STATUS, ZEE_CLIENT, secondsToString } from "../../constants/phase";
import BulkDownload from "./partials/BulkDownload";
import CopyClip from "./partials/CopyClip";
import CreatePromo from "./partials/CreatePromo";
import ClipUpload from "./partials/clipUpload";
import ViewRule from "../RuleBasedContent/partials/ViewRule";

import ManageAccess from "../../components/common/ManageAccess/manageAccess";
import LiveModalContext from "../ClipsNew/context";
import PreviewModal from "../ClipsNew/partials/PreviewModal";
import DownloadStats from "../../components/common/DownloadStats/downloadStats";
import moment from "moment";
import { ZEE_PROCESS_VIDEO_THRESHOLD } from "../../constants/phase";
const ClipsComponent = ({ ...props }) => {
  const {
    createHighlight,
    createPreview,
    getSportsDetail,
    getAllClips,
    getRuleById,
    fetchStorage,
    getPublicUrls,
    fetchMe,
    updateHighlightFolder,
    deleteClips,
    copyClip,
    editClip,
    publishClip,
    getTrimClip,
    s3BucketPublish,
    jsonBucketPublish,
    downloadVideo,
    getFolders,
    createFolder,
    getAspectRatios,
    generateAutoFlip,
    singleClipDetail,
    clipExportJson,
    clipExportXml,
    downloadClips,
    clientWebHookPublish,
    dumpOnS3,
    bucketCopy,
    fetchUserDetails,
    copyClipToReviewer,
    uploadClipInBrightCove,
    fetchBrightCoveToken,
    getCustomFields,
    getUsersByUserIds,
    addThumbnail,
    getUploadPicture,
    updateClipDetail,
    GenerateVideoTitle,
    clipListFolder,
    genrateNewClip,
    uploadClip,
    clip,
    getftplist,
    bulkS3BucketPublish,
    downloadAllClips,
    isValidStream,
    getClipSizeByUrl,
    deleteEditedClip,
    overWriteClip,
    downloadAsZip,
    getSingleFolder,
    updateFolder,
    ftpPublish,
    shareOnSocialMedias,
    getVideoMetaData,
    updatesftp,
    addPublishNotification,
    getClipsByFolder,
  } = props;

  const { clientSetting, getDownloadStatus } = useOutletContext();
  const navigate = useNavigate();
  const { socket, webhookSocket } = useContext(SocketContext);
  const { setBulkDownloadZip, bulkDownloadZip, eventId } = useOutletContext();
  const firstRef = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let updatedSearchParams = new URLSearchParams(searchParams.toString());
  const [uniqueId, setUniqueId] = useState("");
  const [toggle, setToggle] = useState(false);
  const [folderArray, setFolderArray] = useState([]);
  const [sportDeatils, setSportDeatails] = useState({});
  const [clipsDetails, setClipsDetails] = useState({});
  const [loder, setLoder] = useState(true);
  const [folderIsOpen, setFolderIsOpen] = useState(false);
  const [storyIsOpen, setStoryIsOpen] = useState(false);
  const [activePage, setActivePage] = useState(searchParams.get("pageNo") ? parseInt(searchParams.get("pageNo")) : 1);
  const [userId] = useState(localStorage.getItem("__uid"));
  const [events, setEvents] = useState([]);
  const [players, setPlayers] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [playBackSpeed, setPlayBackSpeed] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [count, setCount] = useState(0);
  const [type] = useState("all");
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [streamDetails, setStreamDetails] = useState(null);
  const [storage, setStorage] = useState([]);
  const [ftpList, setftpList] = useState([]);
  const [user, setUser] = useState({});
  const [showGrid, setShowGrid] = useState(true);
  const [folderClips, setFolderClips] = useState([]);
  const [folders, setFolders] = useState([]);
  const [stories, setStories] = useState([]);
  const [notify, setNotify] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [promos, setPromos] = useState([]);
  const [promoId, setPromoId] = useState("");
  const [promoIsOpen, setPromoIsOpen] = useState(false);
  const [storyId, setStoryId] = useState("");
  const [limit, setlimit] = useState(searchParams.get("limit") ? parseInt(searchParams.get("limit")) : 20);
  const [folderLimit] = useState(1000);
  const [state, setState] = useState(false);
  const [folderHide, setFolderHide] = useState(true);
  const [eventDisable, seteventDisable] = useState(true);
  const [checkhighLight, setCheckHighLight] = useState("");
  const [showClass, setShowClass] = useState("vv-video-card");
  const [clipPreviewData, setClipPreviewData] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clipTitle, setClipTitle] = useState("");
  const [uploadModelIsOpen, setUploadModelIsOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [clipTaggingFields, setClipTaggingFields] = useState([]);
  const [deleteClipsIsOpen, setDeleteClipsIsOpen] = useState(false);
  const [s3Open, setS3Open] = useState(false);
  const [ftpOpen, setFTPOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const [bulkDownloadModalOpen, setBulkDownloadModalOpen] = useState(false);
  const [clipDetail, setClipDetail] = useState({});
  const [viewStreamModal, setViewStreamModal] = useState(false);
  const [liveVideoUrl, setLiveVideoUrl] = useState("");
  const [hlsToMp4Data, setHlsToMp4Data] = useState([]);
  const [hlsToMp4Loader, setHlsToMp4Loader] = useState(false);
  const [socketCount, setSocketCount] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(null);

  const [filters, setFilters] = useState(
    JSON.parse(searchParams.get("filters")) || {
      players: [],
      events: [],
      sortBy: "TIME_DESCENDANT",
      aspectRatio: "",
      playBackSpeed: [],
      webhookPublish: "",
      clipData: {},
      clipRating: [],
      dateRange: [],
    },
  );
  const [filterClipsByTag, setFilterClipsByTag] = useState(
    JSON.parse(searchParams.get("filterClips")) || {
      taggedClips: false,
      unTaggedClips: false,
    },
  );

  //------------------ For /rule-based-clip ---------------------------
  const [streamId, setStreamId] = useState(null);
  const [ruleTitle, setRuleTitle] = useState(null);
  const [id, setId] = useState(useParams().id);
  const { id: folderParamId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ruleId = params?.get("ruleId");
  const isRuleBasedClips = location.pathname.includes("/rule-based-clip");

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [clipIdsArray, setClipIdsArray] = useState([]);
  const [eventsFilter, setEventFilters] = useState([]);
  const { dateRange, aspectRatio, webhookPublish, clipRating } = filters;
  const [isManualUpload, setIsManualUpload] = useState(JSON.parse(searchParams.get("isManualUpload") || false));
  const [updateFilter, setUpdateFilter] = useState(false);
  const [viewRuleData, setViewRuleData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [sports, setSports] = useState({});

  const antIcon = <LoadingOutlined style={{ fontSize: 30, color: "#000", align: "center" }} spin />;
  const [copyOpen, setCopyOpen] = useState(false);

  const data = location.state?.data;

  const ruleClipsLength = params.get("clips");

  useEffect(() => {
    firstRef.current = true;
    const pageNo = parseInt(searchParams.get("pageNo")) || 1;
    const limit = parseInt(searchParams.get("limit")) || 20;
    const step = JSON.parse(searchParams.get("step")) || 1;
    const filters = JSON.parse(searchParams.get("filters")) || {
      players: [],
      events: [],
      customTags: [],
      sortBy: "TIME_DESCENDANT",
      aspectRatio: "",
      playBackSpeed: [],
      webhookPublish: "",
      clipData: {},
      clipRating: [],
      isIllustoClip: false,
    };

    const search = JSON.parse(searchParams.get("search")) || "";
    const isManualUpload = JSON.parse(searchParams.get("isManualUpload")) || false;
    setActivePage(pageNo);
    setlimit(limit);
    setFilters(filters);
    setSearch(search);
    setIsManualUpload(isManualUpload);
    setStep(step);
    setIsShow(true);
    if (step !== 4) {
      setCount(count + 1);
    } else {
      getHLSToMP4();
      setTotalCount(1);
    }
  }, [searchParams]);
  useEffect(() => {
    // createPromos();
    if (props) {
      fetchfolders();
      fetchStories();
    }
    getUsers();
    sportsDetail();
    fetchStorages();
    if (isRuleBasedClips || searchParams.get("navigating-from") === "rule-overview") {
      getRule();
    }
    getStreamDetail();
    fetchftpList();
  }, [id]);

  useEffect(() => {
    if (socketCount > 0) {
      getHLSToMP4();
    }
  }, [socketCount]);

  useEffect(() => {
    webhookSocket.on(`livecut${id}`, (data) => {
      setUniqueId(data?.uniqueId);
    });
    webhookSocket.on(`createclip${id}`, (data) => {
      setUniqueId(data?.uniqueId);
    });
    webhookSocket.on(`thumbnail_update_${id}`, (data) => {
      setUniqueId(data?.uniqueId);
    });
    webhookSocket.on(`hlsToMp4Preview${id}`, (data) => {
      setSocketCount((socketCount) => socketCount + 1);
    });
  }, [webhookSocket, id]);

  useEffect(() => {
    if (count > 0) {
      allClips();
      window.scroll(0, 0);
    }
  }, [count]);

  useEffect(() => {
    if (uniqueId) {
      allClips(false);
    }
  }, [uniqueId]);

  useEffect(() => {
    getEventStream(filters.events || [], filters.clipRating || []);
  }, [streamDetails, filters.events]);

  const sportsDetail = async () => {
    const { value } = await getSportsDetail({ userId });
    if (value.success) {
      setSportDeatails(value?.data || {});
      setSports(value?.data?.sports);
    }
  };
  const closeModal = () => {
    setFolderId("");
    setState(false);
    setNotify(false);
    setFolderHide(true);
    seteventDisable(true);
    setFolderIsOpen(false);
  };

  const closeStoryModal = () => {
    setStoryId("");
    setState(false);
    setNotify(false);
    setFolderHide(true);
    seteventDisable(true);
    setStoryIsOpen(false);
  };

  const closePromoModal = () => {
    setPromoId("");
    setState(false);
    setNotify(false);
    setFolderHide(true);
    seteventDisable(true);
    setPromoIsOpen(false);
  };

  const fetchfolders = async () => {
    const { value } = await getFolders({
      streamId: id,
      types: ["clip"],
      search: "",
      activePage: 1,
      limit: folderLimit,
      sortBy: { _id: -1 },
    });
    setFolders(value.data);
  };

  const fetchStories = async () => {
    const { value } = await getFolders({
      streamId: id,
      types: ["stories"],
      search: "",
      activePage: 1,
      limit: folderLimit,
      sortBy: { _id: -1 },
    });
    setStories(value.data);
  };

  const highlightNotification = (message) => {
    if (message === "Folder updated successfully") {
      notification["success"]({ message: "Highlight Updated Successfully", duration: 6 });
    } else {
      if (message.endsWith("exists")) {
        notification["warning"]({ message: message, duration: 6 });
      } else {
        notification["success"]({ message: message, duration: 6 });
      }
    }
    closeModal();
  };

  const storyNotification = (message) => {
    if (message === "Folder updated successfully") {
      notification["success"]({ message: "Story Updated Successfully", duration: 6 });
    } else {
      if (message.endsWith("exists")) {
        notification["warning"]({ message: message, duration: 6 });
      } else {
        notification["success"]({ message: message, duration: 6 });
      }
    }
    closeStoryModal();
  };

  const promoNotification = (message) => {
    if (message === "Folder updated successfully") {
      notification["success"]({ message: "Promo Updated Successfully", duration: 6 });
    } else {
      if (message.endsWith("exists")) {
        notification["warning"]({ message: message, duration: 6 });
      } else {
        notification["success"]({ message: message, duration: 6 });
      }
    }
    closePromoModal();
  };

  const handleFolder = async (id) => {
    setLoder(true);
    setFolderId(id);
    const { value } = await updateHighlightFolder({ clips: folderArray.map(({ _id }) => _id), _id: id });
    if (value.status) {
      if (folderArray.length > 0) {
        fetchfolders();
        folders &&
          folders.forEach((folder) => {
            if (id === folder._id) {
              highlightNotification(value.message);
            }
          });
      } else {
        notification["warning"]({ message: "No clip selected!", duration: 6 });
      }
      setFolderArray([]);
    }
    if (!value.status) {
      notification["warning"]({ message: value.message, duration: 6 });
    }
    setLoder(false);
  };

  const handleStory = async (id) => {
    setLoder(true);
    setStoryId(id);
    const { value } = await updateHighlightFolder({ clips: folderArray.map(({ _id }) => _id), _id: id });
    if (value.status) {
      if (folderArray.length > 0) {
        fetchStories();
        stories &&
          stories.forEach((story) => {
            if (id === story._id) {
              storyNotification(value.message);
            }
          });
      } else {
        notification["warning"]({ message: "No clip selected!", duration: 6 });
      }
      setFolderArray([]);
    }
    if (!value.status) {
      notification["warning"]({ message: value.message, duration: 6 });
    }
    setLoder(false);
  };

  const allClips = async (status) => {
    if (status === true) {
      setLoder(true);
    } else if (status === false) {
      setLoder(false);
    } else {
      setLoder(true);
    }
    if (!isRuleBasedClips) {
      const { value } = await getAllClips({
        filters,
        limit,
        pageNo: activePage,
        userId,
        dateRange: dateRange?.length
          ? [dateRange[0].startOf("day").toISOString(), dateRange[1].endOf("day").toISOString()]
          : [],
        id,
        sort: clipSortBy(),
        type,
        clipType: step === 1 ? "clip" : step === 2 ? "highlight" : "promo",
        search,
        aspectRatio,
        webhookPublish,
        isManualUpload,
        page: "clips",
      });
      if (value.success) {
        setClipsDetails(value?.clips);
        setTotalCount(value?.totalLength);
      }
    } else {
      const payload = {
        id: folderParamId,
        search,
        pageNo: activePage,
        limit,
      };
      const { value } = await getClipsByFolder(payload);
      if (value?.success) {
        setStreamId(value.data[0].streamId);
        setRuleTitle(value.data[0].title);
        setId(value.data[0].streamId);
        setClipsDetails(value.data[0].clips);
        setTotalCount(value.totalLength || 0);
      }
    }
    setLoder(false);
  };
  function handleChange(e) {
    setlimit(e.target.value);
    updatedSearchParams.set("limit", e.target.value);
    updatedSearchParams.set("pageNo", 1);
    setSearchParams(updatedSearchParams);
  }
  const removeManualClip = () => {
    updatedSearchParams.set("isManualUpload", false);
    setSearchParams(updatedSearchParams);
  };
  let pathName = location.pathname.replace("/rule-based-clip/", "");

  const clipSortBy = () => {
    let sortBy = filters?.sortBy || "TIME_DESCENDANT";
    let sort = { start_time: -1, _id: 1 };
    if (sortBy == "TIME_ASCENDANT") {
      sort = { start_time: 1, _id: 1 };
    } else if (sortBy == "RECENT") {
      sort = { _id: -1 };
    }
    return sort;
  };

  const refreshClips = () => {
    getStreamDetail();
    if (step === 4) {
      getHLSToMP4();
    } else {
      allClips();
    }
  };

  const allClipsForCms = async () => {
    const { value } = await getAllClips({
      filters,
      limit,
      pageNo: activePage,
      userId,
      dateRange: dateRange?.length
        ? [dateRange[0].startOf("day").toISOString(), dateRange[1].endOf("day").toISOString()]
        : [],
      id,
      sort: clipSortBy(),
      type,
      clipType: step === 1 ? "clip" : step === 2 ? "highlight" : "promo",
      search,
    });
    if (value.success) {
      setClipsDetails(value?.clips);
      setTotalCount(value?.totalLength || 0);
    }
  };

  const formateANDSetCustomTags = (customTags) => {
    const modifiedData = customTags.map((item) => {
      const match = (filters?.customTags || []).find((d) => d.key === item.key);
      const events = item.events.map((event) => {
        const checked = match && match.events.includes(event.event);
        return {
          ...event,
          checked: checked ? checked : false,
          searchable: true,
        };
      });

      return {
        ...item,
        events,
      };
    });

    setCustomTags(modifiedData);
  };

  const getEventStream = async (event, clipRating) => {
    if (streamDetails?.category) {
      const { getEventStream } = props;
      let data = { streamId: id, category: streamDetails?.category };
      data.events = JSON.stringify(event) || [];
      data.clipRating = JSON.stringify(clipRating) || [];
      data.clipType = step === 1 ? "clip" : step === 2 ? "highlight" : "promo";
      data.isManualUpload = isManualUpload;
      const { value } = await getEventStream(data);
      if (value.success) {
        setEvents(value?.filters?.[0]?.events || []);
        setPlayers(value?.filters?.[0]?.players || []);

        formateANDSetCustomTags(value?.filters?.[0]?.customTags || []);
        setPlayBackSpeed(value?.filters?.[0]?.playBackSpeed || []);
      }
    }
  };

  const getStreamDetail = async () => {
    const { getStreamDetails } = props;
    const { value } = await getStreamDetails(id);
    if (value.success) {
      setStreamDetails(value.stream);
      fetchCategoryConfiguration(value?.stream?.category || "");
      updateDownloadStatus(value.stream);
      setLoder(false);
    }
  };
  const fetchCategoryConfiguration = async (category) => {
    const { getCategoryConfiguration } = props;
    const { value } = await getCategoryConfiguration({ category });
    setClipTaggingFields(value?.data?.clipTaggingFields || []);
  };

  const fetchStorages = async () => {
    const {
      value: { storages },
    } = await fetchStorage({ userId, type: "sports_keymoments" });
    setStorage(storages);
  };

  const fetchftpList = async () => {
    const value = await getftplist({
      userId,
      page_no: 1,
      limit: 20,
    });
    if (value?.value?.status) {
      setftpList(value?.value?.data);
    }
  };

  const handlePageChange = (pageNo) => {
    setActivePage(pageNo);
    updatedSearchParams.set("pageNo", parseInt(pageNo));
    setSearchParams(updatedSearchParams);
  };

  const updateRemoveClipData = (name, data) => {
    let clipdata = filters.clipData;
    let index = clipdata[name].indexOf(data);
    if (clipdata[name].length == 1) {
      delete clipdata[name];
    } else {
      clipdata[name].splice(index, 1);
    }
    updatedSearchParams.set("filters", JSON.stringify({ ...filters, clipData: clipdata }));
    setSearchParams(updatedSearchParams);
  };

  const updateplayer = () => {
    if (_.isEmpty(filters.events)) {
      updatedSearchParams.set("filters", JSON.stringify({ ...filters, players: [], events: eventsFilter }));
    } else {
      let player = filters?.players?.filter(
        (player) => players.map(({ playerName }) => playerName).indexOf(player) !== -1,
      );
      updatedSearchParams.set("filters", JSON.stringify({ ...filters, players: player, events: eventsFilter }));
    }
    setSearchParams(updatedSearchParams);
    setIsShow(true);
    setUpdateFilter(false);
  };

  useEffect(() => {
    if (updateFilter) {
      updateplayer();
    }
  }, [players]);

  const removeFromEventsArray = (key, event) => {
    let customTags = [...(filters?.customTags || [])];
    customTags.forEach((item) => {
      if (item.key === key) {
        const index = item.events.indexOf(event);
        if (index !== -1) {
          item.events.splice(index, 1);
        }
      }
    });
    updatedSearchParams.set("filters", JSON.stringify({ ...filters, customTags }));
  };
  const updateRemoveFilter = (name, data, tagName) => {
    let index;
    switch (name) {
      case "events":
        index = filters.events.indexOf(data);
        filters.events.splice(index, 1);
        setEventFilters(filters.events);
        getEventStream(filters.events, filters.clipRating);
        setUpdateFilter(true);
        break;

      case "players":
        index = filters.players.indexOf(data);
        filters.players.splice(index, 1);
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, players: filters.players }));
        break;

      case "removeAspectRatio":
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, aspectRatio: "" }));
        break;
      case "removeWebhookPublish":
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, webhookPublish: "" }));
        break;

      case "removePlayBackSpeed":
        index = filters.playBackSpeed.indexOf(data);
        filters.playBackSpeed.splice(index, 1);
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, playBackSpeed: filters.playBackSpeed }));
        break;

      case "removeSortBy":
        filters.sortBy = "TIME_DESCENDANT";
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, sortBy: filters.sortBy }));
        break;
      case "removeRating":
        index = filters.clipRating.indexOf(data);
        filters.clipRating.splice(index, 1);
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, clipRating: filters.clipRating }));
        getEventStream(filters.events, filters.clipRating);
        break;
      case "customTags":
        removeFromEventsArray(tagName, data);
        setSearchParams(updatedSearchParams);
        formateANDSetCustomTags(customTags);

        break;
      case "removeAdvancedEditor":
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, isIllustoClip: false }));
        break;
      default:
        break;
    }
    setSearchParams(updatedSearchParams);
  };

  const getUsers = async () => {
    const data = await fetchMe();
    if (data.value.status) {
      setUser(data.value.data);
    }
  };

  const getRule = async () => {
    const { value } = await getRuleById({ ruleId });
    if (value?.success) {
      setViewRuleData(value.data);
    }
  };

  const setShowState = () => {
    setShowGrid(!showGrid);
  };

  const openFolderModal = () => {
    setFolderIsOpen(true);
  };

  const openPromoModal = () => {
    setPromoIsOpen(true);
  };

  const openStoryModal = () => {
    setStoryIsOpen(true);
  };

  const removeSelect = () => {
    setFolderArray([]);
    setShowClass("vv-video-card");
  };

  const openPreviewModal = (clip) => {
    if (step === 4) {
      setViewStreamModal(true);
    } else {
      setClipDetail(clip);
      setClipTitle(clip.episodeTitle);
      if (clip.editedVideos) {
        const url = clip.editedVideos.find(
          (o) => o?.aspect_ratio === filters?.aspectRatio && o.event !== "dynamicCropped",
        )?.video_url;
        const duration = clip.editedVideos.find(
          (o) => o?.aspect_ratio === filters?.aspectRatio && o.event !== "dynamicCropped",
        )?.duration;
        openModal(url || clip?.videoUrl, duration || clip?.duration);
      } else {
        openModal(clip?.videoUrl || clip?.duration);
      }
    }
  };

  const openModal = (url, duration) => {
    setClipPreviewData({ url, duration });
    setIsOpen(true);
  };

  const openDeleteClipsModal = (url) => {
    setDeleteClipsIsOpen(true);
  };

  const fetchUsersByUserIds = async (userIds) => {
    const data = await getUsersByUserIds({
      userIds,
    });
    if (data?.value.success) {
    }
  };

  const openUploadClipModal = () => {
    setUploadModelIsOpen(true);
  };

  //Copy the Clip using Stream ID
  const copyClipFun = async (payload) => {
    setLoder(true);
    const data = await copyClip(payload);
    if (data.value.success) {
      notification["success"]({ message: data.value.message });
      allClips();
    } else {
      notification["error"]({ message: data.value.message });
    }
    setLoder(false);
  };

  // open copy clips modal
  const openCopyFun = () => {
    setCopyOpen(true);
  };

  const deleteClip = async () => {
    setLoder(true);
    const { value } = await deleteClips({ id: folderArray.map((d) => d._id) });
    if (value.success) {
      notification["success"]({ message: value.message });
    }
    allClips();
    setDeleteClipsIsOpen(false);
    setFolderArray([]);
  };
  const handleSelectClips = (e) => {
    setCheckHighLight("Create Highlight");
  };
  const closeToggle = (e) => {
    setCheckHighLight();
    setFolderArray([]);
  };
  const openS3Publish = () => {
    setS3Open(true);
    if (folderArray?.length > 5) {
      notification["warning"]({
        message: "Maximum of 5 clips can be publish or Max file size should be 500 MB or less",
      });
    }
  };
  const openBulkDownload = () => {
    setBulkDownloadModalOpen(true);
  };
  useEffect(() => {
    socket.on(`illustoUpdateClipResponse${localStorage.getItem("__uid")}`, (data) => {
      const step = params.get("step");
      if (step && step == 2) {
        handleSteps(2);
      } else {
        allClips();
        handlePageChange(activePage);
      }
    });
    webhookSocket.on(`illustoUpdateClipResponse${localStorage.getItem("__uid")}`, (data) => {
      const step = params.get("step");
      if (step && step == 2) {
        handleSteps(2);
      } else {
        allClips();
        handlePageChange(activePage);
      }
    });
    return () => {
      socket.off(`illustoUpdateClipResponse${localStorage.getItem("__uid")}`);
      webhookSocket.off(`illustoUpdateClipResponse${localStorage.getItem("__uid")}`);
    };
  }, [socket, webhookSocket]);
  useEffect(() => {
    setClipIdsArray(
      folderArray.filter(({ aspectRatio }) => aspectRatio === ASPECT_RATIO["16_9"]).map(({ _id }) => _id),
    );
  }, [folderArray]);

  const handleNotification = () => {
    if (clipIdsArray?.length > 20) {
      notification["warning"]({ message: "Bulk editing is possible for 20 clips at once" });
    } else if (folderArray.some(({ editedClipData }) => editedClipData?.isPreview)) {
      notification["warning"]({
        message:
          "The editing of one or more clips is currently in progress, so try again after a few minutes or try removing some clips.",
      });
      return false;
    } else {
      if (folderArray.some(({ aspectRatio }) => aspectRatio !== ASPECT_RATIO["16_9"])) {
        notification["warning"]({ message: "Clips with ARs other than 16:9 can not be included in bulk edits" });
      }
      return true;
    }
  };

  const downloadAsZipFolder = async () => {
    const fileUrls = folderArray?.map(({ editedVideos, episodeTitle, videoUrl, duration, _id }) => {
      return {
        url:
          aspectRatio === "16:9" || !aspectRatio
            ? videoUrl
            : editedVideos?.find((v) => v?.aspect_ratio === aspectRatio)?.video_url,
        key: episodeTitle,
        _id,
        duration:
          aspectRatio === "16:9" || !aspectRatio
            ? duration
            : editedVideos?.find((v) => v?.aspect_ratio === aspectRatio)?.duration,
      };
    });
    const data = await downloadAsZip({
      fileUrls: fileUrls,
      webhookURL: `${process.env.REACT_APP_WEBHOOK_SERVER}/zip-url`,
      event: `zip-url-${eventId}`,
      streamId: id,
    });
    if (data.value.status) {
    } else {
      setBulkDownloadZip({ ...bulkDownloadZip, downloadStatus: false });
      if (data.value.limitError) {
        setIsAlertOpen(true);
        setAlertMessage(data.value.message);
      } else {
        notification["error"]({ message: "Something went wrong" });
      }
    }
  };
  const bytesToMB = (bytes) => {
    if (!bytes) {
      return "";
    }
    const mb = bytes / (1024 * 1024);
    if (mb >= 1024) {
      return (mb / 1024).toFixed(2) + " GB";
    } else {
      return mb.toFixed(2) + " MB";
    }
  };

  const selectAllClips = () => {
    const newFolderArray = [...folderArray];
    clipsDetails.forEach((item) => {
      const index = newFolderArray.findIndex((folderItem) => folderItem.id === item.id);
      if (index === -1) {
        newFolderArray.push(item);
      }
    });
    setFolderArray(newFolderArray);
  };

  const handleSteps = (step) => {
    updatedSearchParams.set("step", step);
    updatedSearchParams.set("pageNo", 1);
    updatedSearchParams.delete("search");
    setSearchParams(updatedSearchParams);
  };

  const handleBulkNavigation = () => {
    let isLimitExceeded = folderArray.some(
      (clip) => clip.duration > clientSetting?.limitation?.maxVideoProcessDuration,
    );
    if (isLimitExceeded && clientSetting?.isProcessingLimit) {
      setIsAlertOpen(true);
      setAlertMessage(
        `Some video clips have a duration exceeding ${clientSetting?.limitation?.maxVideoProcessDuration} second(s).`,
      );
    } else {
      setShowClass("vv-video-card selected");
      const status = handleNotification();
      if (status) {
        navigate(`/bulk-edits?clipIds=${JSON.stringify(clipIdsArray)}`);
      }
    }
  };

  const getHLSToMP4 = async () => {
    const { getHLSToMP4 } = props;
    setHlsToMp4Loader(true);
    const { value } = await getHLSToMP4({ streamId: id });
    if (value.status) {
      setHlsToMp4Data(value?.data || []);
    }
    setHlsToMp4Loader(false);
  };

  const updateDownloadStatus = async (stream) => {
    try {
      getDownloadStatus({ userId: localStorage.getItem("__uid"), streamId: stream.streamId });
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getHLSToMP4();
  }, []);

  const actions = {
    add_to_highlight: true,
    add_to_story: true,
    Bulk_clip_editor: true,
    promo: true,
  };

  const handleClipsTableAction = (profileFeature, menuItems) => {
    for (const key in actions) {
      if (profileFeature.hasOwnProperty(key) && profileFeature?.[key]) {
        switch (key) {
          case "add_to_highlight":
            clientSetting?.appAdditionalPermissions?.isAllowAccessToHighlight &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      openFolderModal();
                      setShowClass("vv-video-card selected");
                    }}
                  >
                    <span className="btn ant-dropdown-btn"> Add to Highlight</span>
                  </a>
                ),
                key: "0",
              });
            break;

          case "add_to_story":
            clientSetting?.appAdditionalPermissions?.webStory &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      openStoryModal();
                      setCheckHighLight("Create story");
                      handleSelectClips();
                    }}
                  >
                    <span className="btn ant-dropdown-btn"> Add to Story</span>
                  </a>
                ),
                key: "1",
              });
            break;

          case "Bulk_clip_editor":
            clientSetting?.appAdditionalPermissions?.isBulkClipEditor &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      handleBulkNavigation();
                    }}
                  >
                    <span className="btn ant-dropdown-btn"> Bulk Clip Editor({clipIdsArray?.length})</span>
                  </a>
                ),
                key: "2",
                disabled: clipIdsArray?.length < 2,
              });
            break;

          case "promo":
            menuItems.push({
              label: (
                <a
                  onClick={() => {
                    if (!(folderArray.length > 1)) {
                      notification["error"]({ message: "Please select multiple clips for creating the promo." });
                    } else if (folderArray.filter(({ duration }) => duration <= 3).length) {
                      notification["error"]({ message: "Clips duration should be more than 3 seconds" });
                    } else {
                      openPromoModal();
                      setShowClass("vv-video-card selected");
                    }
                  }}
                >
                  <span className="btn ant-dropdown-btn"> Create Promo</span>
                </a>
              ),
              key: "3",
            });
        }
      }
    }
  };

  const contextValues = {
    streamDetails,
    page: "clips",
    viewStreamModal,
    setViewStreamModal,
    liveVideoUrl,
    controls: true,
  };
  const memoizedContextValue = useMemo(() => contextValues, [viewStreamModal, setViewStreamModal, liveVideoUrl]);

  useEffect(() => {
    const originalTimestamp = streamDetails?.processStartTimeInQueue;
    const inputVideoDuration = streamDetails?.inputVideoDuration;
    if (
      [STREAM_STATUS.PROCESSING, STREAM_STATUS.DELAYED].includes(streamDetails?.status) &&
      originalTimestamp &&
      inputVideoDuration
    ) {
      const threshHoldValue = (inputVideoDuration * ZEE_PROCESS_VIDEO_THRESHOLD) / 100;
      const id = setInterval(() => {
        const timeDiffInSeconds = threshHoldValue - moment.duration(moment().diff(originalTimestamp)).asSeconds();
        setTimerSeconds(timeDiffInSeconds);
      }, 1000);
      return () => clearInterval(id);
    }
  }, [streamDetails]);
  const initiateProcress = async () => {
    const { updateVideoData } = props;
    const { value } = await updateVideoData({ streamId: id, status: STREAM_STATUS.PROCESSING });
    notification[value.success ? "success" : "error"]({ message: value.message });
    getStreamDetail();
  };

  return (
    <>
      <ViewRule viewModel={viewModal} setViewModel={setViewModal} viewRuleData={viewRuleData} sports={sports} />
      <LiveModalContext.Provider value={memoizedContextValue}>
        <PreviewModal key={viewStreamModal} />
      </LiveModalContext.Provider>

      <AlertDialog
        alertModel={isAlertOpen}
        content={alertMessage}
        oncloseAlertModel={() => {
          setIsAlertOpen(false);
        }}
      />
      <BulkDownload
        bulkDownloadModalOpen={bulkDownloadModalOpen}
        setBulkDownloadModalOpen={setBulkDownloadModalOpen}
        folderArray={folderArray}
        setFolderArray={setFolderArray}
        downloadClips={downloadClips}
        downloadAllClips={downloadAllClips}
        setLoder={setLoder}
        loder={loder}
        downloadAsZip={downloadAsZip}
        setBulkDownloadZip={setBulkDownloadZip}
        downloadAsZipFolder={downloadAsZipFolder}
        aspectRatio={aspectRatio}
        streamId={id}
        getS3PresignedURl={props.getS3PresignedURl}
        setIsAlertOpen={setIsAlertOpen}
        setAlertMessage={setAlertMessage}
        filters={filters}
      />
      <DeleteConfirmation
        deleteConfirmModalOpen={deleteClipsIsOpen}
        setDeleteConfirmModalOpen={setDeleteClipsIsOpen}
        clipCounts={folderArray?.length > 1 ? `${folderArray?.length} clips` : `${folderArray?.length} clip`}
        deleteFunction={deleteClip}
        setLoder={setLoder}
        loder={loder}
      />
      {s3Open && (
        <UploadToS3
          s3Open={s3Open}
          setS3Open={setS3Open}
          clip={clip}
          fetchStorage={fetchStorage}
          userId={userId}
          bulkS3BucketPublish={bulkS3BucketPublish}
          streamDetails={streamDetails}
          storage={storage}
          dumpOnS3={dumpOnS3}
          bucketCopy={bucketCopy}
          clientSetting={clientSetting}
          folderArray={folderArray}
          setFolderArray={setFolderArray}
          getClipSizeByUrl={getClipSizeByUrl}
          setLoder={setLoder}
          filters={filters}
        />
      )}
      <AddNewStories
        fetchStories={fetchStories}
        createFolder={createFolder}
        id={id}
        setStoryIsOpen={setStoryIsOpen}
        storyIsOpen={storyIsOpen}
        handleStory={handleStory}
        stories={stories}
        storyId={storyId}
        notify={notify}
        closeStoryModal={closeStoryModal}
        setNotify={setNotify}
        setStoryId={setStoryId}
        state={state}
        setState={setState}
        folderHide={folderHide}
        setFolderHide={setFolderHide}
        eventDisable={eventDisable}
        seteventDisable={seteventDisable}
        folderArray={folderArray}
      />
      {promoIsOpen && (
        <CreatePromo
          updateFolder={updateFolder}
          genrateNewClip={genrateNewClip}
          streamDetails={streamDetails}
          generateAutoFlip={generateAutoFlip}
          getAspectRatios={getAspectRatios}
          folderArray={folderArray}
          setFolderArray={setFolderArray}
          createPreview={createPreview}
          createHighlight={createHighlight}
          getSingleFolder={getSingleFolder}
          createFolder={createFolder}
          id={id}
          setPromoIsOpen={setPromoIsOpen}
          promoIsOpen={promoIsOpen}
          promos={promos}
          promoId={promoId}
          notify={notify}
          closePromoModal={closePromoModal}
          setNotify={setNotify}
          setPromoId={setPromoId}
          state={state}
          setState={setState}
          folderHide={folderHide}
          setFolderHide={setFolderHide}
          eventDisable={eventDisable}
          seteventDisable={seteventDisable}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}

      <CopyClip
        copyOpen={copyOpen}
        setCopyOpen={setCopyOpen}
        copyClipFun={copyClipFun}
        clip={clip}
        loder={loder}
        folderArray={folderArray}
      />

      <CreateNewFolder
        fetchfolders={fetchfolders}
        createFolder={createFolder}
        id={id}
        setFolderIsOpen={setFolderIsOpen}
        folderIsOpen={folderIsOpen}
        handleFolder={handleFolder}
        folders={folders}
        closeModal={closeModal}
        setNotify={setNotify}
        notify={notify}
        folderId={folderId}
        setFolderId={setFolderId}
        state={state}
        setState={setState}
        folderHide={folderHide}
        setFolderHide={setFolderHide}
        eventDisable={eventDisable}
        seteventDisable={seteventDisable}
        getAspectRatios={getAspectRatios}
        folderArray={folderArray}
      />

      <ClipUpload
        uploadModelIsOpen={uploadModelIsOpen}
        setUploadModelIsOpen={setUploadModelIsOpen}
        streamDetails={streamDetails}
        uploadClip={uploadClip}
        allClips={allClips}
        isValidStream={isValidStream}
      />

      <main className="main">
        <ClipsFilter
          id={id}
          ruleId={ruleId}
          ruleTitle={ruleTitle}
          getEventStream={getEventStream}
          sportDeatils={sportDeatils}
          events={events}
          players={players}
          customTags={customTags}
          firstRef={firstRef}
          finalFilters={filters}
          setFinalFilters={setFilters}
          allClips={allClips}
          setIsShow={setIsShow}
          count={count}
          setCount={setCount}
          search={search}
          setSearch={setSearch}
          streamDetails={streamDetails}
          userId={userId}
          getPublicUrls={getPublicUrls}
          setFolderClips={setFolderClips}
          folderClips={folderClips}
          folderArray={folderArray}
          setFolderArray={setFolderArray}
          isShow={isShow}
          clipsDetails={clipsDetails}
          checkhighLight={checkhighLight}
          setCheckHighLight={setCheckHighLight}
          setShowClass={setShowClass}
          showClass={showClass}
          limit={limit}
          setLimit={setlimit}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          updatedSearchParams={updatedSearchParams}
          AccessControl={AccessControl}
          isManualUpload={isManualUpload}
          setIsManualUpload={setIsManualUpload}
          filterClipsByTag={filterClipsByTag}
          setFilterClipsByTag={setFilterClipsByTag}
          disabled={isRuleBasedClips}
        />
        <div className="container-fluid">
          <div className="toolbar-styled">
            <div className="toolbar-styled-left">
              <div className="clip-icons-container">
                <AccessControl code="select_close_toggle" page="clips">
                  <div className="actions-button-group">
                    <div>
                      {checkhighLight === "Create Highlight" || checkhighLight === "Create story" ? (
                        <>
                          <button className="button-toggle" onClick={closeToggle}>
                            <Close />
                          </button>
                        </>
                      ) : (
                        <button className="button-toggle" onClick={(e) => handleSelectClips(e)}>
                          <CheckboxBorder />
                        </button>
                      )}
                    </div>
                  </div>
                </AccessControl>
                {folderArray.length || checkhighLight === "Create Highlight" || checkhighLight === "Create story" ? (
                  <>
                    <span className="countText">
                      {folderArray?.length} / {isRuleBasedClips ? ruleClipsLength : totalCount}
                    </span>
                    <div className="actions-button-group">
                      <ManageAccess value={clientSetting?.appAdditionalPermissions?.isClipBulkActions}>
                        <Dropdown
                          disabled={folderArray.length == 0}
                          overlay={
                            <AccessControl code="menuItem" page="clips" handleTableAction={handleClipsTableAction} />
                          }
                          trigger={["click"]}
                        >
                          <button onClick={(e) => e.preventDefault()}>
                            <PlusBorder />
                          </button>
                        </Dropdown>
                      </ManageAccess>
                      <ManageAccess value={clientSetting?.appAdditionalPermissions?.isBulkCopyClip}>
                        <Tooltip title="Copy">
                          <button onClick={() => openCopyFun()} disabled={folderArray.length == 0}>
                            <CopyClipboard />
                          </button>
                        </Tooltip>
                      </ManageAccess>
                      <ManageAccess value={clientSetting?.appAdditionalPermissions?.isBulkClipUpload}>
                        <Tooltip title="Upload to Cloud">
                          <button disabled={!folderArray.length} onClick={(e) => openS3Publish()}>
                            <CloudUploadOutlined />
                          </button>
                        </Tooltip>
                      </ManageAccess>
                      <ManageAccess value={clientSetting?.appAdditionalPermissions?.isBulkClipDownload}>
                        <Tooltip title="Download">
                          <button disabled={!folderArray.length} onClick={openBulkDownload}>
                            <Download />
                          </button>
                        </Tooltip>
                      </ManageAccess>
                      <ManageAccess value={clientSetting?.appAdditionalPermissions?.isBulkClipDelete}>
                        <Tooltip title="Delete">
                          <button disabled={!folderArray.length} onClick={(e) => openDeleteClipsModal()}>
                            <DeleteBorder />
                          </button>
                        </Tooltip>
                      </ManageAccess>
                    </div>

                    <div className="button-action-clips">
                      <button className="btn btn-transparent" onClick={() => removeSelect()}>
                        Clear All
                      </button>
                      <button className="btn btn-transparent" onClick={() => selectAllClips()}>
                        Select All
                      </button>
                      {/* <button className="btn btn-transparent">Email distribution list</button> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <AccessControl code="upload_clips" page="clips">
                  <ManageAccess value={clientSetting?.appAdditionalPermissions?.isUploadClips}>
                    <div className="toolbar-styled-left">
                      <div className="me-3">
                        <button className="btn btn-primary" onClick={(e) => openUploadClipModal()}>
                          <UploadOutlined className="btn-icon-left" /> Upload clips
                        </button>
                      </div>
                    </div>
                  </ManageAccess>
                </AccessControl>
                <AccessControl code="published_clips" page="clips">
                  <ManageAccess value={clientSetting?.appAdditionalPermissions?.isViewPublishedClips}>
                    <div className="toolbar-styled-left">
                      <div className="me-3">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (isRuleBasedClips) {
                              if (searchParams.get("navigating-from")) {
                                navigate(`/clip-publish/${id}?folderId=${pathName}&${searchParams}`);
                              } else {
                                navigate(
                                  `/clip-publish/${id}?folderId=${pathName}&${searchParams}?&rule-name=${ruleTitle}`,
                                );
                              }
                            } else {
                              navigate(`/clip-publish/${id}`);
                            }
                          }}
                        >
                          <UploadOutlined className="btn-icon-left" /> Published
                        </button>
                      </div>
                    </div>
                  </ManageAccess>
                </AccessControl>
                <div className="me-3">
                  <button className="btn btn-primary" onClick={() => refreshClips()}>
                    <ReloadOutlined className="btn-icon-left" /> Refresh
                  </button>
                </div>
                <ManageAccess value={clientSetting?.clientType === ZEE_CLIENT}>
                  <div className="me-3 d-inline-flex gap-4 align-items-center">
                    <ManageAccess
                      value={[
                        STREAM_STATUS.COMPLETED,
                        STREAM_STATUS.PENDING,
                        STREAM_STATUS.FAILED,
                        STREAM_STATUS.CANCELLED,
                        STREAM_STATUS.DELAYED,
                      ].includes(streamDetails?.status)}
                    >
                      <button className="btn btn-primary" onClick={() => initiateProcress()}>
                        <ReloadOutlined className="btn-icon-left" /> Start Process
                      </button>
                    </ManageAccess>
                    <div className="me-3">
                      <h5 className="mb-0">
                        {timerSeconds && streamDetails?.status !== STREAM_STATUS.COMPLETED ? (
                          <>
                            {timerSeconds < 0 ? "-" : ""}
                            {secondsToString(timerSeconds)}
                          </>
                        ) : (
                          <ManageAccess value={streamDetails?.status === STREAM_STATUS.COMPLETED}>
                            <>
                              <span className="process-completed-status-btn">Completed</span>
                            </>
                          </ManageAccess>
                        )}
                      </h5>
                    </div>
                  </div>
                </ManageAccess>
              </div>
            </div>
            <div className="toolbar-styled-left">
              {(isRuleBasedClips || searchParams.get("navigating-from") === "rule-overview") && (
                <div className="me-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setViewModal(true);
                    }}
                  >
                    <EyeOutlined className="btn-icon-left" />
                    <span>View rule details</span>
                  </button>
                </div>
              )}
              {!isRuleBasedClips && !(searchParams.get("navigating-from") === "rule-overview") && (
                <ul className="filter-tags-list">
                  {isShow &&
                    filters?.events?.map((data) => {
                      return (
                        <li key={data} className="text-capitalize">
                          {data}
                          <button
                            className="btn btn-filter-remove"
                            disabled={isRuleBasedClips}
                            onClick={() => {
                              updateRemoveFilter("events", data);
                            }}
                          >
                            <Close />
                          </button>
                        </li>
                      );
                    })}
                  {isShow &&
                    filters?.players?.map((data) => {
                      return (
                        <li key={data} className="text-capitalize">
                          {data}
                          <button
                            className="btn btn-filter-remove"
                            disabled={isRuleBasedClips}
                            onClick={() => {
                              updateRemoveFilter("players", data);
                            }}
                          >
                            <Close />
                          </button>
                        </li>
                      );
                    })}
                  {filters && filters?.aspectRatio && (
                    <li>
                      {filters.aspectRatio}
                      <button
                        className="btn btn-filter-remove"
                        onClick={() => {
                          updateRemoveFilter("removeAspectRatio", "");
                        }}
                      >
                        <Close />
                      </button>
                    </li>
                  )}
                  {filters?.clipRating?.length !== 0 &&
                    filters?.clipRating?.map((data) => {
                      return (
                        <li key={data} className="text-capitalize">
                          {data} <StarFilled style={{ marginLeft: "5px", color: "#fad905" }} />
                          <button
                            className="btn btn-filter-remove"
                            disabled={isRuleBasedClips}
                            onClick={() => {
                              updateRemoveFilter("removeRating", data);
                            }}
                          >
                            <Close />
                          </button>
                        </li>
                      );
                    })}
                  {filters && filters.webhookPublish && (
                    <li>
                      {filters.webhookPublish?.charAt(0).toUpperCase() + filters.webhookPublish?.slice(1)}
                      <button
                        className="btn btn-filter-remove"
                        onClick={() => {
                          updateRemoveFilter("removeWebhookPublish", "");
                        }}
                      >
                        <Close />
                      </button>
                    </li>
                  )}
                  {isManualUpload && isShow && (
                    <li>
                      {isManualUpload && "User Uploaded Clips"}
                      <button
                        className="btn btn-filter-remove"
                        onClick={() => {
                          removeManualClip();
                        }}
                      >
                        <Close />
                      </button>
                    </li>
                  )}
                  {isShow &&
                    filters?.playBackSpeed?.map((data) => {
                      return (
                        <li className="text-capitalize">
                          {data}
                          <button
                            className="btn btn-filter-remove"
                            onClick={() => updateRemoveFilter("removePlayBackSpeed", data)}
                          >
                            <Close />
                          </button>
                        </li>
                      );
                    })}
                  {filters && ["TIME_ASCENDANT", "RECENT"].includes(filters?.sortBy) && (
                    <li>
                      {filters?.sortBy == "TIME_ASCENDANT" ? " Time sequence (Ascendant)" : "Recent"}
                      <button
                        className="btn btn-filter-remove"
                        onClick={() => {
                          updateRemoveFilter("removeSortBy", "");
                        }}
                      >
                        <Close />
                      </button>
                    </li>
                  )}
                  {filters?.clipData?.shotType?.map((data) => {
                    return (
                      <li key={data} className="text-capitalize">
                        {data}
                        <button
                          className="btn btn-filter-remove"
                          onClick={() => {
                            updateRemoveClipData("shotType", data);
                          }}
                        >
                          <Close />
                        </button>
                      </li>
                    );
                  })}
                  {filters?.clipData?.gameTime?.map((data) => {
                    return (
                      <li key={data} className="text-capitalize">
                        {data}
                        <button
                          className="btn btn-filter-remove"
                          onClick={() => {
                            updateRemoveClipData("gameTime", data);
                          }}
                        >
                          <Close />
                        </button>
                      </li>
                    );
                  })}
                  {filters?.clipData?.heartbeat?.map((data) => {
                    return (
                      <li key={data} className="text-capitalize">
                        {data}
                        <button
                          className="btn btn-filter-remove"
                          onClick={() => {
                            updateRemoveClipData("heartbeat", data);
                          }}
                        >
                          <Close />
                        </button>
                      </li>
                    );
                  })}
                  {filters && filters?.isIllustoClip && (
                    <li>
                      Advanced Editor
                      <button
                        className="btn btn-filter-remove"
                        onClick={() => {
                          updateRemoveFilter("removeAdvancedEditor", "");
                        }}
                      >
                        <Close />
                      </button>
                    </li>
                  )}
                </ul>
              )}
              <div className="view-results me-3">
                <label className="form-label">Results</label>
                <select onChange={handleChange} value={limit} className="custom-select form-control">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              {/* Commenting List View */}
              {/* <button onClick={setShowState} className="btn btn-change-view">
                {showGrid ? <ListIcon /> : <GridIcon />}
              </button> */}
            </div>
          </div>
          <div className="header-sub-left">
            <div className="d-flex justify-content-end">
              <DownloadStats />
            </div>
            <div className="header-sub-title">
              {!isRuleBasedClips && (
                <div className="publish-tab-conatiner">
                  <AccessControl code="normal_clips" page="clips">
                    <button className={step == 1 ? "active" : ""} onClick={() => handleSteps(1)}>
                      Normal Clips
                    </button>
                  </AccessControl>
                  <AccessControl code="highlight_tab" page="clips">
                    <button className={step == 2 ? "active" : ""} onClick={() => handleSteps(2)}>
                      Highlights
                    </button>
                  </AccessControl>

                  <AccessControl code="promo_tab" page="clips">
                    <button className={step == 3 ? "active" : ""} onClick={() => handleSteps(3)}>
                      Promos
                    </button>
                  </AccessControl>
                  <AccessControl code="download_video" page="clips">
                    <ManageAccess value={clientSetting?.appAdditionalPermissions?.isHlsToMp4 && hlsToMp4Data?.length}>
                      <button className={step == 4 ? "active" : ""} onClick={() => handleSteps(4)}>
                        Match Video
                      </button>
                    </ManageAccess>
                  </AccessControl>
                </div>
              )}
            </div>
          </div>
          {loder || (step === 4 && hlsToMp4Loader) ? (
            <Spin indicator={antIcon} className="loader__full" />
          ) : step !== 4 && !clipsDetails?.length ? (
            <AddClipsEmptyState step={step} />
          ) : step == 4 && !hlsToMp4Data?.length ? (
            <AddClipsEmptyState step={step} />
          ) : showGrid ? (
            <Gridview
              isRuleBasedClips={isRuleBasedClips}
              filters={filters}
              bytesToMB={bytesToMB}
              updatesftp={updatesftp}
              clipListFolder={clipListFolder}
              clipsDetails={clipsDetails}
              deleteEditedClip={deleteEditedClip}
              allClips={allClips}
              allClipsForCms={allClipsForCms}
              id={id}
              step={step}
              hoveredVideo={hoveredVideo}
              setHoveredVideo={setHoveredVideo}
              streamDetails={streamDetails}
              storage={storage}
              ftpList={ftpList}
              ftpPublish={ftpPublish}
              copyClip={copyClip}
              deleteClips={deleteClips}
              editClip={editClip}
              publishClip={publishClip}
              aspectRatio={aspectRatio}
              webhookPublish={webhookPublish}
              playBackSpeed={playBackSpeed}
              getTrimClip={getTrimClip}
              s3BucketPublish={s3BucketPublish}
              jsonBucketPublish={jsonBucketPublish}
              downloadVideo={downloadVideo}
              setFolderClips={setFolderClips}
              folderClips={folderClips}
              folderArray={folderArray}
              setFolderArray={setFolderArray}
              showClass={showClass}
              setShowClass={setShowClass}
              checkhighLight={checkhighLight}
              clipExportJson={clipExportJson}
              clipExportXml={clipExportXml}
              downloadClips={downloadClips}
              user={user}
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              clipUrl={clipPreviewData.url}
              clipPreviewData={clipPreviewData}
              openModal={openModal}
              setClipTitle={setClipTitle}
              clipTitle={clipTitle}
              openPreviewModal={openPreviewModal}
              clientWebHookPublish={clientWebHookPublish}
              dumpOnS3={dumpOnS3}
              bucketCopy={bucketCopy}
              fetchUserDetails={fetchUserDetails}
              copyClipToReviewer={copyClipToReviewer}
              getPublicUrls={getPublicUrls}
              uploadClipInBrightCove={uploadClipInBrightCove}
              fetchBrightCoveToken={fetchBrightCoveToken}
              getCustomFields={getCustomFields}
              clientSetting={clientSetting}
              addThumbnail={addThumbnail}
              getUploadPicture={getUploadPicture}
              updateClipDetails={updateClipDetail}
              GenerateVideoTitle={GenerateVideoTitle}
              singleClipDetail={singleClipDetail}
              AccessControl={AccessControl}
              generateAutoFlip={generateAutoFlip}
              getAspectRatios={getAspectRatios}
              setToggle={setToggle}
              toggle={toggle}
              genrateNewClip={genrateNewClip}
              overWriteClip={overWriteClip}
              clipTaggingFields={clipTaggingFields}
              s3Open={s3Open}
              setS3Open={setS3Open}
              setFTPOpen={setFTPOpen}
              ftpOpen={ftpOpen}
              bulkS3BucketPublish={bulkS3BucketPublish}
              clipIdsArray={clipIdsArray}
              setClipIdsArray={setClipIdsArray}
              shareOnSocialMedias={shareOnSocialMedias}
              getVideoMetaData={getVideoMetaData}
              getS3PresignedURl={props.getS3PresignedURl}
              clipDetail={clipDetail}
              addPublishNotification={addPublishNotification}
              hlsToMp4Data={hlsToMp4Data}
              ruleTitle={ruleTitle}
              getHLSToMP4={getHLSToMP4}
              refreshClips={refreshClips}
              handleSteps={handleSteps}
              setLiveVideoUrl={setLiveVideoUrl}
              getStreamDetail={getStreamDetail}
              {...props}
            />
          ) : (
            <Listview
              filters={filters}
              bytesToMB={bytesToMB}
              updatesftp={updatesftp}
              clipListFolder={clipListFolder}
              allClipsForCms={allClipsForCms}
              clipsDetails={clipsDetails}
              allClips={allClips}
              id={id}
              step={step}
              deleteEditedClip={deleteEditedClip}
              streamDetails={streamDetails}
              storage={storage}
              ftpList={ftpList}
              ftpPublish={ftpPublish}
              deleteClips={deleteClips}
              copyClip={copyClip}
              editClip={editClip}
              publishClip={publishClip}
              aspectRatio={aspectRatio}
              webhookPublish={webhookPublish}
              playBackSpeed={playBackSpeed}
              getTrimClip={getTrimClip}
              s3BucketPublish={s3BucketPublish}
              jsonBucketPublish={jsonBucketPublish}
              dowoadVideo={downloadVideo}
              setFolderClips={setFolderClips}
              folderClips={folderClips}
              folderArray={folderArray}
              setFolderArray={setFolderArray}
              showClass={showClass}
              setShowClass={setShowClass}
              checkhighLight={checkhighLight}
              clipExportJson={clipExportJson}
              clipExportXml={clipExportXml}
              downloadClips={downloadClips}
              user={user}
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              clipUrl={clipPreviewData.url}
              clipPreviewData={clipPreviewData}
              openModal={openModal}
              setClipTitle={setClipTitle}
              clipTitle={clipTitle}
              openPreviewModal={openPreviewModal}
              clientWebHookPublish={clientWebHookPublish}
              dumpOnS3={dumpOnS3}
              bucketCopy={bucketCopy}
              getPublicUrls={getPublicUrls}
              fetchUserDetails={fetchUserDetails}
              copyClipToReviewer={copyClipToReviewer}
              uploadClipInBrightCove={uploadClipInBrightCove}
              fetchBrightCoveToken={fetchBrightCoveToken}
              getCustomFields={getCustomFields}
              clientSetting={clientSetting}
              addThumbnail={addThumbnail}
              updateClipDetails={updateClipDetail}
              getUploadPicture={getUploadPicture}
              GenerateVideoTitle={GenerateVideoTitle}
              singleClipDetail={singleClipDetail}
              generateAutoFlip={generateAutoFlip}
              getAspectRatios={getAspectRatios}
              setToggle={setToggle}
              toggle={toggle}
              genrateNewClip={genrateNewClip}
              clipTaggingFields={clipTaggingFields}
              s3Open={s3Open}
              setS3Open={setS3Open}
              setFTPOpen={setFTPOpen}
              ftpOpen={ftpOpen}
              bulkS3BucketPublish={bulkS3BucketPublish}
              clipIdsArray={clipIdsArray}
              shareOnSocialMedias={shareOnSocialMedias}
              clipDetail={clipDetail}
              getS3PresignedURl={props.getS3PresignedURl}
              {...props}
            />
          )}
          <Pagination
            showSizeChanger={false}
            current={activePage}
            onChange={handlePageChange}
            pageSize={limit}
            total={totalCount || 0}
            className="text-center mt-3"
          />
        </div>
      </main>
    </>
  );
};
export default ClipsComponent;
