import React from "react";
import Modal from "react-modal";
import { CloseModal } from "../../icons";
const AlertDialog = ({ alertModel, content, oncloseAlertModel }) => {
  return (
    <>
      <Modal
        isOpen={alertModel}
        onRequestClose={oncloseAlertModel}
        shouldCloseOnOverlayClick={false}
        contentLabel="Alert Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">{"Alert"}</h5>
            <button
              type="button"
              onClick={() => {
                oncloseAlertModel();
              }}
              className="btn btn__default"
            >
              <CloseModal />
            </button>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-body-text">{content}</p>
          </div>
          <div className="react-modal-footer justify-content-center">
            <div className="react-foo-container">
              <button type="button" className="btn btn-primary" onClick={oncloseAlertModel}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AlertDialog;
