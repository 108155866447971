import React, { useEffect, useState, useContext } from "react";
import { notification } from "antd";
import { SocketContext } from "../../../context/socket";
import { InfoIcon } from "../../icons";
import { useOutletContext } from "react-router-dom";

const DownloadStats = (props) => {
  const { webhookSocket } = useContext(SocketContext);
  const { downloadStats, setDownloadStats, clientSetting, user } = useOutletContext();
  const [socketUpdate, setSocketUpdate] = useState({});

  useEffect(() => {
    const id = localStorage.getItem("__uid");
    webhookSocket.on(`downloadStats${id}`, (data) => {
      //call socket use effect here
      setSocketUpdate({
        userId: data?.userId,
        uniqueId: data?.uniqueId,
        payload: data.payload,
        status: data?.status || false,
      });
    });
  }, []);

  useEffect(() => {
    if (socketUpdate.status) {
      setDownloadStats(socketUpdate.payload);
    }
  }, [socketUpdate]);

  return (
    <>
      {clientSetting?.appAdditionalPermissions?.restrictUserProcessingLimit && user?.role === "user" ? (
        <div className="download-satus-container p-2">
          <div className="mx-3 my-auto">
            <InfoIcon />
          </div>
          <div className="mx-3 my-auto">
            Downloaded Clips:{" "}
            {downloadStats?.DownloadClip && downloadStats?.DownloadClip !== 0 ? downloadStats?.DownloadClip : 0}{" "}
          </div>
          <hr className="mx-3 my-auto" />
          <div className="mx-3 my-auto">
            Downloaded Highlights:{" "}
            {downloadStats?.DownloadHighlights && downloadStats?.DownloadHighlights !== 0
              ? downloadStats?.DownloadHighlights
              : 0}{" "}
          </div>
          <hr className="mx-3 my-auto" />
          <div className="mx-3 my-auto">
            Total download duration:{" "}
            {downloadStats?.UsedDownloadDurations && downloadStats?.UsedDownloadDurations !== 0
              ? `${Math.trunc(downloadStats?.UsedDownloadDurations)}s`
              : 0}
            {downloadStats?.TotalDownloadDurations && downloadStats?.TotalDownloadDurations !== 0
              ? ` / ${Math.trunc(downloadStats?.TotalDownloadDurations)}s`
              : ""}{" "}
            used{" "}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DownloadStats;
