import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" fill="none" viewBox="0 0 23 17" {...props}>
    <path
      fill="currentColor"
      d="M21.654 1.91a.909.909 0 00-1.476-.71l-8.237 6.59a.909.909 0 000 1.42l8.237 6.59a.909.909 0 001.476-.71V1.91zM11.054 1.91a.909.909 0 00-1.476-.71L1.341 7.79a.909.909 0 000 1.42l8.237 6.59a.909.909 0 001.476-.71V1.91z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.654 1.91a.909.909 0 00-1.476-.71l-8.237 6.59a.909.909 0 000 1.42l8.237 6.59a.909.909 0 001.476-.71V1.91zM11.054 1.91a.909.909 0 00-1.476-.71L1.341 7.79a.909.909 0 000 1.42l8.237 6.59a.909.909 0 001.476-.71V1.91z"
    ></path>
  </svg>
);

export default SvgComponent;
