import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" fill="none" viewBox="0 0 23 19">
      <path
        fill="currentColor"
        d="M0 12.5c0-1.104.293-2.19.854-3.156a6.63 6.63 0 012.349-2.362c.259-1.93 1.244-3.703 2.77-4.988A8.587 8.587 0 0111.5 0c2.034 0 3.998.709 5.525 1.994 1.527 1.285 2.512 3.059 2.77 4.988a6.582 6.582 0 012.703 3.065 6.235 6.235 0 01.308 3.998 6.463 6.463 0 01-2.203 3.411 6.962 6.962 0 01-3.877 1.525L6.273 19C2.764 18.726 0 15.922 0 12.5zm16.567 4.487a4.82 4.82 0 002.684-1.056 4.474 4.474 0 001.525-2.362c.235-.921.16-1.89-.215-2.769a4.558 4.558 0 00-1.872-2.12l-.844-.504-.125-.942a5.926 5.926 0 00-2.08-3.737 6.44 6.44 0 00-4.142-1.494 6.44 6.44 0 00-4.141 1.494 5.926 5.926 0 00-2.08 3.737l-.126.942-.841.503A4.557 4.557 0 002.438 10.8a4.317 4.317 0 00-.215 2.768 4.475 4.475 0 001.524 2.363 4.82 4.82 0 002.684 1.056l.18.013h9.775l.18-.013zm-4.023-5.986v4h-2.09v-4H7.316L11.5 6l4.181 5h-3.136z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
