import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
      <circle cx="10.5" cy="10.5" r="10.5" fill="#01BEFF"></circle>
      <path
        fill="#fff"
        d="M12.25 7.146a1.896 1.896 0 001.896-1.896h.875c0 1.047.849 1.896 1.896 1.896v.875a1.896 1.896 0 00-1.896 1.896h-.875A1.896 1.896 0 0012.25 8.02v-.875zm-6.416 1.02C5.834 7.523 6.356 7 7 7h4.084V5.833H7a2.333 2.333 0 00-2.333 2.334V14A2.333 2.333 0 007 16.333h7A2.333 2.333 0 0016.334 14v-2.917h-1.167V14c0 .644-.522 1.167-1.167 1.167H7A1.167 1.167 0 015.834 14V8.167z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
