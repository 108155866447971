import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" fill="none" viewBox="0 0 11 15" {...props}>
    <path
      fill="currentColor"
      d="M3.75 1.875a1.875 1.875 0 00-3.75 0v11.25a1.875 1.875 0 003.75 0V1.875zM10.313 1.875a1.875 1.875 0 10-3.75 0v11.25a1.875 1.875 0 003.75 0V1.875z"
    ></path>
  </svg>
);

export default SvgComponent;
