import React from "react";

const BlankState = ({ props, inlineStyle, icon, title }) => {
  return (
    <>
      <div className="blank-state" style={inlineStyle}>
        <div className="blank-state-wrapper">
          <div className="blank-state-icon">{icon}</div>
          <div className="blank-state-title">{title}</div>
        </div>
      </div>
    </>
  );
};
export default BlankState;
