import React, { useState } from "react";
import Modal from "react-modal";
import _ from "lodash";
import { CloseModal } from "../../../components/icons";
import { jwPlayerCDNUrl } from "../../../utils/fetch";
import ReactJWPlayer from "react-jw-player";
import { Tooltip, notification } from "antd";
import { ASPECT_RATIO } from "../../../constants/phase";

const AddThumbnailModal = ({
  filters,
  tModal,
  clipUrl,
  setTModal,
  clipTitle,
  addThumbnail,
  clip,
  setThumbModalOpen,
}) => {
  const closeModal = () => {
    setTModal(false);
  };
  const [liveTime, setLiveTime] = useState(0);
  const generateThumbnail = async () => {
    const data = await addThumbnail({
      time: liveTime,
      videoUrl: clipUrl,
      _id: clip?._id,
      streamId: clip?.streamId,
      aspectRatio: filters?.aspectRatio || ASPECT_RATIO["16_9"],
    });
    if (data?.value?.success) {
      notification["success"]({ message: data?.value?.message });
      closeModal();
      setThumbModalOpen(true);
    } else {
      notification["error"]({ message: "something went wrong" });
      closeModal();
      setThumbModalOpen(true);
    }
  };

  const jwPlayerUrl = _.get(
    process.env,
    "REACT_APP_JW_PLAYER_CDN",
    "https://test-fancode.s3.amazonaws.com/zCI2rGRk.js",
  );

  return (
    <>
      <Modal
        isOpen={tModal}
        vide
        ariaHideApp={false}
        onRequestClose={closeModal}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-xl modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title word-break-two title_Pascal">
              <Tooltip title={clipTitle}>{clipTitle ? clipTitle : null}</Tooltip>
            </h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="video-preview">
              <ReactJWPlayer
                playerId="JwPlayer"
                playerScript={jwPlayerCDNUrl()}
                file={clipUrl}
                onTime={(e) => {
                  setLiveTime(e.currentTime);
                }}
                id="jwplayer"
              />
            </div>
          </div>
          <div className="react-modal-footer">
            <button type="button" className="btn btn-white" onClick={closeModal}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={generateThumbnail}>
              Generate
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddThumbnailModal;
