import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip, notification } from "antd";
import $ from "jquery";
import React, { useState } from "react";
import Modal from "react-modal";
import { useOutletContext } from "react-router-dom";
import AccessControl from "../../../components/common/AccessControl/accessControl";
import Image from "../../../components/common/Image/component";
import { CloseModal, Download, InfoIcon } from "../../../components/icons";
const BulkDownload = (props) => {
  const { setBulkDownloadZip } = useOutletContext();
  const antIcon = <LoadingOutlined style={{ fontSize: 24, align: "center" }} spin />;
  const {
    bulkDownloadModalOpen,
    setBulkDownloadModalOpen,
    folderArray,
    setFolderArray,
    downloadClips,
    downloadAllClips,
    loder,
    setLoder,
    downloadAsZipFolder,
    aspectRatio,
    streamId,
    getS3PresignedURl,
    setIsAlertOpen,
    setAlertMessage,
    filters,
  } = props;
  const [disable, setDisable] = useState(false);
  const downloadClose = () => {
    setBulkDownloadModalOpen(false);
    setFolderArray([]);
  };

  const downloadFile = async (clip) => {
    const videoUrl =
      clip?.editedVideos.find((v) => v?.aspect_ratio === aspectRatio && v?.event !== "dynamicCropped")?.video_url ||
      clip?.videoUrl;
    const { value } = await getS3PresignedURl({
      url: videoUrl,
      streamId: streamId,
      duration: clip.duration,
      title: clip.episodeTitle,
      clipId: clip._id,
    });
    if (value.status && value.data?.url) {
      const link = document.createElement("a");
      link.href = value.data?.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      if (value.limitError) {
        setIsAlertOpen(true);
        setAlertMessage(value.message);
        setBulkDownloadModalOpen(false);
      } else {
        notification["error"]({ message: "Something went wrong" });
      }
    }
  };

  const checkFileSize = async () => {
    const bulkUrls = folderArray?.map(
      (o) => o?.editedVideos.find((v) => v?.aspect_ratio === aspectRatio && v?.event !== "dynamicCropped")?.video_url,
    );
    setDisable(true);
    const data = await downloadAllClips({
      fileUrls: aspectRatio
        ? folderArray?.map(
            (o) =>
              o?.editedVideos.find((v) => v?.aspect_ratio === aspectRatio && v?.event !== "dynamicCropped")?.video_url,
          )
        : folderArray.map((d) => d.videoUrl),
    });

    if (data?.value?.size > 524288000) {
      notification["error"]({ message: "Download file size shouldn't exceed 500 mb" });
    } else {
      var el = document.querySelector(".download-done");
      $(el).addClass("download-close");
      $(el).removeClass("download-done");
      setBulkDownloadZip({
        downloadStatus: true,
        folderArray: aspectRatio ? bulkUrls : folderArray.map((d) => d.videoUrl),
      });
      downloadAsZipFolder();
      setBulkDownloadModalOpen(false);
    }
    setDisable(false);
  };

  return (
    <>
      {loder ? (
        <Spin indicator={antIcon} className="loader__full" />
      ) : (
        <div>
          <Modal
            isOpen={bulkDownloadModalOpen}
            ariaHideApp={false}
            onRequestClose={downloadClose}
            shouldCloseOnOverlayClick={false}
            contentLabel="Filter Modal"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-xl modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">
                  Download {""}
                  <Tooltip placement="topLeft" title="Maximum download limit - 500MB">
                    <InfoIcon className="info-icon-main" />
                  </Tooltip>
                </h5>
                <button type="button" onClick={downloadClose} className="btn btn__default">
                  <CloseModal />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="right-btn-block">
                  <div className="button-container text-end mb-3">
                    <button className="btn btn-white me-3" disabled={disable} onClick={checkFileSize}>
                      Download all as Zip{" "}
                    </button>
                  </div>
                </div>

                <div className="inner-container content-control-block styled-scroll">
                  <div className="vv-grid clip-grid-view" grid-size="5">
                    {folderArray &&
                      folderArray?.map((data, index) => {
                        let thumbnail = data.videoThumbnailUrl;
                        if (filters?.aspectRatio) {
                          thumbnail =
                            data?.editedVideos?.find(
                              ({ aspect_ratio, event }) =>
                                aspect_ratio === filters?.aspectRatio &&
                                (event === "autoFlip" || event === "croppedVideos"),
                            )?.thumbnailUrl || data.videoThumbnailUrl;
                        }
                        return (
                          <>
                            <div className="vv-grid-item" key={index}>
                              <div className="vv-video-card">
                                <div className="vv-video-thumb">
                                  <div className="ratio-image">
                                    <Image src={thumbnail} alt="Magnifi" name="thumburl" />
                                  </div>
                                </div>
                                <AccessControl code="downloadClip" page="clips">
                                  <div className="button-container-bottom mt-2">
                                    <button
                                      className="btn btn-white me-3 w-100"
                                      onClick={() => {
                                        downloadFile(data);
                                      }}
                                    >
                                      Download{" "}
                                      <span style={{ marginLeft: "10px" }}>
                                        <Download />
                                      </span>
                                    </button>
                                  </div>
                                </AccessControl>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      ;
    </>
  );
};

export default BulkDownload;
