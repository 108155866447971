import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { notification, Spin, Tooltip } from "antd";
import { InfoIcon } from "../../../components/icons";
import { ftpChannelLanguage } from "../../../config/index";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { uploadToFTPValidations } from "../../../utils/validations";
import CloseModal from "../../../components/icons/CloseModal";

const UploadToFTP = (props) => {
  const { setFTPOpen, ftpOpen, step, id, loder, clip, ftpList, ftpPublish, filters } = props;
  const [initialState, setInitialState] = useState({
    fileName: "",
    ftpName: "",
    folderPath: "",
  });
  const [language, setLanguage] = useState("");
  const [channelList, setChannelList] = useState(ftpList);

  const handleSubmit = async (values) => {
    // find clip's video url based on the selected aspect ratio
    let videoUrl =
      clip?.editedVideos?.find(
        ({ aspect_ratio, event }) =>
          aspect_ratio == (filters?.aspectRatio || clip.aspectRatio) &&
          (event === "autoFlip" || event === "croppedVideos"),
      )?.video_url || clip.videoUrl;

    const value = await ftpPublish({
      ftp_id: values?.ftpName,
      fileUrl: videoUrl,
      folderPath: values?.folderPath,
      id: clip?._id,
      type: step === 1 ? "clip" : step === 2 ? "highlight" : "promo",
      streamId: id,
      fileName: values?.fileName,
    });
    if (value?.value?.status) {
      notification["success"]({ message: value?.value?.message });
    } else {
      notification["error"]({ message: value?.value?.message });
    }
    closeModal();
  };

  const closeModal = () => {
    setFTPOpen(false);
    setInitialState({
      fileName: "",
      ftpName: "",
      folderPath: "",
    });
  };

  useEffect(() => {
    const filterlist = ftpList?.filter((l) => l?.ln === language);
    setChannelList(filterlist);
    setInitialState((prevState) => ({ ...prevState, ftpName: filterlist?.length > 0 ? filterlist[0]._id : "" }));
  }, [language]);

  useEffect(() => {
    setChannelList(ftpList);
  }, [ftpList]);

  return (
    <>
      <Modal
        isOpen={ftpOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Cloud Publish"
        portalClassName="react-modal"
        overlayClassName="modal"
        ariaHideApp={false}
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">FTP Publish</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik
            initialValues={initialState}
            validationSchema={uploadToFTPValidations}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container row">
                    <div className="form-group">
                      <label className="form-label">Language</label>
                      <select
                        className="custom-select form-control"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        <option value="default">Select Language</option>
                        {ftpChannelLanguage &&
                          ftpChannelLanguage.map((data) => {
                            return (
                              <option key={data.key} value={data.value}>
                                {data.value}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        FTP <span className="required">*</span>
                      </label>
                      <select
                        className="custom-select form-control"
                        name="ftpName"
                        onChange={(e) => setFieldValue("ftpName", e.target.value)}
                      >
                        <option value="default">Select FTP</option>
                        {channelList &&
                          channelList.map((data) => {
                            return (
                              <option key={data._id} value={data._id}>
                                {data.name}
                              </option>
                            );
                          })}
                      </select>
                      {touched.ftpName && errors.ftpName && <div className="invalid-feedback">{errors.ftpName}</div>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Folder Path <span className="required">*</span>
                    </label>
                    <Field type="text" className="form-control" name="folderPath" placeholder="/folderpath/" />
                    {touched.folderPath && errors.folderPath && (
                      <div className="invalid-feedback">{errors.folderPath}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      File Name <span className="required">*</span>
                    </label>
                    <Field type="text" className="form-control" name="fileName" />
                    {touched.fileName && errors.fileName && <div className="invalid-feedback">{errors.fileName}</div>}
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Submit
                    {loder && (
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 50,
                              color: "#000",
                              marginLeft: 50,
                            }}
                            spin
                          />
                        }
                        className="loader__full"
                      ></Spin>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default UploadToFTP;
