import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
      <path
        fill="#3CF"
        d="M23.62 47.24C10.594 47.24 0 36.646 0 23.62 0 10.594 10.594 0 23.62 0c13.026 0 23.62 10.594 23.62 23.62.009 13.026-10.594 23.62-23.62 23.62z"
      ></path>
      <path
        fill="#fff"
        d="M37.201 14.999c-.874.398-1.835.632-2.778.796.45-.078 1.108-.883 1.376-1.211a5.112 5.112 0 00.926-1.688c.026-.052.044-.113-.008-.147-.061-.026-.096-.009-.148.008a14.172 14.172 0 01-3.306 1.264.213.213 0 01-.225-.06 3.108 3.108 0 00-.285-.295 5.655 5.655 0 00-4.06-1.376 5.903 5.903 0 00-2.328.658 6.07 6.07 0 00-1.878 1.532 5.928 5.928 0 00-1.125 2.25c-.19.788-.173 1.575-.06 2.372.016.13.008.147-.113.13-4.536-.676-8.3-2.303-11.356-5.782-.13-.147-.208-.147-.312.008-1.333 2-.683 5.22.978 6.803.226.208.45.425.701.615-.086.017-1.203-.104-2.19-.615-.13-.086-.198-.034-.207.113-.009.216.009.415.035.649.26 2.034 1.661 3.912 3.592 4.64.225.094.484.181.727.224-.442.113-.9.182-2.156.078-.155-.026-.216.052-.155.2.943 2.579 2.986 3.34 4.518 3.782.207.034.406.034.614.086-.009.017-.026.017-.035.035-.51.77-2.267 1.35-3.08 1.635-1.49.52-3.108.753-4.683.598-.251-.035-.303-.035-.372 0-.07.043-.009.104.069.164.32.208.64.398.978.58.995.528 2.043.952 3.133 1.255 5.626 1.55 11.962.416 16.185-3.782 3.315-3.298 4.475-7.842 4.475-12.386 0-.181.208-.277.338-.372.865-.65 1.549-1.428 2.19-2.294.146-.19.138-.363.138-.441v-.026c-.009-.07 0-.052-.113 0z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.879 34.889c0-.043.485 0 0 0 0-.026.052 0 0 0zM28.616 12.316c-.026.026-.069.026-.112 0h.112z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SvgComponent;
