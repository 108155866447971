import { connect } from "react-redux";

import ClipsFilter from "./component";
import { getAllClips, sortHandVideo, getSingleStream, getCsvData } from "../../../store/videogrid/duck";

const ClipsFilterContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  { getAllClips, sortHandVideo, getSingleStream, getCsvData },
)(ClipsFilter);

export default ClipsFilterContainer;
