import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import { loginUser, updateToken } from "../store/user/duck";
import axios from "axios";
const NewHost = process.env.REACT_APP_NEW_API_HOSTNAME;

const loginUserById = (id) => {
  //fetch token and user id based on ObjectId
  return new Promise((resolve, reject) => {
    axios
      .post(`${NewHost}/login_by_qr/${id}`)
      .then((res) => {
        if (res?.data?.status) {
          resolve({
            success: true,
            data: res?.data?.data,
          });
        } else {
          resolve({
            success: false,
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
        });
      });
  });
};

const updateToken = (data) => {
  //update user token and user id for the user for limit exceed
  return new Promise((resolve, reject) => {
    axios
      .post(`${NewHost}/update_token`, data)
      .then((res) => {
        if (res?.data?.status) {
          resolve({
            success: true,
            data: res?.data?.data,
          });
        } else {
          resolve({
            success: false,
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
        });
      });
  });
};

const QRHandler = () => {
  const navigate = useNavigate();

  //fetch params from url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const action = urlParams.get("action");

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    if (!id || !action) {
      //redirect to login in case of null val
      navigate("/login");
    } else {
      const value = await loginUserById(id);
      if (value && value.success) {
        localStorage.setItem("authToken", value.data.token);
        localStorage.setItem("__uid", value.data.userId);
        updateToken({
          userId: localStorage.getItem("__uid"),
          ipToken: localStorage.getItem("authToken"),
        });
        //navigate to desired route
        if (action === "highlights") {
          navigate(`/highlights/${value?.data?.streamId}`);
        } else if (action === "create_video") {
          navigate(`/add-video`);
        } else {
          navigate(`/login`);
        }
      } else {
        navigate("/login");
      }
    }
  };
};

export default QRHandler;
