import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <rect width="23" height="23" x="1" y="1" stroke="currentColor" strokeWidth="2" rx="11.5"></rect>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" d="M7 11.333L11.4 16 18 9"></path>
    </svg>
  );
}

export default SvgComponent;
