import { Radio } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import IllustoAdvEditorLogo from "../../../images/illusto-advance-editor.svg";

import {
  AreaChartOutlined,
  CloudUploadOutlined,
  CopyOutlined,
  CopyrightOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  FormOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PictureOutlined,
  RollbackOutlined,
  SettingOutlined,
  ShareAltOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Spin, notification } from "antd";
import axios from "axios";
import FileDownload from "js-file-download";
import Modal from "react-modal";
import AccessControl from "../../../components/common/AccessControl/accessControl";
import ShowCSV from "../../../components/common/ClipsFilter/partials/ShowCsvModal";
import CommonJsonDetails from "../../../components/common/CommonJsonViewer/commonJsonDetails";
import CommonJsonContext from "../../../components/common/CommonJsonViewer/commonJsonContext";
import AlertDialog from "../../../components/common/Modals/AlertDialog";
import DeleteModal from "../../../components/common/Modals/component";
import { EVENT_NAME, ROLE, ZEE_CATEGORY_TYPES, ZEE_CLIENT } from "../../../constants/phase";
import "../styles.scss";
import ChangeThumbNail from "./ChangeThumbNail";
import CopyClip from "./CopyClip";
import CopyClipToChild from "./CopyClipToChild";
import EditClip from "./EditClip";
import GenerateTitle from "./GenerateTitle";
import ModalPublish from "./ModalPublish";
import PublishToCMS from "./PublishToCMS";
import ShowClipTitle from "./ShowClipTitle";
import TrimClip from "./TrimClip";
import UploadToFTP from "./UploadToFTP";
import UploadTOS3 from "./UploadToS3";
import UploadToZee from "./UploadToZee";
import VideoSpeed from "./VideoSpeed";
const TableAction = ({ ...props }) => {
  const {
    id,
    updatesftp,
    clip,
    step,
    deleteClips,
    copyClip,
    editClip,
    allClips,
    allClipsForCms,
    publishClip,
    streamDetails,
    getTrimClip,
    fetchStorage,
    s3BucketPublish,
    bulkS3BucketPublish,
    jsonBucketPublish,
    storage,
    folderArray,
    setFolderArray,
    clipExportJson,
    clipExportXml,
    user,
    dumpOnS3,
    ftpPublish,
    bucketCopy,
    clientWebHookPublish,
    fetchUserDetails,
    copyClipToReviewer,
    getPublicUrls,
    uploadClipInBrightCove,
    fetchBrightCoveToken,
    getCustomFields,
    fetchUsersByUserIds,
    allUsers,
    clientSetting,
    addThumbnail,
    getUploadPicture,
    updateClipDetails,
    GenerateVideoTitle,
    singleClipDetail,
    s3Open,
    ftpOpen,
    ftpList,
    setFTPOpen,
    setS3Open,
    shareOnSocialMedias,
    aspectRatio,
    getS3PresignedURl,
    createS3PresignedURl,
    filters,
    addSocialMediaData,
    addPublishNotification,
    refreshClips,
    handleSteps,
    advanceEditorIllusto,
    genrateNewClip,
    getStreamDetail,
    getCsvData,
  } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: 24, align: "center" }} spin />;

  const navigate = useNavigate();
  const { getDownloadStatus } = useOutletContext();
  const [delmodalOpen, setDeleteModalOpen] = useState(false);
  const [thumbModelOpen, setThumbModalOpen] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("__uid"));
  const [copyOpen, setCopyOpen] = useState(false);
  const [copyToChildModalOpen, setCopyToChildModalOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [zeeOpen, setZeeOpen] = useState(false);
  const [trimOpen, setTrimOpen] = useState(false);
  const [videoSpeedOpen, setVideoSpeedOpen] = useState(false);
  const [loder, setLoder] = useState(false);
  const [thumbClip, setThumbClip] = useState({});
  const [selectThumbnail, setSelectThumbnail] = useState("");
  const [publishLoader, setPublishLoader] = useState(false);
  const [autoPopulatedHashtags, setAutoPopulatedHashtags] = useState([]);
  const [modalIsOpenPublishOverlay, setIsOpenPublishOverlay] = useState(false);
  const [publishThumbnails, setPublishThumbnails] = useState([]);
  const [thirdPartyUrl, setThirdPartyUrl] = useState("");
  const [publishToCMS, setPublishTOCMS] = useState(false);
  const [genTitleModalOpen, setGenTitleModalOpen] = useState(false);
  const [titleModalOpen, setTitleModalOpen] = useState(false);
  const [publicStatus, setPublicStatus] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [publishClipId, setPublishedClipId] = useState("");
  const [thumbnailUrls, setThumbnialUrls] = useState([]);
  const [isShowCsv, setIsShowCsv] = useState(false);
  const [loader, setLoader] = useState(false);
  const [illustoData, setIllustoData] = useState([]);
  const [illustomodalOpen, setIllustoModalOpen] = useState(false);
  const [sftpOpen, setSftpOpen] = useState(false);
  const [sftpFolder, setSftpFolder] = useState("watch");
  const [openJsonDetails, setOpenJsonDetails] = useState(false);

  const illustopopup = () => {
    setIllustoModalOpen(false);
  };
  const exportJson = async (id, accessType, highlightType) => {
    let data = {
      id,
      accessType,
      highlightType,
    };
    const { value } = await clipExportJson(data);
    if (value.status) {
      notification["success"]({ message: "JSON Downloaded Successfully" });
      FileDownload(JSON.stringify(value.data), `${id}.json`);
    } else {
      notification["error"]({ message: "Data not found" });
    }
  };
  //DownLoad the Clip
  const downloadClip = async (clip) => {
    let url =
      clip.editedVideos &&
      clip.editedVideos.find((o) => o?.aspect_ratio === filters?.aspectRatio && o.event !== "dynamicCropped")
        ?.video_url;

    const { value } = await getS3PresignedURl({
      url: url || clip?.videoUrl,
      streamId: streamDetails.streamId,
      duration: clip.duration,
      clipId: clip._id,
      title: clip?.episodeTitle ? clip?.episodeTitle : undefined,
    });
    if (value.status && value.data?.url) {
      const link = document.createElement("a");
      link.href = value.data?.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      getDownloadStatus({ userId: localStorage.getItem("__uid"), streamId: streamDetails.streamId });
    } else {
      if (value.limitError) {
        setIsAlertOpen(true);
        setAlertMessage(value.message);
      } else {
        notification["error"]({ message: "Something went wrong" });
      }
    }
  };

  const downloadMxf = async (clip) => {
    const link = document.createElement("a");
    link.href = clip?.mxfUrl;
    document.body.appendChild(link);
    link.click();
  };

  const downloadFile = async (clip) => {
    const url = clip.csvData.edlFilePath;
    if (url) {
      const filename = url.split("/")[url.split("/").length - 1];
      const { data } = await axios.get(url);
      var element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  };

  const delOpen = (id) => {
    setUserId(id);
    setDeleteModalOpen(true);
  };

  const openCopyFun = () => {
    setCopyOpen(true);
  };
  const openS3Publish = () => {
    setS3Open(true);
  };
  const openFTPPublish = () => {
    setFTPOpen(true);
  };
  const openPublishCMS = () => {
    setPublishTOCMS(true);
  };

  const openUploadToBrightCove = async () => {
    setLoder(true);

    const clipUrl = clip.videoUrl;
    const clipId = streamDetails?.title;
    const payload = {
      client_id: user.client_id,
      client_secret: user.client_secret,
      account_id: user.account_id,
    };

    const data = await fetchBrightCoveToken(payload);
    if (data.value.data.error) {
      notification["error"]({ message: "Invalid Credentials" });
      return;
    }
    const accessToken = data.action.payload.data.access_token;
    await getPublicUrl(clipUrl, clipId, accessToken, user.account_id);
    setLoder(false);
  };

  // Fetch the public Url
  const getPublicUrl = async (url, clipId, accessToken, accountId) => {
    const data = await getPublicUrls({ url });

    if (data.value.status) {
      const publicUrl = data.value.data.location;
      const token = accessToken;
      const payload = {
        publicUrl,
        clipId,
        token,
        accountId,
      };
      const resp = await uploadClipInBrightCove(payload);

      if (resp.value.status) {
        setLoder(false);
        notification["success"]({ message: resp.value.message });
      } else {
        notification["error"]({ message: resp.value.message });
      }
    } else {
      notification.open({ message: "something went wrong" });
    }
  };

  //Copy the Clip using Stream ID
  const copyClipFun = async (payload) => {
    setLoder(true);
    const data = await copyClip(payload);
    if (data.value.success) {
      notification["success"]({ message: data.value.message });
      allClips();
    } else {
      notification["error"]({ message: data.value.message });
    }
    setLoder(false);
  };

  //Trim the Clip
  const trimClip = async (payload) => {
    setLoder(true);
    const data = await getTrimClip(payload);
    if (data.value.status) {
      notification["success"]({ message: data.value.message });
    } else {
      notification["error"]({ message: data.value.message });
    }
    setLoder(false);
  };

  //Edit the Clip
  const editClips = async (payload) => {
    setLoder(true);
    const data = await editClip(payload);
    if (data.value.success) {
      notification["success"]({ message: data.value.message });
      await allClips();
    } else {
      notification["error"]({ message: data.value.message });
    }
    setLoder(false);
  };

  //Edit the Clip for CMS
  const editClipsForCms = async (payload) => {
    setLoder(true);
    const data = await editClip(payload);
    if (data.value.success) {
      notification["success"]({ message: data.value.message });
      await allClipsForCms();
    } else {
      notification["error"]({ message: data.value.message });
    }
    setLoder(false);
  };

  //Publish the Clip
  const publishClips = async (payload) => {
    setLoder(true);
    const data = await publishClip(payload);
    if (data.value.status === true) {
      notification["success"]({ message: data.value.message });
      await allClips();
    } else if (data.value.status === false) {
      notification["error"]({ message: data.value.message });
    } else if (data.value.status === 101) {
      notification["warning"]({ message: data.value.message });
    }
    setLoder(false);
  };
  const delThumbOpen = (clip) => {
    setThumbClip(clip);
    setThumbModalOpen(true);
    if (filters?.aspectRatio) {
      setThumbnialUrls(
        clip?.editedVideos?.find(
          ({ aspect_ratio, event }) => aspect_ratio === filters?.aspectRatio && event !== EVENT_NAME.DYNAMIC,
        )?.thumbnails,
      );
    } else {
      setThumbnialUrls(clip?.videoThumbnailUrls);
    }
  };
  const addThumbNAil = async () => {
    let payload = {
      videoThumbnailUrl: selectThumbnail,
      id: thumbClip.id,
    };
    if (filters?.aspectRatio) {
      payload.aspectRatio = filters?.aspectRatio || clip?.aspectRatio;
    }
    const data = await editClip(payload);
    notification["success"]({ message: data.value.message });
    if (data.value.success) {
      await allClips();
    }
  };

  //Add New thumbnail on clip
  const handleSubmit = (e) => {
    e.preventDefault();
    addThumbNAil();
  };

  useEffect(() => {
    if (filters?.aspectRatio) {
      setSelectThumbnail(
        clip?.editedVideos?.find(
          ({ aspect_ratio, event }) => aspect_ratio === filters?.aspectRatio && event !== EVENT_NAME.DYNAMIC,
        )?.thumbnailUrl,
      );
    } else {
      setSelectThumbnail(clip.videoThumbnailUrl);
    }
  }, [filters]);

  const deleteGridClip = async () => {
    const clipId = clip._id;
    setLoder(true);
    const { value } = await deleteClips({ id: clipId });
    if (value.success) {
      notification["success"]({ message: value.message });
      allClips();
    }
    const filteredClips = folderArray.filter((item) => item?._id !== clipId);
    setFolderArray(filteredClips);
    delclose();
    setLoder(false);
  };

  const delclose = () => {
    setDeleteModalOpen(false);
  };

  // Export XMP File
  const exportXMPFile = async (id) => {
    const { value } = await clipExportXml({ id: clip._id });
    if (value.status) {
      notification["success"]({ message: "XML File Downloaded Successfully" });
      window.open(value.data, "_blank");
    } else {
      notification["error"]({ message: "Data not found" });
    }
  };
  //Upload to sftp
  const publishToSFTP = async () => {
    setSftpOpen(false);
    notification["success"]({ message: "Process Initiated..." });
    const clipId = clip._id;
    const isMedia = sftpFolder === "watch" ? true : false;
    const value = await updatesftp({ id: clipId, clipsAspectRatio: filters?.aspectRatio || clip.aspectRatio, isMedia });
    if (value?.value?.status) {
      notification["success"]({ message: value?.value?.message });
    } else {
      notification["error"]({ message: value?.value?.message });
    }
  };

  //share on social media
  const shareOnSocialMedia = async (clip) => {
    let data = {};
    setPublishThumbnails(clip?.videoThumbnailUrls || []);
    setIsOpenPublishOverlay(true);
    setPublishedClipId(clip);
    if (clip.editedVideos) {
      const url = clip.editedVideos.find(
        (o) => o?.aspect_ratio === aspectRatio && o.event !== "dynamicCropped",
      )?.video_url;
      let shareUrl = url || clip?.videoUrl;
      data = await createS3PresignedURl({ url: shareUrl, streamId: streamDetails?.streamId });
    } else {
      data = await createS3PresignedURl({
        url: clip?.videoUrl,
        streamId: streamDetails?.streamId,
      });
    }
    setPublicStatus(data?.value?.status);
    setThirdPartyUrl(data?.value?.data?.url);
  };

  const downloadMp4 = () => {
    const link = document.createElement("a");
    link.href = clip.mp4VideoUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const updateClipDetail = async () => {
    let payload = {
      id: clip?._id,
      editClipFilterObject: {
        ...clip?.editClipFilterObject,
        isAddedToClipsPage: !clip?.editClipFilterObject?.isAddedToClipsPage,
        isRollbacked: true,
      },
    };
    const { value } = await updateClipDetails(payload);
    if (value.success) {
      allClips();
      getStreamDetail(id);

      notification.success({ message: "Clip rollbacked successfully!" });
    } else {
      notification.error({ message: "Somthing went wrong!" });
    }
  };

  const deleteHlsToMp4 = async (clip) => {
    const { deleteHlsToMp4 } = props;
    const { value } = await deleteHlsToMp4({ streamId: clip?.streamId });
    if (value?.status) {
      notification.success({ message: value?.message });
      refreshClips();
      handleSteps(1);
    }
  };

  const uploadOnCloud = async () => {
    setLoader(true);
    notification.success({ message: "Process Initiated" });
    let videoUrl =
      clip?.editedVideos?.find(
        ({ aspect_ratio, event }) =>
          aspect_ratio == filters?.aspectRatio && (event === "autoFlip" || event === "croppedVideos"),
      )?.video_url || clip.videoUrl;
    const data = await s3BucketPublish({
      bucket: {},
      streamId: clip?.streamId,
      fileUrl: videoUrl,
      clipId: clip._id,
    });
    if (data.value.success || data.value.status) {
      notification["success"]({ message: data.value.message });
    } else {
      notification["error"]({ message: data.value.message });
    }
    refreshClips();
    setLoader(false);
  };

  const advanceEditor = async () => {
    let videoUrl =
      clip?.editedVideos?.find(
        ({ aspect_ratio, event }) =>
          aspect_ratio == filters?.aspectRatio && (event === "autoFlip" || event === "croppedVideos"),
      )?.video_url || clip.videoUrl;

    const data = await advanceEditorIllusto({
      clipDetails: clip,
      userId: userId,
      type: "clip",
      projectId: clip?.projectId,
      videoUrl: videoUrl,
      aspectRatio: filters?.aspectRatio ? filters?.aspectRatio : clip?.aspectRatio,
    });

    if (get(data, "value.status", false)) {
      notification["success"]({ message: data.value.message });
      if (get(data, "value.data[0].msg", "") !== "project data") {
        const newClipInserted = await genrateNewClip({
          aspectRatio: filters?.aspectRatio ? filters?.aspectRatio : clip?.aspectRatio,
          title: clip?.episodeTitle,
          streamId: clip?.streamId,
          properties: clip?.properties,
          userId: userId,
          videoUrl: "",
          videoThumbnailUrl: clip?.videoThumbnailUrl,
          videoThumbnailUrls: clip?.videoThumbnailUrls,
          isClipCreateByIllusto: true,
          projectId: get(data, "value.data[0].data.projectId", ""),
          illustoProjectUrl: get(data, "value.data[0].data.redirectUrl", ""),
          clipIdArr: clip?.clipIdArr,
          previewData: {
            previewUrl: clip.editedPreviewUrl,
            totalDurationWithoutBumper: clip?.totalDurationWithoutBumper,
            thumbnail: clip?.videoThumbnailUrl,
            thumbnails: clip?.videoThumbnailUrls,
            duration: "",
            customData: clip?.customData,
            clipData: clip?.clipData,
            type: "clip",
          },
        });
      }
      window.open(get(data, "value.data[0].data.redirectUrl", ""), "_blank", "noreferrer");
    } else if (!get(data, "value.status", true)) {
      notification["error"]({ message: data.value.message });
    }
  };

  const actions = {
    advanceEditor: true,
    cropper: true,
    copyClip: true,
    change_thumbnail: true,
    copyToChild: true,
    downloadVideo: true,
    downloadEDL: true,
    edit_publish: true,
    exportJSON: true,
    exportXMP: true,
    generateTitle: true,
    publishToCMS: true,
    socialMediaPublish: true,
    show_title: true,
    uploadToCloud: true,
    uploadToFTP: true,
    uploadToSFTP: true,
    uploadToBrightCove: true,
    exportXMP: true,
    rollback: true,
    show_csv: true,
    webisodeScenceCombination: true,
    deleteClip: true,
  };
  const downloadActions = {
    download_video: true,
    delete_video: true,
  };
  const handleClipsTableAction = (profileFeature, menuItems) => {
    for (const key in actions) {
      if (profileFeature.hasOwnProperty(key) && profileFeature?.[key]) {
        switch (key) {
          case "advanceEditor":
            clientSetting?.appAdditionalPermissions?.advanceEditor &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      advanceEditor(clip?.id, clip?.streamId);
                    }}
                  >
                    <img src={IllustoAdvEditorLogo} alt="illustologo" />
                    <span>&nbsp; Advanced Editor</span>
                  </a>
                ),
                key: "19",
              });
            break;
          case "copyClip":
            clientSetting?.appAdditionalPermissions?.isCopyClip &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      openCopyFun();
                    }}
                  >
                    <CopyOutlined />
                    <span>Copy</span>
                  </a>
                ),
                key: "1",
              });
            break;
          case "change_thumbnail":
            clip?.videoThumbnailUrls?.length !== 1 &&
              clientSetting?.appAdditionalPermissions?.isChangeClipsThumbnail &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      delThumbOpen(clip);
                    }}
                  >
                    <PictureOutlined />
                    <span>Change Thumbnail</span>
                  </a>
                ),
                key: "3",
              });
            break;
          case "cropper":
            (clientSetting?.appAdditionalPermissions?.isStaticCropper ||
              clientSetting?.appAdditionalPermissions?.isDynamicCropper) &&
              menuItems.push({
                label: (
                  <a onClick={() => window.open(`/cropper/${clip?._id}`, "_blank")}>
                    <AreaChartOutlined />
                    <span>Auto flip/Dynamic flip</span>
                  </a>
                ),
                key: "2",
              });
            break;
          case "edit_publish":
            clientSetting?.appAdditionalPermissions?.zeepublishing &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      setZeeOpen(true);
                    }}
                  >
                    <CloudUploadOutlined />
                    <span>Edit & Publish</span>
                  </a>
                ),
                key: "zeePublishing",
              });
            break;

          case "uploadToCloud":
            clientSetting?.appAdditionalPermissions?.isUploadToCloud &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      clientSetting?.clientType == ZEE_CLIENT ? uploadOnCloud() : openS3Publish();
                    }}
                  >
                    <CloudUploadOutlined />
                    {loader && <Spin indicator={antIcon}></Spin>}
                    <span>Upload To Cloud</span>
                  </a>
                ),
                disabled: loader,
                key: "5",
              });
            break;
          case "uploadToFTP":
            clientSetting?.appAdditionalPermissions?.uploadToFTP &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      openFTPPublish();
                    }}
                  >
                    <CloudUploadOutlined />
                    <span>Upload To FTP</span>
                  </a>
                ),
                key: "13",
              });
            break;
          case "uploadToSFTP":
            clientSetting?.appAdditionalPermissions?.uploadToSFTP &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      setSftpOpen(true);
                    }}
                  >
                    <CloudUploadOutlined />
                    <span>Upload To SFTP</span>
                  </a>
                ),
                key: "14",
              });
            break;
          case "copyToChild":
            clientSetting?.appAdditionalPermissions?.reviewerFunctionality &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      setCopyToChildModalOpen(true);
                    }}
                  >
                    <CopyrightOutlined />
                    <span>Copy to Child</span>
                  </a>
                ),
                key: "copyToChild",
              });
            break;

          case "uploadToBrightCove":
            clientSetting?.appAdditionalPermissions?.uploadtoBrightcove &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      openUploadToBrightCove();
                    }}
                  >
                    <UploadOutlined />
                    <span>Upload To BrightCove</span>
                  </a>
                ),
                key: "9",
              });
            break;

          case "downloadVideo":
            clientSetting?.appAdditionalPermissions?.downloadClipButton &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      downloadClip(clip);
                    }}
                  >
                    <DownloadOutlined />
                    <span>Download Video</span>
                  </a>
                ),
                key: "8",
              });
            clip?.mxfUrl &&
              menuItems.push({
                label: (
                  <a onClick={() => downloadMxf(clip)}>
                    <DownloadOutlined />
                    <span>Download MXF</span>
                  </a>
                ),
                key: "19",
              });
            break;

          case "generateTitle":
            clientSetting?.appAdditionalPermissions?.isGenerateClipTitle &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      setGenTitleModalOpen(!genTitleModalOpen);
                    }}
                  >
                    <EditOutlined />
                    <span>Generate Title</span>
                  </a>
                ),
                key: "generateTitle",
              });
            break;
          case "deleteClip":
            clientSetting?.appAdditionalPermissions?.isClipDelete &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      delOpen(clip?.id);
                    }}
                  >
                    <DeleteOutlined />
                    <span>Delete</span>
                  </a>
                ),
                key: "7",
              });
            break;
          case "exportJSON":
            {
              if (clientSetting?.appAdditionalPermissions?.exportClipJSON) {
                if (clip.isHighlightVideo) {
                  menuItems.push({
                    label: (
                      <a>
                        {" "}
                        <ExportOutlined />
                        <span>Export JSON</span>
                      </a>
                    ),
                    children: [
                      {
                        label: (
                          <a
                            onClick={() => {
                              exportJson(clip?._id, "highlight", "highlightClip");
                            }}
                          >
                            <span>Export HL JSON</span>
                          </a>
                        ),
                        key: "JSON1",
                      },
                      {
                        label: (
                          <a
                            onClick={() => {
                              exportJson(clip?._id, "highlight", "onlyClip");
                            }}
                          >
                            <span>Export Clip JSON</span>
                          </a>
                        ),
                        key: "JSON2",
                      },
                    ],
                  });
                } else {
                  menuItems.push({
                    label: (
                      <a
                        onClick={() => {
                          exportJson(clip?._id, "clip", "");
                        }}
                      >
                        <ExportOutlined />
                        <span>Export Clip JSON</span>
                      </a>
                    ),
                    key: "15",
                  });
                }
              }
            }
            break;
          case "socialMediaPublish":
            clientSetting?.appAdditionalPermissions?.socialMediaPublish &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      shareOnSocialMedia(clip);
                    }}
                  >
                    <ShareAltOutlined />
                    <span>
                      Social Media Publish
                      {publishLoader && (
                        <Spin
                          size="large"
                          indicator={<LoadingOutlined style={{ fontSize: 18, color: "#000" }} spin />}
                          className="inline-btn-loader"
                        ></Spin>
                      )}
                    </span>
                  </a>
                ),
                key: "12",
              });
            break;
          case "publishToCMS":
            clientSetting?.appAdditionalPermissions?.publishToCMS &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      openPublishCMS();
                    }}
                  >
                    <SettingOutlined />
                    <span>Publish to CMS</span>
                  </a>
                ),
                key: "6",
              });
            break;
          case "download_edl":
            clientSetting?.appAdditionalPermissions?.zeepublishing &&
              clip?.csvData?.edlFilePath &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      downloadFile(clip);
                    }}
                  >
                    <DownloadOutlined />
                    <span>Download EDL</span>
                  </a>
                ),
                key: "downloadEDL",
              });
            break;
          case "generateTitle":
            Object.keys(clip?.aiTitle || {}).length !== 0 &&
              clientSetting?.appAdditionalPermissions?.isGenerateClipTitle &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      setTitleModalOpen(!titleModalOpen);
                    }}
                  >
                    <FormOutlined />
                    <span>Show Title</span>
                  </a>
                ),
                key: "showTitle",
              });
            break;
          case "exportXMP":
            clientSetting?.appAdditionalPermissions?.exportXMP &&
              menuItems.push({
                label: (
                  <a onClick={() => exportXMPFile(clip._id)}>
                    <ExportOutlined />
                    <span>Export XML file</span>
                  </a>
                ),
                key: "exportXMP",
              });
            break;
          case "rollback":
            clientSetting?.appAdditionalPermissions?.allowEnhancedTagging &&
              clip?.editClipFilterObject?.isAddedToClipsPage &&
              menuItems.push({
                label: (
                  <a onClick={() => updateClipDetail(clip)}>
                    <RollbackOutlined />
                    <span>Rollback</span>
                  </a>
                ),
                key: "isAddedToClipsPage",
              });
            break;
          case "show_csv":
            clientSetting?.clientType == ZEE_CLIENT &&
              menuItems.push({
                label: (
                  <a onClick={() => setIsShowCsv(true)}>
                    <RollbackOutlined />
                    <span>Show CSV</span>
                  </a>
                ),
                key: "show_csv",
              });
            break;
          case "webisodeScenceCombination":
            clientSetting?.clientType == ZEE_CLIENT &&
              streamDetails?.fields?.categoryType === ZEE_CATEGORY_TYPES.WEBISODE &&
              user?.role === ROLE.ADMIN &&
              Object.keys(clip?.webisodeSceneDetails || {}).length !== 0 &&
              menuItems.push({
                label: (
                  <a onClick={() => setOpenJsonDetails(true)}>
                    <EyeOutlined />
                    <span>View Webisode Scene Combinations</span>
                  </a>
                ),
                key: "webisodeScenceCombination",
              });
            break;
        }
      }
    }
  };

  const handleDownloadTableAction = (profileFeature, menuItems) => {
    for (const key in downloadActions) {
      if (profileFeature.hasOwnProperty(key) && profileFeature?.[key]) {
        switch (key) {
          case "download_video":
            clientSetting?.appAdditionalPermissions?.isHlsToMp4 &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      downloadMp4();
                    }}
                  >
                    <DownloadOutlined />
                    <span>Download Video</span>
                  </a>
                ),
                disabled: !clip?.mp4VideoUrl,
                key: "1",
              });
            break;
          case "delete_video":
            clientSetting?.appAdditionalPermissions?.isHlsToMp4 &&
              menuItems.push({
                label: (
                  <a
                    onClick={() => {
                      deleteHlsToMp4(clip);
                    }}
                  >
                    <DeleteOutlined />
                    <span>Delete</span>
                  </a>
                ),
                key: "2",
              });
            break;
        }
      }
    }
  };

  const CommonJsonContextValues = {
    openJsonDetails,
    setOpenJsonDetails,
    jsonData: clip?.webisodeSceneDetails || {},
    modalHeader: "Webisode scene combinations",
  };
  return (
    <>
      <div className="dropdown-btns">
        <AccessControl
          code="menuItem"
          page="clips"
          handleTableAction={step !== 4 ? handleClipsTableAction : handleDownloadTableAction}
        />
      </div>
      <Modal
        isOpen={sftpOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setSftpOpen(false);
        }}
        contentLabel="Upload To SFTP Folder"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered filter-modal custom-small-modal"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Upload To SFTP Folder</h5>
          </div>
          <div className="react-modal-body text-center">
            <Radio.Group
              name="radiogroup"
              defaultValue={""}
              onChange={(e) => {
                setSftpFolder(e.target.value);
              }}
              value={sftpFolder}
            >
              {["aux", "watch"].map((item) => {
                return (
                  <Radio key={item} value={item}>
                    {item.toUpperCase()}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          <div className="react-modal-footer text-center">
            <div className="react-foo-container">
              <button
                type="button"
                onClick={() => {
                  setSftpOpen(false);
                }}
                className="btn btn-white"
              >
                Close{" "}
              </button>
              <button
                type="button"
                onClick={() => {
                  publishToSFTP();
                }}
                className="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={illustomodalOpen}
        ariaHideApp={false}
        onRequestClose={illustopopup}
        contentLabel="Delete Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered filter-modal custom-small-modal"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Illusto Editor URL</h5>
          </div>
          <div className="react-modal-body text-center">
            Redirect to Editor{" "}
            <a href={illustoData} target="_blank" rel="noreferrer">
              Click here{" "}
            </a>
          </div>
          <div className="react-modal-footer text-center">
            <div className="react-foo-container">
              <button type="button" onClick={illustopopup} className="btn-s-one fill">
                Close{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <CommonJsonContext.Provider value={CommonJsonContextValues}>
        <CommonJsonDetails />
      </CommonJsonContext.Provider>
      <ChangeThumbNail
        clip={clip}
        selectThumbnail={selectThumbnail}
        setSelectThumbnail={setSelectThumbnail}
        thumbnailUrls={thumbnailUrls}
        setThumbnialUrls={setThumbnialUrls}
        thumbModelOpen={thumbModelOpen}
        handleSubmit={handleSubmit}
        setThumbModalOpen={setThumbModalOpen}
        addThumbnail={addThumbnail}
        getUploadPicture={getUploadPicture}
        updateClipDetails={updateClipDetails}
        filters={filters}
      />
      <ModalPublish
        setIsOpenPublishOverlay={setIsOpenPublishOverlay}
        modalIsOpenPublishOverlay={modalIsOpenPublishOverlay}
        shareOnSocialMedias={shareOnSocialMedias}
        publishThumbnails={publishThumbnails}
        autoPopulatedHashtags={autoPopulatedHashtags}
        setAutoPopulatedHashtags={setAutoPopulatedHashtags}
        selectedHashtags={[]}
        eventHashtags={[]}
        thirdPartyUrl={thirdPartyUrl}
        publicStatus={publicStatus}
        user={user}
        publishClipId={publishClipId}
        singleClipPublish={true}
        addSocialMediaData={addSocialMediaData}
        streamDetails={streamDetails}
        addPublishNotification={addPublishNotification}
      />
      {isShowCsv && (
        <ShowCSV
          isShowCsv={isShowCsv}
          setIsShowCsv={setIsShowCsv}
          streamDetails={streamDetails}
          getCsvData={getCsvData}
        />
      )}

      {copyOpen && (
        <CopyClip copyOpen={copyOpen} setCopyOpen={setCopyOpen} clip={clip} copyClipFun={copyClipFun} loder={loder} />
      )}
      {copyToChildModalOpen && (
        <CopyClipToChild
          copyToChildModalOpen={copyToChildModalOpen}
          setCopyToChildModalOpen={setCopyToChildModalOpen}
          clip={clip}
          fetchUserDetails={fetchUserDetails}
          copyClipToReviewer={copyClipToReviewer}
          streamDetails={streamDetails}
          fetchUsersByUserIds={fetchUsersByUserIds}
          allUsers={allUsers}
        />
      )}
      <EditClip
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        clip={clip}
        loder={loder}
        editClips={editClips}
        publishClips={publishClips}
      />
      <DeleteModal
        modalIsOpen={delmodalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        userId={userId}
        loder={loder}
        deleteComanModel={deleteGridClip}
        header="Delete"
        content="Are you sure you want to delete?"
      />
      <TrimClip
        trimOpen={trimOpen}
        setTrimOpen={setTrimOpen}
        clip={clip}
        streamDetails={streamDetails}
        trimClip={trimClip}
      />
      <VideoSpeed
        videoSpeedOpen={videoSpeedOpen}
        setVideoSpeedOpen={setVideoSpeedOpen}
        streamDetails={streamDetails}
        trimClip={trimClip}
        clip={clip}
        loder={loder}
      />
      <GenerateTitle
        genTitleModalOpen={genTitleModalOpen}
        setGenTitleModalOpen={setGenTitleModalOpen}
        clip={clip}
        GenerateVideoTitle={GenerateVideoTitle}
      />
      <ShowClipTitle titleModalOpen={titleModalOpen} setTitleModalOpen={setTitleModalOpen} clip={clip} />
      {s3Open && (
        <UploadTOS3
          s3Open={s3Open}
          setS3Open={setS3Open}
          clip={clip}
          fetchStorage={fetchStorage}
          userId={userId}
          s3BucketPublish={s3BucketPublish}
          streamDetails={streamDetails}
          folderArray={folderArray}
          setFolderArray={setFolderArray}
          storage={storage}
          dumpOnS3={dumpOnS3}
          bucketCopy={bucketCopy}
          clientSetting={clientSetting}
          bulkS3BucketPublish={bulkS3BucketPublish}
          filters={filters}
          {...props}
        />
      )}

      {ftpOpen && (
        <UploadToFTP
          id={id}
          ftpList={ftpList}
          clip={clip}
          step={step}
          storage={storage}
          ftpPublish={ftpPublish}
          setFTPOpen={setFTPOpen}
          folderArray={folderArray}
          ftpOpen={ftpOpen}
          filters={filters}
        />
      )}

      <UploadToZee
        zeeOpen={zeeOpen}
        setZeeOpen={setZeeOpen}
        clip={clip}
        fetchStorage={fetchStorage}
        userId={userId}
        jsonBucketPublish={jsonBucketPublish}
        streamDetails={streamDetails}
        storage={storage}
        getCustomFields={getCustomFields}
        filters={filters}
        {...props}
      />
      <PublishToCMS
        publishToCMS={publishToCMS}
        setPublishTOCMS={setPublishTOCMS}
        clip={clip}
        loder={loder}
        publishClips={publishClips}
        editClipsForCms={editClipsForCms}
        clientWebHookPublish={clientWebHookPublish}
        setLoder={setLoder}
        editClip={editClip}
        allClips={allClips}
        singleClipDetail={singleClipDetail}
        streamDetails={streamDetails}
        clientSetting={clientSetting}
        filters={filters}
      />
      <AlertDialog
        alertModel={isAlertOpen}
        content={alertMessage}
        oncloseAlertModel={() => {
          setIsAlertOpen(false);
        }}
      />
    </>
  );
};

export default TableAction;
