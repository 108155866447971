import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseModal from "../../../components/icons/CloseModal";
import { Formik, Form, Field } from "formik";
import { notification, Spin, Radio, Select } from "antd";
import { newFolderValidation } from "../../../utils/validations";
import { LoadingOutlined } from "@ant-design/icons";
import { aspect_ratio, allowAspectRatio } from "../../../config";
const { Option } = Select;

const AddNewStories = (props) => {
  const {
    closeStoryModal,
    storyIsOpen,
    setStoryIsOpen,
    id,
    createFolder,
    fetchStories,
    handleStory,
    stories,
    setStoryId,
    storyId,
    notify,
    setNotify,
    seteventDisable,
    eventDisable,
    setFolderHide,
    folderHide,
    setState,
    state,
    folderArray,
  } = props;
  const [loder, setLoder] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [aspectRatio, setAspectRatio] = useState("16:9");
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [initialState] = useState({
    title: "",
    aspectRatio: "16:9",
  });

  const handleSubmit = async ({ title, aspectRatio }) => {
    setLoder(true);
    const { value } = await createFolder({
      streamId: id,
      title: title.toLowerCase(),
      type: "stories",
      aspectRatio,
    });
    if (value.status) {
      handleStory(value.data._id, setStoryId(storyId));
      notification["success"]({ message: "Story Created Successfully!", duration: 6 });
      closeStoryModal();
      fetchStories(value.message);
      setNotify(false);
      setLoder(false);
      setStoryIsOpen(false);
    } else if (value.message === "Title already exists") {
      setLoder(false);
      setNotify(true);
    } else {
      notification["error"]({ message: value.message, duration: 6 });
    }
  };
  const handlChange = (setFieldValue, e) => {
    if (e?.target?.name === "title") {
      setFieldValue(e.target.name, e.target.value);
    } else {
      setFieldValue("aspectRatio", e);
      setAspectRatio(e);
    }
    setNotify(false);
  };

  const handleSelect = (e) => {
    setStoryId(e.target.value);
  };

  const createNew = () => {
    setState(true);
    setFolderHide(false);
  };

  useEffect(() => {
    if (storyId == "") {
      seteventDisable(true);
    } else {
      seteventDisable(false);
    }
  }, [storyId]);

  useEffect(() => {
    if (storyIsOpen) {
      const containsMatchingAllowedAspect = allowAspectRatio?.find((o) => o.value === aspectRatio);
      let flag = true;
      folderArray?.map((clip) => {
        let checkAspect = containsMatchingAllowedAspect?.allowedRatio.includes(clip?.aspectRatio);
        if (!checkAspect) {
          flag = false;
        }
      });
      if (flag) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    }
  }, [aspectRatio, storyIsOpen]);

  return (
    <>
      <Modal
        isOpen={storyIsOpen}
        onRequestClose={closeStoryModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered filter-modal custom-small-modal"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Add to Stories</h5>
            <button type="button" onClick={closeStoryModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={newFolderValidation} onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="react-modal-body">
                  {state && (
                    <div className="form-group">
                      <label className="form-label">
                        Title <span className="required">*</span>
                      </label>
                      <Field
                        className="form-control"
                        name="title"
                        type="text"
                        value={values.title}
                        onChange={(e) => handlChange(setFieldValue, e)}
                      />
                      {notify && <div className="invalid-feedback">Story Already Exists!</div>}
                      {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                    </div>
                  )}
                  {state && (
                    <div className="form-group">
                      <label className="form-label">
                        Aspect Ratio <span className="required">{"*"}</span>
                      </label>
                      <select
                        className="form-control custom-select"
                        name="aspectRatio"
                        value={values.aspectRatio}
                        onChange={(e) => handlChange(setFieldValue, e.target.value)}
                      >
                        {aspect_ratio &&
                          aspect_ratio.map((ratio) => {
                            return (
                              <option value={ratio.value} key={ratio.key}>
                                {ratio.value}
                              </option>
                            );
                          })}
                      </select>
                      {touched.aspectRatio && errors.aspectRatio && (
                        <div className="invalid-feedback">{errors.aspectRatio}</div>
                      )}
                      {buttonDisable && <div className="invalid-feedback">Story aspect ratio doesn't match</div>}
                    </div>
                  )}
                  {folderHide && (
                    <>
                      <div className="d-flex justify-content-between">
                        <label className="form-label">Add to Existing folders</label>
                        <button type="button" onClick={() => setStoryId("")} className="reset-btn">
                          Reset
                        </button>
                      </div>
                      <div className="content-control-block long filters-events-list styled-scroll">
                        {stories.length > 0
                          ? stories.map((stories) => {
                              return (
                                <Radio.Group
                                  className="folders-radio-list"
                                  key={stories._id}
                                  value={storyId}
                                  onChange={(e) => handleSelect(e)}
                                >
                                  <Radio value={stories._id} className="title_Pascal">
                                    {stories.title}
                                  </Radio>
                                </Radio.Group>
                              );
                            })
                          : "No stories Found"}
                      </div>
                    </>
                  )}
                </div>

                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeStoryModal}>
                    Cancel
                  </button>
                  {folderHide && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={eventDisable || buttonDisable}
                      onClick={() => handleStory(storyId)}
                    >
                      {loder ? <Spin className="lightLoader-spin" indicator={antIcon} /> : "Add"}
                    </button>
                  )}
                  {folderHide && (
                    <button disabled={storyId} type="button" className="btn btn-primary" onClick={createNew}>
                      Create New
                    </button>
                  )}
                  {state && (
                    <button disabled={buttonDisable} className="btn btn-primary" type="submit">
                      {loder ? <Spin className="lightLoader-spin" indicator={antIcon} /> : "Create"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AddNewStories;
