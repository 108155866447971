import React from "react";
import { useNavigate } from "react-router-dom";

import Back from "../../components/icons/Back";

import "./styles.scss";

export default function CookiePolicyComponent(props) {
  const navigate = useNavigate();
  return (
    <>
      <main className="global-main pt-2">
        <div className="title-container mt-4 mb-3">
          <div className="container-fluid page-rl-p">
            <div className="row align-items-center border-back-container">
              <div className="col-md-8">
                <div className="page-title-items back-icon-block">
                  <h1>
                    <button className="pageBack" onClick={() => navigate(-1)}>
                      <Back />
                    </button>
                    Back
                  </h1>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-title">
                  <h1>Cookie Policy</h1>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="text-content-block">
                  <p>
                    This website uses cookies to better the users’ experience while visiting the website. Where
                    applicable this website uses a cookie control system allowing the user on their first visit to the
                    website to allow or disallow the use of cookies on their computer/device. This complies with recent
                    legislation requirements for websites to obtain explicit consent from users before leaving behind or
                    reading files such as cookies on a users’ computer/device. Cookies are small files saved to the
                    users’ computer hard drive that track, save and store information about the users’ interactions and
                    usage of the website. This allows the website, through its server to provide the users with a
                    tailored experience within this website. Users are advised that if they wish to deny the use and
                    saving of cookies from this website onto their computer hard drive they should take necessary steps
                    within their web browser’s security settings to block all cookies from this website and its external
                    serving vendors. This website uses tracking software to monitor its visitors to better understand
                    how they use it. This software is provided by Google Analytics which uses cookies to track visitor
                    usage. The software will save a cookie to your computer’s hard drive in order to track and monitor
                    your engagement and usage of the website but will not store, save or collect personal information.
                    You can read Google’s privacy policy here for further information.
                  </p>

                  <p>
                    Other cookies may be stored on your computer’s hard drive by external vendors when this website uses
                    referral programs, sponsored links or adverts. Such cookies are used for conversion and referral
                    tracking and typically expire after 30 days, though some may take longer. No personal information is
                    stored, saved or collected.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content body end */}
      </main>
    </>
  );
}
