import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none" viewBox="0 0 29 29">
      <path
        fill="currentColor"
        d="M1.242.492a.5.5 0 00-.5.5v4.5a.5.5 0 001 0v-4h4a.5.5 0 000-1h-4.5zm8.839 8.632L1.596.639l-.707.707L9.374 9.83l.707-.707zM28.258 27.509a.5.5 0 01-.5.5h-4.5a.5.5 0 010-1h4v-4a.5.5 0 111 0v4.5zm-8.632-8.839l8.485 8.485-.707.707-8.485-8.485.707-.707zM28.258.992a.5.5 0 00-.5-.5h-4.5a.5.5 0 000 1h4v4a.5.5 0 001 0v-4.5zm-8.632 8.839l8.485-8.485-.707-.707-8.485 8.485.707.707zM1.242 28.009a.5.5 0 01-.5-.5v-4.5a.5.5 0 111 0v4h4a.5.5 0 110 1h-4.5zm8.839-8.632l-8.485 8.485-.707-.707 8.485-8.485.707.707z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
