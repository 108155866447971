import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="none" viewBox="0 0 15 17" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.75 16a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5zM7.75 5.5v3.75M10 9.25H7.75M10 1H5.5M7.75 2.5V1"
    ></path>
  </svg>
);

export default SvgComponent;
