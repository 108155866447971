import { connect } from "react-redux";
import {
  fetchMe,
  createUserProfile,
  addSocialMediaPlatforms,
  fetchLoggerDetails,
  updateLoggerDetails,
  removeIp,
  getProcessList,
  cancelProcess,
} from "../../../store/user/duck";
import Header from "./component";

const HeaderContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    createUserProfile,
    addSocialMediaPlatforms,
    fetchLoggerDetails,
    updateLoggerDetails,
    removeIp,
    getProcessList,
    cancelProcess,
  },
)(Header);

export default HeaderContainer;
