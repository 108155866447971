import React from "react";
import VideoList from "./VideoList";
import EmptyIcon from "../../../images/EmptyIcon.png";

const Listview = (props) => {
  const {
    getAspectRatios,
    clipsDetails,
    allClips,
    filters,
    allClipsForCms,
    setClipData,
    streamDetails,
    aspectRatio,
    deleteClips,
    editClip,
    publishClip,
    getTrimClip,
    folderLength,
    storage,
    id,
    updatesftp,
    step,
    generateAutoFlip,
    setToggle,
    toggle,
    copyClip,
    s3BucketPublish,
    jsonBucketPublish,
    downloadVideo,
    setFolderClips,
    folderClips,
    folderArray,
    setFolderArray,
    setClip,
    setShowClass,
    showClass,
    checkhighLight,
    user,
    clipExportJson,
    clipExportXml,
    downloadClips,
    openPreviewModal,
    modalIsOpen,
    setIsOpen,
    clipUrl,
    clipTitle,
    clientWebHookPublish,
    fetchUserDetails,
    copyClipToReviewer,
    getPublicUrls,
    uploadClipInBrightCove,
    getCustomFields,
    clientSetting,
    addThumbnail,
    getUploadPicture,
    updateClipDetails,
    GenerateVideoTitle,
    singleClipDetail,
    clipListFolder,
    genrateNewClip,
    setSelectedClipIDS,
    selectedClipIDS,
    setClipCounts,
    deleteEditedClip,
    bytesToMB,
    shareOnSocialMedias,
    getS3PresignedURl,
    clipDetail,
  } = props;
  return (
    <>
      {clipsDetails.length ? (
        <div className="table-resposive table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Aspect Ratio</th>
                <th>Storage Consumed</th>
                <th>Date Created</th>
                <th>Time Stamp</th>
                <th>Events</th>
                <th>Duration</th>
                <th>TAT</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <VideoList
                filters={filters}
                bytesToMB={bytesToMB}
                updatesftp={updatesftp}
                singleClipDetail={singleClipDetail}
                generateAutoFlip={generateAutoFlip}
                setToggle={setToggle}
                toggle={toggle}
                deleteEditedClip={deleteEditedClip}
                genrateNewClip={genrateNewClip}
                clipListFolder={clipListFolder}
                getAspectRatios={getAspectRatios}
                clipsDetails={clipsDetails}
                allClips={allClips}
                allClipsForCms={allClipsForCms}
                id={id}
                step={step}
                setClipData={setClipData}
                folderLength={folderLength}
                streamDetails={streamDetails}
                storage={storage}
                aspectRatio={aspectRatio}
                deleteClips={deleteClips}
                copyClip={copyClip}
                editClip={editClip}
                publishClip={publishClip}
                getTrimClip={getTrimClip}
                s3BucketPublish={s3BucketPublish}
                jsonBucketPublish={jsonBucketPublish}
                downloadVideo={downloadVideo}
                setFolderClips={setFolderClips}
                folderClips={folderClips}
                folderArray={folderArray}
                setFolderArray={setFolderArray}
                setClip={setClip}
                showClass={showClass}
                setShowClass={setShowClass}
                checkhighLight={checkhighLight}
                user={user}
                clipExportJson={clipExportJson}
                clipExportXml={clipExportXml}
                downloadClips={downloadClips}
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                clipUrl={clipUrl}
                clipTitle={clipTitle}
                openPreviewModal={openPreviewModal}
                clientWebHookPublish={clientWebHookPublish}
                copyClipToReviewer={copyClipToReviewer}
                fetchUserDetails={fetchUserDetails}
                getPublicUrls={getPublicUrls}
                uploadClipInBrightCove={uploadClipInBrightCove}
                getCustomFields={getCustomFields}
                clientSetting={clientSetting}
                addThumbnail={addThumbnail}
                getUploadPicture={getUploadPicture}
                updateClipDetails={updateClipDetails}
                GenerateVideoTitle={GenerateVideoTitle}
                setSelectedClipIDS={setSelectedClipIDS}
                selectedClipIDS={selectedClipIDS}
                setClipCounts={setClipCounts}
                shareOnSocialMedias={shareOnSocialMedias}
                getS3PresignedURl={getS3PresignedURl}
                clipDetail={clipDetail}
                {...props}
              />
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-block pt-5 empty-screen-block text-center">
          <img src={EmptyIcon} alt="" className="empty-icon" />
          <h1>Clips not found!</h1>
        </div>
      )}
    </>
  );
};

export default Listview;
