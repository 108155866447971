import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      d="M15.315 13.025l.25-.433-.25.433zm.517.895h-.5.5zm-.517.895l.25.433-.25-.433zM9.89 17.948l-.25-.433.25.433zm-1.55-.895h-.5.5zm0-6.265h-.5.5zm1.55-.895l-.25.433.25-.433zM7.977.719a.5.5 0 00-.827.562L7.977.72zm3.1 5.449l-.413.281a.5.5 0 00.808.026l-.394-.307zm3.082-3.137a.5.5 0 00-.788-.616l.788.616zM4.463 7.34h13.436v-1H4.463v1zm13.436 0a2.963 2.963 0 012.963 2.962h1a3.963 3.963 0 00-3.963-3.962v1zm2.963 2.962v7.236h1v-7.236h-1zm0 7.236a2.963 2.963 0 01-2.963 2.962v1a3.963 3.963 0 003.963-3.962h-1zM17.899 20.5H4.463v1h13.436v-1zm-13.436 0A2.963 2.963 0 011.5 17.538h-1A3.963 3.963 0 004.463 21.5v-1zM1.5 17.538v-7.236h-1v7.236h1zm0-7.236A2.963 2.963 0 014.463 7.34v-1A3.963 3.963 0 00.5 10.302h1zm13.565 3.156a.533.533 0 01.196.195l.866-.5a1.534 1.534 0 00-.562-.561l-.5.866zm.196.195a.534.534 0 01.071.267h1c0-.27-.07-.534-.205-.767l-.866.5zm.071.267a.534.534 0 01-.072.267l.867.5c.134-.233.205-.498.205-.767h-1zm-.072.267a.533.533 0 01-.195.195l.5.866c.233-.135.427-.328.562-.561l-.866-.5zm-.195.195L9.64 17.515l.5.866 5.426-3.133-.5-.866zM9.64 17.515a.534.534 0 01-.267.071v1c.27 0 .534-.07.767-.205l-.5-.866zm-.267.071a.534.534 0 01-.267-.071l-.5.866c.233.134.498.205.767.205v-1zm-.267-.071a.534.534 0 01-.195-.195l-.866.5c.135.233.328.426.561.56l.5-.865zm-.195-.195a.533.533 0 01-.072-.267h-1c0 .269.071.533.206.767l.866-.5zm-.072-.267v-6.265h-1v6.265h1zm0-6.265c0-.094.025-.186.072-.267l-.866-.5a1.534 1.534 0 00-.206.767h1zm.072-.267a.534.534 0 01.195-.195l-.5-.866a1.534 1.534 0 00-.561.561l.866.5zm.195-.195a.534.534 0 01.267-.072v-1c-.27 0-.534.071-.767.206l.5.866zm.267-.072c.094 0 .186.025.267.072l.5-.866a1.534 1.534 0 00-.767-.205v1zm.267.072l5.426 3.132.5-.866L10.14 9.46l-.5.866zm-2.49-9.045l3.515 5.168.827-.562L7.977.719l-.827.562zm4.323 5.194l2.687-3.444-.788-.616-2.688 3.445.789.615z"
    ></path>
  </svg>
);

export default SvgComponent;
