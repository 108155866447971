import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <circle cx="13" cy="13" r="13" fill="red"></circle>
      <path fill="#fff" d="M8.95 19.607L20.247 13 8.95 6.393v13.213z"></path>
    </svg>
  );
}

export default SvgComponent;
