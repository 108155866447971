const dashoboardMenu = [
  { key: "all", value: "All" },
  { key: "1", value: "Completed" },
  { key: "2", value: "Processing" },
  { key: "3", value: "Pending" },
  { key: "4", value: "Failed" },
  { key: "5", value: "Cancelled" },
];
const ftpChannelLanguage = [
  { key: "1", value: "Hindi" },
  { key: "2", value: "Bengali" },
  { key: "3", value: "Bhojpuri" },
  { key: "4", value: "Kannada" },
  { key: "5", value: "Malayalam" },
  { key: "6", value: "Marathi" },
  { key: "7", value: "Punjabi" },
  { key: "8", value: "Odia" },
  { key: "9", value: "Tamil" },
  { key: "10", value: "Telugu" },
  { key: "11", value: "All" },
];
const dashboardFilter = {
  all: "All",
  1: "Completed",
  2: "Processing",
  3: "Pending",
  4: "Failed",
  5: "Cancelled",
};

const aspect_ratio = [
  { key: "16:9", value: "16:9", coordinate: [0, 0, 100, 100] },
  {
    key: "1:1",
    value: "1:1",
    coordinate: [27.750000000000004, 10.102564102564097, 73.21153846153847, 90.92307692307693],
  },
  {
    key: "4:3",
    value: "4:3",
    coordinate: [20.173076923076927, 10.102564102564097, 80.78846153846155, 90.92307692307693],
  },
  {
    key: "9:16",
    value: "9:16",
    coordinate: [37.69471153846155, 10.102564102564097, 63.266826923076934, 90.92307692307693],
  },
  {
    key: "4:5",
    value: "4:5",
    coordinate: [27.5, 0, 72.50000000000001, 100],
  },
];

const runObject = [
  {
    run: "1Run",
    runString: "One",
    key: "1",
  },
  {
    run: "2Runs",
    runString: "Two",
    key: "2",
  },
  {
    run: "3Runs",
    runString: "Three",
    key: "3",
  },
  {
    run: "4Runs",
    runString: "Four",
    key: "4",
  },
  {
    run: "6Runs",
    runString: "Six",
    key: "6",
  },
];

const playbackList = [
  {
    title: "1x",
    value: "1",
  },
  {
    title: "0.75x",
    value: "0.75",
  },
  {
    title: "0.5x",
    value: "0.5",
  },
];

const clipRating = [1, 2, 3, 4, 5];
const publishElement = [
  {
    title: "Published",
    value: "published",
  },
  {
    title: "Unpublished",
    value: "unpublished",
  },
  {
    title: "All",
    value: "",
  },
];

const socialMediaLabels = [
  {
    platform: "facebook",
    label: "Caption",
  },
  {
    platform: "instagram",
    label: "Caption",
  },
  {
    platform: "youtube",
    label: "Title",
  },
  {
    platform: "twitter",
    label: "Tweet",
  },
  {
    platform: "tiktok",
    label: "Title",
  },
];

const platformLimit = [
  {
    platform: "facebook",
    captionLimit: 63206,
    hashtagLimit: "No limit",
  },
  {
    platform: "instagram",
    captionLimit: 2200,
    hashtagLimit: "30 hashtags per caption",
  },
  {
    platform: "youtube",
    captionLimit: 100,
    hashtagLimit: "No limit",
  },
  {
    platform: "twitter",
    captionLimit: 280,
    hashtagLimit: "Hashtags are included in 280 character limit",
  },
];

const autoFlipCategories = ["football", "basketball", "tennis", "cricket", "entertainment", "zee_episode"];

const clientPermissions = [
  {
    label: "Add New Video",
    key: "addNewVideo",
    defaultValue: true,
    visibleTo: ["admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "user"], // which role can only see this
  },
  {
    label: "Export Clip JSON",
    key: "exportClipJSON",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Export Stream JSON",
    key: "exportStreamJSON",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Export Match Report",
    key: "exportMatchReport",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Download Clip Button",
    key: "downloadClipButton",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Download Highlight Button",
    key: "downloadHighlightButton",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "ScoreBoard Coordinate",
    key: "scoreBoardCoordinate",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Multiple Storage Path",
    key: "multipleStoragePath",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Reviewer Functionality",
    key: "reviewerFunctionality",
    defaultValue: false,
    visibleTo: ["admin"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Storage",
    key: "addStorages",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Settings",
    key: "showSettings",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Publish to CMS",
    key: "publishToCMS",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Upload to Brightcove",
    key: "uploadtoBrightcove",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Multi Folder Upload",
    key: "multiFolderUpload",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Show Clip TAT",
    key: "showClipTAT",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Web Story",
    key: "webStory",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Live editor/Web story addition",
    key: "liveEditor",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Add Storage Button",
    key: "addStorageButton",
    defaultValue: false,
    visibleTo: ["superadmin"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Social Media Share",
    key: "socialMediaPublish",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Auto Trigger Autoflip",
    key: "triggerAutoflip",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Clip Rating",
    key: "clipRating",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Vod",
    key: "vod",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Save V1",
    key: "livecutv2",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Export Folder JSON",
    key: "exportFolderJSON",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Export Events JSON",
    key: "exportEventsJSON",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "SFTP Upload",
    key: "uploadToSFTP",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "FTP Upload",
    key: "uploadToFTP",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Access Parent Storage",
    key: "accessParentStorage",
    defaultValue: false,
    visibleTo: ["user"],
    accessLevels: ["user"],
  },
  {
    label: "Rule Management",
    key: "ruleManagement",
    defaultValue: false,
    visibleTo: ["superadmin", "admin"],
    accessLevels: ["superadmin"],
  },
  {
    label: "isAmplify User",
    key: "isAmplify",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Restrict User Processing Limit",
    key: "restrictUserProcessingLimit",
    defaultValue: false,
    visibleTo: ["superadmin", "admin"],
    accessLevels: ["superadmin"],
  },
  {
    label: "Output Video Template",
    key: "outputVideoTemplate",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin"],
  },
  {
    label: "Amplify User",
    key: "isAmplify",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Multi Audio",
    key: "multiAudio",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Receive AI Clips & Enhanced Tagging",
    key: "allowEnhancedTagging",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Is HLS to MP4",
    key: "isHlsToMp4",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Bumper exclusion from download limit",
    key: "bumperExclusionFromDownloadLimit",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin"],
  },
  {
    label: "Edit Video",
    key: "isEditVideo",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Delete Video",
    key: "isDeleteVideo",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "View Video Details",
    key: "isViewVideoDetails",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Retrigger Video",
    key: "isRetriggerVideo",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Allow Access To Archive Videos Page",
    key: "isAllowAccessToArchiveVideos",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Clip Bulk Actions",
    key: "isClipBulkActions",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Copy Clip",
    key: "isCopyClip",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Upload To Cloud",
    key: "isUploadToCloud",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Bulk Clip Download",
    key: "isBulkClipDownload",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Upload Bulk Clips On Cloud",
    key: "isBulkClipUpload",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin", "user"], // which role can only see this
  },
  {
    label: "Bulk Copy Clips",
    key: "isBulkCopyClip",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin", "user"], // which role can only see this
  },
  {
    label: "Bulk Clip Delete",
    key: "isBulkClipDelete",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Clip Delete",
    key: "isClipDelete",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "Upload Clips",
    key: "isUploadClips",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"], // if superadmin give permission to Admin then admin also show this to his childs through this check
    accessLevels: ["superadmin", "admin"], // which role can only see this
  },
  {
    label: "View Published Clips",
    key: "isViewPublishedClips",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Change Clip Thumbnail",
    key: "isChangeClipsThumbnail",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Static Cropper",
    key: "isStaticCropper",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Dynamic Cropper",
    key: "isDynamicCropper",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Generate Clip Title",
    key: "isGenerateClipTitle",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Allow Access To Clip Info Page",
    key: "isAllowAccessToClipInfo",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },

  {
    label: "Allow Access To Archive Clips Page",
    key: "isAllowAccessToArchiveClips",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Allow Access To Highlight Page",
    key: "isAllowAccessToHighlight",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Allow Access To Story Page",
    key: "isAllowAccessToStory",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Allow Access To Live Editor Page",
    key: "isAllowAccessToLiveEditor",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Enable Default List View",
    key: "isDefaultListView",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Allow auto rating",
    key: "allowAutoRating",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Export usage report",
    key: "exportUsageReport",
    defaultValue: false,
    visibleTo: ["superadmin", "admin"],
    accessLevels: ["superadmin", "admin"],
  },
  {
    label: "Advance Editor",
    key: "advanceEditor",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Bulk Clip Editor",
    key: "isBulkClipEditor",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Edit And Publish",
    key: "zeepublishing",
    defaultValue: false,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
  {
    label: "Hide Right Click on Video Player",
    key: "isHideRightClick",
    defaultValue: true,
    visibleTo: ["superadmin", "admin", "user"],
    accessLevels: ["superadmin", "admin", "user"],
  },
];

const months = [
  { month: "Jan", value: 1 },
  { month: "Feb", value: 2 },
  { month: "Mar", value: 3 },
  { month: "Apr", value: 4 },
  { month: "May", value: 5 },
  { month: "Jun", value: 6 },
  { month: "Jul", value: 7 },
  { month: "Aug", value: 8 },
  { month: "Sep", value: 9 },
  { month: "Oct", value: 10 },
  { month: "Nov", value: 11 },
  { month: "Dec", value: 12 },
];
const frequencyArr = [
  { name: "30 Sec", value: "30", key: 1 },
  { name: "5 Min", value: "300", key: 1 },
  { name: "10 Min", value: "600", key: 1 },
  { name: "20 Min", value: "1200", key: 2 },
  { name: "30 Min", value: "1800", key: 3 },
];
const steps = (path, id) => {
  let step = 1;
  switch (path) {
    case `/videos/${id}`:
      step = 1;
      break;
    case `/videos/${id}/graphics`:
      step = 3;
      break;
    case `/videos/${id}/sound`:
      step = 4;
      break;
    case `/videos/${id}/bumper`:
      step = 2;
      break;
    case `/videos/${id}/hashtag`:
      step = 5;
      break;
    default:
      break;
  }
  return step;
};

const editFields = {
  cricket: [
    {
      name: "batsman",
      key: "batsman",
      heading: "Batsman",
      type: "select",
      defaultValue: "clipData.batsman",
    },
    {
      name: "bowler",
      key: "bowler",
      heading: "Bowler",
      type: "select",
      defaultValue: "clipData.bowler",
    },
    {
      name: "over",
      key: "over",
      heading: "Over",
      type: "input",
      defaultValue: "clipData.over",
    },
    {
      name: "run",
      key: "run",
      heading: "Run",
      type: "input",
      defaultValue: "clipData.run",
    },
    {
      name: "wicket",
      key: "wicket",
      heading: "Wicket",
      type: "input",
      defaultValue: "clipData.wicket",
    },
    {
      name: "ballPosition",
      key: "ballPosition",
      heading: "Ball Position",
      type: "hardselect",
      defaultValue: "clipData.ballPosition",
      array: [
        "Fine Leg",
        "Square Leg",
        "Mid Wicket",
        "Long On",
        "Long Off",
        "Cover",
        "Mid Off",
        "Backward Short Leg",
        "Slip",
        "Silly point",
      ],
    },
    {
      name: "batsmanPosition",
      key: "batsmanPosition",
      heading: "Batsman Position",
      type: "hardselect",
      defaultValue: "clipData.batsmanPosition",
      array: ["Front Foot", "Back Foot"],
    },
    {
      name: "shotType",
      key: "shotType",
      heading: "Shot Type",
      type: "hardselect",
      defaultValue: "clipData.shotType",
      array: ["Cover Drive", "Flick", "Square Cut", "Pull", "Straight Drive", "Cut", "Scoop", "Sweep", "Reverse Sweep"],
    },
    {
      name: "wicketTerm",
      key: "wicketTerm",
      heading: "Wicket Term",
      type: "hardselect",
      defaultValue: "clipData.wicketTerm",
      array: ["Lbw", "Catch", "Bowled", "Run Out", "Hit Wicket", "Caught Behind", "Caught And Bowled"],
    },
  ],
  filterBy: [
    { key: "all", value: "All" },
    { key: "fileName", value: "File Name" },
    { key: "status", value: "Status" },
    { key: "type", value: "Type" },
    { key: "channelName", value: "Channel Name" },
  ],
  settingUserMenu: [
    { key: "all", value: "All" },
    { key: "1", value: "Completed" },
    { key: "2", value: "Processing" },
    { key: "3", value: "Pending" },
    { key: "4", value: "Failed" },
    { key: "5", value: "Cancelled" },
  ],
  dashoboardMenu: [
    { key: "all", value: "All" },
    { key: "1", value: "Completed" },
    { key: "2", value: "Processing" },
    { key: "3", value: "Pending" },
    { key: "4", value: "Failed" },
    { key: "5", value: "Cancelled" },
  ],
  dashoboardMenuZee: [
    { key: "all", value: "All" },
    { key: "1", value: "Completed" },
    { key: "2", value: "Processing" },
    { key: "3", value: "Pending" },
    { key: "4", value: "Failed" },
    { key: "5", value: "Cancelled" },
  ],
  football: [
    {
      name: "batsman",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.batsman",
    },
    {
      name: "assist",
      key: "assist",
      heading: "Assist",
      type: "select",
      defaultValue: "clipData.assist",
    },
    {
      name: "referee",
      key: "referee",
      heading: "Referee",
      type: "select",
      defaultValue: "clipData.referee",
    },
  ],
  hockey: [
    {
      name: "batsman",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.batsman",
    },
    {
      name: "assist",
      key: "assist",
      heading: "Assist",
      type: "select",
      defaultValue: "clipData.assist",
    },
  ],
  motorcycle_road_racing: [
    {
      name: "racer",
      key: "racer",
      heading: "Racer",
      type: "select",
      defaultValue: "clipData.racer",
    },
  ],
  cornhole: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  a7fl: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  american_7s_football: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  Softball: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  moto_gp: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  floorball: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  volleyball: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  "American Football": [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  squash: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  "esports_-_pubg": [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  tennis: [
    {
      name: "player1",
      key: "player1",
      heading: "Player 1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player 2",
      type: "select",
      defaultValue: "clipData.player2",
    },
    {
      name: "point1",
      key: "player1_points",
      heading: "Set Point for Player 1",
      type: "hardselect",
      defaultValue: "clipData.point1",
      array: ["0", "1", "2", "3", "4", "5", "6", "7"],
    },
    {
      name: "point2",
      key: "player2_points",
      heading: "Set Point for Player 2",
      type: "hardselect",
      defaultValue: "clipData.point2",
      array: ["0", "1", "2", "3", "4", "5", "6", "7"],
    },
    {
      name: "matchPointPlayer1",
      key: "player1_match_points",
      heading: "Match Point for Player 1",
      type: "hardselect",
      defaultValue: "clipData.matchPointPlayer1",
      array: ["0", "15", "30", "40", "AD", "Game"],
    },
    {
      name: "matchPointPlayer2",
      key: "player2_match_points",
      heading: "Match Point for Player 2",
      type: "hardselect",
      defaultValue: "clipData.matchPointPlayer2",
      array: ["0", "15", "30", "40", "AD", "Game"],
    },
  ],
  basketball: [
    {
      name: "batsman",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.batsman",
    },
    {
      name: "assist",
      key: "assist",
      heading: "Assist",
      type: "select",
      defaultValue: "clipData.assist",
    },
  ],
  Baseball: [
    {
      name: "batsman",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.batsman",
    },
  ],
  pickleball: [
    {
      name: "batsman",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.batsman",
    },
  ],
  wrestling: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    // {
    //   name: 'player2',
    //   key: 'player2',
    //   heading: 'Player2',
    //   type: 'select',
    //   defaultValue: 'clipData.player2',
    // },
  ],
  ice_hockey: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
    {
      name: "assist",
      key: "assist",
      heading: "Assist",
      type: "select",
      defaultValue: "clipData.assist",
    },
    {
      name: "goalKeeper",
      key: "goalKeeper",
      heading: "Goal Keeper",
      type: "select",
      defaultValue: "clipData.goalKeeper",
    },
  ],
  figure_skating: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  speed_skating: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  bobsleigh: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  alpine_skiing: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  swimming: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  f1: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  Badminton: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  handball: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  "Table Tennis": [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  Gaming: [
    {
      name: "player",
      key: "player",
      heading: "Player",
      type: "select",
      defaultValue: "clipData.player",
    },
  ],
  MMA: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
  ],
  "rugby league": [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  "rugby union": [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  golf: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
  ],
  waterpolo: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
  ],
  motogp: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
  ],
  "Dirt Racing": [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
    {
      name: "player2",
      key: "player2",
      heading: "Player2",
      type: "select",
      defaultValue: "clipData.player2",
    },
  ],
  Athletics: [
    {
      name: "player1",
      key: "player1",
      heading: "Player1",
      type: "select",
      defaultValue: "clipData.player1",
    },
  ],
};
const matchType = {
  cricket: [
    { name: "ODI", key: "ODI" },
    { name: "Test", key: "Test" },
    { name: "T10", key: "T10" },
    { name: "T20", key: "T20" },
    { name: "Hundred", key: "Hundred" },
    { name: "Sixty", key: "Sixty" },
    { name: "Other", key: "Other" },
  ],
};

const sportTags = {
  cricket: [
    { name: "Batsman", key: "batsman" },
    { name: "Bowler", key: "bowler" },
    { name: "Over", key: "over" },
    { name: "Run", key: "run" },
    { name: "Wicket", key: "wicket" },
  ],
  f1: [
    { name: "Pit Stops", key: "Pit Stops" },
    { name: "Yellow Flag", key: "Yellow Flag" },
    { name: "Red Flag", key: "Red Flag" },
    { name: "Safety Car", key: "Safety Car" },
    { name: "Overtake", key: "Overtake" },
    { name: "Radio Chats", key: "Radio Chats" },
    { name: "Replay", key: "Replay" },
  ],
  tennis: [
    { name: "Player1", key: "player1" },
    { name: "Player2", key: "player2" },
  ],
  boxing: [{ name: "Player1", key: "player1" }],
  football: [
    { name: "Score", key: "score" },
    { name: "Time", key: "time" },
    { name: "Team1", key: "team1" },
    { name: "Team2", key: "team2" },
  ],
  basketball: [
    { name: "Score", key: "score" },
    { name: "Time", key: "time" },
    { name: "Team1", key: "team1" },
    { name: "Team2", key: "team2" },
    { name: "Game Clock", key: "game_clock" },
  ],
  Basketball: [
    { name: "Score", key: "score" },
    { name: "Time", key: "time" },
    { name: "Team1", key: "team1" },
    { name: "Team2", key: "team2" },
    { name: "Game Clock", key: "game_clock" },
  ],
  handball: [
    { name: "Score", key: "score" },
    { name: "Time", key: "time" },
    { name: "Team1", key: "team1" },
    { name: "Team2", key: "team2" },
  ],
  Handball: [
    { name: "Score", key: "score" },
    { name: "Time", key: "time" },
    { name: "Team1", key: "team1" },
    { name: "Team2", key: "team2" },
  ],
  futsal: [
    { name: "Score", key: "score" },
    { name: "Time", key: "time" },
    { name: "Team1", key: "team1" },
    { name: "Team2", key: "team2" },
  ],
};

const transition = [
  {
    tranistionName: "Fade In & Out",
    image: require("../images/transitiongif/fade.gif"),
    selected_transiton: "xfade",
    id: Math.random().toString(36).substr(2, 9),
  },
  {
    tranistionName: "Black Out",
    image: require("../images/transitiongif/BlackOut.gif"),
    selected_transiton: "black",
    id: Math.random().toString(36).substr(2, 9),
  },
];
const languageArr = [
  {
    name: "Hi",
    value: "hi",
  },
  {
    name: "En",
    value: "en",
  },
  {
    name: "Ta",
    value: "ta",
  },
];
const aspectRatio = [
  { aspect_ratio: "9:16", value: "9/16" },
  { aspect_ratio: "1:1", value: "1/1" },
  //{ aspect_ratio: "16:9", value: "16/9" },
  { aspect_ratio: "4:3", value: "4/3" },
  //{ aspect_ratio: "21:9", value: "21/9" },
  { aspect_ratio: "4:5", value: "4/5" },
];

const aspectRatioVal = {
  //ratio of aspect-ratio
  "16:9": 1.77,
  "4:3": 1.33,
  "1:1": 1.0,
  "9:16": 0.56,
  "4:5": 0.8,
};

const tagValues = [
  "batsman",
  "bowler",
  "player1",
  "player2",
  "player",
  "fielder",
  "assist",
  "racer",
  "goalKeeper",
  "referee",
  "outcome",
  "shotType",
  "gameTime",
  "heartbeat",
];

const numberFormat = (num) => {
  return num.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const settingsRecordsPerPage = 20;

const cookieExpiry = { expires: 180 };

const storyInputFields = [
  { name: "story_title", heading: "Title", type: "text", placeholder: "", defaultValue: "Test Story" },
  { name: "url", heading: "Video Url", type: "text", placeholder: "", defaultValue: "" },
  { name: "matchLink", heading: "Webstory Redirect Link", type: "text", placeholder: "", defaultValue: "" },
  { name: "publisher", heading: "Publisher", type: "text", placeholder: "", defaultValue: "" },
  { name: "analytics_ids", heading: "Analytics Ids", type: "text", placeholder: "ID_1, ID_2, ID_3", defaultValue: "" },
  { name: "publisherLogoUrl", heading: "Publisher Logo Url", type: "text", placeholder: "", defaultValue: "" },
  { name: "portraitPosterUrl", heading: "Portrait Poster Url", type: "text", placeholder: "", defaultValue: "" },
  { name: "canonicalUrl", heading: "Canonical Url", type: "text", placeholder: "", defaultValue: "" },
  { name: "favicons", heading: "Favicons Url", type: "text", placeholder: "", defaultValue: "" },
  { name: "no_of_pages", heading: "Number Of Pages", type: "number", placeholder: "", defaultValue: "2" },
];
const sonyClientPublishField = [
  {
    name: "description",
    heading: "Description",
    type: "text",
    placeholder: "",
    defaultValue: "description",
    defaultValueFrom: "stream",
  },
  {
    name: "competitionId",
    heading: "Competition Id",
    type: "text",
    placeholder: "",
    defaultValue: "competitionId",
    defaultValueFrom: "stream",
  },
  {
    name: "tags",
    heading: "Tags",
    type: "text",
    placeholder: "",
    defaultValue: "tags",
    defaultValueFrom: "stream",
  },
  {
    name: "seoTitle",
    heading: "SEO Title",
    type: "text",
    placeholder: "",
    defaultValue: "seoObject.seoTitle",
    defaultValueFrom: "stream",
  },
  {
    name: "seoDescription",
    heading: "SEO Description",
    type: "text",
    placeholder: "",
    defaultValue: "seoObject.seoDescription",
    defaultValueFrom: "stream",
  },
];

const shotType = [
  { name: "None", value: "none" },
  { name: "Jump Shot", value: "jump_shot" },
  { name: "Hook Shot", value: "hook_shot" },
  { name: "Bank Shot", value: "bank_shot" },
  { name: "Free Throw", value: "free_throw" },
  { name: "Layup", value: "layup" },
  { name: "Slam Dunk", value: "slam_dunk" },
  { name: "Tip In", value: "tip_in" },
];

const heartbeat = [
  { name: "None", value: "none" },
  { name: "Tip-off", value: "tipoff" },
  { name: "Crowd reaction", value: "crowdreaction" },
  { name: "Winning shot", value: "winningshot" },
  { name: "Buzzer beater", value: "buzzerbeater" },
];

const gameTime = [
  { name: "Quarter 01", value: "quarter01" },
  { name: "Quarter 02", value: "quarter02" },
  { name: "Quarter 03", value: "quarter03" },
  { name: "Quarter 04", value: "quarter04" },
];
const audios = [
  { name: "Mp3", value: "mp3" },
  { name: "Mpeg", value: "mpeg" },
];
const aspectRatioClip = [
  { name: "All", value: "" },
  { name: "16:9", value: "16:9" },
  { name: "9:16", value: "9:16" },
  { name: "4:3", value: "4:3" },
  { name: "1:1", value: "1:1" },
  { name: "4:5", value: "4:5" },
];
const graphics = [
  { name: "Png", value: "png" },
  { name: "Jpg", value: "jpg" },
  { name: "Jpeg", value: "jpeg" },
];
const bumpers = [
  { name: "Mp4", value: "mp4" },
  { name: "Webm", value: "webm" },
];
const playBackSpeed = [
  { value: "0.5", name: "0.5x" },
  { value: "0.75", name: "0.75x" },
  { value: "1", name: "1x" },
  { value: "all", name: "All" },
];
const deviceArray = [
  { value: "649,360", name: "Samsung S22" },
  { value: "786,393", name: "Samsung S23" },
  { value: "670,360", name: "Samsung S21" },
  { value: "722,412", name: "Samsung S10" },
  { value: "612,308", name: "Samsung S8" },
  { value: "718,412", name: "Samsung Galaxy Note 8" },
  { value: "736,432", name: "Google Pixel 3a XL" },
  { value: "727,393", name: "Google Pixel 4" },
  { value: "797,412", name: "Moto G71 5G" },
  { value: "616,360", name: "Moto G7 Play" },
  { value: "713,384", name: "Oneplus 9" },
  { value: "757,412", name: "Oneplus 6T" },
  { value: "736,393", name: "Redmi Note 11" },
  { value: "719,393", name: "Redmi Note 8" },
  { value: "724,384", name: "Vivo Y21" },
  { value: "665,360", name: "Oppo A96" },
  { value: "657,360", name: "Huawei P30" },
  { value: "664,360", name: "Realme 8" },
  { value: "664,390", name: "iPhone 12" },
  { value: "629,375", name: "iPhone 12 mini" },
  { value: "746,428", name: "iPhone 13 Pro Max" },
  { value: "786,393", name: "iPhone 14 Pro Max" },
  { value: "1080,810", name: "iPad 10th" },
  { value: "954,768", name: "iPad 6th" },
  { value: "1010,810", name: "iPad 8th" },
];

const entertainment_fields = [
  { key: "Summary", value: "summary" },
  { key: "Webisode", value: "webisode" },
  { key: "Best Scene", value: "best_scene" },
];

const playbackLabels = [
  {
    title: "0.25x",
    value: "0.25",
  },
  {
    title: "0.5x",
    value: "0.5",
  },
  {
    title: "1x",
    value: "1",
  },
  {
    title: "1.25x",
    value: "1.25",
  },
  {
    title: "1.5x",
    value: "1.50",
  },
  {
    title: "2x",
    value: "2",
  },
];

const allowAspectRatio = [
  { key: "16:9", value: "16:9", allowedRatio: ["16:9"] },
  {
    key: "1:1",
    value: "1:1",
    allowedRatio: ["16:9", "1:1"],
  },
  {
    key: "4:3",
    value: "4:3",
    allowedRatio: ["16:9", "4:3"],
  },
  {
    key: "9:16",
    value: "9:16",
    allowedRatio: ["16:9", "9:16"],
  },
  {
    key: "4:5",
    value: "4:5",
    allowedRatio: ["16:9", "4:5"],
  },
];
const options = [
  { value: -1, label: "NR" },
  { value: 1, label: "1 star" },
  { value: 2, label: "2 star" },
  { value: 3, label: "3 star" },
  { value: 4, label: "4 star" },
  { value: 5, label: "5 star" },
];
const staticDimension = { width: 900, height: 506.25 };
const downloadTemplates = {
  s3Glacier: "https://d7xz21f54epr.cloudfront.net/3tBwdNb9s/csv/export_csv_streamId.csv",
};
const downloadTemplatesYoutube = {
  youTubeVideoCSV: "https://d7xz21f54epr.cloudfront.net/3tBwdNb9s/csv/export_youtube_csv.csv",
};
const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #e8e8e8",
    fontWeight: 500,
    cursor: "pointer",
    color: state.isSelected ? "#fff" : "#192033",
    background: state.isSelected ? "#5036d6" : "#fff",
    padding: "10px 16px",
  }),
  control: () => ({
    border: "1px solid #d9dce1",
    background: "#fff",
    borderRadius: "4px",
    display: "flex",
    flexWrap: "wrap",
    minHeight: 50,
    padding: "0.375rem .5rem",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    justifyContent: "space-between",
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const awsRegions = [
  { label: "US East (N. Virginia)", value: "us-east-1" },
  { label: "US East (Ohio)", value: "us-east-2" },
  { label: "US West (N. California)", value: "us-west-1" },
  { label: "US West (Oregon)", value: "us-west-2" },
  { label: "AWS GovCloud (US)", value: "us-gov-west-1" },
  { label: "AWS GovCloud (US-East)", value: "us-gov-east-1" },
  { label: "Canada (Central)", value: "ca-central-1" },
  { label: "EU (Stockholm)", value: "eu-north-1" },
  { label: "EU (Ireland)", value: "eu-west-1" },
  { label: "EU (London)", value: "eu-west-2" },
  { label: "EU (Paris)", value: "eu-west-3" },
  { label: "EU (Frankfurt)", value: "eu-central-1" },
  { label: "EU (Milan)", value: "eu-south-1" },
  { label: "Africa (Cape Town)", value: "af-south-1" },
  { label: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
  { label: "Asia Pacific (Seoul)", value: "ap-northeast-2" },
  { label: "Asia Pacific (Osaka-Local)", value: "ap-northeast-3" },
  { label: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
  { label: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
  { label: "Asia Pacific (Jakarta)", value: "ap-southeast-3" },
  { label: "Asia Pacific (Hong Kong)", value: "ap-east-1" },
  { label: "Asia Pacific (Mumbai)", value: "ap-south-1" },
  { label: "South America (São Paulo)", value: "sa-east-1" },
  { label: "Middle East (Bahrain)", value: "me-south-1" },
  { label: "China (Beijing)", value: "cn-north-1" },
  { label: "China (Ningxia)", value: "cn-northwest-1" },
];

const mediaLiveInput = [
  { label: "URL PULL", value: "URL_PULL" },
  { label: "SRT PULL", value: "SRT_PULL" },
  { label: "RTMP PULL", value: "RTMP_PULL" },
  { label: "HLS", value: "HLS" },
  { label: "MP4 FILE", value: "MP4_FILE" },
];
const mediaLiveTemplateOption = [
  { value: "direct", label: "Direct" },
  { value: "medialive", label: "Media live" },
  { value: "reserved_channel", label: "Reserved Channel" },
];
const mediaLiveTemplateRegions = [
  { value: "asia", label: "Asia" },
  { value: "north america", label: "North America" },
  { value: "europe", label: "Europe" },
  { value: "south america", label: "South America" },
  { value: "africa", label: "Africa" },
];

const acceptedFilesSize = 400 * 1024 * 1024;

export const clipFeedbackOption = [
  { key: "Early", value: "early" },
  { key: "Late", value: "late" },
  { key: "Correct", value: "correct" },
];
export const ruleRatingConfig = [
  {
    fields: [
      {
        label: "Rule name",
        type: "TEXT",
        name: "ruleName",
        value: "",
        suggestions: [],
      },
      {
        label: "Events",
        type: "SELECT",
        name: "events",
        value: "",
        multiple: true,
        suggestions: ["Four", "Six", "Wicket"],
      },
      {
        label: "Match Type",
        type: "TAB",
        name: "matchType",
        suggestions: ["ODI", "T20", "Test"],
        value: "",
      },
      // {
      //   label: "Four",
      //   type: "subFields",
      //   suggestions: [],
      //   fields: [
      //     {
      //       label: "Over from",
      //       type: "TEXT",
      //       name: "overFrom",
      //       value: "",
      //       suggestions: [],
      //     },
      //     {
      //       label: "Over to",
      //       name: "overTo",
      //       type: "TEXT",
      //       value: "",
      //       suggestions: [],
      //     },
      //     {
      //       label: "Rating",
      //       type: "SELECT",
      //       name: "rating",
      //       value: "",
      //       suggestions: [1, 2, 3, 4, 5],
      //     },
      //   ],
      // },
    ],
  },
];
export {
  allowAspectRatio,
  aspectRatio,
  aspectRatioClip,
  aspectRatioVal,
  aspect_ratio,
  audios,
  autoFlipCategories,
  bumpers,
  clientPermissions,
  clipRating,
  cookieExpiry,
  dashboardFilter,
  dashoboardMenu,
  deviceArray,
  downloadTemplates,
  downloadTemplatesYoutube,
  editFields,
  entertainment_fields,
  frequencyArr,
  ftpChannelLanguage,
  gameTime,
  graphics,
  heartbeat,
  languageArr,
  matchType,
  months,
  numberFormat,
  options,
  platformLimit,
  playBackSpeed,
  playbackLabels,
  playbackList,
  publishElement,
  runObject,
  selectStyles,
  settingsRecordsPerPage,
  shotType,
  socialMediaLabels,
  sonyClientPublishField,
  sportTags,
  staticDimension,
  awsRegions,
  mediaLiveInput,
  mediaLiveTemplateOption,
  mediaLiveTemplateRegions,
  steps,
  storyInputFields,
  tagValues,
  transition,
  acceptedFilesSize,
};
