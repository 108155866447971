import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="currentColor">
      <path d="M24 10H14V0h-4v10H0v4h10v10h4V14h10z"></path>
    </svg>
  );
}

export default Icon;
