import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";
import { notification, Tooltip, Checkbox } from "antd";
import CloseModal from "../../../components/icons/CloseModal";

const GenerateTitle = (props) => {
  const { genTitleModalOpen, setGenTitleModalOpen, clip, GenerateVideoTitle } = props;
  const initialState = {
    isGenerateTitleWithAdditionalFields: false,
  };
  const handleSubmit = async ({ isGenerateTitleWithAdditionalFields }) => {
    let payload = {
      aiSceneId: clip?.aiSceneId,
      id: clip?._id,
      streamId: clip?.streamId,
      url: clip?.videoUrl,
      title_generate_v2: isGenerateTitleWithAdditionalFields,
    };
    const { value } = await GenerateVideoTitle(payload);
    if (value.status) {
      notification["success"]({ message: value.message });
    } else {
      notification["error"]({ message: value.message });
    }
    closeModal();
  };

  const closeModal = () => {
    setGenTitleModalOpen(false);
  };
  const hasRequiredData = (obj) => {
    return ["ballPosition", "batsmanPosition", "shotType", "wicketTerm", "outcome", "player"].some(
      (field) => obj.hasOwnProperty(field) && obj[field],
    );
  };

  return (
    <>
      <Modal
        isOpen={genTitleModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Trim Clip"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Generate Title</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} onSubmit={handleSubmit}>
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container row">
                    <div className="form-group col-md-12">
                      <label className="form-label">
                        <Tooltip
                          placement={"bottomLeft"}
                          title={!hasRequiredData(clip?.clipData || {}) ? "Additional field required" : ""}
                          visible={!hasRequiredData(clip?.clipData || {})}
                        >
                          <Checkbox
                            name="isGenerateTitleWithAdditionalFields"
                            className="align-items-center"
                            onChange={(e) => {
                              setFieldValue("isGenerateTitleWithAdditionalFields", e.target.checked);
                            }}
                            disabled={!hasRequiredData(clip?.clipData || {})}
                          >
                            Generate title with additional fields
                          </Checkbox>
                        </Tooltip>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Generate
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default GenerateTitle;
