import React from "react";
import { useNavigate } from "react-router-dom";

import Back from "../../components/icons/Back";

import "./styles.scss";

export default function CustomerAgreementComponent(props) {
  const navigate = useNavigate();
  return (
    <>
      <main className="global-main pt-2">
        {/* Content body start */}

        <div className="title-container mt-4 mb-3">
          <div className="container-fluid page-rl-p">
            <div className="row align-items-center border-back-container">
              <div className="col-md-8">
                <div className="page-title-items back-icon-block">
                  <h1>
                    <button className="pageBack" onClick={() => navigate(-1)}>
                      <Back />
                    </button>
                    Back
                  </h1>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-title">
                  <h1>Customer Agreement</h1>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="text-content-block">
                  <p>
                    These Terms of Service govern your use of our web pages located at Magnifi (together or individual
                    “Service”).
                  </p>
                  <p>
                    Your agreement with us includes these Terms. You acknowledge that you have read and understood
                    Agreements, and agree to be bound by them.
                  </p>
                  <p>
                    If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but
                    please let us know by emailing at support@magnifi.ai so we can try to find a solution. These Terms
                    apply to all visitors, users and others who wish to access or use our service.
                  </p>
                  <p>Thank you for being responsible.</p>
                  <h4>Communications</h4>
                  <p>
                    By creating an Account on our Service, you agree to subscribe to newsletters, marketing or
                    promotional materials and other information we may send. However, you may opt out of receiving any,
                    or all, of these communications from us by following the unsubscribe link.
                  </p>
                  <h4>SUBSCRIPTION</h4>
                  <p>3.1 PROOF OF CONCEPT TRIAL SUBSCRIPTION</p>
                  <p>
                    Magnifi may, at its sole discretion, offer a Subscription with a PAID PROOF OF CONCEPT trial for a
                    limited period of ONE WEEK.
                  </p>
                  <p>You may be required to enter your billing information in order to sign up for the trial.</p>
                  <p>
                    At any time and without notice, Magnifi reserves the right to (i) modify Terms of Service of Free
                    Trial offer, or (ii) cancel such PAID PROOF OF CONCEPT Trial offer.
                  </p>
                  <h4>3.2 FULL SUBSCRIPTION</h4>
                  <p>Magnifi may, at its sole discretion, offer a Full Subscription for a period of One year.</p>
                  <p>
                    You may be required to enter your billing information in order to sign up for the subscription. At
                    any time and without notice, Magnifi reserves the right to (i) modify Terms of Service of Full
                    Subscription, or (ii) cancel such Full Subscription.
                  </p>
                  <p>Refunds except when required by law, paid Subscription fees are non-refundable.</p>
                  <h4>Content</h4>
                  <p>
                    Our Service allows you to post, link, store, share and otherwise make available certain information,
                    text, graphics, videos, or other material (“Content”). You are responsible for Content that you post
                    on or through Service, including its legality, reliability, and appropriateness.
                  </p>
                  <p>
                    By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you
                    own it) and/or you have the right to use it and the right to grant us the rights and license as
                    provided in these Terms, and (ii) that the posting of your Content on or through Service does not
                    violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any
                    person or entity. We reserve the right to terminate the account of anyone found to be infringing on
                    a copyright.
                  </p>
                  <p>
                    You retain any and all of your rights to any Content you submit, post or display on or through
                    Service and you are responsible for protecting those rights. We take no responsibility and assume no
                    liability for Content you or any third party posts on or through Service.
                  </p>
                  <p>Magnifi has the right but not the obligation to monitor and edit all Content provided by users.</p>
                  <p>
                    In addition, Content found on or through this Service are the property of Magnifi or used with
                    permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said
                    Content, whether in whole or in part, for commercial purposes or for personal gain, without express
                    advance written permission from us.
                  </p>
                  <h4>Prohibited Uses</h4>
                  You may use Service only for lawful purposes and in accordance with Terms. You agree not to use
                  Service:
                  <ul>
                    <li>6.1.1 In any way that violates any applicable national or international law or regulation.</li>
                    <li>
                      6.1.2 For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way
                      by exposing them to inappropriate content or otherwise.
                    </li>
                    <li>
                      6.1.3 To transmit, or procure the sending of, any advertising or promotional material, including
                      any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
                    </li>
                    <li>
                      6.1.4 To impersonate or attempt to impersonate Company, a Company employee, another user, or any
                      other person or entity.
                    </li>
                    <li>
                      6.1.5 In any way that infringes upon the rights of others, or in any way is illegal, threatening,
                      fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful
                      purpose or activity.
                    </li>
                    <li>
                      6.1.6 To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of
                      Service, or which, as determined by us, may harm or offend Company or users of Service or expose
                      them to liability. Additionally, you agree not to:
                      <ul>
                        <li>
                          Use Service in any manner that could disable, overburden, damage, or impair Service or
                          interfere with any other party’s use of Service, including their ability to engage in real
                          time activities through Service.
                        </li>
                        <li>
                          6.2.1 Use any robot, spider, or other automatic device, process, or means to access Service
                          for any purpose, including monitoring or copying any of the material on Service.
                        </li>
                        <li>
                          6.2.2 Use any manual process to monitor or copy any of the material on Service or for any
                          other unauthorized purpose without our prior written consent.
                        </li>
                        <li>
                          6.2.3 Use any device, software, or routine that interferes with the proper working of Service.
                        </li>
                        <li>
                          6.2.4 Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
                          malicious or technologically harmful.
                        </li>
                        <li>
                          6.2.5 Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
                          Service, the server on which Service is stored, or any server, computer, or database connected
                          to Service.
                        </li>
                        <li>
                          6.2.6 Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
                        </li>
                        <li>6.2.7 Take any action that may damage or falsify Company rating.</li>
                        <li>6.2.8 Otherwise attempt to interfere with the proper working of Service.</li>
                      </ul>
                    </li>
                  </ul>
                  <h4>Analytics</h4>
                  <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                  <h4>Intellectual Property</h4>
                  <p>
                    Service and its original content (excluding Content provided by users), features and functionality
                    are and will remain the exclusive property of Magnifi and its licensors. Service is protected by
                    copyright, trademark, and other laws of the United States and foreign countries. Our trademarks and
                    trade dress may not be used in connection with any product or service without the prior written
                    consent of Type in your company legal name.
                  </p>
                  <h4>Copyright Policy</h4>
                  <p>
                    We respect the intellectual property rights of others. It is our policy to respond to any claim that
                    Content posted on Service infringes on the copyright or other intellectual property rights
                    (“Infringement”) of any person or entity. If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been copied in a way that constitutes copyright
                    infringement, please submit your claim via email to Type in the email of your Copyright Agent, with
                    the subject line: “Copyright Infringement” and include in your claim a detailed description of the
                    alleged Infringement.
                  </p>
                  <p>
                    You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation
                    or bad-faith claims on the infringement of any Content found on and/or through Service on your
                    copyright.
                  </p>
                  <h4>Disclaimer Of Warranty</h4>
                  <p>
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
                    SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR
                    USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
                    RISK.
                  </p>
                  <p>
                    NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
                    RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                    SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY
                    REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                    THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                    CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                    WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                    MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT
                    ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </p>
                  <h4>Limitation Of Liability</h4>
                  <p>
                    EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
                    HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
                    (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT
                    TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN
                    ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH
                    THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
                    ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
                    RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE
                    LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE
                    BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                    PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO
                    YOU.
                  </p>
                  <h4>Termination</h4>
                  <p>
                    We may terminate or suspend your account and bar access to Service immediately, without prior notice
                    or liability, under our sole discretion, for any reason whatsoever and without limitation, including
                    but not limited to a breach of Terms. If you wish to terminate your account, you may simply
                    discontinue using Service.
                  </p>
                  <p>
                    All provisions of Terms which by their nature should survive termination shall survive termination,
                    including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations
                    of liability.
                  </p>
                  <h4>Governing Law</h4>
                  <p>
                    Our failure to enforce any right or provision of these Terms will not be considered a waiver of
                    those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
                    remaining provisions of these Terms will remain in effect. These Terms constitute the entire
                    agreement between us regarding our Service and supersede and replace any prior agreements we might
                    have had between us regarding Service.
                  </p>
                  <h4>13. Changes To Service</h4>
                  <p>
                    We reserve the right to withdraw or amend our Service, and any service or material we provide via
                    Service, in our sole discretion without notice. We will not be liable if for any reason all or any
                    part of Service is unavailable at any time or for any period. From time to time, we may restrict
                    access to some parts of Service, or the entire Service, to users, including registered users.
                  </p>
                  <h4>14. Amendments To Terms</h4>
                  <p>
                    We may amend Terms at any time by posting the amended terms on this site. It is your responsibility
                    to review these Terms periodically.
                  </p>
                  Your continued use of the Platform following the posting of revised Terms means that you accept and
                  agree to the changes.{" "}
                  <p>
                    You are expected to check this page frequently so you are aware of any changes, as they are binding
                    on you. By continuing to access or use our Service after any revisions become effective, you agree
                    to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized
                    to use Service.
                  </p>
                  <h4>15. Acknowledgement</h4>
                  <p>
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
                    SERVICE AND AGREE TO BE BOUND BY THEM.
                  </p>
                  <h4>16. Contact Us</h4>
                  Please send your feedback, comments, requests for technical support: By email: support@magnifi.ai
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content body end */}
      </main>
    </>
  );
}
