import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M5.828 25.6c.08 0 .16-.008.24-.02l6.728-1.18a.392.392 0 00.212-.112L29.964 7.332a.399.399 0 000-.564L23.316.116A.396.396 0 0023.032 0a.396.396 0 00-.284.116L5.792 17.072a.406.406 0 00-.112.212L4.5 24.012a1.34 1.34 0 00.376 1.192c.264.256.596.396.952.396zm2.696-6.976L23.032 4.12l2.932 2.932-14.508 14.504-3.556.628.624-3.56zM30.72 28.96H1.28c-.708 0-1.28.572-1.28 1.28v1.44c0 .176.144.32.32.32h31.36a.32.32 0 00.32-.32v-1.44c0-.708-.572-1.28-1.28-1.28z"
    ></path>
  </svg>
);

export default SvgComponent;
