import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { SocketContext, socket, webhookSocket } from "../context/socket";
import "./App.scss";
//components
import CookieContainer from "../components/common/Cookies/container";
import Clips from "../views/Clips/container";
import CookiePolicyComponent from "../views/CookiePolicy/container";
import CustomerAgreementComponent from "../views/CustomerAgreement/container";
import ErrorBoundaryComponent from "../views/ErrorBoundry/ErrorBoundry";
import FTPcomponent from "../views/FTPpublish/container";
import Layout from "../views/Layout/container";
import PrivacyPoliciesComponent from "../views/PrivacyPolicies/container";
import PrivateRoute from "../views/PrivateRoute/container";
import {
  AsyncAddNewVideoEditForm,
  AsyncAddNewVideoUploadingForm,
  AsyncArchiveClipsComponent,
  AsyncArchiveSettingsComponent,
  AsyncArchiveStreamComponent,
  AsyncBulkEditorComponent,
  AsyncClipsNewComponent,
  AsyncCombineRuleInfoComponent,
  AsyncConfigurationComponent,
  AsyncConnectedApps,
  AsyncCopyStreamContainer,
  AsyncCropperComponent,
  AsyncDashboard,
  AsyncDataConsumed,
  AsyncEditSummaryContainer,
  AsyncEditVideoFormContainer,
  AsyncEditorPage,
  AsyncForgotPassword,
  AsyncGroupMappingComponent,
  AsyncGroupMappingDetailComponent,
  AsyncLiveEditComponent,
  AsyncLogin,
  AsyncMyHighlightsComponent,
  AsyncMyStoriesComponent,
  AsyncOpsLogin,
  AsyncProcessTrackingComponent,
  AsyncPublishClipsComponent,
  AsyncQueueSettingComponent,
  AsyncQuickHighlights,
  AsyncReportsComponent,
  AsyncResetPassword,
  AsyncRuleBasedClipsComponent,
  AsyncRuleBasedComponent,
  AsyncRuleBasedFolderComponent,
  AsyncSSOLogin,
  AsyncScoreBoardContainer,
  AsyncSignup,
  AsyncSingleWebstoryEditorComponent,
  AsyncStreamDetailsContainer,
  AsyncThirdPartyContainer,
  AsyncUpdateStorageComponent,
  AsyncUserManagement,
  AsyncUserProfileBasicInfoEditComponent,
  AsyncUserProfileComponent,
  AsyncUserProfileUpdatePasswordEditComponent,
  AsyncVideoGrid,
  AsyncVideoPlayerContainer,
  AsyncVideosInfoNComponent,
  AsyncPublishHistory,
  AsyncInternalStatus,
  AsyncClipRatingRule,
} from "./LazyRoutes";
import Maintenance from "./Maintenance";
import QRHandler from "./QRHandler";
import { addRenderingErrorToDataDogRum } from "../externals/datadog";

const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
  //function that calls Error Boundry Component with specific error
  return (
    <ErrorBoundaryComponent
      error={error}
      componentStack={componentStack}
      isOpen={true}
      resetErrorBoundary={resetErrorBoundary}
    />
  );
};

function App() {
  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return (
      <ErrorBoundary FallbackComponent={OurFallbackComponent} onError={addRenderingErrorToDataDogRum}>
        <Maintenance />
      </ErrorBoundary>
    );
  } else {
    return (
      <ErrorBoundary FallbackComponent={OurFallbackComponent} onError={addRenderingErrorToDataDogRum}>
        <SocketContext.Provider value={{ socket, webhookSocket }}>
          <Router>
            <Routes>
              <Route element={<CookieContainer />}>
                <Route exact path="/" element={<Home />} />
                <Route exact path="*" element={<Navigate to="/dashboard" />} />
                <Route exact path="/sso-login" element={<AsyncSSOLogin />} />
                <Route element={<PrivateRoute />}>
                  <Route exact path="/dashboard" element={<AsyncDashboard />} />
                  <Route exact path="/dashboard-consumption-calculator-list" element={<AsyncDataConsumed />} />
                  <Route exact path="/videos" element={<AsyncVideoGrid />} />
                  <Route exact path="/clips/:id" element={<Clips />} />
                  <Route exact path="/user-management" element={<AsyncUserManagement />} />
                  <Route exact path="/add-video" element={<AsyncAddNewVideoUploadingForm />} />
                  <Route exact path="/archive-streams" element={<AsyncArchiveStreamComponent />} />
                  <Route exact path="/edit-video/:id" element={<AsyncEditVideoFormContainer />} />
                  <Route exact path="/edit-clips/:id" element={<AsyncEditSummaryContainer />} />
                  <Route exact path="/archive-entities/:id" element={<AsyncArchiveClipsComponent />} />
                  <Route exact path="/copy-stream" element={<AsyncCopyStreamContainer />} />
                  <Route exact path="/video-player" element={<AsyncVideoPlayerContainer />} />
                  <Route exact path="/social-media/share" element={<AsyncThirdPartyContainer />} />
                  <Route exact path="/connected-apps" element={<AsyncConnectedApps />} />
                  <Route exact path="/configuration/:active_tab" element={<AsyncConfigurationComponent />} />
                  <Route exact path="/user-profile/:id" element={<AsyncUserProfileComponent />} />
                  <Route exact path="/reports" element={<AsyncReportsComponent />} />
                  <Route exact path="/basic-info-edit/:id" element={<AsyncUserProfileBasicInfoEditComponent />} />
                  <Route exact path="/update-storage/" element={<AsyncUpdateStorageComponent />} />
                  <Route exact path="/highlights/:id" element={<AsyncMyHighlightsComponent />} />
                  <Route exact path="/stories/:id" element={<AsyncMyStoriesComponent />} />
                  <Route exact path="/clip-publish/:id" element={<AsyncPublishClipsComponent />} />
                  <Route exact path="/live-video/:id" element={<AsyncClipsNewComponent />} />
                  <Route exact path="/live-edit/:id" element={<AsyncLiveEditComponent />} />
                  <Route exact path="/group-mapping-key/:mappingKey" element={<AsyncGroupMappingDetailComponent />} />
                  <Route exact path="/editor-page/:id" element={<AsyncEditorPage />} />
                  <Route exact path="/clip-info/:clipID" element={<AsyncVideosInfoNComponent />} />
                  <Route exact path="/bulk-edits" element={<AsyncBulkEditorComponent />} />
                  <Route exact path="/client-archive" element={<AsyncArchiveSettingsComponent />} />
                  <Route exact path="/clip-info/scoreboard/:clipID" element={<AsyncScoreBoardContainer />} />
                  <Route exact path="/single-webstory-editor/:id" element={<AsyncSingleWebstoryEditorComponent />} />
                  <Route exact path="/stream-details/:id" element={<AsyncStreamDetailsContainer />} />
                  <Route exact path="/quick-highlight/" element={<AsyncEditorPage />} />
                  <Route exact path="/quick-highlight/:id" element={<AsyncEditorPage />} />
                  <Route exact path="/my-highlights" element={<AsyncQuickHighlights />} />
                  <Route exact path="/ftp-publish/:id" element={<FTPcomponent />} />
                  <Route exact path="/rule-listing" element={<AsyncRuleBasedComponent />} />
                  <Route exact path="/rule-overview/:id" element={<AsyncRuleBasedFolderComponent />} />
                  <Route exact path="/rule-based-clip/:id" element={<Clips />} />
                  <Route exact path="/combine-rule-info/:id" element={<AsyncCombineRuleInfoComponent />} />
                  <Route exact path="/cropper/:clipId" element={<AsyncCropperComponent />} />
                  <Route exact path="/queue-setting" element={<AsyncQueueSettingComponent />} />
                  <Route exact path="/vod-queue-setting" element={<AsyncQueueSettingComponent />} />
                  <Route exact path="/process-tracking" element={<AsyncProcessTrackingComponent />} />
                  <Route exact path="/add-video/:id" element={<AsyncAddNewVideoEditForm />} />
                  <Route exact path="/publish-history" element={<AsyncPublishHistory />} />
                  <Route exact path="/internal-status" element={<AsyncInternalStatus />} />
                  <Route exact path="/clip-rating-rule" element={<AsyncClipRatingRule />} />
                </Route>
                <Route element={<Layout />}></Route>
                <Route exact path="/login" element={<AsyncLogin />} />
                <Route exact path="/opslogin" element={<AsyncOpsLogin />} />
                <Route exact path="/forgot-password" element={<AsyncForgotPassword />} />
                <Route exact path="/signup" element={<AsyncSignup />} />
                <Route exact path="/reset/:token" element={<AsyncResetPassword />} />
                <Route exact path="/customer-agreement" element={<CustomerAgreementComponent />} />
                <Route exact path="/privacy-policies" element={<PrivacyPoliciesComponent />} />
                <Route exact path="/cookie-policy" element={<CookiePolicyComponent />} />
                <Route
                  exact
                  path="/update-password-edit/:id"
                  element={<AsyncUserProfileUpdatePasswordEditComponent />}
                />
                <Route exact path="/group-mapping/" element={<AsyncGroupMappingComponent />} />
              </Route>
            </Routes>
          </Router>
        </SocketContext.Provider>
      </ErrorBoundary>
    );
  }

  function Home() {
    //get url params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isQRCode = urlParams.get("source");
    const isAuthenticated = localStorage.getItem("authToken");
    //if req comes from qr code
    return isAuthenticated ? (
      <Navigate to="/dashboard" />
    ) : isQRCode == "qrcode" ? (
      <QRHandler />
    ) : (
      <Navigate to="/login" />
    );
  }
}

export default App;
