import axios from "axios";
import FileDownload from "js-file-download";
import { fetch } from "../../utils";
const HostName = process.env.REACT_APP_API_HOSTNAME;
const NewHost = process.env.REACT_APP_NEW_API_HOSTNAME;
const BearerToken = process.env.REACT_APP_THIRD_PATRY_BEARER_TOKEN;

const SocialMediaHostName = process.env.REACT_APP_SOCIAL_MEDIA_HOSTNAME;
const NewHostName = process.env.REACT_APP_HOSTNAME;
export const getVideoGridStates = (data) =>
  fetch(`${NewHost}/streams/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });

export const getSportsDetails = (data) =>
  fetch(`${NewHost}/get_created_sports`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });

export const downloadVideo = (newVideoUrl) => {
  fetch(newVideoUrl, {
    url: newVideoUrl,
    responseType: "blob",
    headers: {
      referer: `https://staging.toch.ai`,
    },
  })
    .then((response) => {
      FileDownload(response.data, `video.mp4`);
    })
    .catch((err) => {});
};

export const getUserDetails = (data) =>
  fetch(`${NewHost}/get_invite_user/${localStorage.getItem("__uid")}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });

export const getUsersDetails = (data) =>
  fetch(`${NewHost}/get_all_users/${localStorage.getItem("__uid")}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });

export const getStreamDetail = (id) => {
  return fetch(`${NewHost}/stream/get/${id}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const getBulkData = (data) => {
  return fetch(`${NewHost}/fetch_bulk_data`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllClips = (
  {
    id,
    limit,
    pageNo,
    filters,
    dateRange,
    sort,
    type,
    search,
    aspectRatio,
    webhookPublish,
    isManualUpload,
    skipCount,
    clipType,
    page,
    isAddedToClipsPage,
  },
  data,
) => {
  return fetch(
    `${NewHost}/get/clips/${id}?limit=${limit}&pageNo=${pageNo}&filters=${JSON.stringify(
      filters,
    )}&daterange=${JSON.stringify(dateRange)}&sort=${JSON.stringify(
      sort,
    )}&clipType=${clipType}&type=${type}&search=${search}&aspectRatio=${aspectRatio}&webhookPublish=${webhookPublish}&isManualUpload=${isManualUpload}&skipCount=${
      skipCount || ""
    }&userId=${localStorage.getItem("__uid")}&page=${page}&isAddedToClipsPage=${isAddedToClipsPage}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authToken"),
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSingleStream = ({ id }) => {
  return fetch(`${NewHost}/streams/detail/${id}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTrimClip = (data) =>
  fetch(`${NewHost}/create_live_video_clips`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      // Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });

export const getUploadPicture = (data) => {
  return fetch(`${NewHost}/upload`, {
    method: "post",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getImages = (data) => {
  return fetch(`${NewHost}/get_image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addAudio = (data) => {
  return fetch(`${NewHost}/add-audio`, {
    method: "post",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const addAudioToFolder = (data) => {
  return fetch(`${NewHost}/upload-audio`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const updatesftp = (data) => {
  return fetch(`${NewHost}/update_sftp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAudio = (data) => {
  return fetch(`${NewHost}/get-audio`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const addShow = (data) => {
  return fetch(`${NewHost}/shows/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getShows = (data) => {
  return fetch(`${NewHost}/shows/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteShows = (data) => {
  return fetch(`${NewHost}/shows/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const editShow = (data) => {
  return fetch(`${NewHost}/shows/edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addImages = (data) =>
  fetch(`${NewHost}/add_image`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });

export const getAddImages = (data) => {
  return fetch(`${NewHost}/image_text_overlay`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const addSlate = (data) => {
  return fetch(`${NewHost}/sony_create_advertise_video`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    //  body: data,
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const uploadSlate = ({ data }) => {
  return fetch(`${NewHost}/images/upload`, {
    method: "post",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const showSlate = (data) => {
  return fetch(`${NewHost}/sony_get_advertisement`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTournaments = (data) =>
  fetch(`${NewHost}/stream/latest`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });

export const getStorage = (data) =>
  fetch(`${NewHost}/accounts/storage/get`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });

export const createStream = async (data) => {
  return fetch(`${NewHost}/streams/create`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTournamentsData = async (data) => {
  return fetch(`${NewHost}/get-tournaments`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCustomData = async ({ userId }) => {
  return fetch(`${NewHost}/accounts/settings/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getBrightCoveToken = async (data) => {
  return fetch(`${NewHost}/access_token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getBrightCoveVideos = async (data) => {
  return fetch(`${NewHost}/getvideo_by_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const UploadClipInBrightCove = async (data) => {
  return fetch(`${NewHost}/publish/video/bright_cove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchFolderListFromBrightCove = async (data) => {
  return fetch(`${NewHost}/folder/list/bright_cove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchVideoUrlListFromBrightCove = async (data) => {
  return fetch(`${NewHost}/folder/url/list/bright_cove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPlayersStreams = async (data) => {
  return fetch(`${NewHost}/get_player_by_streamid`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
//get and search player with respect to event
export const getPlayersByEvents = async ({ event, userId, search, page_no }) => {
  return fetch(
    `${NewHost}/get_players_by_event?&event=${event}&userId=${userId}&search=${
      search === undefined ? "" : search
    }&page_no=${page_no}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPlayers = async (data) => {
  return fetch(`${NewHost}/get/team_by_ids`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const editStream = async (data) => {
  return fetch(`${NewHost}/streams/edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const fancodeEdit = async (data) => {
  return fetch(`${NewHost}/fancode/edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const addSport = async (data) => {
  return fetch(`${NewHost}/create/add_new_spport`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getEventsStreams = async (data) => {
  let url = `${NewHost}/get-clips-filter/${data.streamId}?category=${data.category}&clipType=${
    data.clipType
  }&isManualUpload=${data.isManualUpload}&userId=${localStorage.getItem("__uid")}`;
  if (data.events) {
    url = url + `&events=${data.events}&clipRating=${data.clipRating}`;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    // body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const getEventsByCategory = async (data) => {
  return fetch(`${NewHost}/get_event_by_category`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFolders = ({ search, activePage, ...data }) => {
  return fetch(`${NewHost}/get-all-folders?&search=${search}&page_no=${activePage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getClipsCount = async (
  { id, limit, pageNo, filters, dateRange, sort, type, search, aspectRatio, isManualUpload },
  data,
) => {
  return fetch(
    `${NewHost}/get_clip_count/${id}?limit=${limit}&pageNo=${pageNo}&filters=${JSON.stringify(
      filters,
    )}&daterange=${JSON.stringify(dateRange)}&sort=${JSON.stringify(
      sort,
    )}&type=${type}&search=${search}&aspectRatio=${aspectRatio}&isManualUpload=${isManualUpload}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authToken"),
      },
      body: JSON.stringify(data),
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFolder = (data) => {
  return fetch(`${NewHost}/folder`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getRTMPChannel = (data) => {
  return fetch(`${NewHost}/rtmp/list_channel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateRTMPState = (data) => {
  return fetch(`${NewHost}/rtmp/update_channel_state`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getChannelDetails = (data) => {
  return fetch(`${NewHost}/rtmp/channel_details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updatertmpChannel = (data) => {
  return fetch(`${NewHost}/rtmp/configure_channel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateFolder = ({ _id, ...data }) => {
  return fetch(`${NewHost}/update-folder/${_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateHighlightFolder = ({ _id, ...data }) => {
  return fetch(`${NewHost}/update-highlight-folder/${_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAspectRatios = (data) => {
  return fetch(`${NewHost}/create_video_via_input_crop`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteStreams = (data) => {
  return fetch(`${NewHost}/streams/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteEditedClip = (data) => {
  return fetch(`${NewHost}/sony_clips/delete_edited_clip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteCategory = (data) => {
  return fetch(`${NewHost}/delete/admin_create_sports`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteArchiveStream = (data) => {
  return fetch(`${NewHost}/delete-archive-streams`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteArchiveClips = (data) => {
  return fetch(`${NewHost}/delete-archive-data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getArchiveClips = (data) => {
  return fetch(`${NewHost}/get-archive-data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getArchiveStream = (data) => {
  return fetch(`${NewHost}/get-archive-streams`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getArchiveBulkData = (data) => {
  return fetch(`${NewHost}/archive_bulk_data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getBulkArchiveData = (data) => {
  return fetch(`${NewHost}/fetch_bulk_archive_data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const restoreArchiveStream = (data) => {
  return fetch(`${NewHost}/restore-archive-streams`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const restoreArchivedStream = (data) => {
  return fetch(`${NewHost}/restore-archive-stream`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const restoreArchiveClips = (data) => {
  return fetch(`${NewHost}/restore-archive-data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getCopiedStreamCount = (data) => {
  return fetch(`${NewHost}/get-copied-stream-count/${data.streamId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createHighlight = (data) => {
  return fetch(`${NewHost}/sony_promo_creation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getCustomFields = ({ userId, clientId }) => {
  return fetch(
    clientId ? `${NewHost}/accounts/settings/${userId}?clientId=${clientId}` : `${NewHost}/accounts/settings/${userId}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authToken"),
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteClip = (data) => {
  return fetch(`${NewHost}/sony_clips/delete_by_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getPublishedClips = (data) => {
  return fetch(`${NewHost}/get-published-clips`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createCustomFields = ({ userId, field }, data) => {
  return fetch(`${NewHost}/setting_fields/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify({ field: field }),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteFolder = (id, data) => {
  return fetch(`${NewHost}/delete-folder/${id.id}`, {
    method: "Delete",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteAudio = (id, data) => {
  return fetch(`${NewHost}/delete-audio/${id}`, {
    method: "Delete",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const cropVideo = (data) => {
  return fetch(`${NewHost}/create_video_via_input_crop`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getVideoMetaData = (data) => {
  return fetch(`${NewHost}/get_video_metadata`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteFields = (data) => {
  return fetch(`${NewHost}/delete/custom_fields`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getSportsTeam = (data) => {
  return fetch(`${NewHost}/get/sport_teams`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createTournament = (data) => {
  return fetch(`${NewHost}/create/tournament`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const addEvent = (data) => {
  return fetch(`${NewHost}/create/add_new/event`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createTeam = (data) => {
  return fetch(`${NewHost}/create/team`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadHashtag = (data) => {
  return fetch(`${NewHost}/hashtag`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createPlayer = (data) => {
  return fetch(`${NewHost}/create/add_new/player`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getHashtag = (data) => {
  return fetch(`${NewHost}/get_filter_hashtag`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getHoursConsumed = ({ userId, search, activePage }) => {
  return fetch(`${NewHost}/dash-steams-data-consumed?&userId=${userId}&search=${search}&page_no=${activePage}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteTournamentData = (data) => {
  return fetch(`${NewHost}/delete-tournament`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteKeymoment = (data) => {
  return fetch(`${NewHost}/delete/admin_create_events`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteTeam = (data) => {
  return fetch(`${NewHost}/delete/team`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deletePlayers = (data) => {
  return fetch(`${NewHost}/delete/admin_create_player`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createPreview = (data) => {
  return fetch(`${NewHost}/create-preview`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const clipCreatePreview = (data) => {
  return fetch(`${NewHost}/create-clip-preview`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const s3BucketPublish = (data) => {
  return fetch(`${NewHost}/s3_bucket_publish`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const bulkS3BucketPublish = (data) => {
  return fetch(`${NewHost}/clips/bulk-s3-upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const jsonBucketPublish = (data) => {
  return fetch(`${NewHost}/json/s3_bucket_publish`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const sortHandVideo = (data) => {
  return fetch(`${NewHost}/generate-sorthand-video`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteSlate = (data) => {
  return fetch(`${NewHost}/delete_slate_video`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// Copy single or multiple clips API
export const copyClip = (data) => {
  return fetch(`${NewHost}/clips/copy-clips`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const editClip = (data) => {
  return fetch(`${NewHost}/sony_clips/edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const uploadClip = (data) => {
  return fetch(`${NewHost}/upload_clip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createRTMPChannel = (data) => {
  return fetch(`${NewHost}/rtmp/add_channel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const publishClip = (data) => {
  return fetch(`${NewHost}/sony_clips/publish`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteImages = (data) => {
  return fetch(`${NewHost}/delete_image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const shareOnSocialMedia = (data) => {
  return fetch(`${NewHost}/generate-html`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const cutCropVideo = (data) => {
  return fetch(`${NewHost}/create_trim_crop_clip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const twitterShare = (data) => {
  return fetch(`${SocialMediaHostName}/auth_twitter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const youtubeShare = (data) => {
  return fetch(`${SocialMediaHostName}/upload-youtube-video`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteHashtag = (id) => {
  return fetch(`${NewHost}/hashtag/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(id),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const isValidStream = (data) => {
  return fetch(`${NewHost}/stream/validation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadStream = (data, config) => {
  axios
    .post(`${NewHost}/upload-stream`, data, config)
    .then((res) => {
      return res.data;
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const tagFilters = (data) => {
  return fetch(`${NewHost}/sony_clips/edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const singleClipDetails = (data) => {
  return fetch(`${NewHost}/sony_clips/clip_detail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const saveInningData = (data) => {
  return fetch(`${NewHost}/save_inning_stream_data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateClipDetails = (data) => {
  return fetch(`${NewHost}/sony_clips/update_by_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPublicUrls = (data) => {
  return fetch(`${NewHost}/get/public-url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const cropImage = (data) => {
  return fetch(`${NewHost}/crop_image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const shareOnSocialMedias = (data) => {
  // return fetchUrlThirdParty(`https://app.ayrshare.com/api/post`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${BearerToken}`,
  //   },
  //   body: JSON.stringify(data),
  // })
  //   .then((res) => {
  //     return res.json();
  //   })
  //   .then((payload) => {
  //     return payload;
  //   })
  //   .catch((error) => {
  //
  //     throw error;
  //   });
  return axios({
    method: "post",
    url: "https://app.ayrshare.com/api/post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer 9BJJR2R-VRS4GQK-G16JA95-46W36EE `,
    },
    data: JSON.stringify(data),
  })
    .then(async function (response) {
      return response;
    })
    .catch(function ({ response }) {
      return response;
    });
};

export const addSocialMediaData = (data) => {
  return fetch(`${NewHost}/add-social-media-data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleFolder = ({ id }) => {
  return fetch(`${NewHost}/folder/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getClipsByFolder = ({ search, pageNo, limit, id }) => {
  return fetch(`${NewHost}/folder/${id}?search=${search}&pageNo=${pageNo}&limit=${limit}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const clipListFolders = (data) => {
  return fetch(`${NewHost}/get_folders_by_clipId/${data.id}?search=${data.searchFolder}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createStory = (data) => {
  return fetch(`${NewHost}/create-story`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addStory = (data) => {
  return fetch(`${NewHost}/add-story`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const editWebStory = (data) => {
  return fetch(`${NewHost}/create-webstory`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const rePublishWebStory = (data) => {
  return fetch(`${NewHost}/republish-story`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getStory = (data) => {
  return fetch(`${NewHost}/get-story`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getStoryDetails = (data) => {
  return fetch(`${NewHost}/get-story-details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createServer = (data) => {
  return fetch(`${NewHost}/create_server`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteServer = (data) => {
  return fetch(`${NewHost}/delete-server/${data.userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteRTMPChannel = (data) => {
  return fetch(`${NewHost}/rtmp/delete_channel/${data}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllInviteUser = ({ userId }) => {
  return fetch(`${NewHost}/get_all_invite_user/${userId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getServerDetails = (data) => {
  return fetch(`${NewHost}/get-server`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getSportsValue = (data) => {
  return fetch(`${NewHost}/get/all_sport_value`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const mapStreams = (data) => {
  return fetch(`${NewHost}/group_streams/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateCustomField = ({ data: { field, userId } }) => {
  return fetch(`${NewHost}/update-setting/${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(field),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const gropuMappingStreams = ({ mappingKey }) => {
  return fetch(`${NewHost}/stream/get/${mappingKey}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const gropuMappingClips = ({ mappingKey, ...data }) => {
  return fetch(`${NewHost}/sony_clips/get_groupclips/${mappingKey}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFoldersById = ({
  limit,
  page_no,
  type,
  search,
  sortBy,
  aspectRatio,
  folderRating,
  category,
  tournaments,
}) => {
  return fetch(
    `${NewHost}/get_folders_by_userId?limit=${limit}&page_no=${page_no}&type=${type}&search=${search}&sortBy=${sortBy}&aspectRatio=${aspectRatio}&folderRating=${folderRating}&category=${category}&tournaments=${tournaments}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authToken"),
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentById = (data) => {
  return fetch(`${NewHost}/get-tournaments-by-id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGroupedStreams = ({ mappingKey }) => {
  return fetch(`${NewHost}/group_streams/detail/${mappingKey}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const generateAutoFlip = (data) => {
  return fetch(`${NewHost}/create_autoflip_video`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateTournament = (data) => {
  return fetch(`${NewHost}/update_tournament`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const streamExportJson = ({ id }) => {
  return fetch(`${NewHost}/export-publish-clips/${id}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
    //body: JSON.stringify(id),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const exportEventsJson = ({ id }) => {
  return fetch(`${NewHost}/export_events_json/${id}`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const exportStreamReport = (data) => {
  return fetch(`${NewHost}/stream/export/report`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const clipExportJson = (data) => {
  return fetch(`${NewHost}/export_clip_json`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const genrateNewClip = (data) => {
  return fetch(`${NewHost}/generate/clip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBulkTournament = (data) => {
  return fetch(`${NewHost}/add_bulk_tournaments`, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBulkPlayer = (data) => {
  return fetch(`${NewHost}/add_bulk_players`, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBulkEvent = (data) => {
  return fetch(`${NewHost}/add_bulk_events`, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBulkTeam = (data) => {
  return fetch(`${NewHost}/add_bulk_teams`, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCropperVideoMetaData = (data) => {
  return fetch(`${NewHost}/get_video_metadata`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getVideoAspectRatio = (data) => {
  return fetch(`${NewHostName}/get_video_metadata`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const bucketCopy = (data) => {
  return fetch(`${NewHost}/bucket_to_bucket_copy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const dumpOnS3 = (data) => {
  return fetch(`${NewHost}/dump_on_s3`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const copyClipToReviewer = (data) => {
  return fetch(`${NewHost}/copy_clip_to_reviewer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const downloadClips = (data) => {
  return axios({
    url: data,
    method: "GET",
    responseType: "blob",
    headers: {
      referer: `https://staging.toch.ai`,
    },
  })
    .then((response) => {
      FileDownload(response.data, `video.mp4`);
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const downloadAllClips = (data) => {
  return fetch(`${NewHost}/get-remote-file-size`, {
    url: data,
    method: "POST",
    //responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const downloadAsZip = (data) => {
  return fetch(`${NewHost}/create-zip-url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
// Api function for Audio FadeIn/Out
export const audiofadeInOut = (data) => {
  return fetch(`${NewHost}/apply/audio_transition`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getClipSizeByUrl = (data) => {
  return fetch(`${NewHost}/get-remote-file-size`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const streamLogs = (data) => {
  return fetch(`${NewHost}/get/logger-by-type`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fileUpload = ({ data }) => {
  return fetch(`${NewHost}/file-upload`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const uploadFileChunks = (data) => {
  return fetch(`${NewHost}/upload-chunck`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const getTimeLineDuration = (data) => {
  return fetch(`${NewHost}/timeline/duration`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const addThumbnail = (data) => {
  return fetch(`${NewHost}/clips/generate-thumbnail-from-video`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const GenerateVideoTitle = (data) => {
  return fetch(`${NewHost}/clips/generate-ai-title`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

// file: (binary)
// filename: UEFA_SuperCup2022V2ByDeepak2.zip
// userId: 3tBwdNb9s
export const uploadStoryTemplate = (data) => {
  return fetch(`${NewHost}/upload-story-template`, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
// {
//   "status": 2
// }
export const getStoryTemplates = (data) => {
  return fetch(`${NewHost}/get-story-templates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
// {
//   "_id": "6246e3179b8acd4f33f2c5ed",
//   "url": "https://d1lw40p9dgniy0.cloudfront.net/vendor/video/mobile/video/v_video/1646660465_5258732/1646660465_5258732.mp4",
//   "category": "cricket"
// }
export const testStoryTemplate = (data) => {
  return fetch(`${NewHost}/test-story-template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
// {
//   "_id": "625d64ac790ec5eb1db3bec1",
//   "title": "test"
// }
export const moveStoryTemplate = (data) => {
  return fetch(`${NewHost}/move-story-template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
// {
//   "_id": "625d64ac790ec5eb1db3bec1"
// }
export const deleteStoryTemplate = (data) => {
  return fetch(`${NewHost}/delete-story-template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCategoryConfiguration = (data) => {
  return fetch(`${NewHost}/category-configuration/${data.category}`, {
    method: "GET",
    headers: {
      // "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const updateCategoryConfiguration = (data) => {
  return fetch(`${NewHost}/update-category-configuration`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const copyStream = (data) => {
  //Api for Copy the Stream
  return fetch(`${NewHost}/copy_streams`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const applyLogoOnThumbnail = (data) => {
  //Api for adding logo on thumbnail
  return fetch(`${NewHost}/clips/apply_logo`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const overWriteClip = (data) => {
  //Api for overwriting clip with generated preview
  return fetch(`${NewHost}/overwrite-clip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const trimandChangeSpeedClip = (data) => {
  //Api for Trim and Chnage Speed of Clip
  return fetch(`${NewHost}/live_video_clips`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const addTenant = (data) => {
  return fetch(`${NewHost}/client/create`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTenant = () => {
  return fetch(`${NewHost}/client/get`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteTenant = (data) => {
  return fetch(`${NewHost}/client/delete`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const updateTenant = (data) => {
  return fetch(`${NewHost}/client/update`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const ftpPublish = (data) => {
  return fetch(`${NewHost}/publish_to_ftp`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const getftplist = (data) => {
  return fetch(`${NewHost}/list_ftp`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const getftpPublishedClips = (data) => {
  return fetch(`${NewHost}/list_ftp_publish/${data.id}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};
export const getClipsData = (data) => {
  //Api for get Clips data by ids
  return fetch(`${NewHost}/get_clip_data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      throw err;
    });
};

//export clips XML file
export const clipExportXml = async ({ id }) => {
  return fetch(`${NewHost}/export_clip_xml/${id}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
    //body: JSON.stringify(id),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getPreAssignedUrl = (data) => {
  return fetch(`${NewHost}/presigned_url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getContentType = (data) => {
  return fetch(`${NewHost}/get-mime-type`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getS3PresignedURl = (data) => {
  return fetch(`${NewHost}/get-presigned-url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const createS3PresignedURl = (data) => {
  return fetch(`${NewHost}/create-presigned-url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getQueueSetting = (data) => {
  return fetch(`${NewHost}/get_firestore_config/${data}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateQueueSetting = (data) => {
  return fetch(`${NewHost}/update_firestore_config`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTrimClipV2 = (data) => {
  return fetch(`${NewHost}/create_live_video_clips_v2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAddRuleData = (data) => {
  return fetch(`${NewHost}/add_rule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const editRulesData = (data) => {
  return fetch(`${NewHost}/edit_rule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getRuleBaseData = (data) => {
  return fetch(`${NewHost}/get_rule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getRulesByCategory = (data) => {
  return fetch(`${NewHost}/get/rulesbycatagory`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRulesByStreamId = (data) => {
  return fetch(`${NewHost}/get/rulesByStreamId`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRuleById = (data) => {
  return fetch(`${NewHost}/get_rule_by_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteRuleBaseData = (data) => {
  return fetch(`${NewHost}/delete_rule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const RuleTriggerPostGame = (data) => {
  return fetch(`${NewHost}/trigger/post_game_rule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const reProcessVideo = (data) => {
  return fetch(`${NewHost}/reprocess-video`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCombineFoldersByRule = (data) => {
  return fetch(`${NewHost}/rules/get_folders_by_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getStreamCountByFilter = (data) => {
  return fetch(`${NewHost}/stream/filter-count`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getClipsByFilter = (data) => {
  return fetch(`${NewHost}/clips/list_by_filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const initialteRequestForHLSToMP4 = (data) => {
  return fetch(`${NewHost}/initiate_request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getHLSToMP4 = ({ streamId }) => {
  return fetch(`${NewHost}/get_hls_to_mp4/${streamId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getMediaTemplate = (id) => {
  return fetch(`${NewHost}/medialive_output_template/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteHlsToMp4 = ({ streamId }) => {
  return fetch(`${NewHost}/delete_hls_to_mp4_data/${streamId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateVideoData = (data) => {
  return fetch(`${NewHost}/stream/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getInternalStatus = (data) => {
  return fetch(`${NewHost}/api/v1/zee/message/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const exportStreamCsv = (data) => {
  return fetch(`${NewHost}/streams/exports_stream_csv`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createReservedChannle = (data) => {
  return fetch(`${NewHost}/reserved/add_channel  `, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const listReservedChannel = (data) => {
  return fetch(`${NewHost}/reserved/list_channel  `, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteReservedChannel = (data) => {
  return fetch(`${NewHost}/reserved/delete_channel/${data}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteMediaTemplate = (id) => {
  return fetch(`${NewHost}/delete_medialive_output_template/${id}`, {
    method: "Delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCsvData = (data) => {
  return fetch(`${NewHost}/streams/download_csv_data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addMediaTemplate = (data) => {
  return fetch(`${NewHost}/create_medialive_output_template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const restartServer = (data) => {
  return fetch(`${NewHost}/api/v1/zee/restart-ai-process`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const editMediaTemplate = (data) => {
  return fetch(`${NewHost}/edit_medialive_output_template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDownloadStatus = (data) => {
  return fetch(`${NewHost}/getDownloadStatus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkAutoAssignRating = (data) => {
  return fetch(`${NewHost}/check-rating`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllRatingRules = (data) => {
  return fetch(`${NewHost}/get-all-rating-rule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("id_token"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const generateUserPermissionsReport = (data) => {
  return fetch(`${NewHost}/user/permissions-report`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const advanceEditorIllusto = (data) => {
  return fetch(`${NewHost}/advanceEditorIllusto`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getVerticalRequestDetails = (data) => {
  return fetch(`${NewHost}/get-queue-states`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const submitSecretValue = (data) => {
  return fetch(`${NewHost}/process_srt_encryption`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const fetchSecretValues = (data) => {
  return fetch(`${NewHost}/get_all_srt_encryption?page_no=${data.page_no}&limit=${data.limit}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const deleteSecretValue = (data) => {
  return fetch(`${NewHost}/process_srt_encryption`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
