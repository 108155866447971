import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" viewBox="0 0 34 34" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.5 17C.5 7.887 7.887.5 17 .5S33.5 7.887 33.5 17 26.113 33.5 17 33.5.5 26.113.5 17zM17 3.5C9.544 3.5 3.5 9.544 3.5 17S9.544 30.5 17 30.5 30.5 24.456 30.5 17 24.456 3.5 17 3.5zm-1.06 7.984a1.5 1.5 0 012.56 1.061V15.5h2.955a1.5 1.5 0 110 3H18.5v2.955a1.5 1.5 0 11-3 0V18.5h-2.955a1.5 1.5 0 110-3H15.5v-2.955c0-.398.158-.78.44-1.06z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
