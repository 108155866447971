import * as React from "react";

const SvgComponent = (props) => (
  <svg width={14} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.001 12.333a5.833 5.833 0 1 0 0-11.666 5.833 5.833 0 0 0 0 11.666Z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 8.833 7.342 7.425A1.167 1.167 0 0 1 7 6.6V3"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
