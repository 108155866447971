import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { Spin, Select, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { copyClipToChildValidation } from "../../../utils/validations";
import CloseModal from "../../../components/icons/CloseModal";
import ReactSelect from "react-select";
// const { Option } = Select;
const CopyClip = (props) => {
  const {
    copyToChildModalOpen,
    setCopyToChildModalOpen,
    clip,
    fetchUserDetails,
    copyClipToReviewer,
    streamDetails,
    allUsers,
  } = props;
  const [initialState] = useState({
    reviewersId: "",
  });
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchedUser, setSearchedUser] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [count, setCount] = useState(0);
  const firstRef = useRef();
  const handleSubmit = async ({ reviewersId }) => {
    const { value } = await copyClipToReviewer({ reviewers: reviewersId, streamId: clip.streamId, clipId: clip.id });
    value?.success
      ? notification["success"]({ message: value?.message })
      : notification["error"]({ message: value?.message });
    closeModal();
  };

  const closeModal = () => {
    setCopyToChildModalOpen(false);
  };
  const getUserDetails = async () => {
    const { value } = await fetchUserDetails({ search: searchedUser });
    const users = (value?.users || []).filter((user) => {
      return (streamDetails?.reviewersId || []).includes(user.userId);
    });
    setUsers(users);
  };

  useEffect(() => {
    if (copyToChildModalOpen) {
      getUserDetails();
    }
  }, [copyToChildModalOpen, streamDetails]);

  const handleSearch = (e, setFieldValue) => {
    setSelectedUser(e);
    let userIds = e.map((val) => val.value);
    setFieldValue("reviewersId", userIds);
  };

  useEffect(() => {
    let option = [];
    users &&
      users.forEach((user) => {
        option.push({ value: user.userId, label: user.name });
      });
    setOptions(option);
  }, [users]);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
    } else {
      const timer = setTimeout(() => {
        setCount(count + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedUser]);

  useEffect(() => {
    getUserDetails();
  }, [count, selectedUser]);

  return (
    <>
      <Modal
        isOpen={copyToChildModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Copy to Child</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={copyClipToChildValidation} onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container">
                    <div className="form-group">
                      <label className="form-label">
                        Reviewers <span className="required">*</span>
                      </label>
                      <ReactSelect
                        className="custom-ant-select"
                        name="reviewersId"
                        placeholder="Select Reviewers"
                        onChange={(value) => handleSearch(value, setFieldValue)}
                        value={selectedUser}
                        component={Select}
                        dropdownStyle={{ zIndex: 99999999 }}
                        mode="multiple"
                        isMulti={true}
                        defaultValue={[]}
                        options={options}
                        onInputChange={(e) => setSearchedUser(e)}
                      />
                      {/* {users &&
                          users.map((user) => {
                            return (
                              <>
                                <Option value={user.name} key={user.userId}>
                                  {user.name}{" "}
                                </Option>
                              </>
                            );
                          })}
                      </Field> */}
                      {touched.reviewersId && errors.reviewersId && (
                        <div className="invalid-feedback">{errors.reviewersId}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Submit
                    {/* {loder && (
                      <Spin
                        size="large"
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: "#000", marginLeft: 50 }} spin />}
                        className="loader__full"
                      ></Spin>
                    )} */}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default CopyClip;
