import * as React from "react";

const SvgComponent = (props) => (
  <svg width={21} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.315 1.96a8.348 8.348 0 0 1-2.398.645A4.152 4.152 0 0 0 19.748.31a8.219 8.219 0 0 1-2.641 1.006A4.152 4.152 0 0 0 14.065 0a4.162 4.162 0 0 0-4.163 4.165c0 .322.04.645.103.955A11.83 11.83 0 0 1 1.42.762a4.134 4.134 0 0 0-.567 2.102c0 1.445.735 2.72 1.856 3.469a4.2 4.2 0 0 1-1.882-.53v.051a4.164 4.164 0 0 0 3.337 4.087c-.358.093-.726.141-1.096.142-.271 0-.527-.027-.786-.063a4.17 4.17 0 0 0 3.891 2.888 8.344 8.344 0 0 1-5.168 1.78c-.35 0-.672-.013-1.006-.052a11.788 11.788 0 0 0 6.394 1.868c7.656 0 11.846-6.343 11.846-11.848 0-.18 0-.362-.012-.542a8.965 8.965 0 0 0 2.087-2.154Z"
      fill="#282828"
    />
  </svg>
);

export default SvgComponent;
