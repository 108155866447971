import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../../../components/icons/CloseModal";
import { frequencyArr, options } from "../../../config/index";

const ViewRule = (props) => {
  const { viewModel, setViewModel, viewRuleData, sports } = props;

  const [initialState, setInitialState] = useState({
    title: "",
    game: "",
    team: [],
    eventConditionAnd: [],
    playerConditionAnd: [],
    tournament: "",
    frequency: "5 Min",
    rating: [],
  });

  useEffect(() => {
    if (viewModel) {
      //Get Rating Inthe Form Of Star
      const correspondingRating = options
        .filter((option) =>
          viewRuleData?.filters
            ?.flatMap((data) => {
              if (data?.condition === "$in" && data?.field === "clipRatings") {
                return data?.value || [];
              }
              return [];
            })
            ?.includes(option.value),
        )
        .map((option) => option.label);
      const sportsFilter =
        viewRuleData?.sportName && sports?.filter(({ value }) => value == viewRuleData?.sportName)[0];
      //Get Corresponding Frequency
      const correspondingFrequencyArr = frequencyArr?.filter((frequence) => frequence.value == viewRuleData?.frequency);
      const getConditionValues = (condition, field) => {
        const valueData = viewRuleData?.filters?.map((data) => {
          return data?.condition === condition && data?.field === field ? data?.value || [] : null;
        });
        return valueData.filter((data) => data !== null);
      };
      setInitialState({
        title: viewRuleData.title,
        game: sportsFilter?.title,
        eventConditionAnd: getConditionValues("$and", "events"),
        team: viewRuleData?.teams?.map((value) => value?.name),
        playerConditionAnd: getConditionValues("$and", "players"),
        tournament: viewRuleData?.tournament,
        frequency: correspondingFrequencyArr[0]?.name,
        rating: correspondingRating,
      });
    }
  }, [viewRuleData, viewModel, sports]);
  const editRuleModelClose = () => {
    setViewModel(false);
  };

  return (
    <div>
      <Modal
        isOpen={viewModel}
        onRequestClose={editRuleModelClose}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">View Rule</h5>
            <button type="button" onClick={editRuleModelClose} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} enableReinitialize>
            {({ values }) => (
              <div className="modal-content">
                <Form>
                  <div className="react-modal-body">
                    <div className="add-form-contianer">
                      <div className="three-column-row">
                        <div className="form-group">
                          <label className="form-label">Rule Name</label>
                          <Field
                            value={values?.title}
                            type="text"
                            placeholder="Rule Name"
                            className="form-control"
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Category</label>
                          <Field
                            value={values?.game}
                            type="text"
                            placeholder="Category"
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="three-column-row">
                        <div className="form-group">
                          <label className="form-label">Tournament</label>
                          <Field
                            value={values.tournament}
                            type="text"
                            placeholder="Tournament"
                            className="form-control"
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Teams</label>
                          <Field
                            value={values?.team}
                            type="text"
                            placeholder="Team"
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="three-column-row">
                        <div className="form-group">
                          <label className="form-label">Players And</label>
                          <div className="players-and">
                            {values.playerConditionAnd.map((value, index) => (
                              <Field
                                value={values?.playerConditionAnd[index]}
                                type="text"
                                placeholder="-"
                                className="form-control"
                                disabled
                              />
                            ))}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Events And</label>
                          <div className="players-and">
                            {values.eventConditionAnd.map((value, index) => (
                              <Field
                                value={values?.eventConditionAnd[index]}
                                type="text"
                                placeholder="-"
                                className="form-control"
                                disabled
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="three-column-row">
                        <div className="form-group">
                          <label className="form-label">Rating</label>
                          <Field value={values?.rating} type="text" placeholder="-" className="form-control" disabled />
                        </div>
                        <div className="form-group"></div>
                      </div>
                    </div>
                  </div>

                  <div className="react-modal-footer">
                    <div className="react-foo-container">
                      <div className="right-btn-block text-md-end text-center">
                        <button type="button" className="btn btn-white" onClick={editRuleModelClose}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ViewRule;
