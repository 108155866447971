import * as React from "react";

const SvgComponent = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 6.875A3.13 3.13 0 0 0 6.874 10a3.13 3.13 0 0 0 3.124 3.124A3.13 3.13 0 0 0 13.123 10 3.13 3.13 0 0 0 10 6.875ZM19.37 10c0-1.294.011-2.576-.062-3.868-.072-1.5-.414-2.83-1.511-3.928-1.1-1.099-2.428-1.439-3.928-1.512-1.294-.072-2.576-.06-3.867-.06-1.294 0-2.576-.012-3.868.06-1.5.073-2.831.415-3.928 1.512C1.107 3.304.767 4.632.694 6.132.622 7.426.634 8.708.634 10c0 1.29-.012 2.575.06 3.867.073 1.5.415 2.831 1.512 3.928 1.1 1.099 2.428 1.439 3.928 1.511 1.294.073 2.576.061 3.867.061 1.294 0 2.576.012 3.868-.06 1.5-.073 2.83-.415 3.928-1.512 1.099-1.1 1.439-2.428 1.511-3.928.076-1.292.061-2.574.061-3.867ZM10 14.807A4.8 4.8 0 0 1 5.191 10 4.8 4.8 0 0 1 10 5.192 4.8 4.8 0 0 1 14.806 10 4.8 4.8 0 0 1 10 14.807Zm5.003-8.689a1.121 1.121 0 0 1-1.123-1.122c0-.621.502-1.123 1.123-1.123a1.121 1.121 0 0 1 .794 1.917 1.121 1.121 0 0 1-.794.328Z"
      fill="#282828"
    />
  </svg>
);

export default SvgComponent;
