import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" fill="none" viewBox="0 0 18 11" {...props}>
    <path
      fill="currentColor"
      d="M6 9.75A.75.75 0 016.75 9h4.5a.75.75 0 110 1.5h-4.5A.75.75 0 016 9.75zm-3-4.5a.75.75 0 01.75-.75h10.5a.75.75 0 110 1.5H3.75A.75.75 0 013 5.25zM0 .75A.75.75 0 01.75 0h16.5a.75.75 0 110 1.5H.75A.75.75 0 010 .75z"
    ></path>
  </svg>
);

export default SvgComponent;
