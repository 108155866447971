import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" fill="none" viewBox="0 0 13 20" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.787 16.101v-9.88c0-.655.487-1.207 1.137-1.288l2.917-.365a1.298 1.298 0 001.136-1.287V1.298c0-.76-.65-1.358-1.407-1.293l-3.78.32a2.596 2.596 0 00-2.375 2.67l.312 9.676a3.893 3.893 0 102.059 3.535v-.094-.005-.006z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
