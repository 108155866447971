import React, { useState } from "react";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import CloseModal from "../../../components/icons/CloseModal";
import Dropzone from "react-dropzone";
import { notification, Spin } from "antd";
import { UploadDropzone } from "../../../components/icons";
import { ASPECT_RATIO } from "../../../constants/phase";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24, align: "center" }} spin />;

const UploadCSVModal = (props) => {
  const {
    isUploadThumbnailModalOpen,
    setIsUploadThumbnailModalOpen,
    updateClipDetails,
    clip,
    setThumbModalOpen,
    setUploadedThumbnails,
    uploadedThumbnails,
    filters,
  } = props;

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [loader, setLoader] = useState(false);

  const closeUploadThumbnailModal = () => {
    setIsUploadThumbnailModalOpen(false);
    setThumbModalOpen(true);
  };
  const onChange = async (e) => {
    const files = e;

    for (let i = 0; i < files.length; i++) {
      var file = files[i];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = async () => {
            const width = img.width;
            const height = img.height;
            const ratio = (width / height).toFixed(2);
            const ratioString = filters?.aspectRatio || ASPECT_RATIO["16_9"];
            const [numerator, denominator] = ratioString.split(":").map(Number);
            const isRatioToCheck = ratio == (numerator / denominator).toFixed(2);
            if (!isRatioToCheck) {
              notification.error({ message: "Please upload a thumbnail that adheres to the selected aspect ratio." });
              return; // Skip file upload
            }

            // Continue with file upload
            const data = new FormData();
            data.append("file", file);
            data.append("filename", file.name);
            data.append("clipId", clip._id);
            data.append("streamId", clip.streamId);
            let type = file?.type.split("/")[0];
            let title = file.name;
            await getUploadPicture(data, type, title, ratio);
          };
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const getUploadPicture = async (data, type, title, ratio) => {
    setLoader(true);
    if (type !== "image") {
      notification["warning"]({ message: "Wrong file uploaded!" });
    } else {
      const { getUploadPicture } = props;
      const { value } = await getUploadPicture(data);
      if (value.status) {
        const response = await updateClipDetails({
          id: clip._id,
          thumbnail: value.data,
          aspectRatio: filters?.aspectRatio || ASPECT_RATIO["16_9"],
        });
        if (response?.value?.success) {
          setUploadedThumbnails([...uploadedThumbnails, value.data]);
          setThumbModalOpen(true);
          notification["success"]({ message: "File uploaded!" });
        } else {
          notification["error"]({ message: "Error in file uploading!" });
          setLoader(false);
        }
      } else {
        notification["error"]({ message: "Error in file uploading!" });
      }
      setIsUploadThumbnailModalOpen(false);
    }
    setLoader(false);
  };

  return (
    <Modal
      isOpen={isUploadThumbnailModalOpen}
      onRequestClose={closeUploadThumbnailModal}
      shouldCloseOnOverlayClick={false}
      contentLabel="Filter Modal"
      portalClassName="react-modal"
      overlayClassName="modal"
      className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
    >
      <Formik enableReinitialize>
        {({ errors, touched, setFieldValue, values }) => (
          <div className="modal-content">
            <Form>
              <div className="react-modal-header">
                <h5 className="react-modal-title">Upload Thumbnail</h5>
                <button type="button" onClick={closeUploadThumbnailModal} className="btn btn__default">
                  <CloseModal />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="form-container">
                  <Dropzone
                    accept="image/*"
                    multiple={false}
                    onDrop={(acceptedFiles) => {
                      onChange(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div className="dropzone-inner" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dropzone-icon">
                            <UploadDropzone />
                          </div>
                          {loader ? <Spin indicator={antIcon}></Spin> : ""}
                          <div className="dropzone-title">Upload a File</div>
                          <div className="dropzone-subtitle">
                            Click to <u>browse</u>, or drag & drop your file here
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default UploadCSVModal;
