import { Checkbox, Collapse, Radio, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import CloseModal from "../../../components/icons/CloseModal";
import FilterIcon from "../../../components/icons/FilterIcon";
import Highlights from "../../../components/icons/Highlights";
import History from "../../../components/icons/History";
import LiveTool from "../../../components/icons/LiveTool";
import {
  aspectRatioClip,
  clipRating,
  gameTime,
  heartbeat,
  playbackList,
  publishElement,
  shotType,
} from "../../../config/index";

import { StarFilled } from "@ant-design/icons";

import ManageAccess from "../ManageAccess/manageAccess";
import "./styles.scss";
import ShowCSV from "./partials/ShowCsvModal";
import { STREAM_STATUS, ZEE_CLIENT } from "../../../constants/phase";

const ClipsFilterComponent = (props) => {
  const {
    id,
    events,
    players,
    customTags,
    setFinalFilters,
    finalFilters,
    setIsShow,
    setSearch,
    search,
    streamDetails,
    clipsDetails,
    setSearchParams,
    updatedSearchParams,
    AccessControl,
    setIsManualUpload,
    isManualUpload,
    getEventStream,
    firstRef,
    getSingleStream,
    getCsvData,
  } = props;
  const { clientSetting } = useOutletContext();
  const { Panel } = Collapse;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchPlayer, setSearchPlayer] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [scrollTop, scrollProps] = useScrollTop();
  const [eventDisable, seteventDisable] = useState(true);
  const [shotTypeDisable, setShotTypeDisable] = useState(true);
  const [heartbeatDisable, setheartbeatDisable] = useState(true);
  const [gameTimeDisable, setgameTimeDisable] = useState(true);
  const [playerDisable, setPlayerDisable] = useState(true);
  const [applyFilter, setApplyFilter] = useState(0);
  const [clipData, setClipData] = useState({});
  const [eventList, setEventList] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [customTagsList, setCustomTagsList] = useState(customTags);
  const [manualClip, setManualClip] = useState(false);
  const [stream, setStream] = useState({});
  const [isShowCsv, setIsShowCsv] = useState(false);
  const [isIllustoClip, setIsIllustoClip] = useState(false);
  const [filters, setFilters] = useState({
    players: [],
    events: [],
    sortBy: "TIME_DESCENDANT",
    aspectRatio: "",
    playBackSpeed: [],
    webhookPublish: "",
    clipData: {},
    clipRating: [],
    isIllustoClip: false,
  });

  useEffect(() => {
    if (events) {
      searchEventFun(searchEvent);
    }
    if (players) {
      setPlayerList(players);
    }
  }, [events, players]);

  useEffect(() => {
    if (customTags) {
      setCustomTagsList(customTags);
    }
  }, [(customTags || []).toString(), modalIsOpen]);

  const onChangeCustomEvents = (key, eventName, e) => {
    const modifiedData = customTagsList?.map((item) => ({
      ...item,
      events:
        item.key === key
          ? item.events.map((event) => ({
              ...event,
              checked: event.event === eventName ? e.target.checked : event.checked,
            }))
          : item.events,
    }));
    setCustomTagsList(modifiedData);
  };

  const searchCustomEvents = (key, searchValue) => {
    const regex = new RegExp(`${searchValue}`, "i"); // Regex pattern for case-insensitive matching
    const modifiedData =
      customTagsList?.map((item) => ({
        ...item,
        events:
          item.key === key
            ? item.events.map((event) => ({
                ...event,
                searchable: regex.test(event.event),
              }))
            : item.events,
      })) ?? [];
    setCustomTagsList(modifiedData);
  };

  const resetCustomEvents = (key) => {
    const modifiedData = customTagsList?.map((item) => ({
      ...item,
      events:
        item.key === key
          ? item.events.map((event) => ({
              ...event,
              checked: false,
            }))
          : item.events,
    }));
    setCustomTagsList(modifiedData);
  };

  // Function to remove fields with blank arrays
  const removeBlankFields = (data) => {
    const newData = { ...data };
    for (const key in newData) {
      if (newData[key]?.length === 0) {
        delete newData[key];
      }
    }
    return newData;
  };

  const filerClips = () => {
    let filter = filters;
    const updatedClipData = removeBlankFields(clipData);
    filter.clipData = updatedClipData;
    setIsManualUpload(manualClip);
    setFinalFilters(filter);
    setApplyFilter((previous) => previous + 1);
    setSearchPlayer("");
    setSearchEvent("");
  };

  useEffect(() => {
    setManualClip(isManualUpload);
  }, [isManualUpload]);

  // const { id } = useParams();
  let navigate = useNavigate();

  const openModal = () => {
    setIsOpen(true);
    if (eventDisable && playerDisable && !manualClip) {
      setIsShow(false);
    }
  };
  const reload = () => {
    getEventStream(finalFilters.events, finalFilters.clipRating);
  };

  const closeModal = (cancel) => {
    setFilters(finalFilters);
    setManualClip(isManualUpload);
    setClipData(finalFilters?.clipData);
    if (cancel == "cancel") {
      reload();
    }
    setIsOpen(false);
    setSearchPlayer("");
    setSearchEvent("");
  };

  // function for filter players
  const onChangePlayers = (e) => {
    const player = e.target.value;
    const players = filters?.players;
    if (!filters?.players.includes(player)) {
      players.push(player);
    } else {
      _.pull(players, player);
    }
    setFilters({ ...filters, players });
  };

  const onChangeEvents = (e) => {
    const event = e.target.value;
    const events = [...filters.events];
    if (!filters?.events.includes(event)) {
      events.push(event);
    } else {
      _.pull(events, event);
    }
    setFilters({ ...filters, events });
    setSearchPlayer("");
    getEventStream(events, filters.clipRating);
  };

  useEffect(() => {
    setFilters({
      ...filters,
      players: filters?.players.filter((player) => players.map(({ playerName }) => playerName).indexOf(player) !== -1),
    });
  }, [playerList]);

  const onChangeClipData = (name, value) => {
    setClipData({ ...clipData, [name]: value });
  };

  const onChangeSpeed = (selectedValues) => {
    setFilters({ ...filters, playBackSpeed: selectedValues });
  };
  const onChangePublish = (e) => {
    setFilters({ ...filters, webhookPublish: e.target.value });
  };
  const onChangeRating = (e) => {
    setFilters({ ...filters, clipRating: e });
    getEventStream(filters.events, e);
  };
  const onChangeFilter = (e) => {
    let name = e?.target?.name,
      value = e?.target?.value;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    closeModal();
    if (applyFilter > 0) {
      if (filters) {
        setIsShow(true);
        const customTags = customTagsList.map((item) => ({
          key: item.key,
          events: item.events.filter((event) => event.checked).map((event) => event.event),
        }));
        updatedSearchParams.set("filters", JSON.stringify({ ...filters, customTags }));
        updatedSearchParams.set("pageNo", 1);
        updatedSearchParams.set("isManualUpload", manualClip);
        setSearchParams(updatedSearchParams);
      }
    }
  }, [applyFilter]);

  const searchFun = (e) => {
    setSearch(e.target.value);
    firstRef.current = false;
  };

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
    } else {
      const timer = setTimeout(() => {
        updatedSearchParams.set("search", JSON.stringify(search));
        updatedSearchParams.set("pageNo", 1);
        setSearchParams(updatedSearchParams);
        firstRef.current = true;
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [search]);

  const searchEventFun = (event) => {
    setSearchEvent(event);
    if (event) {
      setEventList(events.filter((d) => d?.event?.toLowerCase().includes(event.toLowerCase())));
    } else {
      setEventList(events);
    }
  };

  const searchPlayerFun = (player) => {
    setSearchPlayer(player);
    if (player) {
      setPlayerList(players.filter((d) => d?.playerName?.toLowerCase().includes(player.toLowerCase())));
    } else {
      setPlayerList(players);
    }
  };

  const resetEventFunctiion = () => {
    setFilters({ ...filters, events: [] });
    getEventStream([], filters.clipRating);
  };

  const resetClipDataFunctiion = (name) => {
    setClipData({ ...clipData, [name]: undefined });
  };

  const resetPlayerFunctiion = () => {
    setFilters({ ...filters, players: [] });
  };

  function useScrollTop() {
    const [scrollTop, setScrollTop] = useState(0);
    const onScroll = (event) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
  }

  useEffect(() => {
    if (filters?.events?.length !== 0) {
      seteventDisable(false);
    } else {
      seteventDisable(true);
    }
    if (filters?.players?.length !== 0) {
      setPlayerDisable(false);
    } else {
      setPlayerDisable(true);
    }
  }, [filters?.events?.length, filters?.players?.length]);

  const setFieldDisable = (field, value) => {
    switch (field) {
      case "shotType":
        setShotTypeDisable(value);
        break;
      case "heartbeat":
        setheartbeatDisable(value);
        break;
      case "gameTime":
        setgameTimeDisable(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (clipData) {
      setFieldDisable("shotType", !clipData.shotType?.length);
      setFieldDisable("heartbeat", !clipData.heartbeat?.length);
      setFieldDisable("gameTime", !clipData.gameTime?.length);
    }
  }, [clipData]);

  useEffect(() => {
    setFilters(finalFilters);
    setClipData(finalFilters.clipData);
  }, [finalFilters]);

  const ManualUploadfun = (e) => {
    if (e.length) {
      setManualClip(true);
    } else {
      setManualClip(false);
    }
  };

  const location = useLocation();
  let pathName = location.pathname.replace("/rule-based-clip/", "");
  const queryParams = location.search;
  const searchParams = new URLSearchParams(location.search);
  const navigatingFrom = searchParams.get("navigating-from");

  const getStreamDetails = async () => {
    const { value } = await getSingleStream({ id });
    if (value?.stream) {
      setStream(value?.stream);
    }
  };

  useEffect(() => {
    getStreamDetails();
  }, [id]);

  const isCustomTag = customTagsList.some((tagList) => tagList?.events?.length > 0);
  useEffect(() => {
    if (filters.clipRating?.length && (filters?.events.length || filters.players.length)) {
      const commonEvents = filters.events.filter((filterEvent) =>
        events.some((eventObj) => eventObj.event === filterEvent),
      );
      const commonPlayers = filters.players.filter((filterPlayer) =>
        players.some((playerObj) => playerObj.playerName === filterPlayer),
      );
      setFilters({ ...filters, events: commonEvents, players: commonPlayers });
    }
  }, [filters.clipRating, events, players]);

  return (
    <>
      {isShowCsv && (
        <ShowCSV isShowCsv={isShowCsv} setIsShowCsv={setIsShowCsv} streamDetails={streamDetails} {...props} />
      )}

      <div className="page-toolbar">
        <div className="page-breadcrumbs-styled">
          <div>
            <div className="page-title">{queryParams.includes("ruleId") ? "Rule Clips" : "Clips"}</div>
            <nav className="breadcrumbs">
              <ul className="breadcrumbs-list">
                {props.disabled ? (
                  navigatingFrom === "rule-overview" ? (
                    <>
                      <li className="breadcrumbs-item" onClick={() => navigate(`/videos`)}>
                        Videos
                      </li>
                      <li className="breadcrumbs-item" onClick={() => navigate(`/clips/${id}`)}>
                        {streamDetails?.title}
                      </li>
                      <li className="breadcrumbs-item" onClick={() => navigate(`/rule-overview/${id}`)}>
                        Rule Outputs
                      </li>
                      <li className="breadcrumbs-item active title_Pascal">rule-clips</li>
                    </>
                  ) : (
                    <>
                      <li className="breadcrumbs-item" onClick={() => navigate(`/rule-listing`)}>
                        Rule Management
                      </li>
                      <li className="breadcrumbs-item" onClick={() => navigate(`/combine-rule-info/${props.ruleId}`)}>
                        {props?.ruleTitle?.slice(0, 5)}...
                      </li>
                      <li className="breadcrumbs-item active title_Pascal">rule-clips</li>
                    </>
                  )
                ) : (
                  <>
                    <li className="breadcrumbs-item" onClick={() => navigate(`/videos`)}>
                      Videos
                    </li>
                    <li className="breadcrumbs-item active title_Pascal">
                      <Tooltip placement={"bottomLeft"} title={stream?.title}>
                        {stream?.title ? `${stream?.title.slice(0, 5)}...` : "Clips"}
                      </Tooltip>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div className="page-toolbar-styled">
          <div className="page-toolbar-styled-item">
            <input
              type="text"
              onChange={(e) => {
                searchFun(e);
              }}
              value={search}
              placeholder="Search"
              className="form-control filters-search-control"
            />
          </div>
          <div className="page-toolbar-styled-item">
            <button className="btn btn-white btn-filters" onClick={openModal} disabled={props.disabled}>
              <FilterIcon className="me-2" /> Filter
            </button>
          </div>
          <ManageAccess value={clientSetting?.appAdditionalPermissions?.isAllowAccessToArchiveClips}>
            <div className="page-toolbar-styled-item">
              <button
                className="btn btn-primary btn-filters"
                onClick={() => {
                  if (queryParams.includes("ruleId")) {
                    if (navigatingFrom) {
                      navigate(`/archive-entities/${id}?folderId=${pathName}&${searchParams}`);
                    } else {
                      navigate(
                        `/archive-entities/${id}?folderId=${pathName}&${searchParams}&rule-name=${props?.ruleTitle}`,
                      );
                    }
                  } else {
                    navigate(`/archive-entities/${id}`);
                  }
                }}
              >
                Archive
              </button>
            </div>
          </ManageAccess>

          {!(searchParams.get("navigating-from") === "rule-overview") && (
            <AccessControl code="ruleManagement" page="clips">
              <ManageAccess value={clientSetting?.appAdditionalPermissions?.ruleManagement}>
                <div className="page-toolbar-styled-item">
                  <button
                    className="btn btn-primary  btn-filters "
                    onClick={() => window.open(`/rule-overview/${id}`, "_blank")}
                  >
                    View Rule Output
                  </button>
                </div>
              </ManageAccess>
            </AccessControl>
          )}
          <AccessControl code="highlight" page="clips">
            <ManageAccess value={clientSetting?.appAdditionalPermissions?.isAllowAccessToHighlight}>
              <div className="page-toolbar-styled-item">
                <button
                  className="btn btn-primary  btn-filters "
                  onClick={() => {
                    if (queryParams.includes("ruleId")) {
                      if (navigatingFrom) {
                        window.open(`/highlights/${id}?folderId=${pathName}&${searchParams}`, "_blank");
                      } else {
                        window.open(
                          `/highlights/${id}?folderId=${pathName}&${searchParams}&rule-name=${props?.ruleTitle}`,
                          "_blank",
                        );
                      }
                    } else {
                      window.open(`/highlights/${id}`, "_blank");
                    }
                  }}
                  disabled={clipsDetails.length !== 0 ? false : true}
                >
                  <Highlights className="me-3" /> Highlights
                </button>
              </div>
            </ManageAccess>
          </AccessControl>

          <AccessControl code="liveTool" page="clips">
            <ManageAccess value={clientSetting?.appAdditionalPermissions?.isAllowAccessToLiveEditor}>
              <div className="page-toolbar-styled-item">
                <button
                  className="btn btn-primary btn-filters"
                  onClick={() => {
                    if (queryParams.includes("ruleId")) {
                      if (navigatingFrom) {
                        window.open(`/live-video/${id}?folderId=${pathName}&${searchParams}`, "_blank");
                      } else {
                        window.open(
                          `/live-video/${id}?folderId=${pathName}&${searchParams}&rule-name=${props?.ruleTitle}`,
                          "_blank",
                        );
                      }
                    } else {
                      window.open(`/live-video/${id}`, "_blank");
                    }
                  }}
                >
                  <LiveTool className="me-3" /> Live Editor
                </button>
              </div>
            </ManageAccess>
          </AccessControl>
          {clientSetting?.appAdditionalPermissions?.webStory && (
            <AccessControl code="story" page="clips">
              <ManageAccess value={clientSetting?.appAdditionalPermissions?.isAllowAccessToStory}>
                <div className="page-toolbar-styled-item">
                  <button
                    className="btn btn-primary btn-filters"
                    onClick={() => {
                      if (queryParams.includes("ruleId")) {
                        if (navigatingFrom) {
                          navigate(`/stories/${id}?folderId=${pathName}&${searchParams}`);
                        } else {
                          navigate(`/stories/${id}?folderId=${pathName}&${searchParams}&rule-name=${props?.ruleTitle}`);
                        }
                      } else {
                        navigate(`/stories/${id}`);
                      }
                    }}
                    disabled={clipsDetails.length !== 0 ? false : true}
                  >
                    <History className="me-3" /> Stories
                  </button>
                </div>
              </ManageAccess>
            </AccessControl>
          )}
          <AccessControl code="show_csv" page="clips">
            <ManageAccess
              value={clientSetting?.clientType == ZEE_CLIENT && streamDetails?.status === STREAM_STATUS.COMPLETED}
            >
              <div className="page-toolbar-styled-item">
                <button
                  disabled={!streamDetails?.joinedFileUpdateKey && !streamDetails?.joinedFileKey}
                  onClick={() => setIsShowCsv(true)}
                  className="btn btn-primary btn-filters"
                >
                  Show CSV
                </button>
              </div>
            </ManageAccess>
          </AccessControl>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Filter Modal"
        ariaHideApp={false}
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-xl modal-dialog-centered filter-modal"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Filters</h5>
            <button type="button" onClick={() => closeModal("cancel")} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <div className="react-modal-body">
            {/* <div
              className={`filter-column-container ${
                streamDetails?.category !== "basketball" ? "filter-column-container-basket" : ""
              }`}
            > */}
            <div className="filter-column-container filter-grid-container">
              <ManageAccess
                value={streamDetails?.allowClipRating && clientSetting?.appAdditionalPermissions?.clipRating}
              >
                <div className="filter-column">
                  <div className="orientation-block mt-0">
                    <div className="titleblock">
                      <h6>Rating</h6>
                    </div>
                    <div className="custom-radio-group">
                      <Checkbox.Group
                        onChange={onChangeRating}
                        className="reviewGroupFilter"
                        value={filters?.clipRating}
                      >
                        {clipRating.map((element) => {
                          return (
                            <Checkbox value={element} key={element}>
                              <StarFilled />
                              {`${element} Star`}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              </ManageAccess>
              <div className="filter-column">
                <div className="titleblock">
                  <h6>Events</h6>
                  <button disabled={eventDisable} onClick={resetEventFunctiion} className="reset-btn">
                    Reset
                  </button>
                </div>
                <div className="custom-checkbox-group-container">
                  <div className="field-container">
                    <input
                      type="text"
                      onChange={(e) => searchEventFun(e.target.value)}
                      placeholder="Search"
                      className="searchInput-field"
                    />
                  </div>
                  <div className={`checkbox-container-main  ${isCustomTag ? "bottom-border-1" : ""} pb-3`}>
                    <div
                      className="content-control-block long filters-events-list styled-scroll"
                      {...scrollProps}
                      style={{
                        boxShadow:
                          scrollTop > 0
                            ? "inset 0 8px 15px -15px rgb(0 0 0 / 0.4), inset 0px -8px 15px -18px rgb(0 0 0 / 0.4)"
                            : "none",
                        transition: "box-shadow 0.3s",
                        height: "auto",
                        width: "100%",
                        overflow: "auto",
                      }}
                    >
                      <div className="custom-radio-group pt-2 two-column-modal-icon">
                        <div className="custom-radio-group pt-2 two-column-modal-icon checkbox-container-flex">
                          {eventList &&
                            !_.isEmpty(eventList) &&
                            eventList.map((event) => {
                              if (event) {
                                return (
                                  <div key={event.event}>
                                    <Checkbox
                                      value={event.event}
                                      checked={(filters.events || []).includes(event.event)}
                                      className="text-capitalize"
                                      onChange={onChangeEvents}
                                    >
                                      {event.event} ({event?.count})
                                    </Checkbox>
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isCustomTag && (
                  <>
                    <div className="titleblock mt-4">
                      <h6>Custom Tags</h6>
                    </div>
                    {customTagsList &&
                      customTagsList?.map((tags, index) => {
                        const checkedEventCounts = tags?.events?.reduce(
                          (count, event) => (event.checked ? count + 1 : count),
                          0,
                        );
                        return tags.events && tags.events.length > 0 ? (
                          <Collapse className="collapse-container my-2" key={index}>
                            <Panel
                              showArrow={false}
                              header={`${tags.name} ${checkedEventCounts ? `(${checkedEventCounts})` : ""}`}
                            >
                              <div class="titleblock border-0 mb-0 pb-0 justify-content-end">
                                <button
                                  disabled={customTagsList
                                    ?.filter((item) => item.key === tags.key)
                                    .every((item) => item.events.every((event) => event.checked === false))}
                                  onClick={() => resetCustomEvents(tags.key)}
                                  className="reset-btn"
                                >
                                  Reset
                                </button>
                              </div>
                              <div className="field-container mt-1">
                                <input
                                  type="text"
                                  onChange={(e) => searchCustomEvents(tags.key, e.target.value)}
                                  placeholder="Search"
                                  className="searchInput-field"
                                />
                              </div>
                              <div className="checkbox-container-main">
                                <div
                                  className="content-control-block long filters-events-list styled-scroll"
                                  {...scrollProps}
                                  style={{
                                    boxShadow:
                                      scrollTop > 0
                                        ? "inset 0 8px 15px -15px rgb(0 0 0 / 0.4), inset 0px -8px 15px -18px rgb(0 0 0 / 0.4)"
                                        : "none",
                                    transition: "box-shadow 0.3s",
                                    height: "auto",
                                    width: "100%",
                                    overflow: "auto",
                                  }}
                                >
                                  <div className="custom-radio-group pt-0 pb-2 two-column-modal-icon">
                                    <div className="custom-radio-group pt-2 two-column-modal-icon checkbox-container-flex">
                                      {tags.events.map((event) => {
                                        if (event && event.searchable) {
                                          return (
                                            <div key={event.event}>
                                              <Checkbox
                                                value={event.event}
                                                checked={event.checked}
                                                className="text-capitalize"
                                                onChange={(e) => {
                                                  onChangeCustomEvents(tags.key, event.event, e);
                                                }}
                                              >
                                                {event.event} ({event?.count})
                                              </Checkbox>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        ) : null;
                      })}
                  </>
                )}
              </div>
              <div className="filter-column">
                <div className="titleblock">
                  <h6>Players</h6>
                  <button disabled={playerDisable} onClick={resetPlayerFunctiion} className="reset-btn">
                    Reset
                  </button>
                </div>
                <div className="custom-checkbox-group-container">
                  <div className="field-container">
                    <input
                      type="text"
                      onChange={(e) => searchPlayerFun(e.target.value)}
                      value={searchPlayer}
                      placeholder="Search"
                      className="searchInput-field"
                    />
                  </div>
                  <div className="checkbox-container-main">
                    <div
                      className={
                        playerList.length
                          ? "content-control-block xslong styled-scroll filters-players-list"
                          : "text-center"
                      }
                      {...scrollProps}
                      style={{
                        boxShadow:
                          scrollTop > 0
                            ? "inset 0 8px 15px -15px rgb(0 0 0 / 0.4), inset 0px -8px 15px -18px rgb(0 0 0 / 0.4)"
                            : "none",
                        transition: "box-shadow 0.3s",
                        height: "auto",
                        width: "100%",
                        overflow: "auto",
                      }}
                    >
                      <div className="custom-radio-group pt-2 two-column-modal-icon checkbox-container-flex">
                        {playerList &&
                          !_.isEmpty(playerList) &&
                          playerList.map((event) => {
                            if (event && event.playerName) {
                              return (
                                <div key={event.playerName}>
                                  <Checkbox
                                    value={event.playerName}
                                    checked={(filters.players || []).includes(event.playerName)}
                                    onChange={onChangePlayers}
                                    className="text-capitalize"
                                  >
                                    {event.playerName} ({event?.count})
                                  </Checkbox>
                                </div>
                              );
                            }
                            return null;
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {streamDetails?.category == "basketball" && (
                <div className="filter-column">
                  <div className="titleblock">
                    <h6>Shot type</h6>
                    <button
                      disabled={shotTypeDisable}
                      onClick={() => resetClipDataFunctiion("shotType")}
                      className="reset-btn"
                    >
                      Reset
                    </button>
                  </div>
                  <div className="custom-radio-group pt-2">
                    <Checkbox.Group
                      className="text-capitalize"
                      value={clipData?.shotType}
                      defaultValue={""}
                      name="shotType"
                      onChange={(e) => {
                        onChangeClipData("shotType", e);
                      }}
                    >
                      {shotType &&
                        shotType.map((shot, index) => {
                          return (
                            <Checkbox value={shot.value} key={index}>
                              {shot.name}
                            </Checkbox>
                          );
                        })}
                    </Checkbox.Group>
                  </div>

                  <div className="orientation-block">
                    <div className="titleblock">
                      <h6>Heartbeats</h6>
                      <button
                        disabled={heartbeatDisable}
                        onClick={() => resetClipDataFunctiion("heartbeat")}
                        className="reset-btn"
                      >
                        Reset
                      </button>
                    </div>

                    <div className="custom-radio-group">
                      <Checkbox.Group
                        name="heartbeat"
                        value={clipData?.heartbeat}
                        defaultValue={""}
                        onChange={(e) => {
                          onChangeClipData("heartbeat", e);
                        }}
                      >
                        {heartbeat &&
                          heartbeat.map((HB, ind) => {
                            return (
                              <Checkbox value={HB.value} key={ind}>
                                {HB.name}
                              </Checkbox>
                            );
                          })}
                      </Checkbox.Group>
                    </div>
                  </div>
                  <div className="orientation-block">
                    <div className="titleblock">
                      <h6>Game Time</h6>
                      <button
                        disabled={gameTimeDisable}
                        onClick={() => resetClipDataFunctiion("gameTime")}
                        className="reset-btn"
                      >
                        Reset
                      </button>
                    </div>
                    <div className="custom-radio-group">
                      <Checkbox.Group
                        name="gameTime"
                        value={clipData?.gameTime}
                        defaultValue={""}
                        onChange={(e) => {
                          onChangeClipData("gameTime", e);
                        }}
                      >
                        {gameTime &&
                          gameTime.map((time, ind) => {
                            return (
                              <Checkbox value={time.value} key={ind}>
                                {time.name}
                              </Checkbox>
                            );
                          })}
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              )}

              <div className="filter-column">
                <div className="titleblock">
                  <h6>Sort By</h6>
                </div>
                <div className="custom-radio-group">
                  <Radio.Group
                    name="sortBy"
                    value={filters.sortBy}
                    onChange={(e) => {
                      onChangeFilter(e);
                    }}
                    defaultValue="TIME_DESCENDANT"
                  >
                    <Radio value="TIME_DESCENDANT"> Time sequence (Descendant)</Radio>
                    <Radio value="TIME_ASCENDANT"> Time sequence (Ascendant)</Radio>
                    <Radio value="RECENT">Recent</Radio>
                  </Radio.Group>
                </div>

                <div className="orientation-block">
                  <div className="titleblock">
                    <h6>Aspect Ratio (Select One)</h6>
                  </div>
                  <div className="custom-radio-group two-aspect-ratio-block d-block">
                    <Radio.Group
                      name="aspectRatio"
                      value={filters.aspectRatio}
                      onChange={(e) => {
                        onChangeFilter(e);
                      }}
                    >
                      {aspectRatioClip &&
                        aspectRatioClip.map((ratio, ind) => {
                          return (
                            <Radio value={ratio.value} key={ind}>
                              {ratio.name}
                            </Radio>
                          );
                        })}
                    </Radio.Group>
                  </div>
                </div>
                <div className="orientation-block">
                  <div className="titleblock">
                    <h6>Playback Speed (Select One)</h6>
                  </div>
                  <div className="custom-radio-group pt-2">
                    <Checkbox.Group
                      options={playbackList.map((element) => ({
                        label: element?.title,
                        value: element?.value,
                      }))}
                      value={filters.playBackSpeed}
                      onChange={onChangeSpeed}
                    />
                  </div>
                </div>
                <div className="orientation-block">
                  <div className="titleblock">
                    <h6>Webhook Status</h6>
                  </div>
                  <div className="custom-radio-group pt-2">
                    <Radio.Group
                      name="sortBy"
                      value={filters.webhookPublish}
                      onChange={(e) => {
                        onChangePublish(e);
                      }}
                      defaultValue="TIME_DESCENDANT"
                    >
                      {publishElement &&
                        publishElement.map((element, index) => {
                          return (
                            <Radio value={element?.value} key={index}>
                              {element?.title}
                            </Radio>
                          );
                        })}
                    </Radio.Group>
                  </div>
                </div>
                <div className="orientation-block">
                  <div className="titleblock" />
                  <div className="custom-radio-group pt-2">
                    <Checkbox.Group
                      onChange={(e) => {
                        ManualUploadfun(e);
                      }}
                      value={manualClip ? [true] : []}
                    >
                      <Checkbox value={true}>User uploaded clips</Checkbox>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className="ant-checkbox-group">
                  <Checkbox
                    onChange={(e) => setFilters({ ...filters, isIllustoClip: e.target.checked })}
                    checked={filters.isIllustoClip}
                  >
                    Advanced Editor
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button type="button" className="btn btn-primary" onClick={filerClips}>
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ClipsFilterComponent;
