import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { Suspense, lazy } from "react";
import AccessControl from "../components/common/AccessControl/accessControl";
//Lazy components
const Dashboard = lazy(() => import("../views/Dashboard/container"));
const DataConsumed = lazy(() => import("../views/DataConsumed/container"));
const Login = lazy(() => import("../views/Login/container"));
const OpsLogin = lazy(() => import("../views/OpsLogin/container"));
const ForgotPassword = lazy(() => import("../views/ForgotPassword/container"));
const Signup = lazy(() => import("../views/Signup/container"));
const ResetPassword = lazy(() => import("../views/ResetPassword/container"));
const VideoGrid = lazy(() => import("../views/VideoGrid/container"));
// const Clips = lazy(() => import("../views/Clips/container"));
const UserManagement = lazy(() => import("../views/UserManagement/container"));
const AddNewVideoUploadingForm = lazy(() => import("../views/AddNewVideoUploadingForm/container"));
const EditVideoFormContainer = lazy(() => import("../views/EditVideoForm/container"));
const EditSummaryContainer = lazy(() => import("../views/EditSummary/container"));
const CopyStreamContainer = lazy(() => import("../views/CopyStream/container"));
const ThirdPartyContainer = lazy(() => import("../views/ThirdPartyMediaShare/container"));
const ConnectedApps = lazy(() => import("../views/ConnectedApps/container"));
const ArchiveClipsComponent = lazy(() => import("../views/ArchiveClips/container"));
const ConfigurationComponent = lazy(() => import("../views/Configuration/container"));
const UserProfileComponent = lazy(() => import("../views/UserProfile/container"));
const UserProfileBasicInfoEditComponent = lazy(() => import("../views/UserProfileBasicInfoEdit/container"));
const UserProfileUpdatePasswordEditComponent = lazy(() => import("../views/UserProfileUpdatePasswordEdit/container"));
const ReportsComponent = lazy(() => import("../views/Reports/container"));
const UpdateStorageComponent = lazy(() => import("../views/UpdateStorage/container"));
const GroupMappingComponent = lazy(() => import("../views/GroupMapping/container"));
const PublishClipsComponent = lazy(() => import("../views/PublishClips/container"));
const ArchiveStreamComponent = lazy(() => import("../views/ArchiveStreams/container"));
const VideosInfoNComponent = lazy(() => import("../views/VideosInfoN/container"));
const EditorPage = lazy(() => import("../views/EditorPage/container"));
const ArchiveSettingsComponent = lazy(() => import("../views/ArchiveSettings/container"));
const ClipsNewComponent = lazy(() => import("../views/ClipsNew/container"));
const LiveEditComponent = lazy(() => import("../views/LiveEdit/container"));
const MyHighlightsComponent = lazy(() => import("../views/MyHighlights/container"));
const MyStoriesComponent = lazy(() => import("../views/MyStories/container"));
const VideoPlayerContainer = lazy(() => import("../views/VideoPlayer/container"));
const GroupMappingDetailComponent = lazy(() => import("../views/GroupMappingDetails/container"));
const SingleWebstoryEditorComponent = lazy(() => import("../views/SingalWebstoryEditor/container"));
const ScoreBoardContainer = lazy(() => import("../views/ScoreBoard/container"));
const StreamDetailsContainer = lazy(() => import("../views/StreamDetails/container"));
const QuickHighlights = lazy(() => import("../views/QuickHighlights/container"));
const BulkEditorComponent = lazy(() => import("../views/BulkEdits/container"));
const RuleBasedContainer = lazy(() => import("../views/RuleBasedContent/container"));
const RuleoverViewContainer = lazy(() => import("../views/RuleBasedOverView/container"));
const RuleBasedClipsContainer = lazy(() => import("../views/RuleBasedClip/container"));
const CombineRuleInfoContainer = lazy(() => import("../views/CombineRuleInfo/container"));
const QueueSettingContainer = lazy(() => import("../views/QueueManagement/container"));
const ProcessContainer = lazy(() => import("../views/ProcessTracking/container"));
const CropperContainer = lazy(() => import("../views/Cropper/container"));
const AddNewVideoEditForm = lazy(() => import("../views/AddNewVideoEditForm/container"));
const SSOLogin = lazy(() => import("../views/SSO/container"));
const PublishHistory = lazy(() => import("../views/PublishHistory/container"));
const InternalStatus = lazy(() => import("../views/InternalStatus/container"));
const ClipRatingRule = lazy(() => import("../views/ClipRatingRule/container"));
const antIcon = <LoadingOutlined style={{ fontSize: 24, align: "center" }} spin />;
const Loader = () => {
  return (
    <Spin
      indicator={antIcon}
      className="loader__full"
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        zIndex: "99",
      }}
    />
  );
};

export const AsyncDashboard = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Dashboard />
    </Suspense>
  );
};

export const AsyncDataConsumed = () => {
  return (
    <Suspense fallback={<Loader />}>
      <DataConsumed />
    </Suspense>
  );
};

export const AsyncLogin = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Login />
    </Suspense>
  );
};

export const AsyncOpsLogin = () => {
  return (
    <Suspense fallback={<Loader />}>
      <OpsLogin />
    </Suspense>
  );
};

export const AsyncForgotPassword = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ForgotPassword />
    </Suspense>
  );
};

export const AsyncSignup = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Signup />
    </Suspense>
  );
};
//----------------------
export const AsyncResetPassword = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ResetPassword />
    </Suspense>
  );
};

export const AsyncVideoGrid = () => {
  return (
    <Suspense fallback={<Loader />}>
      <VideoGrid />
    </Suspense>
  );
};

// export const AsyncClips = () => {
//   return (
//     <Suspense fallback={<Loader />}>
//       <Clips />
//     </Suspense>
//   );
// };

export const AsyncUserManagement = () => {
  return (
    <Suspense fallback={<Loader />}>
      <UserManagement />
    </Suspense>
  );
};

export const AsyncAddNewVideoUploadingForm = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AddNewVideoUploadingForm />
    </Suspense>
  );
};

export const AsyncEditVideoFormContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <EditVideoFormContainer />
    </Suspense>
  );
};

export const AsyncEditSummaryContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AccessControl code="accessible" page="edit-clip">
        <EditSummaryContainer />
      </AccessControl>
    </Suspense>
  );
};

export const AsyncArchiveClipsComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AccessControl code="accessible" page="archive-entities">
        <ArchiveClipsComponent />
      </AccessControl>
    </Suspense>
  );
};

export const AsyncCopyStreamContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <CopyStreamContainer />
    </Suspense>
  );
};

export const AsyncVideoPlayerContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <VideoPlayerContainer />
    </Suspense>
  );
};

export const AsyncThirdPartyContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ThirdPartyContainer />
    </Suspense>
  );
};

export const AsyncConnectedApps = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ConnectedApps />
    </Suspense>
  );
};

export const AsyncConfigurationComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ConfigurationComponent />
    </Suspense>
  );
};

export const AsyncUserProfileComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <UserProfileComponent />
    </Suspense>
  );
};

export const AsyncReportsComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ReportsComponent />
    </Suspense>
  );
};

export const AsyncUserProfileBasicInfoEditComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <UserProfileBasicInfoEditComponent />
    </Suspense>
  );
};

export const AsyncUserProfileUpdatePasswordEditComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <UserProfileUpdatePasswordEditComponent />
    </Suspense>
  );
};

export const AsyncUpdateStorageComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <UpdateStorageComponent />
    </Suspense>
  );
};

export const AsyncGroupMappingComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <GroupMappingComponent />
    </Suspense>
  );
};

export const AsyncMyHighlightsComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <MyHighlightsComponent />
    </Suspense>
  );
};

export const AsyncMyStoriesComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <MyStoriesComponent />
    </Suspense>
  );
};

export const AsyncPublishClipsComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <PublishClipsComponent />
    </Suspense>
  );
};

export const AsyncArchiveStreamComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ArchiveStreamComponent />
    </Suspense>
  );
};

export const AsyncVideosInfoNComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <VideosInfoNComponent />
    </Suspense>
  );
};

export const AsyncEditorPage = () => {
  return (
    <Suspense fallback={<Loader />}>
      <EditorPage />
    </Suspense>
  );
};

export const AsyncArchiveSettingsComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AccessControl code="accessible" name="client-archive" page="client-archive">
        <ArchiveSettingsComponent />
      </AccessControl>
    </Suspense>
  );
};

export const AsyncClipsNewComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ClipsNewComponent />
    </Suspense>
  );
};

export const AsyncLiveEditComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <LiveEditComponent />
    </Suspense>
  );
};

export const AsyncGroupMappingDetailComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <GroupMappingDetailComponent />
    </Suspense>
  );
};

export const AsyncSingleWebstoryEditorComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <SingleWebstoryEditorComponent />
    </Suspense>
  );
};

export const AsyncScoreBoardContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AccessControl code="accessible" page="scoreboard">
        <ScoreBoardContainer />
      </AccessControl>
    </Suspense>
  );
};

export const AsyncStreamDetailsContainer = () => {
  return (
    <Suspense fallback={<Loader />}>
      <StreamDetailsContainer />
    </Suspense>
  );
};

export const AsyncQuickHighlights = () => {
  return (
    <Suspense fallback={<Loader />}>
      <QuickHighlights />
    </Suspense>
  );
};

export const AsyncBulkEditorComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BulkEditorComponent />
    </Suspense>
  );
};

export const AsyncRuleBasedComponent = () => {
  return (
    <AccessControl code="accessible" page="rule-configuration">
      <Suspense fallback={<Loader />}>
        <RuleBasedContainer />
      </Suspense>
    </AccessControl>
  );
};

export const AsyncRuleBasedFolderComponent = () => {
  return (
    <AccessControl code="accessible" page="rule-overview">
      <Suspense fallback={<Loader />}>
        <RuleoverViewContainer />
      </Suspense>
    </AccessControl>
  );
};

export const AsyncRuleBasedClipsComponent = () => {
  return (
    // <AccessControl code="accessible" page="rule-overview">
    <Suspense fallback={<Loader />}>
      <RuleBasedClipsContainer />
    </Suspense>
    // </AccessControl>
  );
};

export const AsyncCombineRuleInfoComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <CombineRuleInfoContainer />
    </Suspense>
  );
};

export const AsyncQueueSettingComponent = () => {
  return (
    <AccessControl code="accessible" name="queue-setting" page="queue-setting">
      <Suspense fallback={<Loader />}>
        <QueueSettingContainer />
      </Suspense>
    </AccessControl>
  );
};

export const AsyncProcessTrackingComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ProcessContainer />
    </Suspense>
  );
};

export const AsyncCropperComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <CropperContainer />
    </Suspense>
  );
};
export const AsyncAddNewVideoEditForm = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AddNewVideoEditForm />
    </Suspense>
  );
};
export const AsyncSSOLogin = () => {
  return (
    <Suspense fallback={<Loader />}>
      <SSOLogin />
    </Suspense>
  );
};
export const AsyncPublishHistory = () => {
  return (
    <Suspense fallback={<Loader />}>
      <PublishHistory />
    </Suspense>
  );
};
export const AsyncInternalStatus = () => {
  return (
    <Suspense fallback={<Loader />}>
      <InternalStatus />
    </Suspense>
  );
};

export const AsyncClipRatingRule = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ClipRatingRule />
    </Suspense>
  );
};
