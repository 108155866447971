import * as React from "react";

const SvgComponent = (props) => (
  <svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.999 2.167h-8a2.667 2.667 0 0 0-2.667 2.666V11.5a2.667 2.667 0 0 0 2.667 2.666h8a2.667 2.667 0 0 0 2.666-2.666V4.833A2.667 2.667 0 0 0 12 2.166ZM1.332 6.166h13.333M5.332.833V3.5.833Zm5.333 0V3.5.833Z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
