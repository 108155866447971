import { connect } from "react-redux";

import CookiePolicyComponent from "./component";

const CookiePolicy = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {},
)(CookiePolicyComponent);

export default CookiePolicy;
