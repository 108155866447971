import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { notification, Spin, Select, Tooltip } from "antd";
import { InfoIcon } from "../../../components/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { uploadToS3Validation } from "../../../utils/validations";
import CloseModal from "../../../components/icons/CloseModal";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";
import ManageAccess from "../../../components/common/ManageAccess/manageAccess";

const { Option } = Select;

const UploadToS3 = (props) => {
  const { clientSetting } = useOutletContext();
  const {
    s3Open,
    setS3Open,
    clip,
    loder,
    s3BucketPublish,
    storage,
    streamDetails,
    dumpOnS3,
    bucketCopy,
    bulkS3BucketPublish,
    folderArray,
    setFolderArray,
    setLoder,
    getClipSizeByUrl,
    filters,
  } = props;
  const [multipleStorageArr, setMultipleStorageArr] = useState([]);
  const [uploadToS3Validations, setUploadToS3Validations] = useState(uploadToS3Validation);
  const [initialState, setInitialState] = useState({
    storageName: "",
    folderPath: "",
    folderPaths: [],
  });
  const [disable, setDisable] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [bucketAction, setBucketAction] = useState("copy_bucket");
  const [stName, setStName] = useState("");

  const { storageName } = initialState;

  useEffect(() => {
    if (clientSetting?.appAdditionalPermissions?.multiFolderUpload && !folderArray?.length) {
      setUploadToS3Validations(
        Yup.object().shape({
          // storage_name: Yup.string().required("Required"),
          folderPaths: Yup.array().min(1).required("Required"),
        }),
      );
    }
  }, [clientSetting?.appAdditionalPermissions?.multiFolderUpload, folderArray?.length]);

  useEffect(() => {
    if (folderArray?.length) {
      setButtonDisable(true);
      handleClipSizeByUrl();
    }
  }, [folderArray?.length]);

  const uploadAgain = async ({ storageName, folderPath }, folderPaths) => {
    const data = await bulkS3BucketPublish({
      storageId: storage.filter(({ storage_name }) => storage_name === storageName)[0]?._id,
      folderPath: folderPath,
      fileUrls: folderPaths,
    });
    if (data.value.success || data.value.status) {
      notification["success"]({ message: data.value.message });
    } else {
      notification["error"]({
        message: data.value.message,
        duration: 5,
        description: (
          <>
            <a
              style={{ textDecoration: "underline", fontSize: "12px" }}
              onClick={() => uploadAgain({ storageName, folderPath }, folderPaths)}
            >
              Upload again
            </a>
            <br></br>
            <ul>
              {data.value.data.map((d) => (
                <li style={{ fontSize: "10px" }} key={d}>
                  {d}
                </li>
              ))}{" "}
            </ul>
          </>
        ),
      });
    }
  };
  const handleSubmit = async ({ storageName, folderPath, folderPaths }) => {
    notification["success"]({ message: "Process Initiated..." });
    if (folderArray?.length) {
      let videoUrls = folderArray?.map(
        (obj) =>
          obj?.editedVideos?.find(
            ({ aspect_ratio, event }) =>
              aspect_ratio == filters?.aspectRatio && (event === "autoFlip" || event === "croppedVideos"),
          )?.video_url || obj.videoUrl,
      );

      const data = await bulkS3BucketPublish({
        storageId: storage.filter(({ storage_name }) => storage_name === storageName)[0]._id,
        folderPath: folderPath,
        fileUrls: videoUrls,
      });
      if (data.value.success || data.value.status) {
        notification["success"]({ message: data.value.message });
        setFolderArray([]);
      } else {
        notification["error"]({
          message: data.value.message,
          duration: 5,
          description: (
            <>
              <a
                style={{ textDecoration: "underline", fontSize: "12px" }}
                onClick={() => uploadAgain({ storageName, folderPath }, data?.value?.data)}
              >
                Upload again
              </a>
              <ul>
                {data.value.data.map((d) => (
                  <li style={{ fontSize: "10px" }} key={d}>
                    {d}
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
    } else {
      let videoUrl =
        clip?.editedVideos?.find(
          ({ aspect_ratio, event }) =>
            aspect_ratio == filters?.aspectRatio && (event === "autoFlip" || event === "croppedVideos"),
        )?.video_url || clip.videoUrl;
      const data = await s3BucketPublish({
        bucket: storage.filter(({ storage_name }) => storage_name === storageName)[0],
        streamId: clip?.streamId,
        folderPath: folderPath,
        folderPaths,
        fileUrl: videoUrl,
        clipId: clip._id,
        isMultiFolderUpload: folderPaths.length > 0,
        isGameChanger: clientSetting?.appAdditionalPermissions?.exportXMP ? true : false,
      });
      if (data.value.success || data.value.status) {
        notification["success"]({ message: data.value.message });
      } else {
        notification["error"]({ message: data.value.message });
      }
    }

    closeModal();
  };
  const handleClipSizeByUrl = async () => {
    const data = await getClipSizeByUrl({
      fileUrls: folderArray.map((d) => d.videoUrl),
    });

    if (data?.value?.size > 524288000) {
      notification["error"]({ message: "Download file size shouldn't exceed 500 mb" });
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  };
  const closeModal = () => {
    setS3Open(false);
    setMultipleStorageArr([]);
    // setFolderArray([]);
    setInitialState({
      storageName: "",
      folderPath: "",
      folderPaths: [],
    });
  };

  const bucketActions = async () => {
    if (bucketAction == "copy_bucket") {
      const data = await bucketCopy({
        bucket: storage.filter((data) => data.storage_name === stName)[0],
        folderPath: `${localStorage.getItem("__uid")}/${streamDetails?.streamId}/`,
        urlArr: [clip?.videoUrl, clip?.videoThumbnailUrl],
        id: clip?.id,
        sourceBuket: streamDetails?.config?.storage?.credentials?.bucket_name,
        sourceAccessKey: streamDetails?.config?.storage?.credentials?.access_key,
        sourceSecretAccessKey: streamDetails?.config?.storage?.credentials?.secret_key,
        sourceRegion: streamDetails?.config?.storage?.credentials?.region,
        sourceCdnSuffix: streamDetails?.config?.storage?.credentials?.cdn_suffix,
      });
    } else {
      var payload = {
        bucket: storage.filter((data) => data.storage_name === stName)[0],
        folderPath: `${localStorage.getItem("__uid")}/${streamDetails?.streamId}/`,
        urlArr: [clip?.videoUrl, clip?.videoThumbnailUrl],
        id: clip?.id,
      };
    }
  };

  //to set folderpath with respect to storage name selected
  const setBucketValue = (setFieldValue, value) => {
    setFieldValue("folderPaths", []);
    setFieldValue("storageName", value);
    if (value === "default") {
      setFieldValue("folderPath", "");
      setMultipleStorageArr([]);
      setDisable(true);
    } else {
      setDisable(false);
    }
    setStName(value);
    var temp = [];
    storage.filter((obj) => {
      if (obj.storage_name === value) {
        setFieldValue("folderPath", obj?.credentials?.folder_path);
        temp = obj?.multiple_folder_path || [];
        setMultipleStorageArr(temp);
      } else if (value === "") {
        setMultipleStorageArr([]);
        setFieldValue("folderPath", "");
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={s3Open}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Cloud Publish"
        portalClassName="react-modal"
        overlayClassName="modal"
        ariaHideApp={false}
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">
              Cloud Publish
              <Tooltip placement="topLeft" title={`Max files: 5 files or Max size: 500MB`}>
                <InfoIcon className="info-icon-main" />
              </Tooltip>
            </h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={uploadToS3Validations} onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue, values, dirty }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container row">
                    <div className="form-group">
                      <label className="form-label">
                        Storage <span className="required">*</span>
                      </label>
                      <select
                        className="custom-select form-control"
                        name="storageName"
                        onChange={(e) => setBucketValue(setFieldValue, e.target.value)}
                      >
                        <option value="default">Select Storage</option>
                        {storage &&
                          storage.map((data) => {
                            return (
                              <option key={data._id} value={data.storage_name}>
                                {data.storage_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {clientSetting?.appAdditionalPermissions?.multiFolderUpload && !folderArray?.length ? (
                      <>
                        <div className="form-group">
                          <label className="form-label">
                            Select Folder Paths <span className="required">*</span>
                          </label>
                          <div className="form-pop-trigger-block">
                            <Field
                              className="custom-ant-select"
                              name="folderPaths"
                              onChange={(e) => setFieldValue("folderPaths", e)}
                              component={Select}
                              dropdownStyle={{ zIndex: 99999999 }}
                              mode="multiple"
                              placeholder="Select Paths"
                              showSearch
                              value={values?.folderPaths || []}
                            >
                              {multipleStorageArr?.map((data) => {
                                return (
                                  <Option key={data._id} value={data.multiple_folder_path}>
                                    {data.multiple_folder_path}
                                  </Option>
                                );
                              })}
                            </Field>
                            {touched.folderPaths && errors.folderPaths && (
                              <div className="invalid-feedback">Must have at least 1 path</div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {multipleStorageArr?.length > 1 ? (
                          <div className="form-group">
                            <label className="form-label">
                              Select Folder Path <span className="required">*</span>
                            </label>
                            <select className="custom-select form-control" name="storageName">
                              {multipleStorageArr?.map((data) => {
                                return (
                                  <option key={data._id} value={data.multiple_folder_path}>
                                    {data.multiple_folder_path}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          <div className="form-group">
                            <label className="form-label">
                              Folder Path <span className="required">*</span>
                            </label>
                            <Field type="text" className="form-control" name="folderPath" />
                            {touched.folderPath && errors.folderPath && (
                              <div className="invalid-feedback">{errors.folderPath}</div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  <button disabled={!dirty || disable || buttonDisable} className="btn btn-primary" type="submit">
                    Submit
                    {buttonDisable ||
                      (loder && (
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 50,
                                color: "#000",
                                marginLeft: 50,
                              }}
                              spin
                            />
                          }
                          className="loader__full"
                        ></Spin>
                      ))}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default UploadToS3;
