import * as React from "react";

const SaveIcon = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" fill="none" viewBox="0 0 17 18" {...props}>
    <path
      fill="currentColor"
      d="M16.32 4.515L12.985 1.18a1.313 1.313 0 00-.929-.383H.953a.656.656 0 00-.656.656v15.094c0 .363.293.656.656.656h15.094a.655.655 0 00.656-.656V5.442c0-.349-.137-.681-.383-.927zM5.875 2.109h5.25v2.297h-5.25V2.11zM8.5 13.471a2.954 2.954 0 11.002-5.908A2.954 2.954 0 018.5 13.47zm0-4.594a1.64 1.64 0 100 3.28 1.64 1.64 0 000-3.28z"
    ></path>
  </svg>
);

export default SaveIcon;
