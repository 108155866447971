import React from "react";

function SoundDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.75 6.25s1 .5 1 1.75-1 1.75-1 1.75m-9-4v4.5h2.5l4 3V2.75l-4 3h-2.5z"
      ></path>
    </svg>
  );
}

export default SoundDown;
