import * as React from "react";

const SvgComponent = (props) => (
  <svg width={24} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.5 3.507a2.786 2.786 0 0 0-.765-1.27 3.05 3.05 0 0 0-1.338-.742C19.519 1 11.995 1 11.995 1a76.622 76.622 0 0 0-9.39.47c-.502.14-.962.4-1.338.76-.37.356-.638.795-.778 1.276A29.09 29.09 0 0 0 0 9c-.012 1.841.15 3.68.488 5.494.137.48.404.916.775 1.27.37.352.833.607 1.34.742 1.904.494 9.39.494 9.39.494 3.141.036 6.281-.121 9.403-.47a3.05 3.05 0 0 0 1.338-.742 2.78 2.78 0 0 0 .765-1.27c.345-1.811.512-3.651.5-5.495.027-1.851-.141-3.7-.5-5.517v.001ZM9.604 12.424V5.577l6.26 3.424-6.26 3.423Z"
      fill="#282828"
    />
  </svg>
);

export default SvgComponent;
