import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.773 1.528a10.493 10.493 0 00-.774-.028V0c.295 0 .59.011.883.033l-.11 1.496v-.001zm3.005.675c-.48-.185-.975-.335-1.478-.448l.33-1.464c.573.129 1.14.3 1.687.513l-.54 1.4v-.001zm2.056 1.066a10.503 10.503 0 00-.66-.405l.74-1.305c.512.29 1.003.618 1.468.98l-.922 1.184c-.204-.159-.413-.31-.627-.453v-.002.001zm2.75 2.684a10.55 10.55 0 00-.98-1.194l1.087-1.035c.405.428.78.885 1.12 1.365l-1.227.864zM21.7 7.981a10.634 10.634 0 00-.323-.702l1.34-.675c.265.527.49 1.073.675 1.632l-1.425.47c-.08-.245-.17-.486-.267-.724v-.001zm.793 3.761a10.48 10.48 0 00-.15-1.538l1.479-.255c.1.58.159 1.168.174 1.755l-1.5.038h-.003zm-.195 2.307c.05-.255.09-.508.122-.765l1.489.184a11.926 11.926 0 01-.346 1.733l-1.445-.4c.069-.248.128-.498.18-.752zm-1.428 3.569c.276-.435.519-.891.729-1.362l1.37.607c-.24.54-.517 1.059-.831 1.557l-1.268-.803v.001zm-1.447 1.807c.184-.183.358-.372.526-.567l1.137.98c-.192.223-.393.439-.602.647l-1.06-1.06z"
    ></path>
    <path
      fill="currentColor"
      d="M12 1.5a10.5 10.5 0 107.426 17.925l1.06 1.06A12 12 0 1112 0v1.5H12zM12 17.333V12zM12 12V6.667zm0 0h5.334zm0 0H6.666z"
    ></path>
    <path stroke="currentColor" strokeLinecap="round" d="M12 17.333V12m0 0V6.667M12 12h5.334M12 12H6.667"></path>
  </svg>
);

export default SvgComponent;
