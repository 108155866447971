import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M0 2.143A2.143 2.143 0 012.143 0h4.286A2.143 2.143 0 018.57 2.143v4.286A2.143 2.143 0 016.43 8.57H2.143A2.143 2.143 0 010 6.43V2.143zm11.429 0A2.143 2.143 0 0113.57 0h4.286A2.143 2.143 0 0120 2.143v4.286a2.143 2.143 0 01-2.143 2.142h-4.286A2.143 2.143 0 0111.43 6.43V2.143zM0 13.57a2.143 2.143 0 012.143-2.142h4.286A2.143 2.143 0 018.57 13.57v4.286A2.143 2.143 0 016.43 20H2.143A2.143 2.143 0 010 17.857v-4.286zm11.429 0a2.143 2.143 0 012.142-2.142h4.286A2.143 2.143 0 0120 13.57v4.286A2.143 2.143 0 0117.857 20h-4.286a2.143 2.143 0 01-2.142-2.143v-4.286z"
    ></path>
  </svg>
);

export default SvgComponent;
