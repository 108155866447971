import React from "react";
import Modal from "react-modal";
import CloseModal from "../../../components/icons/CloseModal";

const ShowClipTitle = (props) => {
  const { titleModalOpen, setTitleModalOpen, clip } = props;

  const closeModal = () => {
    setTitleModalOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={titleModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Trim Clip"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title"> Show Title</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <div className="inner-table-container margin-top-custom">
            <div className="row margin-top-custom">
              <div className="col-md-12">
                <div className="table-resposive table-container modal-table-container">
                  <table className="table">
                    <tbody>
                      {Object.entries(clip?.aiTitle || {}).map((key) => (
                        <tr>
                          <td className="time-tag">{key[0]}</td>
                          <td className="time-tag">{key[1]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowClipTitle;
