import { Switch } from "antd";
import JSCookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Outlet, useLocation } from "react-router-dom";
import { cookieExpiry } from "../../../config/index";

import "./styles.scss";

const Cookies = () => {
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [customizeCookieIsOpen, setCustomizeCookieIsOpen] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    functional: true,
    analytics: true,
    performance: true,
    others: false,
  });

  const location = useLocation();

  useEffect(() => {
    if (!JSCookies.get("cookiesOptions")) {
      setTimeout(() => {
        setShowCookiePopup(true);
      }, 2000);
    }
  }, [location.pathname]);

  const cookies = [
    {
      title: "Necessary",
      description:
        "Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.",
      tag: "Always Active",
    },
    {
      title: "Functional",
      description:
        "Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms. collecting feedback, and other third-party features.",
      checkbox: true,
      default: true,
    },
    {
      title: "Analytics",
      description:
        "Analytical cookies are used to understand how visitors interact with the websites. Those cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc",
      checkbox: true,
      default: true,
    },
    {
      title: "Performance",
      description:
        "Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.",
      tag: "Always Active",
    },
    {
      title: "Others",
      description:
        "Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet",
      checkbox: true,
    },
  ];

  const handleRejectCookie = async () => {
    const cookieOptions = {
      necessary: true,
      functional: false,
      analytics: false,
      performance: true,
      others: false,
    };
    JSCookies.set("cookiesOptions", JSON.stringify(cookieOptions), cookieExpiry);
    setShowCookiePopup(false);
    setCustomizeCookieIsOpen(false);
  };

  const handleAcceptAllCookie = async () => {
    const cookieOptions = {
      necessary: true,
      functional: true,
      analytics: true,
      performance: true,
      others: true,
    };
    JSCookies.set("cookiesOptions", JSON.stringify(cookieOptions), cookieExpiry);
    setShowCookiePopup(false);
    setCustomizeCookieIsOpen(false);
  };

  const handleCustomizeCookie = () => {
    setShowCookiePopup(false);
    setCustomizeCookieIsOpen(true);
  };

  const onToggleChange = (e, title) => {
    if (title === "Analytics") {
      setCookiePreferences({
        ...cookiePreferences,
        analytics: e,
      });
    } else if (title === "Others") {
      setCookiePreferences({
        ...cookiePreferences,
        others: e,
      });
    } else {
      setCookiePreferences({
        ...cookiePreferences,
        functional: e,
      });
    }
  };

  const handleAcceptCookie = async () => {
    JSCookies.set("cookiesOptions", JSON.stringify(cookiePreferences), cookieExpiry);
    setShowCookiePopup(false);
    setCustomizeCookieIsOpen(false);
  };

  const cookieInfoText = (
    <p className="small-text">
      (Even if you opt to decline all cookies, <b>necessary</b> and <b>performance</b> cookies will remain enabled, as
      they are vital for the proper operation of the application.)
    </p>
  );

  const closeModal = () => {
    setCustomizeCookieIsOpen(false);
    setShowCookiePopup(true);
  };

  return (
    <div className="cookie-container">
      <Outlet />
      <Modal
        isOpen={customizeCookieIsOpen}
        onRequestClose={closeModal}
        contentLabel="Filter Modal"
        ariaHideApp={false}
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={true}
        className="cookie-customize-container"
      >
        <h4>Customize Consent Preferences</h4>
        <div className="divider"></div>
        <div>
          <p>
            We use cookies to help you navigate efficiently and perform certain functions. You will find detailed
            information about all cookies under each consent category below.
          </p>
          <p>
            The cookies that are categorized as "Necessary" are stored on your browser as they are essential for
            enabling the basic functionalities of the site.
          </p>
          <p>
            We also uses third-party cookies that help us analyze how you use this website, store your preferences, and
            provide the content and advertisements that are relevant to you. These cookies will only be stored in your
            browser with your previous consent.
          </p>
          <p>
            You can choose to enable or disable some or all of these cookies but disabling some of them may affect your
            browsing experience.
          </p>
          <p>
            If at any point in the future you wish to modify your preferences kindly take the necessary action of
            clearing your cookies.
          </p>
        </div>
        <div>
          {cookies.map((cookie) => (
            <div className="cookie-box" key={cookie.title}>
              <div className="divider"></div>
              <div className="cookie-box-row">
                <h6>{cookie.title}</h6>
                {cookie.checkbox && (
                  <Switch
                    className="small-switcher"
                    disabled={false}
                    defaultChecked={cookie.default}
                    onClick={(e) => {
                      onToggleChange(e, cookie.title);
                    }}
                  />
                )}
              </div>
              <p>{cookie.description}</p>
              {cookie.tag && <span className="cookie-tag">{cookie.tag}</span>}
            </div>
          ))}
        </div>
        <div className="cookie-box">
          <div className="cookie-btn-container">
            <button className="btn btn-border cookie-btn" onClick={handleRejectCookie}>
              Reject All
            </button>
            <button className="btn btn-border cookie-btn" onClick={handleAcceptCookie}>
              Accept
            </button>
            <button className="btn btn-primary cookie-btn" onClick={handleAcceptAllCookie}>
              Accept All
            </button>
          </div>
          {cookieInfoText}
        </div>
      </Modal>
      {showCookiePopup && (
        <div className="cookie-popup">
          <div>
            <h3>We value your privacy</h3>
            <p className="cookie-content">
              We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our
              traffic. By clicking "Accept All", you consent to our use of cookies.
            </p>
          </div>
          <div className="cookie-box">
            <div className="cookie-btn-container">
              <button className="btn btn-border cookie-btn" onClick={handleCustomizeCookie}>
                Customize
              </button>
              <button className="btn btn-border cookie-btn" onClick={handleRejectCookie}>
                Reject All
              </button>
              <button className="btn btn-primary cookie-btn" onClick={handleAcceptAllCookie}>
                Accept All
              </button>
            </div>
            {cookieInfoText}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cookies;
