import React, { useContext, useEffect } from "react";
import LiveModalContext from "../../../views/ClipsNew/context";
import ReactPlayer from "react-player";
import { secondsToString, millisecondsToString, milliConversion } from "../../../constants/phase";
import { useOutletContext } from "react-router-dom";
import ManageAccess from "../ManageAccess/manageAccess";
import defaultThumbNail from "../../../images/defaulThumb.png";
const ReactPlayerComponent = ({
  playerRef,
  onProgress,
  playVideo,
  streamTime,
  playedSeconds,
  onBufferEnd,
  handleVideoPlayer,
  onBuffer,
  onSeekMouseUp,
  onSeekMouseDown,
  isMute,
  playBackSpeed,
}) => {
  const { liveVideoUrl, controls, page } = useContext(LiveModalContext);
  const currentDuration = playerRef.current?.getCurrentTime();
  const { clientSetting } = useOutletContext();

  useEffect(() => {
    const { startTime } = streamTime;
    if (playerRef.current && startTime) {
      playerRef.current.seekTo(startTime / milliConversion, "seconds");
    }
  }, [playerRef.current, streamTime.startTime]);

  const currentDurationStr = secondsToString(currentDuration);
  const endTimeStr = millisecondsToString(streamTime.endTime);
  const min = streamTime.startTime / milliConversion;
  const max = streamTime.endTime / milliConversion;

  return (
    <>
      <div className="video-preview position-relative">
        <ReactPlayer
          className="VideoPlayerComponent"
          url={liveVideoUrl.replace("index.m3u8", "index_360p.m3u8")}
          ref={playerRef || ""}
          width="100%"
          height="100%"
          controls={controls || false}
          playing={playVideo}
          onProgress={onProgress}
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          playbackRate={playBackSpeed}
          muted={isMute}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", // Hide the download and playbackspeed options
                poster: defaultThumbNail,
                onContextMenu: (e) => clientSetting.isProcessingLimit && e.preventDefault(),
              },
            },
          }}
        />
        <ManageAccess value={page !== "clips"}>
          <div className="PlayerSeekbarComponent flex-wrap">
            <input
              type="range"
              className="video-seekbar"
              value={playedSeconds}
              min={min}
              max={max < playedSeconds ? 0 : max}
              step={0.01}
              onChange={handleVideoPlayer}
              onMouseDown={onSeekMouseUp}
              onMouseUp={onSeekMouseDown}
            />
          </div>
        </ManageAccess>
      </div>
      <ManageAccess value={page !== "clips"}>
        <div className="flex-grow-1 count-stream-bar-text">
          {<span>{currentDurationStr}</span>}
          <span>/</span>
          {<span>{endTimeStr}</span>}
        </div>
      </ManageAccess>
    </>
  );
};

export default ReactPlayerComponent;
