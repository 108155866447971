import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const AntdSpinner = ({ children, spin, style, className }) => {
  return (
    <div>
      <Spin
        size="large"
        spinning={spin}
        indicator={<LoadingOutlined style={style ? style : null} />}
        className={className ? className : ""}
      >
        {children}
      </Spin>
    </div>
  );
};

export default AntdSpinner;
