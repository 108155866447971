import { CloudUploadOutlined, StarFilled } from "@ant-design/icons";
import { Checkbox, Dropdown, Tag, Tooltip } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../../components/common/Image/component";
import PreviewDialog from "../../../components/common/Modals/PreviewDialog";
import TheeDotsIcon from "../../../components/icons/TheeDotsIcon";
import { fetchTagsFromClip } from "../../../helpers";
import defaultThumbNail from "../../../images/defaulThumb.png";
import TableAction from "./TableAction";

const VideoList = (props) => {
  const {
    id,
    filters,
    updatesftp,
    ftpOpen,
    setFTPOpen,
    getAspectRatios,
    clipsDetails,
    deleteClips,
    copyClip,
    ftpPublish,
    editClip,
    allClips,
    publishClip,
    streamDetails,
    getTrimClip,
    fetchStorage,
    s3BucketPublish,
    storage,
    videoType,
    folderArray,
    setFolderArray,
    allClipsForCms,
    setShowClass,
    showClass,
    checkhighLight,
    showCheck,
    clipExportJson,
    clipExportXml,
    user,
    downloadClips,
    modalIsOpen,
    setIsOpen,
    clipUrl,
    openPreviewModal,
    clipTitle,
    dumpOnS3,
    aspectRatio,
    bucketCopy,
    fetchUserDetails,
    copyClipToReviewer,
    clientWebHookPublish,
    jsonBucketPublish,
    getPublicUrls,
    uploadClipInBrightCove,
    fetchBrightCoveToken,
    getCustomFields,
    clientSetting,
    addThumbnail,
    generateAutoFlip,
    setToggle,
    toggle,
    step,
    ftpList,
    deleteEditedClip,
    singleClipDetail,
    clipListFolder,
    GenerateVideoTitle,
    genrateNewClip,
    clipTaggingFields,
    bytesToMB,
    getS3PresignedURl,
    clipDetail,
  } = props;
  const navigate = useNavigate();
  const navClipId = (clip) => {
    if (clip) {
      const clipid = clip?._id;
      let aspectR = clip?.aspectRatio ? clip?.aspectRatio : "16:9";
      if (aspectRatio) {
        aspectR = aspectRatio;
      }
      navigate(`/clip-info/${clipid}?status=edits&ratio=${aspectR}`);
    }
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const tableAction = (clip) => {
    return (
      <TableAction
        id={id}
        updatesftp={updatesftp}
        setFTPOpen={setFTPOpen}
        ftpOpen={ftpOpen}
        deleteEditedClip={deleteEditedClip}
        clipListFolder={clipListFolder}
        generateAutoFlip={generateAutoFlip}
        setToggle={setToggle}
        toggle={toggle}
        ftpPublish={ftpPublish}
        genrateNewClip={genrateNewClip}
        getAspectRatios={getAspectRatios}
        clip={clip}
        step={step}
        deleteClips={deleteClips}
        copyClip={copyClip}
        editClip={editClip}
        allClips={allClips}
        allClipsForCms={allClipsForCms}
        aspectRatio={aspectRatio}
        publishClip={publishClip}
        clipsDetails={clipsDetails}
        streamDetails={streamDetails}
        getTrimClip={getTrimClip}
        fetchStorage={fetchStorage}
        s3BucketPublish={s3BucketPublish}
        jsonBucketPublish={jsonBucketPublish}
        storage={storage}
        ftpList={ftpList}
        folderArray={folderArray}
        setFolderArray={setFolderArray}
        setShowClass={setShowClass}
        showClass={showClass}
        checkhighLight={checkhighLight}
        clipExportJson={clipExportJson}
        clipExportXml={clipExportXml}
        downloadClips={downloadClips}
        user={user}
        openPreviewModal={openPreviewModal}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        clipUrl={clipUrl}
        clipTitle={clipTitle}
        dumpOnS3={dumpOnS3}
        bucketCopy={bucketCopy}
        clientWebHookPublish={clientWebHookPublish}
        fetchUserDetails={fetchUserDetails}
        copyClipToReviewer={copyClipToReviewer}
        getPublicUrls={getPublicUrls}
        uploadClipInBrightCove={uploadClipInBrightCove}
        fetchBrightCoveToken={fetchBrightCoveToken}
        getCustomFields={getCustomFields}
        clientSetting={clientSetting}
        addThumbnail={addThumbnail}
        singleClipDetail={singleClipDetail}
        GenerateVideoTitle={GenerateVideoTitle}
        getS3PresignedURl={getS3PresignedURl}
        {...props}
      />
    );
  };

  const handleClip = (clip) => {
    const array = [...folderArray];
    const index = array.map(({ _id }) => _id).indexOf(clip._id);
    if (index === -1) {
      setFolderArray([...array, clip]);
    } else {
      array.splice(index, 1);
      setFolderArray(array);
    }
  };

  const setClass = (e) => {
    if (e.target.checked === true) {
      setShowClass("vv-video-card selected");
    } else {
      setShowClass("vv-video-card");
    }
  };
  return (
    <>
      <PreviewDialog
        clipTitle={clipTitle}
        clipUrl={clipUrl}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        streamId={id}
        clip={clipDetail}
        duration={clipDetail.duration}
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
      {clipsDetails &&
        clipsDetails.map((clip) => {
          let eventsArray = clip?.filter?.split(",") || [];
          eventsArray = [...eventsArray, ...fetchTagsFromClip(clip.clipData, clipTaggingFields)];
          clip?.clipData?.events?.map((obj) => {
            eventsArray.push(obj.type);
            eventsArray.push(obj.playerName);
          });
          eventsArray.push(...(clip?.clipData?.outcome?.split(",") || []));
          eventsArray = eventsArray.filter((n) => n);
          eventsArray = [...new Set(eventsArray)];
          return (
            <tr key={clip._id}>
              <td>
                <div className="table-name-img vv-video-card ">
                  {checkhighLight === "Create Highlight" || checkhighLight === "Create story" ? (
                    <Checkbox
                      className="round-checkbox me-3 dark-checkbox"
                      onChange={() => handleClip(clip)}
                      onClick={(e) => setClass(e)}
                      checked={folderArray.map(({ _id }) => _id).indexOf(clip._id) !== -1 ? true : false}
                    />
                  ) : (
                    ""
                  )}
                  <div
                    className="img-container "
                    // onClick={() => {
                    //   openModal(videoType === 1 ? clip.videoUrl : clip.potriat_video_url);
                    // }}
                    onClick={() => navClipId(clip)}
                  >
                    {clip.videoThumbnailUrl ? (
                      <Image
                        src={clip.videoThumbnailUrl}
                        // width={100}
                        // height={80}
                        // style={{ cursor: "pointer", objectFit: "cover" }}
                        alt=""
                        className="matchImageIcon"
                      />
                    ) : (
                      <Image src={defaultThumbNail} alt="" className="matchImageIcon" />
                    )}
                  </div>
                  <div className="word-break-two title_Pascal">
                    <Tooltip title={clip.episodeTitle}>{clip.episodeTitle ? clip.episodeTitle : "--"}</Tooltip>
                  </div>
                </div>
              </td>
              <td>{filters?.aspectRatio || clip?.aspectRatio}</td>
              <td>{bytesToMB(clip?.storageConsumed)}</td>
              <td>
                {moment(get(clip, "createdAt", "")).format("ll")}, {moment(clip.createdAt).format("h:mm A")}
              </td>
              <td>
                {clip.start_time && clip.end_time && (
                  <>
                    {moment()
                      .startOf("day")
                      .seconds(parseInt(clip.trim_start_time || clip.start_time))
                      .format("H:mm:ss")}{" "}
                    -{" "}
                    {moment()
                      .startOf("day")
                      .seconds(parseInt(clip.trim_end_time || clip.end_time))
                      .format("H:mm:ss")}
                  </>
                )}
              </td>
              {/* <td>{clip.clipData?.outcome}</td> */}
              <td>
                <div>
                  {/* {clip?.story_published_status === "published" && (
            <Tag>
              <strong>{"P"}</strong>
            </Tag>
          )} */}

                  {eventsArray
                    ?.filter((e) => e)
                    .map((event, i) => {
                      if (i === 0) {
                        return event ? (
                          <Tooltip title={event}>
                            {" "}
                            <Tag key={event}> {event.substring(0, 15) + (event.length >= 15 ? "..." : "")}</Tag>
                          </Tooltip>
                        ) : null;
                      }
                      return null;
                    })}
                  {eventsArray?.filter((e) => e).length > 1 ? (
                    <Tooltip
                      className="title_Pascal"
                      title={eventsArray
                        .map((events, index) => {
                          return (
                            <span className="text-capitalize" key={index}>
                              {events},{" "}
                            </span>
                          );
                        })
                        ?.filter((e, i) => (i !== 0 ? e : null))}
                    >
                      <Tag className="z-99">+{eventsArray?.filter((e, i) => (i !== 0 ? e : null)).length}</Tag>
                    </Tooltip>
                  ) : null}
                </div>
              </td>
              <td>
                {moment
                  .utc(
                    (clip?.editedVideos?.find((o) => o?.aspect_ratio === aspectRatio)?.duration || clip.duration) *
                      1000,
                  )
                  .format("mm:ss")}
              </td>
              {clientSetting?.appAdditionalPermissions?.hasOwnProperty("showClipTAT") && (
                <td>{clip?.timeTaken ? moment.utc(clip.timeTaken * 1000).format("mm:ss") : ""}</td>
              )}

              {clip?.clipRating !== 0 ? (
                <td className="clips-card-rating-block ms-2">
                  <StarFilled style={{}} /> {`${clip?.clipRating} / 5`}{" "}
                </td>
              ) : (
                <td></td>
              )}

              <td>
                <div className="table-action-block text-center">
                  {clip?.isManualUpload && (
                    <CloudUploadOutlined className="vv-video-preview-btn vv-video-preview-btn-icon" />
                  )}
                  <div className="vv-video-preview-btn me-3" onClick={() => openPreviewModal(clip)}>
                    Preview
                  </div>
                  <Dropdown
                    className=""
                    overlay={tableAction(clip)}
                    trigger={["click"]}
                    overlayClassName={"tableActionBlock"}
                    placement={"bottomRight"}
                  >
                    <a className="ant-dropdown-link">
                      <TheeDotsIcon />
                    </a>
                  </Dropdown>
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default VideoList;
