import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <circle cx="13" cy="13" r="13" fill="#1A121F"></circle>
      <path
        fill="#FF004F"
        d="M16.7 10.587a6.842 6.842 0 003.972 1.262v-2.83c-.28 0-.56-.029-.834-.086v2.227a6.843 6.843 0 01-3.973-1.262v5.774c0 2.889-2.359 5.23-5.268 5.23a5.271 5.271 0 01-2.934-.885 5.272 5.272 0 003.768 1.574c2.91 0 5.269-2.342 5.269-5.23v-5.774zm1.029-2.855a3.93 3.93 0 01-1.03-2.308V5.06h-.79a3.961 3.961 0 001.82 2.672zM9.504 17.801a2.37 2.37 0 01-.492-1.448 2.401 2.401 0 012.41-2.393c.248 0 .495.038.731.112V11.18a5.37 5.37 0 00-.834-.048v2.252a2.431 2.431 0 00-.73-.113 2.401 2.401 0 00-2.411 2.393c0 .934.54 1.743 1.326 2.137z"
      ></path>
      <path
        fill="#fff"
        d="M15.865 9.898a6.844 6.844 0 003.973 1.262V8.933a3.995 3.995 0 01-2.11-1.2A3.961 3.961 0 0115.91 5.06h-2.077v11.3a2.402 2.402 0 01-2.41 2.385c-.783 0-1.478-.37-1.918-.944a2.39 2.39 0 01-1.326-2.137 2.401 2.401 0 012.41-2.392c.255 0 .5.039.732.112v-2.252c-2.86.059-5.158 2.377-5.158 5.229 0 1.423.572 2.713 1.501 3.656a5.272 5.272 0 002.934.885c2.91 0 5.269-2.341 5.269-5.23V9.898z"
      ></path>
      <path
        fill="#00F2EA"
        d="M19.838 8.932V8.33a3.994 3.994 0 01-2.11-.598 3.99 3.99 0 002.11 1.2zM15.909 5.06a3.99 3.99 0 01-.044-.325V4.37h-2.867v11.3a2.401 2.401 0 01-2.41 2.385c-.377 0-.748-.087-1.084-.255.44.574 1.136.944 1.918.944a2.402 2.402 0 002.41-2.384V5.06h2.077zm-4.59 6.072v-.641a5.361 5.361 0 00-.722-.049c-2.91 0-5.27 2.342-5.27 5.23 0 1.81.928 3.406 2.337 4.345a5.192 5.192 0 01-1.502-3.656c0-2.852 2.299-5.17 5.158-5.229z"
      ></path>
    </svg>
  );
}

export default Icon;
