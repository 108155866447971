import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { message, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Papa from "papaparse";
import DataTable from "react-data-table-component";

const ShowCSV = ({ ...props }) => {
  const { setIsShowCsv, isShowCsv, streamDetails } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState("");
  const [loader, setLoader] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 30, align: "center" }} spin />;

  const closeModel = () => {
    setIsShowCsv(false);
  };
  const getCsvUrl = () => {
    const categoryType = streamDetails?.fields.categoryType;
    const fileKey = categoryType === "performance" ? streamDetails?.joinedFileUpdateKey : streamDetails?.joinedFileKey;
    return `${streamDetails?.fields?.cdn_suffix}/${fileKey}`;
  };

  const getCsvData = async () => {
    let csvUrl = getCsvUrl();
    if (csvUrl !== "") {
      csvUrl = csvUrl.replace(".mp4", ".csv");
      const { getCsvData } = props;
      const { value } = await getCsvData({
        url: csvUrl,
      });
      if (value?.status) {
        setCsvData(value?.data);
      }
    } else {
      notification.error({ message: "Something went wrong" });
    }
    setLoader(false);
  };

  useEffect(() => {
    getCsvData();
  }, [streamDetails]);
  const parseCsvData = () => {
    Papa.parse(csvData, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data.length > 0) {
          const extractedColumns = Object.keys(result.data[0]).map((columnName) => ({
            name: columnName,
            selector: (row) => row[columnName],
          }));
          setColumns(extractedColumns);
          const extractedData = result.data.map((row, index) => ({
            id: index + 1,
            ...row,
          }));

          setData(extractedData);
        }
      },
      error: (error) => {
        notification.error({ message: `CSV parsing error:${error.message}` });
      },
    });
  };

  useEffect(() => {
    parseCsvData();
  }, [csvData]);

  return (
    <>
      <Modal
        isOpen={isShowCsv}
        ariaHideApp={false}
        onRequestClose={closeModel}
        contentLabel="Delete Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Export CSV</h5>
          </div>
          <div className="react-modal-body">
            {loader ? (
              <div className="bg-loader-fade">
                <Spin className="fade-loader" indicator={antIcon} />
                <div className="loader-back-text">
                  <h3>No Details Found</h3>
                </div>
              </div>
            ) : (
              <div className="content-control-block long styled-scroll">
                {" "}
                <DataTable columns={columns} data={data} pagination highlightOnHover />{" "}
              </div>
            )}
          </div>
          <div className="react-modal-footer text-center">
            <div className="react-foo-container">
              <button type="button" onClick={closeModel} className="btn btn-primary btn-white">
                Close{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowCSV;
