import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" fill="none" viewBox="0 0 18 22">
      <path
        fill="currentColor"
        d="M2.7 2.7A2.7 2.7 0 015.4 0h4.841c.537 0 1.052.214 1.431.594l5.285 5.284c.38.38.593.894.593 1.43V16.2a2.7 2.7 0 01-2.7 2.7H5.4a2.7 2.7 0 01-2.7-2.7V2.7zm2.7-1.35A1.35 1.35 0 004.05 2.7v13.5a1.35 1.35 0 001.35 1.35h9.45a1.35 1.35 0 001.35-1.35V8.1h-4.725A2.025 2.025 0 019.45 6.075V1.35H5.4zm5.4.28v4.445a.675.675 0 00.675.675h4.446L10.8 1.63zM0 4.05A1.35 1.35 0 011.35 2.7v13.5a4.05 4.05 0 004.05 4.05h9.45a1.35 1.35 0 01-1.35 1.35H5.319A5.319 5.319 0 010 16.281V4.05z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
