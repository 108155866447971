import { compact, flatten } from "lodash";
import CryptoJS from "crypto-js";
import { ASPECT_RATIO, getTimeInSeconds } from "./constants/phase";

export const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  );
  // if (window.focus) newWindow.focus();
};

export const fetchTagsFromClip = (clipDataObj = {}, tagListArr = []) => {
  let clipData = clipDataObj || {};
  let tagList = tagListArr || [];
  return tagList.reduce((tags, tagObj) => {
    if (tagObj?.showInTagList) {
      const tag = (clipData?.[tagObj?.key] || "").toString().trim();
      const tag2 = (clipData?.[tagObj?.name] || "").toString().trim();
      if (tag || tag2) {
        tags.push(tag);
        tags.push(tag2);
      }
    }
    tags.push(clipData.gameTime, clipData.heartbeat, clipData.shotType);
    return compact(flatten(tags));
  }, []);
};

export const encryptString = (stringToEncrypt) => {
  const encrypted = CryptoJS.AES.encrypt(stringToEncrypt, process.env.REACT_APP_AES_PASS).toString();
  return encrypted;
};

export const overwritePayload = ({ streamTime: clip, startTime, endTime }) => {
  const { liveVideoUrl, singleClip } = clip;
  const { stream: streamDetails } = singleClip;
  let currentPlayingURLData = null;
  if (streamDetails && !streamDetails.vod) {
    if (
      streamDetails.previousRecordingURLs &&
      singleClip.cutVersion &&
      streamDetails.previousRecordingURLs.length >= singleClip.cutVersion
    ) {
      currentPlayingURLData = streamDetails.previousRecordingURLs[singleClip.cutVersion - 1];
    }
  }
  let payload = {
    start_time: getTimeInSeconds(startTime),
    end_time: getTimeInSeconds(endTime),
    server_address: streamDetails?.server_address,
    streamId: singleClip?.streamId,
    title: singleClip?.title || "",
    video_url: streamDetails?.vod ? streamDetails?.url : liveVideoUrl,
    zee: false,
    env: "production",
    isMediaLive: "false",
    seiEnable: streamDetails?.seiEnable,
    playback_speed: 1,
    isOverWrite: true,
    clipId: singleClip?.id,
    jsonTemplate: singleClip?.jsonTemplate || {},
    filter: singleClip?.filter || "",
    editedClipData: singleClip?.editedClipData || {},
    vod: streamDetails?.vod,
    cutVersion: singleClip.cutVersion || 1,
    videoUrl: streamDetails?.vod ? streamDetails?.url : liveVideoUrl || streamDetails?.s3RecordedUrl || "",
    cutFromPreviousURL: currentPlayingURLData ? true : false,
    hls_s3_url: liveVideoUrl?.replace("index.m3u8", "index_360p.m3u8"),
    _id: singleClip._id,
  };
  if (streamDetails?.glacierRecoveredStream || streamDetails?.status === 1 || currentPlayingURLData) {
    payload.sourceUrl = true;
    payload.videoUrl = streamDetails?.vod
      ? streamDetails?.url
      : currentPlayingURLData?.s3RecordedUrl || streamDetails?.s3RecordedUrl || "";
  }
  return payload;
};
