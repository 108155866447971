import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" fill="none" viewBox="0 0 19 23" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.813 4.875h9.75c1.108 0 1.937.82 1.937 1.75v13.5c0 .93-.829 1.75-1.938 1.75h-9.75c-1.108 0-1.937-.82-1.937-1.75v-13.5c0-.93.829-1.75 1.938-1.75z"
    ></path>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.25 1h-9A2.25 2.25 0 001 3.25v13.5"
      opacity="0.5"
    ></path>
  </svg>
);

export default SvgComponent;
