import React, { useState } from "react";
import Modal from "react-modal";
import { CloseModal } from "../../icons";
import $ from "jquery";
import ReactPlayer from "react-player";
import { Tooltip, notification } from "antd";
import defaultThumbNail from "../../../images/defaulThumb.png";
import { useOutletContext } from "react-router-dom";
import AntdSpin from "../AntdSpinner/antd-spinner";
import { DownloadOutlined } from "@ant-design/icons";
import ManageAccess from "../ManageAccess/manageAccess";
import AlertDialog from "../../../components/common/Modals/AlertDialog";
import { ZEE_CLIENT } from "../../../constants/phase";
const PreviewDialog = ({
  modalIsOpen,
  clipUrl,
  imageUrl,
  slateUrl,
  setIsOpen,
  clipTitle,
  streamId,
  setAlertMessage,
  setIsAlertOpen,
  isAlertOpen,
  alertMessage,
  duration,
  hideDownloadBtn,
  clip,
  type,
}) => {
  const { getS3PresignedURl, clientSetting, getDownloadStatus } = useOutletContext();
  const [downloadLoder, setDownloadLoder] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  $(document).ready(function () {
    $("video").bind("contextmenu", function () {
      if (clientSetting.isProcessingLimit) return false;
    });
  });
  const downloadVideo = async () => {
    setDownloadLoder(true);
    const { value } = await getS3PresignedURl({
      url: clipUrl,
      streamId,
      duration,
      title: clipTitle ? clipTitle : undefined,
      clipId: clip._id,
      type: type || "clip",
    });
    if (value.status && value.data?.url) {
      const link = document.createElement("a");
      link.href = value.data?.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      getDownloadStatus({ userId: localStorage.getItem("__uid"), streamId });
    } else {
      if (value.limitError) {
        setIsAlertOpen(true);
        setAlertMessage(value.message);
        closeModal();
      } else {
        notification["error"]({ message: "Something went wrong" });
      }
    }
    setDownloadLoder(false);
  };

  return (
    <>
      <AlertDialog
        alertModel={isAlertOpen}
        content={alertMessage}
        oncloseAlertModel={() => {
          setIsAlertOpen(false);
        }}
      />
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-xl modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            {/* <h5 className="react-modal-title">Clip Preview</h5> */}
            <h5 className="react-modal-title word-break-two title_Pascal">
              <Tooltip title={clipTitle ? clipTitle : ""}>{clipTitle ? clipTitle : ""}</Tooltip>
            </h5>
            <div>
              <ManageAccess
                value={
                  !imageUrl &&
                  !slateUrl &&
                  !hideDownloadBtn &&
                  (clientSetting?.appAdditionalPermissions?.downloadClipButton ||
                    clientSetting?.appAdditionalPermissions?.downloadHighlightButton)
                }
              >
                <Tooltip title="Download">
                  <button className="btn btn-white download-video-btn" onClick={() => downloadVideo()}>
                    <AntdSpin spin={downloadLoder}>
                      <DownloadOutlined style={{ fontSize: "25px" }} />
                    </AntdSpin>
                  </button>
                </Tooltip>
              </ManageAccess>
              <button type="button" onClick={closeModal} className="btn btn__default">
                <CloseModal />
              </button>
            </div>
          </div>
          <div className="react-modal-body">
            <ManageAccess value={imageUrl}>
              <div className="image-preview-modal-container mx-auto text-center">
                <img src={imageUrl} alt="Image Preview" className="" />
              </div>
            </ManageAccess>
            <ManageAccess value={!imageUrl}>
              <div className="video-preview" width="100%" height="100%">
                <ReactPlayer
                  url={clipUrl ? clipUrl : slateUrl}
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: type === "highlight" ? "nodownload" : "nodownload noplaybackrate", // Hide the download and playbackspeed options
                        poster: defaultThumbNail,
                        disablePictureInPicture: true,
                        ...(clientSetting?.appAdditionalPermissions?.isHideRightClick && {
                          onContextMenu: (e) => e.preventDefault(),
                        }), // Conditionally include onContextMenu based condition to prevent right click on video player
                      },
                    },
                  }}
                  playing={true}
                  width="100%"
                  height="100%"
                />
              </div>
            </ManageAccess>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PreviewDialog;
