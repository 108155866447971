import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { notification } from "antd";

const Layout = ({ fetchMe }, props) => {
  useEffect(async () => {
    try {
      const {
        value: { data },
      } = await fetchMe();
    } catch (error) {
      if (error.statusCode === 401) {
        notification["info"]({ message: "Session Expired !! Please login again.." });
        setTimeout(() => {
          window.location.pathname = "/login";
        }, 2000);
      }
    }
  }, []);

  const isAuthenticated = localStorage.getItem("authToken");
  return isAuthenticated ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};
export default Layout;
