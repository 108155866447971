import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" fill="none" viewBox="0 0 20 17" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18.857 5.286l-5 6.428m0-6.428l5 6.428M1 5.286v6.428h3.571L10.286 16V1L4.57 5.286H1z"
    ></path>
  </svg>
);

export default SvgComponent;
