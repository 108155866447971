import React from "react";
import BlankState from "../../../components/common/BlankState/component";
import { LiveIcon } from "../../../components/icons";

const AddClipsEmptyState = (props) => {
  const { step } = props;
  return (
    <>
      <BlankState
        inlineStyle={{ minHeight: "calc(100vh - 246px)" }}
        icon={<LiveIcon />}
        title={`You don’t have any ${step === 1 ? "clip" : step === 2 ? "highlight" : "promo"} right now!`}
      />
    </>
  );
};

export default AddClipsEmptyState;
