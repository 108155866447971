import React, { useState, useEffect, useRef } from "react";
import { UploadDropzone } from "../../../components/icons";
import { Progress } from "antd";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import ClientSideServices from "./partials/clientSideService";
import _ from "lodash";
import { notification } from "antd";
var MAX_SIZE = 1048576 * 200; // 1048576 bits == 1 MB so max file upload is 500 MB
const ClientSideService = new ClientSideServices();
function ClientSideUpload(props) {
  const { setUploadUrl, storageCred, acceptFileType, callBackFun, setDisableEdit, uploadRef, setIsAbort } = props;
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [isMaxSizeShowMessage, setIsMaxSizeShowMessage] = useState({});
  useEffect(() => {
    if (!_.isEmpty(storageCred)) {
      credFun(storageCred);
    }
  }, [storageCred]);

  const credFun = async (storageCred) => {
    let credentials = (await ClientSideService.decrypt_storage_credentials(storageCred, "aws")) || {};
    credentials.region = credentials.region?.replace("s3.", "")?.replace(".amazonaws.com", "")?.replace("s3-", "");

    setCredentials(credentials);
  };

  const onUpload = async (files) => {
    setIsMaxSizeShowMessage({});
    setProgress(0);
    let type = files[0].type.split("/")[1];
    if (type !== "mp4") {
      setIsMaxSizeShowMessage({ message: "Only allows files in the MP4 format." });
      return;
    }
    if (!_.isEmpty(files) && files[0].size < MAX_SIZE) {
      setDisableEdit(true);
      setIsAbort(true);
      let file = files[0];
      let name = Math.random().toString(36).substring(2, 10);
      name = (name + file?.name?.replace(/ +/g, "")).replace(/[{()}]/g, "");
      try {
        setUploading(true);
        AWS.config.update({
          accessKeyId: credentials.access_key,
          secretAccessKey: credentials.secret_key,
          region: credentials.region,
        });

        const s3 = new AWS.S3({
          params: {
            Bucket: credentials.bucket_name,
          },
        });

        let Key = `uploadFile/${name}`;

        const obj = {
          Body: file,
          Key,
        };
        let parallelUploads3 = s3.upload(obj, (err, data) => {
          if (err) {
            notification["error"]({ message: err.message });
            setUploading(false);
            setProgress(0);
          } else if (data) {
            let location = credentials.cdn_suffix + data.Location.split("amazonaws.com/")[1];
            setUploadUrl(location);
            setUploading(false);
          }
        });
        uploadRef.current = parallelUploads3;
        parallelUploads3.on("httpUploadProgress", (progress) => {
          setProgress(parseFloat((progress.loaded * 100) / progress.total).toFixed(2));
        });
      } catch (e) {
        notification["error"]({ message: "Something went wrong" });
      }
    } else {
      setIsMaxSizeShowMessage({ message: "Please select clip less than 200 MB" });
    }
  };
  return (
    <div className="form-group">
      <Dropzone
        onDrop={(acceptedFiles) => {
          onUpload(acceptedFiles);
        }}
        accept={acceptFileType}
        disabled={uploading}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dropzone-inner" {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              <div className="uploadClip-title">Drag file here</div>
              <div className="uploadClip-subtitle">or, if you prefer</div>
              <div className="uploadclip-inside">Choose file to add</div>
              {/* <div className="uploadClip-subtitle">size: upto 200 MB only</div> */}
              {_.isEmpty(isMaxSizeShowMessage) ? (
                <div className="uploadClip-subtitle">size: upto 200 MB only</div>
              ) : (
                <div className="uploadClip-subtitle" style={{ color: "red" }}>
                  {isMaxSizeShowMessage.message}
                </div>
              )}
              <Progress strokeLinecap="butt" type="line" percent={progress} /> {progress > 0 ? `${progress} %` : ""}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}

export default ClientSideUpload;
