import * as React from "react";

const SvgComponent = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M12.773 1.528A10.497 10.497 0 0 0 12 1.5V0a12 12 0 0 1 .883.033l-.11 1.496Zm3.005.675c-.48-.185-.974-.335-1.477-.448L14.63.291c.574.129 1.14.3 1.688.513l-.54 1.4Zm2.056 1.066a10.52 10.52 0 0 0-.659-.405l.74-1.305c.512.29 1.003.618 1.468.98l-.922 1.184c-.204-.159-.413-.31-.627-.453v-.002Zm2.75 2.684c-.296-.421-.624-.82-.979-1.194l1.086-1.035c.405.428.78.885 1.12 1.365l-1.227.864Zm1.117 2.028a10.627 10.627 0 0 0-.322-.702l1.34-.675c.265.527.49 1.073.675 1.632l-1.425.47c-.08-.245-.17-.486-.268-.724Zm.794 3.761a10.486 10.486 0 0 0-.15-1.538l1.478-.255c.1.58.159 1.168.174 1.755l-1.5.038h-.002Zm-.196 2.307c.05-.255.09-.508.122-.765l1.489.184a11.935 11.935 0 0 1-.345 1.733l-1.446-.4c.069-.248.129-.498.18-.752Zm-1.428 3.569c.276-.435.519-.891.729-1.362l1.371.607c-.24.54-.518 1.059-.832 1.557l-1.268-.803Zm-1.446 1.807c.183-.183.358-.372.525-.567l1.137.98c-.192.223-.393.439-.602.647l-1.06-1.06Z"
        fill="#fff"
      />
      <path d="M12 1.5a10.5 10.5 0 1 0 7.426 17.925l1.06 1.06A12 12 0 1 1 12.001 0v1.5Z" fill="#fff" />
      <path d="M12.001 17.333V12m0 0V6.667m0 5.333h5.334M12 12H6.668" stroke="#fff" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
