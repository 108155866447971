import * as React from "react";

const SvgComponent = (props) => (
  <svg width={12} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M2.978 24V12.739H0V8.684h2.978V5.22C2.978 2.499 4.737 0 8.79 0c1.64 0 2.854.157 2.854.157l-.095 3.787s-1.238-.012-2.588-.012c-1.462 0-1.696.673-1.696 1.791v2.96h4.4l-.192 4.056H7.265V24H2.978Z"
        fill="#282828"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h11.665v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
