import { fetch } from "../../utils";
import { get } from "lodash";
const HostName = process.env.REACT_APP_API_HOSTNAME;
const NewHostName = process.env.REACT_APP_NEW_API_HOSTNAME;

export const LTWeeklyData = (value) => {
  return fetch(
    `${HostName}/api/CustomItem/GetLTWeeklies?count=${get(value, "limit", 10)}&page=${get(value, "pageNumber", 1)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getltWeeklyByDate = (value) => {
  return fetch(
    `${HostName}/api/CustomItem/GetLTWeeklyByDate?date=${get(value, "date", "")}&direction=${get(
      value,
      "direction",
      "",
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetLTWeeklyArchiveList = (value) => {
  return fetch(`${HostName}/api/CustomItem/GetLTWeeklyArchiveList?year=${get(value, "year", 2022)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDashReports = (data) =>
  fetch(`${NewHostName}/dash_reports`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("authToken"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
