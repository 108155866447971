import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { notification } from "antd";

const NewHost = process.env.REACT_APP_NEW_API_HOSTNAME;

const ErrorBoundaryComponent = ({ error, componentStack, resetErrorBoundary, isOpen }) => {
  const sendErrorReport = (data) => {
    //api call that send error to support
    return new Promise((resolve, reject) => {
      axios
        .post(`${NewHost}/error_report`, data)
        .then((res) => {
          if (res?.data?.status) {
            notification["success"]({ message: "Report Sent Successfully!" });
            resolve({
              success: true,
              data: res?.data?.data,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((error) => {
          resolve({
            success: false,
          });
        });
    });
  };

  const handleError = (isSendReport) => {
    if (isSendReport) {
      //if user select to send report
      const data = {
        message: {
          userId: localStorage.getItem("__uid"),
          location: window.location.href.toString(),
          error: error?.message.toString(),
          stack: error?.stack.toString(),
          createdAt: new Date(),
        },
      };
      sendErrorReport(data);
    }
    window.location.pathname = "/dashboard";
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        // onRequestClose={closeFolderModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Error Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Error</h5>
          </div>
          <div className="react-modal-body">
            <div role="alert">
              <code>
                <pre>{error?.message}</pre>
              </code>
              <code>
                <pre>{error?.stack}</pre>
              </code>
              {componentStack ? (
                <code>
                  <pre>{componentStack}</pre>
                </code>
              ) : null}
              <div className="react-modal-footer">
                <button type="button" className="btn btn-white" onClick={() => handleError()}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" onClick={() => handleError("sendReport")}>
                  Send Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ErrorBoundaryComponent;
