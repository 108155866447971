import { connect } from "react-redux";
import { fetchMe, getClientSetting, getUserProcessingLimit, fetchAccessControl } from "../../store/user/duck";
import { getStreamStates } from "../../store/sportkeymoment/duck";
import { downloadAsZip, getS3PresignedURl, getDownloadStatus } from "../../store/videogrid/duck";
import LayoutComponent from "./component";

const LayoutContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    fetchMe,
    getClientSetting,
    getStreamStates,
    getUserProcessingLimit,
    fetchAccessControl,
    downloadAsZip,
    getS3PresignedURl,
    getDownloadStatus,
  },
)(LayoutComponent);

export default LayoutContainer;
