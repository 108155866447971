import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { CloseModal } from "../../icons";
import s from "../../../views/EditorPage/Timeline.module.css";
import CommonJsonContext from "./commonJsonContext";

const CommonJsonDetails = () => {
  const { openJsonDetails, setOpenJsonDetails, jsonData, modalHeader } = useContext(CommonJsonContext);
  const antIcon = <LoadingOutlined style={{ fontSize: 30, align: "center" }} spin />;
  const [loader, setLoader] = useState(true);

  const handleModalClose = () => {
    setOpenJsonDetails(false);
  };

  useEffect(() => {
    if (Object.keys(jsonData).length !== 0) {
      setLoader(false);
    }
  }, [jsonData]);

  return (
    <>
      <Modal
        isOpen={openJsonDetails}
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">{modalHeader}</h5>
            <button type="button" onClick={handleModalClose} className="btn btn__default">
              <CloseModal />
            </button>
          </div>

          {loader ? (
            <>
              <div className="bg-loader-fade">
                <Spin className="fade-loader" indicator={antIcon} />
                <div className="loader-back-text">
                  <h3>No Details Found</h3>
                </div>
              </div>
            </>
          ) : (
            <div className="react-modal-body">
              <ul className={`${s.TimelineTagsList} ${s.TimelineTagsListModal}`}>
                {Object.keys(jsonData).length !== 0 ? (
                  Object.entries(jsonData).map(function ([key, value]) {
                    return (
                      <li className={s.TimelineTagsListItem} key={1}>
                        <span className={s.TimelineTagsTitle}>{key.replace(/_/g, " ")}</span>
                        <span className={s.TimelineTagsInfo} key={value}>
                          {value.toString()}
                        </span>
                      </li>
                    );
                  })
                ) : (
                  <div className="text-center pt-5 pb-5">
                    <h3>No Details Found</h3>
                  </div>
                )}
              </ul>
            </div>
          )}
          <div className="react-modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleModalClose}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CommonJsonDetails;
