import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" fill="none" viewBox="0 0 22 17" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.571 6s1.508.714 1.508 2.5-1.508 2.5-1.508 2.5m1.508-9.286c3.016 1.429 4.524 3.572 4.524 6.786 0 3.214-1.508 5.357-4.524 6.786M1 5.286v6.428h3.77L10.802 16V1L4.77 5.286H1z"
    ></path>
  </svg>
);

export default SvgComponent;
