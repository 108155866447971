import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" fill="none" viewBox="0 0 23 17" {...props}>
    <path
      fill="currentColor"
      d="M1 1.91a.909.909 0 011.476-.71l8.237 6.59a.909.909 0 010 1.42L2.476 15.8A.909.909 0 011 15.09V1.91zM11.6 1.91a.909.909 0 011.476-.71l8.237 6.59a.909.909 0 010 1.42l-8.237 6.59a.909.909 0 01-1.476-.71V1.91z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1 1.91a.909.909 0 011.476-.71l8.237 6.59a.909.909 0 010 1.42L2.476 15.8A.909.909 0 011 15.09V1.91zM11.6 1.91a.909.909 0 011.476-.71l8.237 6.59a.909.909 0 010 1.42l-8.237 6.59a.909.909 0 01-1.476-.71V1.91z"
    ></path>
  </svg>
);

export default SvgComponent;
