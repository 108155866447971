import "rxjs";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import home from "./home/duck";
import user from "./user/duck";
import sportkeymoment, { dashboardEpic } from "./sportkeymoment/duck";

import videogrid, { videogridEpic } from "./videogrid/duck";
import App from "../app/App";

// Bundling Epics
// const rootEpic = combineEpics(dashboardEpic,videogridEpic)
const rootEpic = combineEpics(dashboardEpic, videogridEpic);

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware();

// Define Middleware
const middleware = [thunk, promise, epicMiddleware];

// Define Reducers
const reducers = combineReducers({
  home,
  App,
  user,
  sportkeymoment,
  videogrid,
  form: formReducer,
});

// Create Store
// const store = createStore(
//   reducers,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   applyMiddleware(...middleware)
// )
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancer(applyMiddleware(...middleware)));

epicMiddleware.run(rootEpic);
export default store;
