import * as React from "react";

const Close = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M15 15L1 1m14 0L1 15"></path>
    </svg>
  );
};
export default Close;
