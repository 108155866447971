import { connect } from "react-redux";

import LayoutComponent from "./component";
import { fetchMe } from "../../store/user/duck";

const LayoutContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  { fetchMe },
)(LayoutComponent);

export default LayoutContainer;
