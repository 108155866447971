import React from "react";
import Modal from "react-modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { numberFormat } from "../../../config/index";
import { editClipValidation } from "../../../utils/validations";
import CloseModal from "../../../components/icons/CloseModal";
const EditClip = (props) => {
  const { editOpen, setEditOpen, clip, loder, editClips, publishClips } = props;
  const initialState = {
    title: clip.episodeTitle,
    start_time: numberFormat(clip.start_time || 0),
  };
  const handleSubmit = async ({ title, start_time }) => {
    await editClips({
      episodeTitle: title,
      id: clip.id,
      start_time: start_time,
      _id: clip._id,
      userId: localStorage.getItem("__uid"),
    });
    closeModal();
  };

  const onPublish = async () => {
    await publishClips({
      id: clip.id,
      title: clip.episodeTitle,
      storageName: "",
    });
    closeModal();
  };

  const closeModal = () => {
    setEditOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={editOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered filter-modal"
      >
        <Formik initialValues={initialState} validationSchema={editClipValidation} onSubmit={handleSubmit}>
          {({ errors, touched }) => (
            <Form>
              <div className="modal-content">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Edit Clip</h5>
                  <button type="button" onClick={closeModal} className="btn btn__default">
                    <CloseModal />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="form-container">
                    <div className="field-items mb-4">
                      <label>
                        Title <span className="required">*</span>
                      </label>
                      <Field
                        className={touched.title && errors.title ? "error" : ""}
                        name="title"
                        type="text"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="field-items mb-4">
                      <label>
                        Start Time <span className="required">*</span>
                      </label>
                      <Field
                        className={touched.start_time && errors.start_time ? "error" : ""}
                        name="start_time"
                        type="number"
                        placeholder="Enter duration (seconds)"
                      />
                    </div>
                  </div>
                </div>
                <div className="react-modal-footer">
                  <div className="react-foo-container">
                    <div className="right-btn-block text-center">
                      <button type="button" className="btn-s-one" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="btn-s-one fill ms-3" type="submit">
                        Save
                        {loder && (
                          <Spin
                            size="large"
                            indicator={<LoadingOutlined style={{ fontSize: 50, color: "#000", marginLeft: 50 }} spin />}
                            className="loader__full"
                          ></Spin>
                        )}
                      </button>
                      <button className="btn-s-one fill ms-3" type="button" onClick={onPublish}>
                        Publish
                        {loder && (
                          <Spin
                            size="large"
                            indicator={<LoadingOutlined style={{ fontSize: 50, color: "#000", marginLeft: 50 }} spin />}
                            className="loader__full"
                          ></Spin>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default EditClip;
