import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" fill="none" viewBox="0 0 22 16" {...props}>
    <path
      fill="currentColor"
      d="M15 0a1 1 0 011 1v4.2l5.213-3.65a.5.5 0 01.787.41v12.08a.5.5 0 01-.787.41L16 10.8V15a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h14zM7 4v3H4v2h2.999L7 12h2l-.001-3H12V7H9V4H7z"
    ></path>
  </svg>
);

export default SvgComponent;
