import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Image from "../../../components/common/Image/component";
import AddThumbnailModal from "./AddThumbnailModal";
import UploadThumbnail from "./UploadThumbnail";
import { Close, CloseModal } from "../../../components/icons";
import { Tooltip } from "antd";
import { ASPECT_RATIO, EVENT_NAME } from "../../../constants/phase";

const ChangeThumbNail = (props) => {
  const {
    thumbModelOpen,
    clip,
    setThumbModalOpen,
    handleSubmit,
    selectThumbnail,
    setSelectThumbnail,
    addThumbnail,
    getUploadPicture,
    updateClipDetails,
    thumbnailUrls,
    filters,
  } = props;
  const [isUploadThumbnailModalOpen, setIsUploadThumbnailModalOpen] = useState(false);
  const [clipDetails, setClipDetails] = useState({});
  const [uploadedThumbnails, setUploadedThumbnails] = useState([]);

  const delThumbClose = () => {
    setThumbModalOpen(false);
  };
  const [tModal, setTModal] = useState(false);

  const addThumbnailModal = () => {
    setTModal(true);
    delThumbClose();
  };
  const deleteThumbnail = async (index, clipThumb) => {
    const clipDetail = { ...clipDetails };
    const uploadedThumbnail = [...uploadedThumbnails];

    if (index !== -1) {
      uploadedThumbnail.splice(index, 1);

      const aspectRatioFilter = filters?.aspectRatio || ASPECT_RATIO["16_9"];
      const matchingEntryIndex = clipDetail.generatedThumbnailUrls.findIndex(
        (entry) => entry.aspectRatio === aspectRatioFilter,
      );

      if (matchingEntryIndex !== -1) {
        const thumbnailToRemoveIndex =
          clipDetail.generatedThumbnailUrls[matchingEntryIndex].thumbnails.indexOf(clipThumb);

        if (thumbnailToRemoveIndex !== -1) {
          clipDetail.generatedThumbnailUrls[matchingEntryIndex].thumbnails.splice(thumbnailToRemoveIndex, 1);
        }
        if (clipDetail.generatedThumbnailUrls[matchingEntryIndex].thumbnails.length === 0) {
          clipDetail.generatedThumbnailUrls.splice(matchingEntryIndex, 1);
        }
      }
    }

    setClipDetails(clipDetail);
    setUploadedThumbnails(uploadedThumbnail);
    await updateClipDetails({ generatedThumbnailUrls: clipDetail.generatedThumbnailUrls, id: clip._id });
  };

  useEffect(() => {
    setClipDetails(clip);
  }, [clip]);

  useEffect(() => {
    setUploadedThumbnails(
      clipDetails?.generatedThumbnailUrls?.find(
        (object) => object.aspectRatio === (filters?.aspectRatio || ASPECT_RATIO["16_9"]),
      )?.thumbnails || [],
    );
  }, [clipDetails?.generatedThumbnailUrls]);

  const getClipUrlByAspectRatio = () => {
    return (
      clip?.editedVideos?.find(
        ({ aspect_ratio, event }) => aspect_ratio === filters?.aspectRatio && event !== EVENT_NAME.DYNAMIC,
      )?.video_url || clip?.videoUrl
    );
  };

  return (
    <div>
      <UploadThumbnail
        isUploadThumbnailModalOpen={isUploadThumbnailModalOpen}
        setIsUploadThumbnailModalOpen={setIsUploadThumbnailModalOpen}
        getUploadPicture={getUploadPicture}
        updateClipDetails={updateClipDetails}
        clip={clip}
        setThumbModalOpen={setThumbModalOpen}
        setUploadedThumbnails={setUploadedThumbnails}
        uploadedThumbnails={uploadedThumbnails}
        filters={filters}
      />
      <AddThumbnailModal
        filters={filters}
        tModal={tModal}
        setTModal={setTModal}
        clipUrl={getClipUrlByAspectRatio()}
        clipTitle={clip?.episodeTitle}
        addThumbnail={addThumbnail}
        setThumbModalOpen={setThumbModalOpen}
        clip={clip}
      />
      <Modal
        isOpen={thumbModelOpen}
        ariaHideApp={false}
        onRequestClose={delThumbClose}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xl modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Choose Thumbnail</h5>
            <button type="button" onClick={delThumbClose} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="inner-container content-control-block styled-scroll">
              <ul className="choose-thumbnail-grid aspect-ratio-thumb">
                {thumbnailUrls?.length !== 0 &&
                  thumbnailUrls.map((clipThumb, index) => {
                    return (
                      <>
                        <li
                          onClick={() => setSelectThumbnail(clipThumb)}
                          className={selectThumbnail === clipThumb ? "thumb-select " : ""}
                          key={index}
                        >
                          <div className="img-container-ratio">
                            <Image src={clipThumb} alt="Magnifi" name="thumburl" />
                          </div>
                          <span className="slected-thumb-text">Selected</span>
                        </li>
                      </>
                    );
                  })}
                {uploadedThumbnails?.length !== 0 &&
                  uploadedThumbnails.map((clipThumb, index) => {
                    return (
                      <>
                        <li
                          onClick={() => setSelectThumbnail(clipThumb)}
                          className={selectThumbnail === clipThumb ? "thumb-select " : ""}
                          key={index + Math.floor(Math.random() * 100)}
                        >
                          <Tooltip title="Remove Thumbnail">
                            <span className="remove-thumb" onClick={() => deleteThumbnail(index, clipThumb)}>
                              <Close />
                            </span>
                          </Tooltip>
                          <div class="vv-video-clip-tags title_Pascal">
                            <span class="ant-tag">Custom</span>
                          </div>
                          <span className="slected-thumb-text">Selected</span>
                          <div className="img-container-ratio">
                            <Image src={clipThumb} alt="Magnifi" name="thumburl" />
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="react-modal-footer row m-0">
            <div className="col-lg-6">
              <button type="button" className="btn btn-white ms-0 " onClick={addThumbnailModal}>
                Generate Thumbnail
              </button>
              <button
                type="button"
                className="btn btn-white"
                onClick={() => {
                  setIsUploadThumbnailModalOpen(true);
                  delThumbClose();
                }}
              >
                Upload Thumbnail
              </button>
            </div>
            <div className="col-lg-6 text-center text-lg-end">
              <button type="button" className="btn btn-white" onClick={delThumbClose}>
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChangeThumbNail;
