import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <circle cx="13" cy="13" r="13" fill="#0076B2"></circle>
      <path
        fill="#fff"
        d="M6.203 10.499h2.959v9.534h-2.96v-9.534zm1.48-4.745a1.714 1.714 0 011.683 2.054 1.719 1.719 0 01-1.35 1.35A1.714 1.714 0 015.968 7.47a1.72 1.72 0 011.716-1.716zm3.335 4.745h2.837v1.309h.04c.395-.75 1.359-1.54 2.798-1.54 2.997-.006 3.553 1.968 3.553 4.53v5.235h-2.96v-4.639c0-1.104-.019-2.526-1.537-2.526s-1.776 1.204-1.776 2.454v4.71h-2.955V10.5z"
      ></path>
    </svg>
  );
}

export default Icon;
