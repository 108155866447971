import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useOutletContext } from "react-router-dom";
import { CloseModal } from "../../../components/icons";
import ReactPlayer from "../../../components/common/ReactPlayer/react-player";
import { Radio } from "antd";
import LiveModalContext from "../context";
import AlertDialog from "../../../components/common/Modals/AlertDialog";

import {
  TimelineForwardIcon,
  TimelinePauseIcon,
  TimelinePlayIcon,
  TimelinePreviousIcon,
  SoundMute,
  SoundUp,
} from "../../../components/icons";
import { Field, Formik, Form } from "formik";
import { modalLiveToolValidation, modalLiveToolValidationMilli } from "../../../utils/validations";
import AntdSpinner from "../../../components/common/AntdSpinner/antd-spinner";
import { milliConversion, stringToMilli, milliSecondsToString, getTimeInSeconds } from "../../../constants/phase";
import ManageAccess from "../../../components/common/ManageAccess/manageAccess";

const PreviewModal = React.memo(() => {
  const playerRef = useRef(null);
  const {
    viewStreamModal,
    setViewStreamModal,
    streamTime: streamTimeProp,
    setCutClips,
    setStreamTime: setStreamTimeProp,
    radioValue,
    page,
    setIinitialState,
    setSaveOpenModal,
    setPlaybackRate,
    callBack,
    setIsAlertOpen,
    setAlertMessage,
  } = useContext(LiveModalContext);
  const [playVideo, setPlayVideo] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [seekSeconds, setSeekSeconds] = useState(1);
  const [isEndTimeEdit, setIsEndTimeEdit] = useState(false);
  const [onLoadPlayer, setOnLoadPlayer] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [mute, setMute] = useState(true);
  const { clientSetting } = useOutletContext();

  const [streamTime, setStreamTime] = useState({
    startTime: 0,
    endTime: 0,
    startTimeStr: "00:00:00:000",
    endTimeStr: "00:00:00:000",
    playBackSpeed: 1,
  });

  const format = radioValue === "second" ? "HH:mm:ss" : "HH:mm:ss:SSS";

  useEffect(() => {
    if (streamTimeProp && streamTimeProp.startTime && streamTimeProp.endTime) {
      const startTime = stringToMilli(streamTimeProp.startTime);
      const endTime = stringToMilli(streamTimeProp.endTime);
      const startTimeStr = streamTimeProp.startTime;
      const endTimeStr = streamTimeProp.endTime;
      const playBackSpeed = streamTimeProp.playBackSpeed;
      setStreamTime({ startTime, endTime, startTimeStr, endTimeStr, playBackSpeed });
    }
  }, [streamTimeProp]);

  useEffect(() => {
    if (!isEndTimeEdit) return;
    const { endTime } = streamTime;
    if (playerRef.current && endTime) {
      playerRef.current.seekTo(endTime / milliConversion, "seconds");
    }
    setPlayedSeconds(endTime / milliConversion);
  }, [isEndTimeEdit, streamTime.endTime]);

  const handleForward = () => {
    const endTime = streamTime.endTime / milliConversion;
    const currentSec = playedSeconds + 1;
    if (endTime > currentSec) {
      playerRef.current?.seekTo(currentSec, "seconds");
      setPlayedSeconds((seekSeconds) => seekSeconds + 1);
    }
  };

  const handleBackward = () => {
    const startTime = streamTime.startTime / milliConversion;
    const currentSec = playedSeconds - 1;
    if (startTime < currentSec) {
      playerRef.current?.seekTo(currentSec, "seconds");
      setPlayedSeconds((seekSeconds) => seekSeconds - 1);
    }
  };

  const handleProgress = (progress) => {
    const streamStartTime = streamTime.startTime / milliConversion;
    const streamEndTime = streamTime.endTime / milliConversion;
    // This function will run every seconds with seeker
    if (playerRef.current && streamStartTime && !isEndTimeEdit && !seeking) {
      const { playedSeconds } = progress;
      if (playedSeconds <= streamStartTime || playedSeconds >= streamEndTime) {
        playerRef.current.seekTo(streamStartTime, "seconds");
      }
      setPlayedSeconds(playedSeconds);
    }
  };

  const handleStartTimeChange = (event, setFieldValue) => {
    const inputTime = event.target.value;
    const inputEvent = event.target.name;
    setStreamTime((prevState) => ({ ...prevState, [inputEvent]: inputTime }));
    setFieldValue(inputEvent, inputTime);
  };

  const handleStartTimeBlur = (setFieldValue, errors) => {
    const startTime = stringToMilli(streamTime.startTimeStr, "HH:mm:ss:SSS");
    const endTime = stringToMilli(streamTime.endTimeStr, "HH:mm:ss:SSS");
    if (!Object.keys(errors)?.length) {
      setStreamTime((prevState) => ({ ...prevState, startTime, endTime }));
      setIsEndTimeEdit(false);
    }
    setFieldValue("startTime", startTime);
    setFieldValue("endTime", endTime);
  };

  const onClickSeekButton = (key) => {
    const _seekSeconds = seekSeconds * milliConversion;
    switch (key) {
      case "startForward":
        if (
          streamTime.startTime + _seekSeconds >= 0 &&
          streamTime.startTime + _seekSeconds < streamTime.endTime - 1000
        ) {
          setStreamTime((prevState) => ({
            ...prevState,
            startTime: prevState.startTime + _seekSeconds,
            startTimeStr: milliSecondsToString(prevState.startTime + _seekSeconds, format),
          }));
          setIsEndTimeEdit(false);
          setPlayVideo(false);
        }
        break;
      case "endForward":
        if (streamTime.endTime + _seekSeconds >= streamTime.startTime) {
          setStreamTime((prevState) => ({
            ...prevState,
            endTime: prevState.endTime + _seekSeconds,
            endTimeStr: milliSecondsToString(prevState.endTime + _seekSeconds, format),
          }));
          setIsEndTimeEdit(true);
          setPlayVideo(false);
        }
        break;
      case "startBackward":
        if (streamTime.startTime - _seekSeconds > 0 && streamTime.startTime - _seekSeconds < streamTime.endTime) {
          setStreamTime((prevState) => ({
            ...prevState,
            startTime: prevState.startTime - _seekSeconds,
            startTimeStr: milliSecondsToString(prevState.startTime - _seekSeconds, format),
          }));
          setIsEndTimeEdit(false);
          setPlayVideo(false);
        }
        break;
      case "endBackward":
        if (streamTime.endTime - _seekSeconds >= streamTime.startTime + 2000) {
          setStreamTime((prevState) => ({
            ...prevState,
            endTime: prevState.endTime - _seekSeconds,
            endTimeStr: milliSecondsToString(prevState.endTime - _seekSeconds, format),
          }));
          setIsEndTimeEdit(true);
          setPlayVideo(false);
        }
        break;
      default:
        break;
    }
  };

  const handleVideoPlayer = (e) => {
    //This function will run when seek to a perticular time
    const seconds = parseFloat(e.target.value);
    setPlayedSeconds(seconds);
  };

  const handleSeekMouseUp = () => {
    setSeeking(true);
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(false);
    const seconds = parseFloat(e.target.value);
    playerRef.current.seekTo(seconds);
  };

  const updateClipTime = () => {
    const { startTimeStr, endTimeStr } = streamTime;
    let clipDuration = getTimeInSeconds(endTimeStr) - getTimeInSeconds(startTimeStr);
    let isLimitExceeded = clipDuration > clientSetting?.limitation?.maxVideoProcessDuration;
    if (isLimitExceeded && clientSetting?.isProcessingLimit) {
      setIsAlertOpen(true);
      setAlertMessage(
        `Video duration should be less than or equal to ${
          clientSetting?.limitation?.maxVideoProcessDuration || 0
        } second(s).`,
      );
      setViewStreamModal(false);
    } else {
      const { index, title } = streamTimeProp;
      switch (page) {
        case "InfoClips":
          setIinitialState((prevState) => ({
            ...prevState,
            start_time: startTimeStr,
            end_time: endTimeStr,
          }));
          setSaveOpenModal(true);
          break;
        case "Highlight":
          callBack(streamTime);
          break;
        default:
          setCutClips((prevCutClips) => {
            const updatedClips = [...prevCutClips];
            if (index >= 0 && index < updatedClips.length) {
              updatedClips[index] = {
                ...updatedClips[index],
                startTime: startTimeStr,
                endTime: endTimeStr,
                title,
              };
            }
            return updatedClips;
          });
          setViewStreamModal(false);
          break;
      }
    }
  };
  const handleSpeedChange = (e) => {
    const pbRate = parseFloat(e.target.value);
    setStreamTime({ ...streamTime, playBackSpeed: pbRate });
    setPlaybackRate(pbRate);
  };

  return (
    <>
      <Modal
        isOpen={viewStreamModal}
        ariaHideApp={false}
        onRequestClose={() => setViewStreamModal(false)}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title word-break-two title_Pascal">View Stream</h5>
            <button type="button" onClick={() => setViewStreamModal(false)} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <AntdSpinner spin={onLoadPlayer}>
            <div className="react-modal-body">
              <ReactPlayer
                playerRef={playerRef}
                playVideo={playVideo}
                onProgress={handleProgress}
                playedSeconds={playedSeconds}
                streamTime={streamTime}
                handleVideoPlayer={handleVideoPlayer}
                onBuffer={() => setOnLoadPlayer(true)}
                onBufferEnd={() => setOnLoadPlayer(false)}
                onSeekMouseUp={handleSeekMouseUp}
                onSeekMouseDown={handleSeekMouseDown}
                isMute={mute}
                playBackSpeed={streamTime.playBackSpeed}
                config={{
                  file: {
                    attributes: {
                      onContextMenu: (e) => clientSetting.isProcessingLimit && e.preventDefault(),
                    },
                  },
                }}
              />
              <ManageAccess value={page !== "clips"}>
                <Formik
                  initialValues={streamTime}
                  validationSchema={radioValue === "second" ? modalLiveToolValidation : modalLiveToolValidationMilli}
                  enableReinitialize
                  onSubmit={updateClipTime}
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form>
                      <div className="player-actions-container-trim PlayerControls mt-2 align-items-start">
                        <div className="PlayerDuration">
                          <div className="view-results me-3">
                            <ManageAccess value={page === "InfoClips"}>
                              <label className="form-label">Change Speed</label>
                              <select
                                className="custom-select form-control custom-select-small"
                                onChange={handleSpeedChange}
                                value={streamTime.playBackSpeed}
                              >
                                <option value={1}>1x</option>
                                <option value={0.75}>0.75x</option>
                                <option value={0.5}>0.5x</option>
                              </select>
                            </ManageAccess>
                            <button
                              className="ButtonNext ms-2"
                              type="button"
                              onClick={() => {
                                setMute(!mute);
                              }}
                            >
                              {mute ? <SoundMute /> : <SoundUp />}
                            </button>
                          </div>
                        </div>
                        <div className="PlayerDuration">
                          <div className="d-flex justify-content-center">
                            <button type="button" className="ButtonPrev" onClick={() => handleBackward()}>
                              <TimelinePreviousIcon />
                            </button>
                            <button
                              type="button"
                              className="ButtonPlayPause"
                              onClick={() => {
                                setPlayVideo(!playVideo);
                                setIsEndTimeEdit(false);
                              }}
                            >
                              {playVideo ? <TimelinePauseIcon /> : <TimelinePlayIcon />}
                            </button>
                            <button type="button" className="ButtonNext" onClick={() => handleForward()}>
                              <TimelineForwardIcon />
                            </button>
                          </div>
                        </div>
                        <div className="PlayerDuration">
                          <div className="d-flex justify-content-end apply-button-seekbar">
                            <button type="submit" className="btn btn-primary">
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="player-actions-container-trim PlayerControls mt-2 align-items-start">
                        <div className="PlayerDuration" style={{ width: "20%" }}>
                          <div className="view-results gap-2 flex-column align-items-start">
                            <label className="form-label mb-0">Start frame</label>
                            <div className="form-field">
                              <Field
                                name="startTimeStr"
                                onBlur={() => handleStartTimeBlur(setFieldValue, errors)}
                                placeholder="Start time"
                                onChange={(event) => handleStartTimeChange(event, setFieldValue)}
                                value={streamTime.startTimeStr}
                                className="form-control me-2"
                              />
                              {touched.startTimeStr ||
                                (errors.startTimeStr && (
                                  <div className="invalid-feedback small">{errors.startTimeStr}</div>
                                ))}
                            </div>
                            <div className="bottomActionbtngrp">
                              <button
                                type="button"
                                className="audioPlayerBtn"
                                onClick={() => onClickSeekButton("startBackward")}
                              >
                                <TimelinePreviousIcon />
                              </button>
                              <button
                                type="button"
                                className="audioPlayerBtn"
                                onClick={() => onClickSeekButton("startForward")}
                              >
                                <TimelineForwardIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="PlayerControl mt-2 flex-column justify-content-center"
                          style={{ width: "auto" }}
                        >
                          <div className="player-actions-container-trim trim-block-container-two PlayerControls justify-content-end gap-3 mt-4">
                            <div>
                              <Radio.Group
                                className="modal-player-action"
                                onChange={(e) => setSeekSeconds(e.target.value)}
                                value={seekSeconds}
                              >
                                <Radio value={0.01}>0.01s</Radio>
                                <Radio value={0.05}>0.05s</Radio>
                                <Radio value={0.1}>0.1s</Radio>
                                <Radio value={0.3}>0.3s</Radio>
                                <Radio value={0.5}>0.5s</Radio>
                                <Radio value={1}>1s</Radio>
                                <Radio value={3}>3s</Radio>
                                <Radio value={5}>5s</Radio>
                                <Radio value={10}>10s</Radio>
                              </Radio.Group>
                            </div>
                          </div>
                        </div>
                        <div className="PlayerAudio  flex-column text-end gap-2" style={{ width: "20%" }}>
                          <label className="form-label mb-0">End frame</label>
                          <div className="form-field">
                            <Field
                              name="endTimeStr"
                              onBlur={() => handleStartTimeBlur(setFieldValue, errors)}
                              placeholder="End time"
                              onChange={(event) => handleStartTimeChange(event, setFieldValue)}
                              value={streamTime.endTimeStr}
                              className="form-control"
                            />
                            {touched.endTimeStr ||
                              (errors.endTimeStr && <div className="invalid-feedback small">{errors.endTimeStr}</div>)}
                          </div>
                          <div className="bottomActionbtngrp">
                            <button
                              type="button"
                              className="audioPlayerBtn"
                              onClick={() => onClickSeekButton("endBackward")}
                            >
                              <TimelinePreviousIcon />
                            </button>
                            <button
                              type="button"
                              className="audioPlayerBtn"
                              onClick={() => onClickSeekButton("endForward")}
                            >
                              <TimelineForwardIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </ManageAccess>
            </div>
          </AntdSpinner>
        </div>
      </Modal>
    </>
  );
});

export default PreviewModal;
