import React, { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import Modal from "react-modal";
import MSFacebook from "../../../components/icons/MSFacebook";
import MSInstagram from "../../../components/icons/MSInstagram";
import MSYoutube from "../../../components/icons/MSYoutube";
import MSLinkedin from "../../../components/icons/MSLinkedin";
import MSTwitter from "../../../components/icons/MSTwitter";
import CloseModal from "../../../components/icons/CloseModal";
import { Formik, Form, Field } from "formik";
import { LoadingOutlined } from "@ant-design/icons";
import { socialMediaValidation } from "../../../utils/validations";
import { useNavigate } from "react-router-dom";
import shortUUID from "short-uuid";

import { notification, Spin, Checkbox, Tooltip, Select } from "antd";
import AccessControl from "../../../components/common/AccessControl/accessControl";
import { MSTelegram, MSTiktok } from "../../../components/icons";
import moment from "moment";
const { Option } = Select;
const ModalPublish = (props) => {
  const {
    modalIsOpenPublishOverlay,
    setIsOpenPublishOverlay,
    user,
    shareOnSocialMedias,
    thirdPartyUrl,
    selectedHashtags,
    eventHashtags,
    autoPopulatedHashtags,
    setAutoPopulatedHashtags,
    timeline,
    addSocialMediaData,
    publishThumbnails,
    publicStatus,
    singleClipPublish,
    publishClipId, //this will be clip/highlight details
    addPublishNotification,
    streamDetails,
  } = props;

  Modal.setAppElement("*");
  const { clientSetting } = useOutletContext();
  const navigate = useNavigate();

  const [initialState] = useState({
    title: "",
  });
  const [platforms, setPlatForms] = useState([]);
  const [shareLoader, setShareLoader] = useState();
  const [autohashtags, setAutoHashtags] = useState([]);
  const [toolTitle, setToolTitle] = useState("");
  const [disable, setDisable] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24, align: "center" }} spin />;
  const { id } = useParams();

  const socialPublish = async ({ title }) => {
    if (selectedProfile.length > 1 && platforms.includes("twitter")) {
      setDisable(true);
      notification.error({ message: "Multiple profile publishing is not allowed on Twitter", duration: 10 });
    } else {
      setShareLoader(true);
      const scheduleDate = moment().add(15, "seconds").toDate();
      try {
        const publishId = shortUUID().generate().substring(0, 8);
        selectedProfile.map(async (profile) => {
          const sharedOptions = {
            post: `${title} `.concat(autohashtags.map((hash) => `#${hash.split(" ").join("_")} `)),
            mediaUrls: [thirdPartyUrl],
            platforms: platforms,
            profileKeys: [profile || ""],
            instagramOptions: {
              reels: true,
              shareReelsFeed: true,
            },
            youTubeOptions: {
              title: title,
              youTubeVisibility: "public",
              tags: ["magnifi", "videoverse"],
            },
            scheduleDate,
            isVideo: true,
          };

          if (clientSetting.appAdditionalPermissions.isAmplify) {
            sharedOptions.twitterOptions = {
              longVideo: true,
            };
          }

          const data = await shareOnSocialMedias(sharedOptions);

          if (data?.value?.data?.status === "success") {
            for (let i of platforms) {
              let obj = {
                id: id,
                data: data.value.data.posts[0],
              };
              data.value.data.posts.map((data) => {
                notification["success"]({ message: `Publish initiated on ${i} for ${data.profileTitle}!` });
              });

              await addSocialMediaData(obj);
            }
            setShareLoader(false);
          }
          const { status, data: { posts } = {} } = data.value;
          const { errors = [], postIds = [] } = posts?.[0] || {};

          if ([400, 502].includes(status)) {
            errors.forEach(({ platform, message } = {}) => {
              if (platform && message) {
                notification.error({ message: `${platform}:->${message}`, duration: 10 });
              }
            });

            for (const { platform } of postIds) {
              notification.success({ message: `Publish initiated on ${platform}!`, duration: 10 });
              await addSocialMediaData({ id, data: posts[0] });
            }

            setShareLoader(false);
          }
          if (data.value.data.status === "error") {
            notification["error"]({
              message: `Error in publishing`,
              duration: 10,
            });
            setShareLoader(false);
          }
          let typeOfPublish = "single";
          if (selectedProfile?.length > 1) {
            typeOfPublish = "bulk";
          }
          pushPublishNotification(data.value.data.posts[0].id, profile, typeOfPublish, publishId);
          setShareLoader(false);
          setPlatForms([]);
          setDisable(false);
          setSelectedProfile([]);
          setIsOpenPublishOverlay(false);
        });
      } catch (err) {
        setShareLoader(false);
      }
    }
  };
  const pushPublishNotification = async (postId, profileKey, typeOfPublish, publishId) => {
    const { value } = await addPublishNotification({
      streamId: streamDetails.streamId,
      userId: user.userId,
      socialProfileKey: profileKey,
      postId: postId,
      clipId: publishClipId._id,
      clipTitle: publishClipId.episodeTitle || publishClipId?.title,
      typeOfPublish,
      publishId,
    });
  };
  const setSocialPlatformFun = (platform) => {
    const index = platforms.indexOf(platform);
    if (index === -1) {
      setPlatForms([...platforms, platform]);
    } else {
      const newArray = [...platforms];
      const index = newArray.indexOf(platform);
      newArray.splice(index, 1);
      setPlatForms(newArray);
    }
  };

  useEffect(() => {
    if (platforms.length && selectedProfile.length == 0 && publicStatus) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [platforms, selectedProfile, publicStatus]);

  const individualShareFunction = () => {
    navigate("/social-media/share", {
      state: {
        thirdPartyUrl: thirdPartyUrl,
        timeline: timeline,
        id: id,
        publishThumbnails: publishThumbnails,
        singleClipPublish: singleClipPublish,
        publishClipId: publishClipId._id,
        publishTitle: publishClipId.episodeTitle || publishClipId?.title,
      },
    });
  };
  const onChange = (hashtag) => {
    setAutoHashtags(hashtag);
  };

  useEffect(() => {
    if (modalIsOpenPublishOverlay) {
      setAutoPopulatedHashtags(eventHashtags?.concat(selectedHashtags));
      setAutoHashtags(eventHashtags?.concat(selectedHashtags));
    }
  }, [modalIsOpenPublishOverlay]);

  useEffect(() => {
    if (!publicStatus) {
      setToolTitle("Please wait. we are preparing the file for upload");
    } else {
      setToolTitle("");
    }
  }, [publicStatus]);

  const onClose = () => {
    setIsOpenPublishOverlay(false);
    setPlatForms([]);
    setSelectedProfile([]);
    setDisable(false);
  };
  const handleSelectedProfile = (e) => {
    setSelectedProfile(e);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpenPublishOverlay}
        onRequestClose={setIsOpenPublishOverlay}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered filter-modal modal-process-container"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Publish</h5>
            <button
              type="button"
              onClick={() => {
                onClose();
              }}
              className="btn btn__default"
            >
              <CloseModal />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="chooseMedia-block mb-3">
              <ul>
                <li>
                  <button
                    className={platforms.includes("facebook") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("facebook")}
                  >
                    <MSFacebook />
                  </button>
                </li>
                <li>
                  <button
                    className={platforms.includes("instagram") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("instagram")}
                  >
                    <MSInstagram />
                  </button>
                </li>
                <li>
                  <button
                    className={platforms.includes("twitter") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("twitter")}
                  >
                    <MSTwitter />
                  </button>
                </li>
                <li>
                  <button
                    className={platforms.includes("youtube") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("youtube")}
                  >
                    <MSYoutube />
                  </button>
                </li>
                <li>
                  <button
                    className={platforms.includes("tiktok") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("tiktok")}
                  >
                    <MSTiktok />
                  </button>
                </li>
                <li>
                  <button
                    className={platforms.includes("linkedin") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("linkedin")}
                  >
                    <MSLinkedin />
                  </button>
                </li>
                <li>
                  <button
                    className={platforms.includes("telegram") ? "active" : ""}
                    onClick={() => setSocialPlatformFun("telegram")}
                  >
                    <MSTelegram />
                  </button>
                </li>
              </ul>
            </div>
            <Formik initialValues={initialState} validationSchema={socialMediaValidation} onSubmit={socialPublish}>
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="modal-content">
                    <div className="react-modal-body p-0">
                      <div className="form-container">
                        <div className="form-group">
                          <label className="form-label">
                            Title <span className="required">*</span>
                          </label>
                          <Field className="form-control" name="title" type="text" placeholder="Enter Title" />
                          {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                        </div>
                      </div>
                      {Array.isArray(user?.socialProfileKey) ? (
                        <>
                          <div className="form-container">
                            <div className="field-items mb-4">
                              <label className="form-label">
                                Select Profile <span className="required">*</span>
                              </label>
                              <Select
                                className="custom-ant-select"
                                style={{ width: "100%" }}
                                mode="multiple"
                                name="profileKeys"
                                placeholder="Select Profile"
                                dropdownStyle={{ zIndex: 2000 }}
                                value={selectedProfile}
                                onChange={(e) => {
                                  handleSelectedProfile(e);
                                }}
                              >
                                {user.socialProfileKey &&
                                  user.socialProfileKey.map((data) => (
                                    <Option key={data.profileKey} value={data.profileKey}>
                                      {data.title}
                                    </Option>
                                  ))}
                              </Select>
                              {errors.profileKeys && <div className="invalid-feedback">{errors.profileKeys}</div>}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="field-block form-group">
                      <div className="checkbox-items-container hashtag-container-main hashtags-container-height h-auto">
                        <Checkbox.Group value={autohashtags} onChange={onChange}>
                          {autoPopulatedHashtags?.map((hashtag, index) => {
                            return (
                              <React.Fragment key={index || hashtag}>
                                <div className="inline-label-items">
                                  <Checkbox value={hashtag}>
                                    {"#"}
                                    {hashtag}{" "}
                                  </Checkbox>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </Checkbox.Group>
                      </div>
                      <div className="text-end">
                        <Tooltip title={toolTitle} trigger={["hover"]}>
                          <button
                            className="btn btn-white me-3 white-btn disabled-btn"
                            onClick={() => {
                              individualShareFunction();
                            }}
                            disabled={!publicStatus}
                          >
                            Customize
                          </button>
                        </Tooltip>
                        <AccessControl code="publish" page="editor-page">
                          <Tooltip title={toolTitle} trigger={["hover"]}>
                            <button
                              className="btn btn-primary disabled-btn"
                              type="submit"
                              disabled={disable || !publicStatus || !selectedProfile.length || !platforms.length}
                            >
                              {shareLoader ? <Spin className="lightLoader-spin" indicator={antIcon} /> : "Publish"}
                            </button>
                          </Tooltip>
                        </AccessControl>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalPublish;
