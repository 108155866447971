import React from "react";
const ImageComponent = (props) => {
  let count = 0;
  const handleImgError = (e, src) => {
    if (count < 3) {
      e.target.src = src;
      count++;
    }
  };

  const { src, className, alt, name, id } = props;
  return (
    <>
      <img className={className} id={id} src={src} alt={alt} name={name} onError={(e) => handleImgError(e, src)} />
    </>
  );
};

export default ImageComponent;
