import * as React from "react";

const SvgComponent = (props) => (
  <svg width={22} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={1.15} y={6.65} width={19.7} height={13.7} rx={3.35} stroke="#fff" strokeWidth={1.3} />
    <path
      d="M15 12.634a1 1 0 0 1 0 1.732l-5.25 3.031a1 1 0 0 1-1.5-.866V10.47a1 1 0 0 1 1.5-.866L15 12.634Z"
      fill="#fff"
    />
    <path d="m7.5 1 3.4 5 2.6-3.333" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgComponent;
