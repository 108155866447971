import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="24" fill="none" viewBox="0 0 31 24">
      <path
        fill="currentColor"
        d="M21.39 12.034l9.286-8.377a.955.955 0 00.313-.76.947.947 0 00-.4-.72L28.424.648a3.478 3.478 0 00-4.135.115l-10.803 8.52-3.15-2.407a5.15 5.15 0 00-.162-3.754A5.236 5.236 0 007.498.453 5.323 5.323 0 003.702.261 5.253 5.253 0 00.763 2.645a5.153 5.153 0 00-.549 3.718A5.204 5.204 0 002.34 9.478a5.313 5.313 0 007.03-.911l2.531 1.91-4.097 3.295a5.35 5.35 0 00-3.792-.469A5.295 5.295 0 00.888 15.48a5.186 5.186 0 00-.828 3.689 5.221 5.221 0 001.899 3.278 5.336 5.336 0 003.637 1.161 5.323 5.323 0 003.48-1.561 5.205 5.205 0 001.51-3.47 5.196 5.196 0 00-1.245-3.572L25.487 2.253a1.528 1.528 0 011.816-.048l1.189.84-9.962 8.998 9.962 8.979-1.189.84a1.53 1.53 0 01-1.816-.047l-9.48-7.498-1.439 1.299 9.72 7.64a3.478 3.478 0 004.145.115l2.165-1.528a.958.958 0 00.4-.72.946.946 0 00-.313-.76l-9.295-8.33zM5.34 8.557a3.371 3.371 0 01-1.86-.552 3.314 3.314 0 01-1.235-1.481 3.27 3.27 0 01-.194-1.91 3.293 3.293 0 01.912-1.695 3.355 3.355 0 011.712-.907 3.38 3.38 0 011.933.186c.611.25 1.134.673 1.502 1.217a3.278 3.278 0 01-.412 4.17 3.363 3.363 0 01-2.358.972zm0 13.105a3.37 3.37 0 01-1.86-.552 3.314 3.314 0 01-1.235-1.482 3.27 3.27 0 01-.194-1.91 3.293 3.293 0 01.912-1.695 3.355 3.355 0 011.712-.907 3.379 3.379 0 011.933.187c.611.25 1.134.673 1.502 1.217a3.278 3.278 0 01-.412 4.17 3.363 3.363 0 01-2.358.972z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
