import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <g clipPath="url(#clip0_12_9)">
        <path
          fill="url(#paint0_linear_12_9)"
          d="M13 0a12.998 12.998 0 00-9.191 22.192A12.998 12.998 0 1022.19 3.808 12.998 12.998 0 0013 0z"
        ></path>
        <path
          fill="#fff"
          d="M5.885 12.863c3.79-1.651 6.317-2.74 7.58-3.266 3.612-1.501 4.361-1.762 4.85-1.771.108-.002.348.025.505.151a.56.56 0 01.184.352c.017.101.039.332.02.512-.194 2.056-1.041 7.044-1.472 9.346-.18.975-.54 1.301-.888 1.333-.755.07-1.328-.499-2.06-.978-1.143-.75-1.789-1.217-2.9-1.949-1.284-.845-.45-1.31.28-2.07.191-.199 3.518-3.225 3.582-3.499.008-.034.016-.162-.062-.23-.075-.067-.186-.044-.268-.026-.115.026-1.942 1.234-5.484 3.624-.518.357-.987.53-1.41.521-.463-.01-1.357-.262-2.02-.478-.813-.265-1.461-.405-1.404-.854.028-.234.351-.474.967-.718z"
        ></path>
      </g>
      <defs>
        <linearGradient id="paint0_linear_12_9" x1="1300" x2="1300" y1="0" y2="2600" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE"></stop>
          <stop offset="1" stopColor="#229ED9"></stop>
        </linearGradient>
        <clipPath id="clip0_12_9">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
