import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Drawer, Popover, Radio, Spin, notification } from "antd";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ManageAccess from "../../../components/common/ManageAccess/manageAccess";
import ArrowDownWhite from "../../../components/icons/ArrowDown";
import { createProfileValidation } from "../../../utils/validations";
import { Close, Hamburger, Logo, ProcessTracking } from "../../icons";
import AccessControl from "../AccessControl/accessControl";
import "./styles.scss";

const HeaderContent = [
  {
    label: "Sports Key Moments",
    value: "sports_keymoments",
    disabled: false,
  },
  {
    label: "Entertainment",
    value: "entertainment",
    disabled: true,
  },
  {
    label: "Autoflip",
    value: "h2v_maker",
    disabled: true,
  },
  {
    label: "Content Moderation",
    value: "Content Moderation",
    disabled: true,
  },
];

const Header = (props) => {
  const {
    createUserProfile,
    fetchMe,
    setType,
    type,
    addSocialMediaPlatforms,
    removeIp,
    clientSetting,
    parentClientSetting,
    profileFeatures,
    getProcessList,
    cancelProcess,
  } = props;

  const [userId] = useState(localStorage.getItem("__uid"));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);
  const [loder, serLoder] = useState(false);
  const [userData, setUserData] = useState({});
  const [darkTheme, setDarkTheme] = useState(false);
  const [openSocialProfile, setOpenSocialProfile] = useState(false);
  const [permitted, setPermitted] = useState([]);
  const [loggerDetails, setLoggerDetails] = useState([]);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [markAllRead, setMarkAllRead] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [massage, setMassage] = useState([]);
  const [initialState] = useState({
    title: "",
  });
  const [payload, setPayload] = useState({
    isAllNotificationSeen: true,
    markAllRead: false,
    id: "",
  });
  const [unread, setunRead] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const fetchData = async () => {
    const { value } = await fetchMe();
    setUserData(value.data);
    setPermitted(value?.data?.permissions || []);
  };

  const fetchLoggerDetails = async () => {
    const { fetchLoggerDetails } = props;
    const { value } = await fetchLoggerDetails({
      limit: 20,
      activePage: 1,
    });
    if (value.success) {
      setLoggerDetails(value);
    }
  };
  const removeIP = async () => {
    // function for removing token for login limit functionality
    const { value } = await removeIp({
      userId: localStorage.getItem("__uid"),
      ipToken: localStorage.getItem("authToken"),
      opsEmail: localStorage.getItem("opsEmail") ? localStorage.getItem("opsEmail") : undefined,
    });
    localStorage.clear();
    navigate("/login");
    // if (value.status) {
    // }
  };
  const updateLoggerDetails = async () => {
    const { updateLoggerDetails } = props;
    const { value } = await updateLoggerDetails({
      payload,
    });
    if (value.status) {
      fetchLoggerDetails();
    }
  };

  useEffect(() => {
    fetchData();
    fetchLoggerDetails();
  }, []);

  useEffect(() => {
    if (!payload.isAllNotificationSeen || payload.markAllRead || payload.id) {
      updateLoggerDetails();
    }
  }, [payload.isAllNotificationSeen, payload.id, payload.markAllRead]);

  const name = userData?.name || "";

  const onClose = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    if (e.target.value === "sports_keymoments") {
      setType("sports_keymoments");
    }
    if (e.target.value === "entertainment") {
      setType("entertainment");
    }
    if (e.target.value === "h2v_maker") {
      setType("h2v_maker");
    }
    if (e.target.value === "Content Moderation") {
      setType("Content Moderation");
    }
  };

  const content = (
    <div className="image-slate-container">
      <div className="custom-radio-group">
        <Radio.Group name="radiogroup" value={type} onChange={(e) => handleChange(e)}>
          {HeaderContent.map(({ disabled, value, label }) => {
            return (
              <Radio key={value} value={value} disabled={disabled}>
                {label}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    </div>
  );

  // const logout = () => {
  //   localStorage.removeItem("__uid");
  //   localStorage.removeItem("authToken");
  //   navigate("/login");
  // };
  const closeModal = () => {
    setOpenSocialProfile(false);
  };

  const createProfile = async ({ title }) => {
    const data = await createUserProfile({
      userId: localStorage.getItem("__uid"),
      title: title,
    });
    if (data.value) {
      profileNotification(data.value.message);
    }
    closeModal();
  };
  const AvatarContent = (
    <div className="avatart-content-header">
      <ul>
        <AccessControl code="profile" page="profile-dropdown">
          <li>
            <button onClick={() => navigate(`/user-profile/${userId}`)}>Profile</button>
          </li>
        </AccessControl>

        <AccessControl code="user_management" page="profile-dropdown">
          {/* {userData?.role === "superadmin" || userData?.role === "admin" ? ( */}
          <li>
            <button onClick={() => navigate("/user-management")}>User Management</button>
          </li>
          {/* ) : null} */}
        </AccessControl>
        {/* <ManageAccess value={clientSetting?.appAdditionalPermissions?.allowAutoRating}>
          <li>
            <button onClick={() => navigate("/clip-rating-rule")}>Clip rating rule</button>
          </li>
        </ManageAccess> */}
        <AccessControl code="ruleManagement" page="profile-dropdown">
          <ManageAccess value={clientSetting?.appAdditionalPermissions?.ruleManagement}>
            <li>
              <button onClick={() => navigate("/rule-listing")}>Rule Management</button>
            </li>
          </ManageAccess>
        </AccessControl>
        {userData?.socialProfileKey?.length > 0 && (
          <li>
            <button onClick={() => navigate("/publish-history")}>Publish History</button>
          </li>
        )}
        <AccessControl code="settings" page="profile-dropdown">
          <li>
            <button onClick={() => navigate("/configuration/category")}>Settings</button>
          </li>
          {/* ) : null} */}
        </AccessControl>

        <li>
          <button className="logoutBtn" onClick={removeIP}>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );

  const [styling, setstyling] = useState({
    status: false,
    from: "",
    style: "",
  });

  const handleClick = (childNo) => {
    setstyling({ status: true, from: childNo, style: "child-menu-active" });
  };

  useEffect(() => {
    const theme = localStorage.getItem("_th") === "dark-theme" ? true : false;
    setDarkTheme(theme);
  }, []);

  useEffect(() => {
    const theme = !darkTheme ? "data-theme" : "dark-theme";
    document.body.className = theme;
    localStorage.setItem("_th", theme);
  }, [darkTheme]);

  const headerContentTitle = HeaderContent.filter(({ value }) => value === type)[0].label;

  const profileNotification = (message) => {
    notification.open({
      message: message,
    });
  };

  const setId = (id) => {
    setunRead(true);
    setPayload({ ...payload, id: id });
    const index = massage.indexOf(id);
    if (index === -1) {
      setMassage([...massage, id]);
    }
  };

  const redirectOnPage = (data) => {
    // if (data.action == "fancode-event-publish") {
    //   return `/app/sports_keymoments/clipdetails/${data?.referenceDetails?.clipId}`;
    // }
    // if (data.action == "stream-creation") {
    //   return `/app/sports_keymoments/stream_summary/${data?.referenceDetails?.streamId}`;
    // }
    return data?.action_page;
  };

  const NotificationContent = (
    // <div className="avatart-content-header">
    <div className="notification-menu">
      <div className="notification-header">
        <h4 className="modal-heading">Notifications</h4>
        <span className="notification-clear-items" onClick={() => setPayload({ ...payload, markAllRead: true })}>
          <CheckOutlined />
          Mark all as read
        </span>
      </div>
      <ul className={payload.markAllRead ? "allRead" : ""}>
        {loggerDetails?.data?.map((loggerDetails) => {
          return (
            <li
              onClick={() => setId(loggerDetails._id)}
              className={massage.indexOf(loggerDetails._id) === -1 ? "unread" : ""}
              key={loggerDetails._id}
            >
              <div className="notification-list-block">
                <div className="name-avatar">
                  <span>
                    {" "}
                    {name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </span>
                </div>
                <Link to={redirectOnPage(loggerDetails)}>
                  <div className="notification-content">
                    <p>{loggerDetails.message}</p>
                    <div className="notification-date">{moment(loggerDetails.createdAt).format("MMM DD, YYYY")}</div>
                  </div>
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
  // const [profileFeatures , setProfileFeatures] = useState([])

  return (
    <>
      <header className="header-container">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-5">
              <div className="left-logo-container">
                <div className="header-logo-block">
                  <Link to={"/"} className="header-logo">
                    <Logo />
                  </Link>
                </div>
                {/* <div className="header-dropdown dropdown mobile-hide">
                  <Popover content={content} trigger="hover" placement="bottom" className="headerDowndownitems">
                    <span className="filter-dropdown-trigger">{headerContentTitle}</span>
                    <ArrowDownWhite className="arrowDark ms-1" />
                  </Popover>
                </div> */}
              </div>
            </div>
            <div className="col-7">
              <div className="right-menu-container">
                <div className="top__navigation--hamburger">
                  <button className="btn btn__hamburger" onClick={showDrawer}>
                    <Hamburger />
                  </button>
                </div>
                <ul className="desktop-menu">
                  <>
                    <li>
                      <Link to="/dashboard" className={pathname === "/dashboard" ? "active" : ""}>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to={"/videos"} className={pathname === "/videos" ? "active" : ""}>
                        Videos
                      </Link>
                    </li>{" "}
                    <li>
                      <Link to={"/my-highlights"} className={pathname === "/my-highlights" ? "active" : ""}>
                        Highlights
                      </Link>
                    </li>{" "}
                  </>
                </ul>
                <button
                  className="btn btn-primary p-2 px-2 mx-2"
                  onClick={() => window.open(`/process-tracking`, "_blank")}
                >
                  <ProcessTracking />
                </button>
                <div className="header-avatar d-flex">
                  <Popover
                    content={AvatarContent}
                    overlayClassName="header-pop-avatar"
                    trigger="click"
                    placement="bottomRight"
                    className="header-inline-avatart-items"
                  >
                    <div className="profile-img">
                      {name
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                    </div>
                    <div className="avatar-desc">
                      <div className="profiletxt-sec">
                        <p className="profile-txt">{userData?.name}</p>
                        <p className="role-font-small">{userData?.role}</p>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {userData?.showAnalyticsMessage && (
        <React.Fragment>
          <div className="alert-msg-container">Data unavailable due to inactivity or content auto-deletion</div>
          <div className="blank-msg-space"></div>
        </React.Fragment>
      )}

      <Modal
        isOpen={openSocialProfile}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xl modal-dialog-centered video-play-modal"
      >
        <Formik initialValues={initialState} validationSchema={createProfileValidation} onSubmit={createProfile}>
          {({ errors, touched }) => (
            <Form>
              <div className="modal-content">
                <div className="react-modal-footer">
                  <div className="react-modal-body">
                    <div className="form-container">
                      <div className="field-items mb-4">
                        <label>
                          Profile Name <span className="required">*</span>
                        </label>
                        <Field
                          className={touched.title && errors.title ? "error" : ""}
                          name="title"
                          type="text"
                          placeholder="Enter Profile Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="react-foo-container">
                    <div className="right-btn-block text-center">
                      <button type="button" className="btn-s-one" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="btn-s-one fill ms-3" type="submit">
                        Add
                        {loder && (
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 50,
                                  color: "#000",
                                  marginLeft: 50,
                                }}
                                spin
                              />
                            }
                            className="loader__full"
                          ></Spin>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <Drawer className="drawer__nav--drawer" placement="left" onClose={onClose} visible={visible}>
        <div className="drawer__nav--header">
          <button onClick={onClose} className="btn drawer__nav--close">
            <Close />
          </button>
        </div>
        <div className="drawer__nav--container">
          <div className="drawer__nav--wrap">
            <ul className="drawer__nav">
              {(permitted.includes("resources") === true && userData?.role === "user") ||
              userData?.role === "superadmin" ||
              userData?.role === "admin" ||
              (parentClientSetting?.appAdditionalPermissions?.reviewerFunctionality && userData?.role == "user") ? (
                <>
                  <li>
                    <Link to="/dashboard" className={pathname === "/dashboard" ? "active" : ""}>
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/videos"} className={pathname === "/videos" ? "active" : ""}>
                      Videos
                    </Link>
                  </li>{" "}
                  <li>
                    <Link to={"/my-highlights"} className={pathname === "/my-highlights" ? "active" : ""}>
                      Highlights
                    </Link>
                  </li>{" "}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default Header;
