import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Radio, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CloseModal from "../../../components/icons/CloseModal";

const VideoSpeed = (props) => {
  const { videoSpeedOpen, setVideoSpeedOpen, clip, loder, streamDetails, trimClip } = props;
  const [videoSpeed, setVideoSpeed] = useState("1");
  const [liveVideoUrl, setLiveVideoUrl] = useState("");

  const closeModal = () => {
    setVideoSpeedOpen(false);
  };

  useEffect(() => {
    if (streamDetails?.hlsS3URL !== "") {
      setLiveVideoUrl(streamDetails?.hlsS3URL);
    } else {
      setLiveVideoUrl(
        `https://km-video-stream.s3.ap-south-1.amazonaws.com/vendor/stream/hls/${streamDetails?.streamId}/index.m3u8`,
      );
    }
  }, [streamDetails]);

  const generateClip = async (isOverWrite) => {
    if (clip.start_time && clip.end_time) {
      let payload = {
        start_time: clip?.start_time,
        end_time: clip?.end_time,
        server_address: streamDetails?.server_address,
        streamId: streamDetails?.streamId,
        isOverWrite: isOverWrite,
        title: clip?.episodeTitle,
        video_url: liveVideoUrl,
        zee: false,
        env: "production",
        id: clip?.id,
        isMediaLive: "false",
        seiEnable: streamDetails?.seiEnable,
        playback_speed: videoSpeed,
      };
      if (streamDetails?.glacierRecoveredStream || streamDetails?.status === 1) {
        payload.sourceUrl = true;
        payload.videoUrl = streamDetails?.s3RecordedUrl || "";
      }
      await trimClip(payload);
      closeModal();
    } else {
      notification["error"]({ message: "Timestamp is required" });
      closeModal();
    }
  };

  const checkDisabled = (clipValue, value) => {
    if (!isNaN(clipValue) && !isNaN(value)) {
      return parseFloat(clipValue) < parseFloat(value);
    }
    return false;
  };

  useEffect(() => {
    setVideoSpeed(clip?.playback_speed);
  }, [clip, videoSpeedOpen]);

  return (
    <>
      <Modal
        isOpen={videoSpeedOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Trim Clip"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Change Speed</h5>
            <br />
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <h4 className="heading-para">Note: changing the speed to .75x or .5x will remove the audio from the clip.</h4>
          <div className="radio-btn radio-btn-group">
            <Radio.Group
              onChange={(e) => {
                setVideoSpeed(e.target.value);
              }}
              value={videoSpeed}
            >
              <Radio.Button disabled={checkDisabled(clip?.playback_speed, "1")} value={"1"}>
                1x
              </Radio.Button>
              <Radio.Button disabled={checkDisabled(clip?.playback_speed, "0.75")} value={"0.75"}>
                0.75x
              </Radio.Button>
              <Radio.Button disabled={checkDisabled(clip?.playback_speed, "0.5")} value={"0.5"}>
                0.5x
              </Radio.Button>
            </Radio.Group>
          </div>
          <div className="react-modal-footer justify-content-center">
            <button type="button" className="btn btn-white" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="btn btn-primary light-blue"
              onClick={() => {
                generateClip(true);
              }}
            >
              Override
              {loder && (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 50,
                        color: "#000",
                        marginLeft: 50,
                      }}
                      spin
                    />
                  }
                  className="loader__full"
                ></Spin>
              )}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                generateClip(false);
              }}
            >
              Save as new
              {loder && (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 50,
                        color: "#000",
                        marginLeft: 50,
                      }}
                      spin
                    />
                  }
                  className="loader__full"
                ></Spin>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VideoSpeed;
