import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" fill="none" viewBox="0 0 25 23">
      <path
        fill="currentColor"
        d="M23.482 13.984c-1.19-2.515-3.944-3.766-6.692-3.04-.291.074-.507-.013-.648-.267a560.492 560.492 0 00-1.964-3.49.588.588 0 01-.013-.613c.472-.931.19-2.016-.684-2.633-.803-.568-1.972-.45-2.66.267-.595.621-.754 1.338-.467 2.126.282.778.838 1.225 1.707 1.356.013.004.03.004.044.004.146.018.388.053.543.337.423.774.869 1.553 1.292 2.31.141.249.278.494.42.743l1.296 2.3c.01-.004.013-.008.022-.012.212-.114.415-.223.613-.341a4.01 4.01 0 013.084-.416c.993.258 1.866.94 2.391 1.872.516.914.644 1.986.349 2.935-.455 1.479-1.478 2.428-2.952 2.747-1.398.302-2.673-.087-3.692-1.124l-1.377 1.089c1.58 1.737 4.006 2.331 6.23 1.514a5.656 5.656 0 003.269-3.15c.6-1.452.56-3.097-.11-4.514z"
      ></path>
      <path
        fill="currentColor"
        d="M16.69 17.628c.534.682 1.235.936 2.074.752.842-.18 1.376-.7 1.583-1.535A2.028 2.028 0 0019.2 14.5c-.94-.415-2.002-.127-2.585.709a.66.66 0 01-.582.297c-2.17-.004-4.341-.004-6.445-.004 0 .074.004.153.008.227.014.377.027.77-.022 1.155a3.863 3.863 0 01-1.544 2.629 3.828 3.828 0 01-2.938.709c-1.667-.28-2.775-1.273-3.198-2.861-.42-1.566.04-2.948 1.323-3.998.287-.232.613-.407.931-.573.08-.04.155-.083.234-.127l-.432-1.658a7.58 7.58 0 00-.239.08C.778 12.16-.73 15.414.35 18.335c.52 1.409 1.632 2.572 3.044 3.193 1.42.622 2.973.652 4.376.084 1.791-.722 2.951-2.043 3.454-3.928.08-.302.27-.447.578-.447h.005c1.213.005 2.65.014 4.076 0 .348-.004.591.114.807.39z"
      ></path>
      <path
        fill="currentColor"
        d="M4.415 18.012c.737.556 1.791.56 2.51.014.795-.604 1.055-1.58.662-2.485-.106-.24-.088-.45.053-.682.874-1.409 1.756-2.843 2.607-4.23l.31-.503c.132-.219.264-.437.4-.656l.036-.053c-.093-.056-.19-.118-.282-.175-.314-.192-.636-.389-.931-.625a3.8 3.8 0 01-1.412-2.896 3.801 3.801 0 011.288-2.943c1.21-1.063 2.656-1.308 4.178-.713 1.606.625 2.616 2.383 2.356 4.094a2.673 2.673 0 00-.022.157l.159.044c.022.004.044.013.07.022.018.004.036.013.058.017.44.118.886.232 1.336.35l.097.026c.238-1.054.093-2.427-.388-3.446C16.525 1.238 14.487 0 12.334 0c-.63 0-1.27.105-1.892.328-2.26.8-3.539 2.419-3.803 4.807-.185 1.697.366 3.206 1.645 4.492.216.215.243.446.08.709-.336.538-.67 1.085-.997 1.614-.415.674-.843 1.37-1.27 2.056a.672.672 0 01-.504.293 2.116 2.116 0 00-1.901 1.474c-.26.818.03 1.719.723 2.24z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
