import * as Yup from "yup";
import moment from "moment";

export const formValidationSchema = Yup.object().shape({
  clientSecret: Yup.string()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Enter Client Secret"),
  clientId: Yup.string()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Enter Client Id"),
  accountId: Yup.string().required("Enter Account Id"),
  reviewersId: Yup.array(),
});

export const ssoValidation = Yup.object().shape({
  clientName: Yup.string().required("Enter Client Name"),
});

const isSameDate = (startTime, endTime) => {
  return moment(startTime).isSame(moment(endTime));
};
const isSameOrBeforedate = (startTime, endTime) => {
  return moment(startTime).isSameOrBefore(moment(endTime));
};
const isSameOrAfterdate = (startTime, endTime) => {
  return moment(endTime).isSameOrAfter(moment(startTime));
};
const isDiffBetween = (startTime, endTime) => {
  return moment(endTime).diff(startTime, "minutes") > 9;
};
const isDiffBetweenFireon = (startTime, endTime) => {
  return moment(startTime).diff(endTime, "minutes") >= 0;
};

export const copyStreamValidation = Yup.object().shape({
  //Validation for copy Stream
  videoId: Yup.string().required("Required"),
  user: Yup.string().required("Required"),
  storage: Yup.string().required("Required"),
});

export const addNewStreamformValidation = Yup.object().shape({
  title: Yup.string()
    .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    .required("Please enter title"),
  url: Yup.string().required("Please enter url"),
  category: Yup.string().required("Required"),
  matchMappingKey: Yup.string().min(2, "Too Short!").max(20, "Too Long!"),
  reviewersId: Yup.array(),
  fireOn: Yup.string(),
  outputVideo: Yup.array().when("category", {
    is: "entertainment",
    then: Yup.array().of(
      Yup.object().shape({
        min: Yup.number()
          .required("Min value is required")
          .moreThan(0, "Min value must be greater than 0")
          .lessThan(Yup.ref("max"), "Min value must be less than Max value"),
        max: Yup.number().required("Max value is required").moreThan(0, "Max value must be greater than 0"),
      }),
    ),
    otherwise: Yup.array(),
  }),
  autoIndexAudioVideo: Yup.string().oneOf(["true", "false"]).required(),
  audioIndex: Yup.string().when("autoIndexAudioVideo", {
    is: "false",
    then: Yup.string()
      .matches(/^\d*(,\d+)*$/, "Only allow comma-separated list of non-negative numbers")
      .test("positive-numbers", "Must be non-negative numbers", (value) => {
        if (!value) return true;
        const numbers = value.split(",").map(Number);
        return numbers.every((num) => num >= 0);
      }),
    otherwise: Yup.string(),
  }),
  videoIndex: Yup.string().when("autoIndexAudioVideo", {
    is: "false",
    then: Yup.string()
      .matches(/^(0|\d+)$/, "Only positive numbers allowed")
      .test("positive-number", "Only positive numbers allowed", (value) => {
        if (!value) return false;
        const num = Number(value);
        return num >= 0;
      }),
    otherwise: Yup.string(),
  }),
  streamBitrate: Yup.number()
    .typeError("Stream Bitrate must be a number")
    .integer("Stream Bitrate must be an integer")
    .min(6, "Stream Bitrate must be at least 6")
    .max(50, "Stream Bitrate cannot exceed 50")
    .nullable(),
  isMediaLive: Yup.string().oneOf(["true", "false"]).required(),
  outputTemplateId: Yup.string().when("isMediaLive", {
    is: "true",
    then: Yup.string().required(),
  }),
  isAllowAutoRating: Yup.boolean(), // Assuming isAllowAutoRating is a boolean field
  matchType: Yup.string().when("isAllowAutoRating", {
    is: true, // Checking if isAllowAutoRating is true
    then: Yup.string()
      .trim() // Remove leading and trailing whitespaces
      .min(1, "Please select match type")
      .required("Please select match type"),
    otherwise: Yup.string(), // If isAllowAutoRating is false, no specific validation
  }),
  srtKeyEnabled: Yup.boolean(),
  srtKey: Yup.string().when(["srtKeyEnabled", "isMediaLive"], {
    is: true,
    then: Yup.string().required("Please enter Secret value"),
  }),
});
export const addNewStreamEditformValidation = Yup.object().shape({
  title: Yup.string()
    .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    .required("Please enter title"),
  url: Yup.string().required("Please enter url"),
  category: Yup.string().required("Required"),
  matchMappingKey: Yup.string().min(2, "Too Short!").max(20, "Too Long!"),
  reviewersId: Yup.array(),
  fireOn: Yup.string(),
  outputVideo: Yup.array().when("category", {
    is: "entertainment",
    then: Yup.array().of(
      Yup.object().shape({
        min: Yup.number()
          .required("Min value is required")
          .moreThan(0, "Min value must be greater than 0")
          .lessThan(Yup.ref("max"), "Min value must be less than Max value"),
        max: Yup.number().required("Max value is required").moreThan(0, "Max value must be greater than 0"),
      }),
    ),
    otherwise: Yup.array(),
  }),
  audioIndexWithsrt: Yup.string().when("url", {
    is: (url) => url?.startsWith("srt"),
    then: Yup.string()
      .matches(/^\d*(,\d+)*$/, "Only allow comma-separated list of non-negative numbers")
      .test("positive-numbers", "Must be non-negative numbers", (value) => {
        if (!value) return true;
        const numbers = value.split(",").map(Number);
        return numbers.every((num) => num >= 0);
      }),
    otherwise: Yup.string(),
  }),
  videoIndexWithsrt: Yup.string().when("url", {
    is: (url) => url?.startsWith("srt"),
    then: Yup.string()
      .matches(/^(0|\d+)$/, "Only positive numbers allowed, including 0")
      .test("positive-numbers", "Only positive numbers allowed, including 0", (value) => {
        if (!value) return false;
        const num = Number(value);
        return num >= 0;
      }),
    otherwise: Yup.string(),
  }),
  audioIndexWithOutsrt: Yup.array().when("url", {
    is: (url) => url?.startsWith("srt"),
    then: Yup.array().of(
      Yup.string()
        .matches(/^\d+$/, "Only allow positive numbers")
        .test("positive-number", "Must be a positive number", (value) => parseInt(value) > 0),
    ),
    otherwise: Yup.array(),
  }),
  videoIndexWithOutsrt: Yup.string().when("url", {
    is: (url) => url?.startsWith("srt"),
    then: Yup.string()
      .matches(/^(\d+|0)(,\d+|0)*$/, "Only positive numbers allow")
      .test("positive-numbers", "Only positive numbers allow", (value) => {
        if (value === "") return true; // Allow empty string
        const numbers = value.split(",").map(Number);
        return numbers.every((num) => num >= 0);
      }),
    otherwise: Yup.string(),
  }),
  streamBitrate: Yup.number()
    .typeError("Stream Bitrate must be a number")
    .integer("Stream Bitrate must be an integer")
    .min(6, "Stream Bitrate must be at least 6")
    .max(50, "Stream Bitrate cannot exceed 50")
    .nullable(),
  isMediaLive: Yup.string().oneOf(["true", "false"]),
  outputTemplateId: Yup.string().when("isMediaLive", {
    is: "true",
    then: Yup.string().required(),
  }),
  isAllowAutoRating: Yup.boolean(),
  matchType: Yup.string().when("isAllowAutoRating", {
    is: true,
    then: Yup.string().trim().min(1, "Please select match type").required("Please select match type"),
    otherwise: Yup.string(),
  }),
});
export const uploadClipValidation = Yup.object().shape({
  uploadUrl: Yup.string()
    .matches(/^https.*\.mp4$/, "Only allows files in the MP4 format.")
    .required("Please enter url"),
});

export const editVideoFormValidation = Yup.object().shape({
  title: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .required("Please enter title"),
  url: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  allowAutoRating: Yup.boolean(),
  matchType: Yup.string().when("allowAutoRating", {
    is: true,
    then: Yup.string().trim().min(1, "Please select match type").required("Please select match type"),
    otherwise: Yup.string(),
  }),
});
export const cricketStoryValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  score: Yup.string().required("Required"),
  overs: Yup.string().required("Required"),
  team1: Yup.string().required("Required"),
  // player: Yup.string().required("Required"),
  // storage: Yup.string().required("Required"),
});
export const footballStoryValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  score: Yup.string().required("Required"),
  team1: Yup.string().required("Required"),
  team2: Yup.string().required("Required"),
  time: Yup.string().required("Required"),
  quarter: Yup.string().required("Required"),
  // storage: Yup.string().required("Required"),
});
export const defaultStoryValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  score: Yup.string().required("Required"),
  // storage: Yup.string().required("Required"),
});
export const cricketEditStoryValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  scorecard: Yup.string().required("Required"),
  overs: Yup.string().required("Required"),
  team1: Yup.string().required("Required"),
  // player: Yup.string().required("Required"),
});
export const footballEditValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  scorecard: Yup.string().required("Required"),
  team1: Yup.string().required("Required"),
  team2: Yup.string().required("Required"),
  time: Yup.string().required("Required"),
  quarter: Yup.string().required("Required"),
});
export const defaultEditValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  scorecard: Yup.string().required("Required"),
});
export const forgotPassWordFormValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Please enter email"),
});

export const loginFormValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Please enter email"),
  password: Yup.string().required("Please enter password"),
});

export const opsloginFormValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Please enter email"),
  password: Yup.string().required("Please enter password"),
  opsemail: Yup.string().email("Invalid email").required("Please enter your emailId"),
});
export const copyClipValidation = Yup.object().shape({
  streamId: Yup.string().trim().required("Required"),
});
export const addRuleValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  condition: Yup.string().required("Required"),
  game: Yup.string().required("Required"),
  event: Yup.array().compact().min(1).required(),
  team: Yup.array().compact().min(1).required(),
  player: Yup.array().compact().min(1).required(),
});
export const addNewRuleValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  // condition: Yup.string().required("Required"),
  game: Yup.string().required("Required"),
  // eventConditionAnd: Yup.array().compact().min(1).required(),
  team: Yup.array().compact().min(1, "Please select at least 1 team").required(),
  tournament: Yup.string().required("Required"),
  // playerConditionAnd: Yup.array().compact().min(1).required(),
  rating: Yup.array().compact().min(1, "Please select at least 1 rating").required(),
  // frequency: Yup.string().required("Frequency must be required"),
});
export const copyClipToChildValidation = Yup.object().shape({
  reviewersId: Yup.array().required("Required"),
});
export const newFolderValidation = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Title required"),
  aspectRatio: Yup.string().required("Aspect ratio required"),
});
export const createFolderValidation = Yup.object().shape({
  folderTitle: Yup.string()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Title required"),
  aspectRatios: Yup.string().required("Aspect ratio required"),
  folderStreamId: Yup.string().required("streamId required"),
});
export const editFolderValidation = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Title required"),
});
export const ratingValidation = Yup.object().shape({
  reasonForRating: Yup.string().max(256, "Character limit exceeded (max 256 characters)"),
});
export const copyClipEditorValidation = Yup.object().shape({
  fields: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title is required").min(2, "Too Short!").max(150, "Too Long!").trim(),
    }),
  ),
  // title: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(150, "Too Long!")
  //   .trim()
  //   // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
  //   .required("Title required"),
});
export const copyClipInfoValidation = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Title required"),
});
export const resetFormvalidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 number and 1 special case Character",
    )
    .required("Password required"),
  confirmPassword: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
export const signUpFormValidationSchema = Yup.object().shape({
  fullName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Please enter full name"),
  email: Yup.string().email("Invalid email").required("Please enter email"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must contain 8 characters, 1 Uppercase, 1 Lowercase, 1 number and 1 special case character",
    )
    .required("Please enter a password"),
});
export const addUserValidation = Yup.object().shape({
  name: Yup.string()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Password is required"),
  confirmpassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const queueValidation = Yup.object().shape({
  queuelimit: Yup.number().max(200, "Too Long!").required("Required"),
  liveCut: Yup.number().max(100, "Too Long!").required("Required"),
  highlight: Yup.number().max(100, "Too Long!").required("Required"),
  autoflip: Yup.number().max(100, "Too Long!").required("Required"),
  dynamicflip: Yup.number().max(100, "Too Long!").required("Required"),
  singleclip: Yup.number().max(100, "Too Long!").required("Required"),
  staticCrop: Yup.number().max(100, "Too Long!").required("Required"),
});

export const editUserManagmentValidation = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});
export const addCategoryValidation = Yup.object().shape({
  // catValue: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  catName: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
});
export const addCustomFieldValidationWithInput = Yup.object().shape({
  inputFieldName: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  inputPlaceholderTitle: Yup.string()
    .trim()
    .matches(/^[_A-z0-9'",()-]*((-|\s)*[()_A-z0-9'",-])*$/, "Should not contain any special characters"),
  inputFieldLabel: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  // selectLabel: Yup.string().required("Required"),
  selectoption: Yup.string()
    .trim()
    .matches(/^[_A-z0-9'",()-]*((-|\s)*[()_A-z0-9'",-])*$/, "Should not contain any special characters"),
  defaultValue: Yup.string()
    .trim()
    .matches(/^[_A-z0-9'",()-]*((-|\s)*[()_A-z0-9'",-])*$/, "Should not contain any special characters"),
});
export const addCustomFieldValidationWithDropdown = Yup.object().shape({
  selectLabel: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  inputFieldLabel: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  selectoption: Yup.string()
    .trim()
    .matches(/^[_A-z0-9,]+(-[_A-z0-9,]+)*$/, "Should not contain any special characters")
    .required("Required"),
});

export const addTournamentValidation = Yup.object().shape({
  event: Yup.string().required("Required"),
  tournamentName: Yup.string()
    .trim()
    // .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  // selectedTeams: Yup.array().min(2).required(),
});
export const addCustomTaggingFieldValidation = Yup.object().shape({
  category: Yup.string().required("Required"),
  name: Yup.string()
    .trim()
    .matches(
      /^(?=[^!@#$%^&*()_+=\-[\]{};':"\\|,.<>/?~`]*$)(?:[A-Z][a-z]*)(?:\s+[A-Z][a-z]*)*$/,
      "Every word should start with a capital letter and special characters are not allowed",
    )
    .required("Required"),
  type: Yup.string().required("Required"),
  array: Yup.array().of(Yup.string().required("Required")),
});
export const uploadToZeeValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  packageName: Yup.array().compact().min(1).required(),
  storageName: Yup.string().required("Required"),
});
export const uploadToS3Validation = Yup.object().shape({
  // storage_name: Yup.string().required("Required"),
  folderPath: Yup.string().required("Required"),
});
export const uploadToFTPValidations = Yup.object().shape({
  ftpName: Yup.string().required("Required"),
  folderPath: Yup.string()
    .required("Required")
    .matches(/^\/.*\/$/, 'Folder path must start and ends with a "/"'),
  fileName: Yup.string().required("Required"),
});
export const editTournamentValidation = Yup.object().shape({
  editTournamentName: Yup.string()
    .trim()
    // .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  // selectedEditTeams: Yup.array().min(2).required(),
});
export const addTeamValidation = Yup.object().shape({
  event: Yup.string().required("Required"),
  teamName: Yup.string()
    .trim()
    // .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  players: Yup.array().min(1).required(),
});
export const addTemplateValidation = Yup.object().shape({
  templateName: Yup.string().trim().min(2, "Too Short!").max(256, "Too Long!").required("Name is required"),
  height: Yup.number()
    .typeError("Height must be a number")
    .positive("Height must be greater than zero")
    .required("Required"),
  width: Yup.number()
    .typeError("Width must be a number")
    .positive("Width must be greater than zero")
    .required("Required"),
  bitrate: Yup.number()
    .typeError("Stream Bitrate must be a number")
    .integer("Stream Bitrate must be an integer")
    .min(6, "Stream Bitrate must be at least 6")
    .max(50, "Stream Bitrate cannot exceed 50")
    .required("Required"),
  fps: Yup.number().typeError("FPS must be a number").positive("FPS must be greater than zero").required("Required"),
  region: Yup.string().trim().required("Required"),
  type: Yup.string().trim().required("Required"),
  inputType: Yup.string().when("type", {
    is: (type) => type === "medialive",
    then: Yup.string().trim().required("Required"),
    otherwise: Yup.string().trim(),
  }),
  mediaLiveRegion: Yup.string().when("type", {
    is: (type) => type === "medialive",
    then: Yup.string().trim().required("Required"),
    otherwise: Yup.string().trim(),
  }),
  mediaLiveStorageId: Yup.string().when("type", {
    is: (type) => type === "medialive" || "direct",
    then: Yup.string().trim().required("Required"),
    otherwise: Yup.string().trim(),
  }),
});
export const editTemplateValidation = Yup.object().shape({
  templateName: Yup.string().trim().min(2, "Too Short!").max(256, "Too Long!").required("Name is required"),
  height: Yup.number()
    .typeError("Height must be a number")
    .positive("Height must be greater than zero")
    .required("Required"),
  width: Yup.number()
    .typeError("Width must be a number")
    .positive("Width must be greater than zero")
    .required("Required"),
  bitrate: Yup.number()
    .typeError("Bitrate must be a number")
    .integer("Bitrate must be an integer")
    .min(6, "Bitrate must be at least 6")
    .max(50, "Bitrate cannot exceed 50")
    .required("Required"),
  fps: Yup.number().typeError("FPS must be a number").positive("FPS must be greater than zero").required("Required"),
  outputRegion: Yup.string().trim().required("Required"),
  outputTemplateType: Yup.string().trim().required("Required"),
  inputType: Yup.string().when("outputTemplateType", {
    is: (outputTemplateType) => outputTemplateType === "medialive",
    then: Yup.string().trim().required("Required"),
    otherwise: Yup.string().trim(),
  }),
  mediaLiveRegion: Yup.string().when("outputTemplateType", {
    is: (outputTemplateType) => outputTemplateType === "medialive",
    then: Yup.string().trim().required("Required"),
    otherwise: Yup.string().trim(),
  }),
  mediaLiveStorageId: Yup.string().when("outputTemplateType", {
    is: (outputTemplateType) => outputTemplateType === "medialive" || "direct",
    then: Yup.string().trim().required("Required"),
    otherwise: Yup.string().trim(),
  }),
});
export const addChannelValidation = Yup.object().shape({
  channelid: Yup.string().trim().required("channelid number is required"),
  channelName: Yup.string().min(1, "Too Short!").max(256, "Too Long!").trim().required("Required"),
  region: Yup.string().trim().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  secret_key: Yup.string().trim().required("Required"),
  access_key: Yup.string().trim().required("Required"),
  // users: Yup.array().min(1).required(),
});
export const addReservedChannelValidation = Yup.object().shape({
  channelId: Yup.string().trim().required("channelId number is required"),
  channelName: Yup.string().min(1, "Too Short!").max(256, "Too Long!").trim().required("Required"),
  inputId: Yup.string().trim().required("Required"),
  region: Yup.string().trim().required("Required"),
  flowId: Yup.string().trim(),
  outboundIp: Yup.string().trim(),
});

export const editChannelValidation = Yup.object().shape({
  bitrate: Yup.number().min(1, "Too Short!").required("Required"),
  frame_rate: Yup.number().min(1, "Too Short!").required("Required"),
  folder_path: Yup.string()
    .min(1, "Too Short!")
    .matches(/^\S*$/g, "Should not contain any spaces")
    .required("Required"),
});

export const editSummaryValidation = Yup.object().shape({
  // outcome: Yup.array().compact().min(1).required(),
  reasonForRating: Yup.string().max(256, "Character limit exceeded (max 256 characters)"),
});

export const basketballeditSummaryValidation = Yup.object().shape({
  reasonForRating: Yup.string().max(256, "Character limit exceeded (max 256 characters)"),
});
export const fancodeValidation = Yup.object().shape({
  // outcome: Yup.array().min(1).required(),
  reasonForRating: Yup.string().max(256, "Character limit exceeded (max 256 characters)"),
});
export const editTeamValidation = Yup.object().shape({
  teamName: Yup.string()
    .trim()
    // .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  players: Yup.array().min(1).required(),
});

const isSameorBefore = (startTime, endTime) => {
  return moment(startTime, "HH:mm:ss").isSameOrBefore(moment(endTime, "HH:mm:ss"));
};

const issame = (startTime, endTime) => {
  return moment(startTime, "HH:mm:ss").isSame(moment(endTime, "HH:mm:ss"));
};

export const trimClipValidation = Yup.object().shape({
  // title: Yup.string()
  //   .trim()
  //   .min(2, "Too Short!")
  //   .max(150, "Too Long!")
  //   .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
  //   .required("Title required"),
  start_time: Yup.string()
    .matches(/(^([01]\d|2[0-3]):[0-5]\d:[0-5]\d:\d{3}$)/, "Invalid!")
    .test("not empty", "Start time cant be empty", function (value) {
      return !!value;
    })
    .test("start_time_test", "Start time OR End time cannot be same", function (value) {
      const { end_time } = this.parent;
      return !issame(value, end_time);
    })
    .test("start_time_test", "Start time must be before end time", function (value) {
      const { end_time } = this.parent;

      return isSameorBefore(value, end_time);
    })
    .min(12, "Invalid!")
    //.matches(/^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/, "Invalid!")
    .required("Required"),
  end_time: Yup.string()
    .min(12, "Invalid!")
    .matches(/^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?(?:.([0-9]{1,3}))?$/, "Invalid!")
    .required("Required"),
});

export const createProfileValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
});

export const brightCoveCredentialValidation = Yup.object().shape({
  clientSecret: Yup.string().required("Required"),
  clientId: Yup.string().required("Required"),
  accountId: Yup.string().required("Required"),
});
export const socialMediaValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
});
// export const facValidation = Yup.object().shape({
//   facebookcaption: Yup.string().required("Required"),
// });
// export const insValidation = Yup.object().shape({
//   instagramcaption: Yup.string().required("Required"),
// });
export const addFolderValidation = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Title required"),
  aspectRatio: Yup.string().required("Aspecr ratio required"),
});
export const addPlayerValidation = Yup.object().shape({
  event: Yup.string().required("Required"),
  playerName: Yup.string()
    .trim()
    // .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  // jerseyNumber: Yup.number()
  //   .required("ERROR: The number is required!")
  //   .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
});
export const editPlayerValidation = Yup.object().shape({
  playerName: Yup.string().trim().required("Required"),
  // jerseyNumber: Yup.number()
  //   .required("ERROR: The number is required!")
  //   .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
});
export const editClipValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  start_time: Yup.number()
    .required("ERROR: The number is required!")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
});
export const addEventValidation = Yup.object().shape({
  event: Yup.string().required("Required"),
  keyMoment: Yup.string()
    .trim()
    // .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
});
export const addWebHookValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  url: Yup.string().url("Please enter a valid webhook URL").required("Required"),
});
export const addShowValidation = Yup.object().shape({
  showName: Yup.string().trim().required("Required"),
  showcast: Yup.string().trim(),
});
export const addServerValidation = Yup.object().shape({
  videoType: Yup.string().required("Required"),
  serverType: Yup.string().required("Required"),
  serverName: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Required"),
  serverIP: Yup.string().trim().min(12).required("Required"),
});
export const addPackageValidation = Yup.object().shape({
  name: Yup.string().required("Please Enter Name"),
});
export const addClient = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Please Enter Name"),
  clientId: Yup.array().min(1, "Please select at least one user").required("Required"),
});
export const editClient = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Please Enter Name"),
  users: Yup.array().min(1, "Please select at least one user").required("Required"),
});
export const addSubClient = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .required("Please Enter Name"),
  users: Yup.array().min(1, "Please select at least one user").required("Required"),
});
export const addZeeFolderValidation = Yup.object().shape({
  name: Yup.string().required("Please Enter Name"),
});
export const editPackageValidation = Yup.object().shape({
  name: Yup.string().required("Please Enter Name"),
});
export const editZeeFolderValidation = Yup.object().shape({
  name: Yup.string().required("Please Enter Name"),
});
export const addStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  folder_path: Yup.string().required("Required"),
  cdn_suffix: Yup.string().required("Required"),
  storage_name: Yup.string().required("Required"),
});
export const addMultipleStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  multiple_folder_path: Yup.array().required("Required"),
  cdn_suffix: Yup.string().required("Required"),
  storage_name: Yup.string().required("Required"),
});
export const ClipValidation = Yup.object().shape({
  cliptitle: Yup.string()
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .trim()
    .required("Please enter title"),
});
export const CMSValidation = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Title required"),
  tlmMarker: Yup.string()
    .matches(/(^([0-1]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)$)|(^([0-5]?\d):([0-5]?\d)$)|(^[0-5]?\d$)/, "Invalid!")
    .test("not empty", "Start time cant be empty", function (value) {
      return !!value;
    })
    .trim()
    .min(8, "Invalid!")
    .required(),
});
export const addAWSStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  bucket_name: Yup.string().trim().required("Required"),
  secret_key: Yup.string().trim().required("Required"),
  access_key: Yup.string().trim().required("Required"),
  region: Yup.string().trim().required("Required"),
  folder_path: Yup.string().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
//validation for multiple forlder path
export const addMultipleAWSStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  bucket_name: Yup.string().trim().required("Required"),
  secret_key: Yup.string().trim().required("Required"),
  access_key: Yup.string().trim().required("Required"),
  region: Yup.string().trim().required("Required"),
  multiple_folder_path: Yup.array().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
export const addAkimaiStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  ns_hostname: Yup.string().trim().required("Required"),
  ns_keyname: Yup.string().trim().required("Required"),
  ns_key: Yup.string().trim().required("Required"),
  ns_cpcode: Yup.string().trim().required("Required"),
  folder_path: Yup.string().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
//validation for multiple forlder path
export const addMultipleAkimaiStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  ns_hostname: Yup.string().trim().required("Required"),
  ns_keyname: Yup.string().trim().required("Required"),
  ns_key: Yup.string().trim().required("Required"),
  ns_cpcode: Yup.string().trim().required("Required"),
  multiple_folder_path: Yup.array().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
export const addGCPStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  file: Yup.mixed().required("Required"),
  gcp_bucket_name: Yup.string().trim().required("Required"),
  folder_path: Yup.string().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
//validation for multiple forlder path
export const addMultipleGCPStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_type: Yup.string().required("Required"),
  file: Yup.mixed().required("Required"),
  gcp_bucket_name: Yup.string().trim().required("Required"),
  // multiple_folder_path: Yup.array().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
export const addAzureStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_name: Yup.string().trim().required("Required"),
  bucket_type: Yup.string().required("Required"),
  folder_path: Yup.string().required("Required"),
  connection_string: Yup.string().trim().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
//validation for multiple forlder path
export const addMultipleAzureStorageValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  bucket_name: Yup.string().trim().required("Required"),
  bucket_type: Yup.string().required("Required"),
  multiple_folder_path: Yup.array().required("Required"),
  connection_string: Yup.string().trim().required("Required"),
  cdn_suffix: Yup.string().trim().required("Required"),
  storage_name: Yup.string().trim().required("Required"),
});
export const userProfilePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
    )
    .required("Password required"),
  confirmPassword: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
export const userProfileFormValidation = Yup.object().shape({
  name: Yup.string()
    .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, "Should not contain any special characters")
    .min(2, "Mininum 2 characters")
    .max(30, "Maximum 30 characters")
    .required("Name must be required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const isSameOrBefore = (startTime, endTime) => {
  return moment(startTime, "H:mm:ss").isSameOrBefore(moment(endTime, "H:mm:ss"));
};

const isSame = (startTime, endTime) => {
  return moment(startTime, "H:mm:ss").isSame(moment(endTime, "H:mm:ss"));
};

export const titleValidation = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Required"),
  startTime: Yup.string()
    .matches(/(^([0-1]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)$)|(^([0-5]?\d):([0-5]?\d)$)|(^[0-5]?\d$)/, "Invalid!")
    .test("not empty", "Start time cant be empty", function (value) {
      return !!value;
    })
    .test("start_time_test", "Start time must be greater than 00:00:00", function (value) {
      const endTime = "00:00:00";
      return !isSame(value, endTime);
    })
    .test("start_time_test", "Start time OR End time cannot be same", function (value) {
      const { endTime } = this.parent;
      return !isSame(value, endTime);
    })
    .test("start_time_test", "Start time must be before end time", function (value) {
      const { endTime } = this.parent;
      return isSameOrBefore(value, endTime);
    })
    .min(7, "Invalid!")
    .required("Required"),
  endTime: Yup.string()
    .matches(/^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/, "Invalid!")
    .min(7, "Invalid!")
    .required("Required"),
  reasonForRating: Yup.string().max(256, "Character limit exceeded (max 256 characters)"),
});

const isSameOrBeforeMilliSeconds = (startTime, endTime) => {
  return moment(startTime, "HH:mm:ss:SSS").isSameOrBefore(moment(endTime, "HH:mm:ss:SSS"));
};

const isSameMilliSeconds = (startTime, endTime) => {
  return moment(startTime, "HH:mm:ss:SSS").isSame(moment(endTime, "HH:mm:ss:SSS"));
};

export const titleMilliSecondsValidation = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    // .matches(/^[a-zA-Z0-9!@&()_\-;':"\\|\s,.\/?]*$/, "Should not contain any special characters")
    .required("Required"),
  startTime: Yup.string()
    .matches(/(^([01]\d|2[0-3]):[0-5]\d:[0-5]\d:\d{3}$)/, "Invalid!")
    .test("not empty", "Start time cant be empty", function (value) {
      return !!value;
    })
    .test("start_time_test", "Start time must be greater than 00:00:01:000", function (value) {
      const endTime = "00:00:00:0000";
      return !isSameMilliSeconds(value, endTime);
    })
    .test("start_time_test", "Start time OR End time cannot be same", function (value) {
      const { endTime } = this.parent;
      return !isSameMilliSeconds(value, endTime);
    })
    .test("start_time_test", "Start time must be less than end time", function (value) {
      const { startTime } = this.parent;
      return isSameOrBeforeMilliSeconds(value, startTime);
    })

    .min(12, "Invalid!")
    .required("Required"),
  endTime: Yup.string()
    .min(12, "Invalid!")
    .required("Required")
    .matches(/^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?(?:.([0-9]{1,3}))?$/, "Invalid!")
    .test("end_time_test", `Clip below 1 second won’t generate`, function (value) {
      const { startTime } = this.parent;
      return isDiffBetweenStartAndEndTime(startTime, value);
    }),
  reasonForRating: Yup.string().max(256, "Character limit exceeded (max 256 characters)"),
});
const isDiffBetweenStartAndEndTime = (startTime, endTime) => {
  const startMoment = moment(startTime, "HH:mm:ss:SSS");
  const endMoment = moment(endTime, "HH:mm:ss:SSS");
  const diffInSeconds = endMoment.diff(startMoment, "seconds");
  if (diffInSeconds >= 1) {
    return true;
  }
};
export const editWebStoryValidation = Yup.object().shape({
  templateTitle: Yup.string().required("Required"),
  title: Yup.string().max(70, "Maximum 70 characters").required("Required"),
  description: Yup.string().max(170, "Maximum 170 characters"),
  storageId: Yup.string().required("Required"),
  matchLink: Yup.string().required("Required"),
  sortBy: Yup.string().required("Required"),
  noOfPagesAllowed: Yup.number()
    .required("ERROR: The number is required!")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
});

export const folderValidationSchema = Yup.object().shape({
  // outcome: Yup.array().compact().min(1).required(),
  title: Yup.string().required("Required"),
  uploadUrl: Yup.string().required("Please enter url"),
});

export const goodToGoStoryValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
});
export const modalLiveToolValidation = Yup.object().shape({
  startTimeStr: Yup.string()
    .matches(/(^([0-1]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)$)|(^([0-5]?\d):([0-5]?\d)$)|(^[0-5]?\d$)/, "Invalid!")
    .test("not empty", "Start time cant be empty", function (value) {
      return !!value;
    })
    .test("start_time_test", "Start time must be greater than 00:00:00", function (value) {
      const endTime = "00:00:00";
      return !isSame(value, endTime);
    })
    .test("start_time_test", "Start time OR End time cannot be same", function (value) {
      const { endTimeStr } = this.parent;
      return !isSame(value, endTimeStr);
    })
    .test("start_time_test", "Start time must be before end time", function (value) {
      const { endTimeStr } = this.parent;
      return isSameOrBefore(value, endTimeStr);
    })
    .min(7, "Invalid!")
    .required("Required"),
  endTimeStr: Yup.string()
    .matches(/^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/, "Invalid!")
    .min(7, "Invalid!")
    .required("Required"),
});

export const modalLiveToolValidationMilli = Yup.object().shape({
  startTimeStr: Yup.string()
    .matches(/(^([01]\d|2[0-3]):[0-5]\d:[0-5]\d:\d{3}$)/, "Invalid!")
    .test("not empty", "Start time cant be empty", function (value) {
      return !!value;
    })
    .test("start_time_test", "Start time must be greater than 00:00:01:000", function (value) {
      const endTime = "00:00:00:0000";
      return !isSameMilliSeconds(value, endTime);
    })
    .test("start_time_test", "Start time OR End time cannot be same", function (value) {
      const { endTimeStr } = this.parent;
      return !isSameMilliSeconds(value, endTimeStr);
    })
    .test("start_time_test", "Start time must be less than end time", function (value) {
      const { startTimeStr } = this.parent;
      return isSameOrBeforeMilliSeconds(value, startTimeStr);
    })

    .min(12, "Invalid!")
    .required("Required"),
  endTimeStr: Yup.string()
    .min(12, "Invalid!")
    .required("Required")
    .matches(/^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?(?:.([0-9]{1,3}))?$/, "Invalid!")
    .test("end_time_test", `Clip below 1 second won’t generate`, function (value) {
      const { startTimeStr } = this.parent;
      return isDiffBetweenStartAndEndTime(startTimeStr, value);
    }),
});

export const userLimitationsValidation = Yup.object().shape({
  maxVideoDownloadDuration: Yup.number()
    .required("Required")
    .min(0, "Value must be greater than or equal to 0")
    .max(59940, "Value must be less than or equal to 59940 seconds")
    .test("isNotDecimal", "Decimal value are not allowed", function (value) {
      return Number.isInteger(value);
    })
    .when("currentMaxVideoDownloadDuration", (currentMaxVideoDownloadDuration, schema) => {
      return schema.test({
        test: function (value) {
          if (value < currentMaxVideoDownloadDuration) {
            return false;
          }
          return true;
        },
        message: `Value must not be lower than the previously set value (${currentMaxVideoDownloadDuration}).`,
      });
    }),

  maxVideoProcessDuration: Yup.number()
    .required("Required")
    .min(0, "Value must be greater than or equal to 0")
    .max(59940, "Value must be less than or equal to 59940 seconds")
    .test("isNotDecimal", "Decimal value are not allowed", function (value) {
      return Number.isInteger(value);
    })
    .when("currentMaxVideoProcessDuration", (currentMaxVideoProcessDuration, schema) => {
      return schema.test({
        test: function (value) {
          if (value < currentMaxVideoProcessDuration) {
            return false;
          }
          return true;
        },
        message: `Value must not be lower than the previously set value (${currentMaxVideoProcessDuration}).`,
      });
    }),
});

export const csvDateValidation = Yup.object().shape({
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .when("startDate", (startDate, schema) => {
      return startDate ? schema.min(startDate, "End date cannot be less than the start date") : schema;
    }),
  expectedTAT: Yup.number().required("Required"),
});

export const exportCSVValidation = Yup.object().shape({
  startDate: Yup.date()
    .typeError("Start Date is required")
    .required("Start Date is required")
    .min(new Date(new Date().setMonth(new Date().getMonth() - 3)), "Start Date must be within the last three months")
    .max(new Date(), "Start Date cannot be future or current date"),

  endDate: Yup.date()
    .typeError("End Date is required")
    .required("End Date is required")
    .max(new Date(), "End Date cannot be future or current date ")
    .min(Yup.ref("startDate"), "End Date should be after Start Date")
    .notOneOf([Yup.ref("startDate")], "End Date cannot be equal to Start Date"),
});
