import React from "react";
// import "./../styles.scss";
import Modal from "react-modal";
const DeleteConfirmation = ({ ...props }) => {
  const { deleteConfirmModalOpen, setDeleteConfirmModalOpen, deleteFunction, clipCounts } = props;

  const closeModel = () => {
    setDeleteConfirmModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={deleteConfirmModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModel}
        contentLabel="Delete Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Delete</h5>
          </div>
          <div className="react-modal-body text-center">
            <h6 className="react-modal-body-text">Are you sure you want to delete {clipCounts}?</h6>
          </div>
          <div className="react-modal-footer text-center">
            <div className="react-foo-container">
              <button type="button" onClick={closeModel} className="btn btn-white">
                Close{" "}
              </button>
              <button type="button" onClick={deleteFunction} className="btn btn-primary">
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteConfirmation;
