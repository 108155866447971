import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" fill="none" viewBox="0 0 24 20" {...props}>
    <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M16.005 13.998l-4-4-4 4M12.005 9.998v9"></path>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.395 16.387a5 5 0 00-2.39-9.39h-1.26a8 8 0 10-13.74 7.3"
    ></path>
    <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M16.005 13.998l-4-4-4 4"></path>
  </svg>
);

export default SvgComponent;
