import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M3.354 3.153a.833.833 0 01.814-.653h11.667c.39 0 .728.271.813.653l1.667 7.5c.013.059.02.12.02.18v5.834c0 .46-.373.833-.834.833h-15a.833.833 0 01-.833-.833v-5.834c0-.06.007-.121.02-.18l1.666-7.5zm1.482 1.014L3.54 10h3.961a2.5 2.5 0 105 0h3.961l-1.296-5.833H4.836zm8.985 7.5a4.168 4.168 0 01-7.64 0H3.335v4.166h13.333v-4.166h-2.847z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
