import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useOutletContext } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Spin, notification, Checkbox, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CloseModal from "../../../components/icons/CloseModal";
import moment from "moment";
import { CMSValidation } from "../../../utils/validations";
import { sonyClientPublishField } from "../../../config/index";
import { get, compact } from "lodash";
import { calculateActualDuration, stringToSeconds } from "../../../constants/phase";
const { Option } = Select;

const PublishToCMS = (props) => {
  const {
    publishToCMS,
    setPublishTOCMS,
    editClip,
    clip,
    publishClips,
    clientWebHookPublish,
    allClips,
    singleClipDetail,
    streamDetails,
    filters,
  } = props;
  const closeModal = () => {
    setPublishTOCMS(false);
  };
  const { clientSetting } = useOutletContext();
  const [singleClip, setSingleClip] = useState({});
  const [loader, setLoader] = useState(false);
  const [cmsIdError, setCmsIdError] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [isReelPublish, setIsReelPublish] = useState(false);
  const [initialState, setInitialState] = useState({
    title: clip?.episodeTitle,
    tlmMarker: clip?.tlmMarker
      ? moment.utc(clip?.tlmMarker * 1000).format("HH:mm:ss")
      : calculateActualDuration(streamDetails, clip),
    cmsId: "",
  });
  useEffect(() => {
    if (clientSetting?.clientWebHookURLs?.length) {
      setInitialState({ ...initialState, cmsId: clientSetting?.clientWebHookURLs[0]?.id || "" });
    }
  }, [clientSetting?.clientWebHookURLs?.length]);

  const updateData = async () => {
    const seoObject = { seoTitle: initialState.seoTitle, seoDescription: initialState.seoDescription };
    const payload = {
      ...initialState,
      episodeTitle: initialState.title,
      tlmMarker: stringToSeconds(initialState?.tlmMarker),
      tags: Array.isArray(initialState?.tags)
        ? initialState?.tags
        : compact((initialState?.tags || "")?.split(",")?.map((tag) => tag?.trim())),
      id: clip.id,
      _id: clip._id,
      userId: localStorage.getItem("__uid"),
      seoObject,
    };
    delete payload.title;
    delete payload.seoDescription;
    delete payload.seoTitle;
    const data = await editClip(payload);
    if (data.value.success) {
      notification["success"]({ message: data.value.message });
      getSingleClipDetails();
    } else {
      notification["error"]({ message: "something went wrong" });
    }
  };

  const publishFun = async () => {
    await publishClips({
      id: clip.id,
      title: clip.episodeTitle,
      storageName: "",
      clipsAspectRatio: filters?.aspectRatio || clip.aspectRatio,
    });
    setLoader(false);
    closeModal();
  };

  const publishClipToCMS = async () => {
    if (!initialState.cmsId) {
      setCmsIdError(true);
      return;
    }
    setLoader(true);
    if (clientSetting?.appAdditionalPermissions?.dynamicCms) {
      webHookPublish();
    } else {
      publishFun();
    }
  };
  const webHookPublish = async () => {
    const data = await clientWebHookPublish({
      clipId: singleClip.id,
      userId: localStorage.getItem("__uid"),
      cmsId: initialState.cmsId,
      streamId: clip.streamId,
      isPublish: isPublish ? 0 : 1,
      isReel: isReelPublish,
      clipsAspectRatio: filters?.aspectRatio || clip.aspectRatio,
    });
    if (data.value.success) {
      allClips();
      notification["success"]({ message: data.value.message });
    } else {
      notification["error"]({ message: data.value.message });
    }
    setLoader(false);
    closeModal();
  };

  const getSingleClipDetails = async () => {
    const data = await singleClipDetail({
      id: clip?._id,
    });
    if (data?.value?.success) {
      setSingleClip(data?.value?.data);
    }
  };

  useEffect(() => {
    getSingleClipDetails();
  }, [clip]);

  useEffect(() => {
    let initialStateData = { ...initialState, cmsId: clientSetting?.clientWebHookURLs?.[0]?.id || "" };
    if (sonyClientPublishField && streamDetails && clip && clientSetting?.appAdditionalPermissions?.sonyFeed) {
      sonyClientPublishField.forEach((data) => {
        // if(data.name=="tags" ){
        initialStateData[`${data.name}`] =
          get(clip, `${data.name}`, "") ||
          get(clip, `${data.defaultValue}`, "") ||
          get(streamDetails, `${data.defaultValue}`, "");
        // }else{
        //   initialStateData[`${data.name}`] = get(clip, `${data.defaultValue}`, "")
        //     ? get(clip, `${data.defaultValue}`, "")
        //     : get(streamDetails, `${data.defaultValue}`, "");
        // }
      });
      setInitialState(initialStateData);
    }
  }, [streamDetails, sonyClientPublishField, clip, clientSetting]);

  return (
    <div>
      <Modal
        isOpen={publishToCMS}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Publish to CMS"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered filter-modal"
      >
        <Formik initialValues={initialState} enableReinitialize validationSchema={CMSValidation} onSubmit={updateData}>
          {({ errors, touched, values }) => (
            <div className="modal-content">
              <Form>
                <div className="react-modal-header">
                  <h5 className="react-modal-title">
                    Publish to CMS <span>{clip?.id}</span>
                  </h5>
                  <button type="button" onClick={closeModal} className="btn btn__default">
                    <CloseModal />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="form-container inlinebtn-row">
                    <div className="form-group">
                      <label className="form-label">
                        CMS <span className="required">{"*"}</span>
                      </label>
                      <Select
                        showSearch
                        placeholder="select"
                        optionFilterProp="children"
                        className="custom-ant-select"
                        style={{ textTransform: "capitalize" }}
                        name="cmsId"
                        onChange={(e) => {
                          if (e) {
                            setCmsIdError(false);
                          } else {
                            setCmsIdError(true);
                          }
                          setInitialState({ ...initialState, cmsId: e });
                        }}
                        value={values.cmsId}
                      >
                        <Option value="">Select</Option>
                        {clientSetting?.clientWebHookURLs?.length &&
                          clientSetting?.clientWebHookURLs.map((webhook, index) => {
                            return (
                              <Option value={webhook?.id} key={index}>
                                {webhook?.name.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                              </Option>
                            );
                          })}
                      </Select>
                      {cmsIdError && <div className="invalid-feedback">Required</div>}
                    </div>
                    <div className="row">
                      <div className="three-column-row">
                        <div className="form-group">
                          <label className="form-label">
                            Title <span className="required">*</span>
                          </label>
                          <Field
                            className="form-control"
                            name="title"
                            type="text"
                            placeholder="Enter title"
                            value={initialState.title}
                            onChange={(e) => {
                              setInitialState({
                                ...initialState,
                                title: e.target.value,
                              });
                            }}
                          />
                          {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Time Line Marker <span className="required">*</span>
                          </label>
                          <Field
                            className="form-control"
                            name="tlmMarker"
                            type="text"
                            placeholder="hh:mm:ss"
                            value={initialState.tlmMarker}
                            onChange={(e) => {
                              setInitialState({
                                ...initialState,
                                tlmMarker: e.target.value,
                              });
                            }}
                          />
                          {touched.tlmMarker && errors.tlmMarker && (
                            <div className="invalid-feedback">{errors.tlmMarker}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="three-column-row">
                        <div className="form-group">
                          <label className="form-label">Draft Publish</label>
                          <div className="">
                            <Checkbox
                              value={isPublish}
                              onChange={(e) => {
                                setIsPublish(e.target.checked);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Reel Publish</label>
                          <div className="">
                            <Checkbox
                              value={isReelPublish}
                              onChange={(e) => {
                                setIsReelPublish(e.target.checked);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {clientSetting?.appAdditionalPermissions?.sonyFeed && (
                      <div className="">
                        {sonyClientPublishField &&
                          sonyClientPublishField.map((data, index) => {
                            return (
                              <div className="mt-4" key={index}>
                                <div className="form-group">
                                  <label className="form-label">{data.heading}</label>
                                  <Field
                                    className="form-control"
                                    name={data.name}
                                    type="text"
                                    placeholder={`Enter ${data.heading}`}
                                    defaultValue={
                                      get(clip, `${data.defaultValue}`, "")
                                        ? get(clip, `${data.defaultValue}`, "")
                                        : get(streamDetails, `${data.defaultValue}`, "")
                                    }
                                    onChange={(e) => {
                                      setInitialState({
                                        ...initialState,
                                        [`${data.name}`]: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="react-modal-footer">
                  <div className="react-foo-container">
                    <div className="right-btn-block text-end">
                      <button type="button" className="btn btn-white" onClick={closeModal}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <>
                        <button
                          disabled={singleClip?.episodeTitle === "" || singleClip?.start_time === "" ? true : false}
                          className="btn btn-primary"
                          type="button"
                          onClick={publishClipToCMS}
                        >
                          Publish to CMS
                          {loader && (
                            <Spin
                              size="large"
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 50,
                                    color: "#000",
                                    marginLeft: 50,
                                  }}
                                  spin
                                />
                              }
                              className="loader__full"
                            ></Spin>
                          )}
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PublishToCMS;
