import * as React from "react";

const SvgComponent = (props) => (
  <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 6.848h3.25v1.619c.468-.931 1.669-1.768 3.472-1.768 3.457 0 4.278 1.853 4.278 5.254v6.297h-3.5v-5.523c0-1.936-.468-3.028-1.66-3.028-1.653 0-2.34 1.177-2.34 3.027v5.524h-3.5V6.848ZM1.25 18.1h3.5V6.7h-3.5v11.402ZM5.25 2.981a2.214 2.214 0 0 1-.659 1.577 2.266 2.266 0 0 1-3.183 0A2.23 2.23 0 0 1 .75 2.982c0-.592.236-1.16.659-1.577a2.26 2.26 0 0 1 3.182 0c.422.418.66.985.66 1.577Z"
      fill="#282828"
    />
  </svg>
);

export default SvgComponent;
