import { CloudUploadOutlined, FieldTimeOutlined, StarFilled } from "@ant-design/icons";
import { Checkbox, Dropdown, Tag, Tooltip } from "antd";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Image from "../../../components/common/Image/component";
import ManageAccess from "../../../components/common/ManageAccess/manageAccess";
import { ClipStarIcon, DotsIcon, SftpIcon, TimeIcon } from "../../../components/icons";
import { fetchTagsFromClip } from "../../../helpers";
import defaultThumbNail from "../../../images/defaulThumb.png";
import IllustoLogo from "../../../images/illusto-watermark-new.svg";
import "../styles.scss";
import TableAction from "./TableAction";
import { EVENT_NAME, SERVER, ZEE_CLIENT } from "../../../constants/phase";

const VideoGrid = ({ ...props }) => {
  const {
    id,
    filters,
    step,
    updatesftp,
    getAspectRatios,
    deleteClips,
    copyClip,
    editClip,
    ftpOpen,
    allClips,
    allClipsForCms,
    aspectRatio,
    publishClip,
    streamDetails,
    getTrimClip,
    fetchStorage,
    s3BucketPublish,
    jsonBucketPublish,
    storage,
    ftpList,
    folderArray,
    setFolderArray,
    setShowClass,
    showClass,
    checkhighLight,
    clipExportJson,
    clipExportXml,
    downloadClips,
    user,
    hoveredVideo,
    setHoveredVideo,
    openPreviewModal,
    modalIsOpen,
    setIsOpen,
    clipUrl,
    clipTitle,
    dumpOnS3,
    bucketCopy,
    clientWebHookPublish,
    fetchUserDetails,
    copyClipToReviewer,
    getPublicUrls,
    uploadClipInBrightCove,
    fetchBrightCoveToken,
    getCustomFields,
    fetchUsersByUserIds,
    allUsers,
    clientSetting,
    clientPermission,
    setClipDetails,
    addThumbnail,
    getUploadPicture,
    updateClipDetails,
    GenerateVideoTitle,
    clipsDetails,
    generateAutoFlip,
    setToggle,
    toggle,
    setFTPOpen,
    deleteEditedClip,
    clipListFolder,
    singleClipDetail,
    genrateNewClip,
    AccessControl,
    overWriteClip,
    clipTaggingFields,
    clipIdsArray,
    setClipIdsArray,
    bytesToMB,
    ftpPublish,
    shareOnSocialMedias,
    getVideoMetaData,
    filterClipsByTag,
    addPublishNotification,
    hlsToMp4Data,
    ruleTitle,
    refreshClips,
    handleSteps,
    setLiveVideoUrl,
    getStreamDetail,
  } = props;
  const { id: folderId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const ruleId = query.get("ruleId");
  const clips = query.get("clips");
  const navigatingFrom = query.get("navigating-from");

  const breadcrumData = {
    folder: { id: folderId, title: "Clips" },
    rule: { id: ruleId, title: ruleTitle },
    stream: { id: streamDetails?.streamId, title: streamDetails?.title },
  };

  const navigate = useNavigate();
  const navClipId = (clip) => {
    if (clip) {
      const clipid = clip?._id;
      let aspectR = clip?.aspectRatio ? clip?.aspectRatio : "16:9";
      if (aspectRatio) {
        aspectR = aspectRatio;
      }
      if (props?.isRuleBasedClips) {
        if (navigatingFrom) {
          navigate(
            `/clip-info/${clipid}?status=edits&ratio=${aspectR}&breadcrum-data=${JSON.stringify(
              breadcrumData,
            )}&clips=${clips}&navigating-from=${navigatingFrom}`,
          );
        } else {
          navigate(
            `/clip-info/${clipid}?status=edits&ratio=${aspectR}&breadcrum-data=${JSON.stringify(
              breadcrumData,
            )}&clips=${clips}`,
          );
        }
      } else {
        navigate(`/clip-info/${clipid}?status=edits&ratio=${aspectR}`);
      }
    }
  };

  const tableAction = (clip) => {
    return (
      <TableAction
        id={id}
        updatesftp={updatesftp}
        setFTPOpen={setFTPOpen}
        ftpOpen={ftpOpen}
        ftpPublish={ftpPublish}
        deleteEditedClip={deleteEditedClip}
        clipListFolder={clipListFolder}
        generateAutoFlip={generateAutoFlip}
        setToggle={setToggle}
        toggle={toggle}
        genrateNewClip={genrateNewClip}
        getAspectRatios={getAspectRatios}
        clip={clip}
        step={step}
        deleteClips={deleteClips}
        copyClip={copyClip}
        editClip={editClip}
        allClips={allClips}
        allClipsForCms={allClipsForCms}
        aspectRatio={aspectRatio}
        publishClip={publishClip}
        streamDetails={streamDetails}
        getTrimClip={getTrimClip}
        fetchStorage={fetchStorage}
        s3BucketPublish={s3BucketPublish}
        jsonBucketPublish={jsonBucketPublish}
        storage={storage}
        ftpList={ftpList}
        folderArray={folderArray}
        setFolderArray={setFolderArray}
        setShowClass={setShowClass}
        showClass={showClass}
        checkhighLight={checkhighLight}
        clipExportJson={clipExportJson}
        clipExportXml={clipExportXml}
        downloadClips={downloadClips}
        user={user}
        openPreviewModal={openPreviewModal}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        clipUrl={clipUrl}
        clipTitle={clipTitle}
        dumpOnS3={dumpOnS3}
        bucketCopy={bucketCopy}
        clientWebHookPublish={clientWebHookPublish}
        fetchUserDetails={fetchUserDetails}
        copyClipToReviewer={copyClipToReviewer}
        getPublicUrls={getPublicUrls}
        uploadClipInBrightCove={uploadClipInBrightCove}
        fetchBrightCoveToken={fetchBrightCoveToken}
        getCustomFields={getCustomFields}
        fetchUsersByUserIds={fetchUsersByUserIds}
        allUsers={allUsers}
        clientSetting={clientSetting}
        addThumbnail={addThumbnail}
        getUploadPicture={getUploadPicture}
        updateClipDetails={updateClipDetails}
        GenerateVideoTitle={GenerateVideoTitle}
        singleClipDetail={singleClipDetail}
        overWriteClip={overWriteClip}
        shareOnSocialMedias={shareOnSocialMedias}
        getVideoMetaData={getVideoMetaData}
        filters={filters}
        addPublishNotification={addPublishNotification}
        hlsToMp4Data={hlsToMp4Data}
        refreshClips={refreshClips}
        handleSteps={handleSteps}
        getStreamDetail={getStreamDetail}
        {...props}
      />
    );
  };

  const handleClip = (clip) => {
    const array = [...folderArray];
    const index = array.map(({ _id }) => _id).indexOf(clip._id);
    if (index === -1) {
      setFolderArray([...array, clip]);
    } else {
      array.splice(index, 1);
      setFolderArray(array);
    }
  };
  const setClass = (e) => {
    if (e.target.checked === true) {
      setShowClass("vv-video-card selected");
    } else {
      setShowClass("vv-video-card");
    }
  };

  const streamFunction = (clip) => {
    const clipTitle = clip?.convertVersion !== "default" ? `${clip?.title} V${clip?.convertVersion}` : clip?.title;
    return (
      <>
        <div className="vv-card-yt">
          <div className={"vv-video-card review-container"}>
            <div>
              <div className="vv-video-thumb">
                <Image src={defaultThumbNail} alt="" />
              </div>
            </div>
            <div className="vv-video-top-info">
              <div />
              <div
                className="vv-video-top-left-info "
                onClick={() => {
                  setLiveVideoUrl(clip?.mp4VideoUrl || "");
                }}
              >
                <ManageAccess value={clip?.initialStatus > 0}>
                  <div className="vv-video-top-right-info">
                    <div
                      className="vv-video-preview-btn"
                      onClick={() => {
                        openPreviewModal(clip);
                      }}
                    >
                      Preview
                    </div>
                  </div>
                </ManageAccess>
                <div className="vv-video-action-bar">
                  <Dropdown
                    overlay={tableAction(clip)}
                    trigger={["click"]}
                    className="custom-ant-more-dropdown"
                    overlayClassName={"tableActionBlock"}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <DotsIcon />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="vv-video-bottom-info bottom-container-clip-data pt-0 align-items-start mt-2">
            <div className="vv-video-bottom-left-info">
              <div className="vv-video-title word-break-two t itle_Pascal my-1">
                <Tooltip title={clipTitle}>
                  {clipTitle ? clipTitle.substring(0, 75) + (clipTitle.length > 75 ? "..." : "") : ""}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const clipFunction = (clip, eventsArray, index) => {
    let thumbnail = clip.videoThumbnailUrl;

    if (filters?.aspectRatio) {
      thumbnail =
        clip?.editedVideos?.find(
          ({ aspect_ratio, event }) => aspect_ratio === filters?.aspectRatio && event !== EVENT_NAME.DYNAMIC,
        )?.thumbnailUrl || clip.videoThumbnailUrl;
    }
    return (
      <>
        <div className="vv-card-yt" key={clip._id}>
          <div
            className={
              checkhighLight === "Create Highlight" ||
              checkhighLight === "Create story" ||
              folderArray.indexOf(clip) !== -1
                ? "vv-video-card selected review-container"
                : "vv-video-card review-container"
            }
          >
            <div key={index} onMouseOver={() => setHoveredVideo(index)} onMouseOut={() => setHoveredVideo(null)}>
              {hoveredVideo === index ? (
                <div className="vv-video-thumb">
                  <video
                    onMouseOver={(e) => e.target.play()}
                    onMouseOut={(e) => e.target.pause()}
                    src={clip?.videoUrl}
                    autoPlay
                    loop
                    muted
                  />
                  <ManageAccess value={clientSetting?.appAdditionalPermissions?.isAllowAccessToClipInfo}>
                    <div className="vv-video-block-area" onClick={() => navClipId(clip)}></div>
                  </ManageAccess>
                </div>
              ) : (
                <div className="vv-video-thumb">
                  {clip.videoThumbnailUrl ? (
                    <div className="ratio-image">
                      <Image src={thumbnail} alt="Clip image" />
                    </div>
                  ) : (
                    <Image src={defaultThumbNail} alt="" />
                  )}
                  <ManageAccess value={clientSetting?.appAdditionalPermissions?.isAllowAccessToClipInfo}>
                    <div className="vv-video-block-area" onClick={() => navClipId(clip)}></div>
                  </ManageAccess>
                </div>
              )}
            </div>
            <div className="vv-video-top-info">
              <div className="vv-video-top-left-info vv-video-title ">
                {checkhighLight === "Create Highlight" ? (
                  <>
                    <Checkbox
                      className="round-checkbox checkbox-selection cs-t-0"
                      onChange={() => handleClip(clip)}
                      onClick={(e) => setClass(e)}
                      checked={folderArray.map(({ _id }) => _id).indexOf(clip._id) !== -1 ? true : false}
                    />
                  </>
                ) : (
                  ""
                )}
                <ManageAccess
                  value={
                    clientSetting?.appAdditionalPermissions?.clipRating &&
                    streamDetails?.allowClipRating &&
                    clip?.clipRating > 0
                  }
                >
                  <div className="clips-card-rating-block ms-2 vv-video-tags">
                    <StarFilled className="me-1" />
                    {clip?.clipRating}
                  </div>
                </ManageAccess>
                <ManageAccess
                  value={
                    clientSetting?.appAdditionalPermissions?.allowEnhancedTagging &&
                    streamDetails?.server_address !== SERVER &&
                    !clip.isManual
                  }
                >
                  <div class="ms-2">
                    <ClipStarIcon />
                  </div>
                </ManageAccess>
                <div class="d-flex align-items-center gap-1">
                  <div class="vv-video-top-left-info red card-video-ar ms-2">
                    {filters.aspectRatio || clip?.aspectRatio}
                  </div>
                  {clip?.isClipCreateByIllusto && (
                    <div class="vv-video-top-left-info red card-video-ar">
                      {clip.illustoClipStatus === "pending"
                        ? "Draft"
                        : clip.illustoClipStatus === "complete"
                        ? "Exported"
                        : clip.illustoClipStatus === "failed"
                        ? "Failed"
                        : null}
                    </div>
                  )}
                </div>

                <AccessControl code="clip_data_consumption" page="clips">
                  <span className={bytesToMB(clip?.storageConsumed) ? "m-1 vv-video-tags card-mb-info" : ""}>
                    {bytesToMB(clip?.storageConsumed)}
                  </span>
                </AccessControl>
              </div>
              <div className="vv-video-top-right-info">
                {(clip?.csvData?.edlFilePath || "") !== "" &&
                  clientSetting?.appAdditionalPermissions?.zeepublishing && (
                    <div className="vv-video-preview-btn">Edl</div>
                  )}
                {clip?.isManualUpload && (
                  <CloudUploadOutlined className="vv-video-preview-btn  vv-video-preview-btn-icon" />
                )}
                {(clip?.playback_speed === "0.75" || clip?.playback_speed == "0.5") && (
                  <span className="custom-ant-more-dropdown" style={{ padding: "2px" }}>
                    <Tooltip title={`${clip?.playback_speed}x`}>
                      <FieldTimeOutlined style={{ fontSize: "18px", color: "#fff" }} />
                    </Tooltip>
                  </span>
                )}
                {clip?.isFTPPublished && (
                  <Tooltip title={"FTP Published"}>
                    <div className="vv-video-preview-btn ms-2 sftp-icon-block">
                      <SftpIcon />
                    </div>
                  </Tooltip>
                )}
                {clip?.isClipCreateByIllusto && clip.illustoClipStatus === "pending" ? null : (
                  <div
                    className="vv-video-preview-btn"
                    onClick={() => {
                      openPreviewModal(clip);
                      setClipDetails(clip);
                    }}
                  >
                    Preview
                  </div>
                )}
                <div className="vv-video-action-bar">
                  <Dropdown
                    overlay={tableAction(clip)}
                    trigger={["click"]}
                    className="custom-ant-more-dropdown"
                    overlayClassName={"tableActionBlock"}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <DotsIcon />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div
              className="vv-video-bottom-info"
              onClick={() =>
                clientSetting?.appAdditionalPermissions?.isAllowAccessToClipInfo ? navClipId(clip) : null
              }
            >
              <div className="vv-video-bottom-info pt-0">
                <div className="vv-video-bottom-left-info">
                  {clip?.isClipCreateByIllusto == true ? <img src={IllustoLogo} alt="illustologo" /> : ""}
                  {clip.clipData && streamDetails?.category === "cricket" && (
                    <div className="vv-video-clip-info cricket-score-text">
                      <>
                        {["NA", "", null, undefined].includes(clip.clipData?.run) ? 0 : clip.clipData?.run} -{" "}
                        {["NA", "", null, undefined].includes(clip.clipData?.wicket) ? 0 : clip.clipData?.wicket} |{" "}
                        {["NA", "", null, undefined].includes(clip.clipData?.over) ? 0 : clip.clipData?.over}
                      </>
                    </div>
                  )}
                </div>
                <div className="vv-video-bottom-right-info text-end">
                  {clientSetting?.appAdditionalPermissions?.hasOwnProperty("showClipTAT") && (
                    <div className="vv-video-title word-break-two title_Pascal tat-block-text">
                      {clip?.timeTaken ? `TAT: ${moment.utc(clip?.timeTaken * 1000).format("mm:ss")}` : ""}
                    </div>
                  )}
                  <div className="vv-video-duration">
                    {moment
                      .utc(
                        (clip?.editedVideos?.find((o) => o?.aspect_ratio === aspectRatio)?.duration || clip.duration) *
                          1000,
                      )
                      .format("mm:ss")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="vv-video-bottom-info bottom-container-clip-data pt-0 align-items-start mt-2"
            onClick={() => (clientSetting?.appAdditionalPermissions?.isAllowAccessToClipInfo ? navClipId(clip) : null)}
          >
            <div className="vv-video-bottom-left-info">
              <div className="vv-video-clip-tags title_Pascal blue-tags-group mt-0">
                {clip?.story_published_status === "published" && (
                  <Tag>
                    <strong>{"P"}</strong>
                  </Tag>
                )}

                {eventsArray
                  ?.filter((e) => e)
                  .map((event, i) => {
                    if (i === 0) {
                      return event ? (
                        <Tooltip title={event}>
                          {" "}
                          <Tag className="word-break-two title_Pascal" key={event}>
                            {event.substring(0, 15) + (event.length >= 15 ? "..." : "")}
                          </Tag>
                        </Tooltip>
                      ) : null;
                    }
                    return null;
                  })}
                {eventsArray?.filter((e) => e).length > 1 ? (
                  <Tooltip
                    title={eventsArray
                      .map((events, index) => {
                        return (
                          <span className="text-capitalize" key={index}>
                            {events},{" "}
                          </span>
                        );
                      })
                      ?.filter((e, i) => (i !== 0 ? e : null))}
                  >
                    <Tag className="z-99 ms-1">+{eventsArray?.filter((e, i) => (i !== 0 ? e : null)).length}</Tag>
                  </Tooltip>
                ) : null}
              </div>
              <div className="vv-video-title word-break-two t itle_Pascal my-1">
                <Tooltip title={clip.episodeTitle}>
                  {clip.episodeTitle
                    ? clip.episodeTitle.substring(0, 40) + (clip.episodeTitle.length > 40 ? "..." : "")
                    : ""}
                </Tooltip>
              </div>
              <div className="vv-video-clip-time">
                {clip?.start_time > 0 && clip?.end_time > 0 && (
                  <>
                    <TimeIcon />{" "}
                    {moment()
                      .startOf("day")
                      .seconds(parseInt(clip.trim_start_time || clip.start_time))
                      .format("H:mm:ss")}{" "}
                    -{" "}
                    {moment()
                      .startOf("day")
                      .seconds(parseInt(clip.trim_end_time || clip.end_time))
                      .format("H:mm:ss")}
                  </>
                )}
              </div>
            </div>
            <div className="vv-video-bottom-right-info text-end">
              <div className="vv-video-top-left-info red card-video-ar ms-2 d-inline-block">
                {filters?.aspectRatio || clip?.aspectRatio}
              </div>
              <div className="vv-video-title word-break-two title_Pascal tat-block-text">
                {get(clip, "illustoUpdateDate", null)
                  ? moment(get(clip, "illustoUpdateDate", "")).format("ll") +
                    ", " +
                    moment(clip.illustoUpdateDate).format("h:mm A")
                  : moment(get(clip, "createdAt", "")).format("ll") + ", " + moment(clip.createdAt).format("h:mm A")}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {step !== 4 && clipsDetails?.length
        ? clipsDetails.map((clip, index) => {
            let eventsArray = clip?.filter?.split(",") || [];
            clip?.clipData?.events?.map((obj) => {
              eventsArray.push(obj.type);
              eventsArray.push(obj.playerName);
            });
            eventsArray.push(...(clip?.clipData?.outcome?.split(",") || []));
            eventsArray = eventsArray.filter((n) => n);
            eventsArray = [...eventsArray, ...fetchTagsFromClip(clip.clipData, clipTaggingFields)];
            eventsArray = [...new Set(eventsArray)];

            return (
              <div className="vv-grid-item" key={clip._id}>
                {clip?.cmsPublish ||
                  (clientSetting?.clientType === ZEE_CLIENT && clip?.lastPublishedDate && (
                    <Tooltip
                      title={`Published At ${moment(
                        clientSetting?.clientType === ZEE_CLIENT ? clip?.lastPublishedDate : clip?.publishTime,
                      ).format("YYYY-MM-DD h:mm A")}`}
                    >
                      <div className="publish-background"></div>
                    </Tooltip>
                  ))}
                {clip?.cmsPublish || (clientSetting?.clientType === ZEE_CLIENT && clip?.lastPublishedDate) ? (
                  <Tooltip
                    title={`Published At ${moment(
                      clientSetting?.clientType === ZEE_CLIENT ? clip?.lastPublishedDate : clip?.publishTime,
                    ).format("YYYY-MM-DD h:mm A")}`}
                  >
                    {clipFunction(clip, eventsArray, index)}
                  </Tooltip>
                ) : (
                  clipFunction(clip, eventsArray, index)
                )}
              </div>
            );
          })
        : hlsToMp4Data?.length &&
          step === 4 &&
          hlsToMp4Data?.map((data) => {
            return streamFunction(data);
          })}
    </>
  );
};

export default VideoGrid;
