import { LoadingOutlined } from "@ant-design/icons";
import { Spin, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import shortUUID from "short-uuid";
import Header from "../../components/common/Header/container";
import BulkDownloadPopup from "../../components/common/bulkdownload";
import profileFeatureContext from "../../context/profile-access";
import { SocketContext } from "../../context/socket";
import { clientPermissions } from "../../config";
import { setUserProperties } from "../../utils";
import { isUserAuthenticated } from "../../utils/Storage";

const antIcon = <LoadingOutlined style={{ fontSize: 30, color: "#000", align: "center" }} spin />;

const PrivateRoute = (
  {
    fetchMe,
    getClientSetting,
    getStreamStates,
    getUserProcessingLimit,
    fetchAccessControl,
    getS3PresignedURl,
    getDownloadStatus,
  },
  props,
) => {
  const [clientPermission] = useState(
    clientPermissions.reduce((obj, cur) => ({ ...obj, [cur.key]: cur.defaultValue }), {}),
  );
  const { webhookSocket } = useContext(SocketContext);
  const [eventId, setEventId] = useState("");
  const [type, setType] = useState("sports_keymoments");
  const isAuthenticated = isUserAuthenticated();
  const [lodar, setLodar] = useState(true);
  const [user, setUser] = useState(null);
  const [clientSetting, setClientSetting] = useState({});
  const [parentClientSetting, setParentClientSetting] = useState({});
  const [streamStates, setStreamStates] = useState(0);
  const [totalProcessingLimit, setTotalProcessingLimit] = useState(0);
  const [streamProcessCount, setStreamProcessCount] = useState(0);
  const [profileFeatures, setProfileFeatures] = useState([]);
  const userId = localStorage.getItem("__uid");
  const [bulkDownloadZip, setBulkDownloadZip] = useState({ downloadStatus: false, folderArray: [], eventId });
  const [downloadStats, setDownloadStats] = useState({});

  useEffect(() => {
    setLodar(true);
    if (isAuthenticated) {
      (async () => {
        try {
          const {
            value: { data },
          } = await fetchMe();
          setUser(data);
          setUserProperties(data);
          let arr = [getClientSetting({ userId: data?.userId })];
          if (data?.isBillingAdmin) {
            arr.push(getClientSetting({ userId: data?.isBillingAdmin }));
          }
          const [clientSetting, parentClientSetting] = await Promise.all(arr);
          let clientData = clientSetting.value.data;
          if (clientData?.appAdditionalPermissions) {
            // Iterate over keys in clientPermission
            Object.keys(clientPermission).forEach((key) => {
              // Check if the key is not present in clientData?.appAdditionalPermissions then add the keys
              if (!clientData?.appAdditionalPermissions.hasOwnProperty(key)) {
                clientData.appAdditionalPermissions[key] = clientPermission[key];
              }
            });
          }

          setClientSetting(clientData);
          if (parentClientSetting) {
            let parentData = parentClientSetting.value.data;
            if (parentData?.appAdditionalPermissions) {
              Object.keys(clientPermission).forEach((key) => {
                if (!parentData?.appAdditionalPermissions.hasOwnProperty(key)) {
                  parentData.appAdditionalPermissions[key] = clientPermission[key];
                }
              });
            }
            setParentClientSetting(parentData);
          }
          setLodar(false);
        } catch (error) {
          if (error.statusCode === 401) {
            notification["info"]({ message: "Session Expired !! Please login again.." });
            setTimeout(() => {
              window.location.pathname = "/login";
            }, 2000);
          }
        }
      })();
      const id = shortUUID().generate(5);
      setEventId(id);
      setBulkDownloadZip({ ...bulkDownloadZip, eventId: id });
    }
  }, []);
  // To get the count of users stream proccesing Limit set by superAdmin
  const getStreamState = async () => {
    const { value } = await getStreamStates({
      type,
      userId: user?.role === "admin" ? userId : user?.isBillingAdmin,
      isProduction: false,
    });
    if (value.success) {
      setStreamStates(value?.states?.videosProcessCount);
      setTotalProcessingLimit(value.totalProcessingLimit);
    }
  };
  // To get the complete status of proccessing streams of the Admin and its users
  const getProcessingCount = async () => {
    const { value } = await getUserProcessingLimit({
      userId: localStorage.getItem("__uid"),
    });
    if (value.success) {
      setStreamProcessCount(value?.streams?.length);
    }
  };

  const getAccessControl = async () => {
    const { value } = await fetchAccessControl(user?.role);
    if (value?.status) {
      setProfileFeatures(value.data.privileges);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      getStreamState();
      getProcessingCount();
      getAccessControl();
    }
  }, [user]);

  useEffect(() => {
    if (eventId) {
      webhookSocket.on(`zip-url-${eventId}`, (data) => {
        if (data) {
          handleDownload(data.payload.event, data.payload.zipURL);
          setBulkDownloadZip({ downloadStatus: false, folderArray: [], eventId });
          setTimeout(() => {
            handleDownloadClose();
          }, 5000);
        }
      });
    }
  }, [eventId]);

  const handleDownload = (fileName, fileUrl) => {
    notification["success"]({ message: "Zip has been downloaded" });
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.rel = "noopener noreferrer";
    link.click();
  };

  const handleDownloadClose = () => {
    setBulkDownloadZip({ downloadStatus: false, folderArray: [], eventId: "" });
  };

  return isAuthenticated ? (
    <>
      {lodar && !user && !streamStates && !totalProcessingLimit ? (
        <Spin indicator={antIcon} className="loader__full" />
      ) : (
        <>
          <profileFeatureContext.Provider value={profileFeatures}>
            <Header
              type={type}
              setType={setType}
              clientSetting={clientSetting}
              parentClientSetting={parentClientSetting}
            />

            <Outlet
              context={{
                type,
                setType,
                user,
                clientSetting,
                streamStates,
                totalProcessingLimit,
                getStreamState,
                streamProcessCount,
                getProcessingCount,
                parentClientSetting,
                profileFeatures,
                bulkDownloadZip,
                eventId,
                setBulkDownloadZip,
                getS3PresignedURl,
                getDownloadStatus,
                downloadStats,
                setDownloadStats,
              }}
            />
            {bulkDownloadZip?.downloadStatus && (
              <BulkDownloadPopup
                folderArray={bulkDownloadZip?.folderArray}
                downloadStatus={bulkDownloadZip?.downloadStatus}
                handleDownloadClose={handleDownloadClose}
              />
            )}
          </profileFeatureContext.Provider>
        </>
      )}
    </>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoute;
