import React from "react";

function SvgComponent({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="none" viewBox="0 0 28 30" {...props}>
      <path
        fill="currentColor"
        d="M8.366 2.7h-.29a.295.295 0 00.29-.3v.3h10.96v-.3c0 .165.13.3.29.3h-.29v2.7h2.597v-3c0-1.324-1.035-2.4-2.308-2.4H8.077C6.804 0 5.769 1.076 5.769 2.4v3h2.597V2.7zm18.172 2.7H1.154C.516 5.4 0 5.935 0 6.6v1.2c0 .165.13.3.289.3h2.177l.891 19.613C3.414 28.99 4.431 30 5.661 30h16.37c1.233 0 2.247-1.005 2.304-2.287l.89-19.613h2.18a.295.295 0 00.287-.3V6.6c0-.665-.516-1.2-1.154-1.2zm-4.785 21.9H5.94L5.066 8.1h17.56l-.873 19.2z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
