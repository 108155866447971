import React from "react";
import socketIO from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_NEW_API_HOSTNAME;
const REACT_APP_WEBHOOK_SERVER = process.env.REACT_APP_WEBHOOK_SERVER;

export const socket = socketIO.connect(SOCKET_URL, { withCredentials: true, transports: ["websocket"] });
export const webhookSocket = socketIO.connect(REACT_APP_WEBHOOK_SERVER, {
  withCredentials: true,
  transports: ["websocket"],
});

export const SocketContext = React.createContext();
