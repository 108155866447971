import React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none" viewBox="0 0 16 15">
      <path
        fill="currentColor"
        d="M3.198 3.2L5.046 0h2.952L6.151 3.2H3.198zm4.8 0L9.846 0h2.953L10.95 3.2H7.998zm4.8 0L14.647 0h.56c.439 0 .794.356.794.795v12.81a.8.8 0 01-.793.795H.793A.794.794 0 010 13.605V.795A.8.8 0 01.793 0h2.405L1.6 2.769V12.8h12.8V3.2h-1.601z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
