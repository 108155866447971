import _ from "lodash";
import Services from "./services";
const DecryptServive = new Services();
class ClientSideServices {
  async decrypt_storage_credentials(credentials, type) {
    let decryptionFields = [];
    if (type == "aws") {
      decryptionFields = ["bucket_name", "access_key", "region", "secret_key"];
    } else if (type == "azure") {
      decryptionFields = ["bucket_name", "connection_string"];
    } else {
      return credentials;
    }
    decryptionFields.forEach((field) => {
      const value = _.get(credentials, field, "");
      if (value.startsWith("Encrypted(") && value.endsWith(")")) {
        const decryptedValue = DecryptServive.get_decrypted(value.slice(10, -1));
        _.set(credentials, field, decryptedValue);
      }
    });
    return credentials;
  }
}
export default ClientSideServices;
