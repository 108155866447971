import React from "react";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Close } from "../../components/icons";
import $ from "jquery";
const BulkDownloadPopup = ({ ...props }) => {
  const { folderArray, downloadStatus, handleDownloadClose } = props;

  return (
    <div className={`bulk-download-container ${!downloadStatus ? "download-done" : "download-close"}`}>
      <div className="title-block">
        <h5> {downloadStatus ? "Preparing Download" : "Download Ready"} </h5>
        <div className="header-icon-block">
          <span className="download-close" onClick={() => handleDownloadClose()}>
            <Close />
          </span>
        </div>
      </div>

      <div className="download-items-container">
        {downloadStatus ? (
          <>
            <div className="download-item">
              <div className="file">
                <h6>Zipping {folderArray?.length} Files</h6>
              </div>
              <div className="download-progress-block">
                <LoadingOutlined style={{ fontSize: 20, color: "#5036d6" }} spin />
              </div>
            </div>{" "}
          </>
        ) : (
          <div className="download-item">
            <div className="file">
              <h6>Files Zipped</h6>
            </div>
            <div className="download-progress-block">
              <CheckCircleOutlined style={{ color: "#0B8235" }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BulkDownloadPopup;
