import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { copyClipValidation } from "../../../utils/validations";
import CloseModal from "../../../components/icons/CloseModal";

const CopyClip = (props) => {
  const { copyOpen, setCopyOpen, clip, copyClipFun, loder, folderArray } = props;
  const [clipIds, setClipIds] = useState([]);
  const [initialState] = useState({
    streamId: "",
  });

  // const clipIds=(folderArray || []).map((obj)=>obj._id);
  const handleSubmit = async ({ streamId }) => {
    await copyClipFun({ clipIds, streamId });
    closeModal();
  };

  const closeModal = () => {
    setCopyOpen(false);
  };

  useEffect(() => {
    if (folderArray) {
      setClipIds((folderArray || []).map((obj) => obj._id));
    }
    if (clip) {
      setClipIds([clip?._id]);
    }
  }, [folderArray, clip]);

  return (
    <>
      <Modal
        isOpen={copyOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Filter Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Copy Clip</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={copyClipValidation} onSubmit={handleSubmit}>
            {({ errors, touched }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container">
                    <div className="form-group">
                      <label className="form-label">
                        Video ID <span className="required">*</span>
                      </label>
                      <Field className="form-control" name="streamId" type="text" />
                      {touched.streamId && errors.streamId && <div className="invalid-feedback">{errors.streamId}</div>}
                    </div>
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Submit
                    {loder && (
                      <Spin
                        size="large"
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: "#000", marginLeft: 50 }} spin />}
                        className="loader__full"
                      ></Spin>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default CopyClip;
