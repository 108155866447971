import * as React from "react";

const TheeDotsIconWhiteComponent = (props) => (
  <svg width={20} height={4} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 .5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM10 .5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM18 .5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TheeDotsIconWhiteComponent;
