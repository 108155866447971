import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseModal from "../../../components/icons/CloseModal";
import { Formik, Form, Field } from "formik";
import { notification, Spin, Radio, Select } from "antd";
import { addFolderValidation } from "../../../utils/validations";
import { LoadingOutlined } from "@ant-design/icons";
import { aspect_ratio, allowAspectRatio, aspectRatio } from "../../../config";
import { logDOM } from "@testing-library/react";
const { Option } = Select;

const CreateNewFolder = (props) => {
  const {
    id,
    createFolder,
    fetchfolders,
    setFolderIsOpen,
    folderIsOpen,
    handleFolder,
    folders,
    closeModal,
    setNotify,
    notify,
    setFolderId,
    folderId,
    state,
    setState,
    setFolderHide,
    folderHide,
    eventDisable,
    seteventDisable,
    folderArray,
  } = props;
  const [loder, setLoder] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [buttonDisable, setButtonDisable] = useState(false);
  const [aspectRatio, setAspectRatio] = useState("16:9");
  const [initialState] = useState({
    title: "",
    aspectRatio: aspectRatio,
  });

  const handleSubmit = async ({ title, aspectRatio }) => {
    setLoder(true);

    const { value } = await createFolder({
      streamId: id,
      title: title.toLowerCase(),
      type: "clip",
      aspectRatio,
    });

    if (value.status) {
      handleFolder(value.data._id, setFolderId(folderId));
      notification["success"]({ message: "Highlight Created Successfully!", duration: 6 });
      closeModal();
      // fetchfolders(value.message);
      setNotify(false);
      setLoder(false);
      setFolderIsOpen(false);
    } else if (value.message === "Title already exists") {
      setLoder(false);
      setNotify(true);
    } else {
      notification["error"]({ message: value.message, duration: 6 });
    }
  };

  const handlChange = (setFieldValue, e) => {
    if (e?.target?.name === "title") {
      setFieldValue(e.target.name, e.target.value);
    } else {
      setFieldValue("aspectRatio", e);
      setAspectRatio(e);
    }

    setNotify(false);
  };

  const handleSelect = (e) => {
    setAspectRatio(e?.aspectRatio);
    setFolderId(e._id);
  };

  const createNew = async () => {
    setState(true);
    setFolderHide(false);
  };

  useEffect(() => {
    if (folderId == "") {
      seteventDisable(true);
    } else {
      seteventDisable(false);
    }
  }, [folderId]);
  useEffect(() => {
    if (folderIsOpen) {
      const containsMatchingAllowedAspect = allowAspectRatio?.find((o) => o.value === aspectRatio);
      let flag = true;
      folderArray?.map((clip) => {
        let checkAspect = containsMatchingAllowedAspect?.allowedRatio.includes(clip?.aspectRatio);
        if (!checkAspect) {
          flag = false;
        }
      });
      if (flag) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    }
  }, [aspectRatio, folderIsOpen, folderId]);
  return (
    <>
      <Modal
        isOpen={folderIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        portalClassName="react-modal"
        overlayClassName="modal"
        contentLabel="Create Highlight"
        className="modal-dialog modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Add to Highlight</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={addFolderValidation} onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="react-modal-body">
                  {state && (
                    <div className="form-group">
                      <label className="form-label">
                        Title <span className="required">*</span>
                      </label>
                      <Field
                        className="form-control"
                        name="title"
                        type="text"
                        value={values.title}
                        onChange={(e) => handlChange(setFieldValue, e)}
                      />
                      {notify && <div className="invalid-feedback">Highlight Already Exists!</div>}
                      {touched.title && errors.title && <div className="invalid-feedback">{errors.title}</div>}
                    </div>
                  )}
                  {state && (
                    <div className="form-group">
                      <label className="form-label">
                        Aspect Ratio <span className="required">*</span>
                      </label>
                      <select
                        className="custom-select form-control"
                        name="aspectRatio"
                        value={values.aspectRatio}
                        onChange={(e) => handlChange(setFieldValue, e.target.value)}
                      >
                        {aspect_ratio &&
                          aspect_ratio.map((ratio) => {
                            return (
                              <option value={ratio.value} key={ratio.key}>
                                {ratio.value}
                              </option>
                            );
                          })}
                      </select>
                      {touched.aspectRatio && errors.aspectRatio && (
                        <div className="invalid-feedback">{errors.aspectRatio}</div>
                      )}
                      {buttonDisable && <div className="invalid-feedback">Highlight aspect ratio doesn't match</div>}
                    </div>
                  )}

                  {folderHide && (
                    <>
                      <div className="d-flex justify-content-between">
                        <label className="form-label">Add to Existing folders</label>
                        <button onClick={() => setFolderId("")} type="button" className="reset-btn">
                          Reset
                        </button>
                      </div>
                      <div className="content-control-block long filters-events-list styled-scroll">
                        {folders?.length > 0
                          ? folders.map((folder) => {
                              return (
                                <Radio.Group
                                  className="folders-radio-list"
                                  key={folder._id}
                                  value={folderId}
                                  onChange={(e) => handleSelect(folder)}
                                >
                                  <Radio value={folder._id} className="title_Pascal">
                                    {folder.title}
                                  </Radio>
                                </Radio.Group>
                              );
                            })
                          : "No Folder Found"}
                      </div>
                      {buttonDisable && folderId !== "" && (
                        <div className="invalid-feedback">Highlight aspect ratio doesn't match</div>
                      )}
                    </>
                  )}
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>
                  {folderHide && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={eventDisable || buttonDisable}
                      onClick={() => handleFolder(folderId)}
                    >
                      {loder ? <Spin className="lightLoader-spin" indicator={antIcon} /> : "Add"}
                    </button>
                  )}
                  {folderHide && (
                    <button type="button" disabled={folderId} className="btn btn-primary" onClick={createNew}>
                      Create New
                    </button>
                  )}
                  {state && (
                    <button disabled={buttonDisable} className="btn btn-primary" type="submit">
                      {loder ? <Spin className="lightLoader-spin" indicator={antIcon} /> : "Create"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default CreateNewFolder;
