import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M15 0C6.726 0 0 6.726 0 15c0 8.274 6.726 15 15 15 8.274 0 15-6.726 15-15 0-8.274-6.726-15-15-15zm4.832 15.758l-7.074 4.705c-.6.41-1.421-.032-1.421-.758v-9.41c0-.727.82-1.169 1.42-.758l7.075 4.705c.536.347.536 1.168 0 1.516z"
    ></path>
  </svg>
);

export default SvgComponent;
