import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" fill="none" viewBox="0 0 12 15" {...props}>
    <path
      fill="currentColor"
      d="M11.315 8.828l-4.533 2.926c-.036 0-.036.04-.072.04l-4.533 2.925c-.214.16-.5.281-.785.281C.642 15 0 14.319 0 13.437V1.533c0-.28.071-.52.178-.761C.571.051 1.392-.23 2.07.211l4.605 2.966 4.568 2.966c.214.12.393.32.536.56.428.762.214 1.724-.464 2.125z"
    ></path>
  </svg>
);

export default SvgComponent;
