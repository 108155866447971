import * as React from "react";

const PlaybtnWhite = (props) => (
  <svg width={18} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.091 12.568 2.774 20.875C1.56 21.58 0 20.727 0 19.307V2.693C0 1.275 1.557.42 2.774 1.127l14.317 8.307a1.804 1.804 0 0 1 0 3.134Z"
      fill="#fff"
    />
  </svg>
);

export default PlaybtnWhite;
