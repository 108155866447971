import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { compact, flatten, uniq } from "lodash";
import { notification, Spin, Select, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import { uploadToZeeValidation } from "../../../utils/validations";
import CloseModal from "../../../components/icons/CloseModal";
import { languageArr } from "../../../../src/config/index";

const { Option } = Select;
const UploadToZee = ({ ...props }) => {
  const { zeeOpen, setZeeOpen, jsonBucketPublish, storage, clip, streamDetails, getCustomFields, filters } = props;

  const [multipleStorageArr, setMultipleStorageArr] = useState([]);
  const [multiLanguages, setMultiLanguages] = useState([]);
  const [audio_languages, setAudioLanguages] = useState([]);
  const [clipLanguageArr, setClipLanguageArr] = useState([]);
  const [loder, setLoder] = useState(false);
  // const [description, setDescription] = useState("");
  const [assignedCollectionId, setAssignedCollectionId] = useState([]);
  const [digitalKeywords, setDigitalKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const [sponsoredvod, setSponseredVod] = useState(false);
  const [nothumbnail, setNoThumbnail] = useState(false);
  const [disable, setDisable] = useState(true);
  const [firstPackage, setFirstPackage] = useState("");
  const [initialState, setInitialState] = useState({
    title: "",
    description: "",
    storageName: "",
    folderPath: "",
    audio_languages: [],
    packageName: [],
  });
  const { title, packageName, storageName, description } = initialState;
  const [packageNameArr, setPackageNameArr] = useState([]);
  const userId = localStorage.getItem("__uid");

  const { folderPath } = initialState;
  const handleSubmit = async ({ storageName, title, description }) => {
    notification["success"]({ message: "Process Initiated..." });
    let lfilter = multiLanguages.filter((l) => l !== null);
    let videoUrl =
      clip?.editedVideos?.find(
        ({ aspect_ratio, event }) =>
          aspect_ratio == filters?.aspectRatio && (event === "autoFlip" || event === "croppedVideos"),
      )?.video_url || clip.videoUrl;
    let payload = {
      bucket: storage.filter(({ storage_name }) => storage_name === storageName)[0],
      folderPath: folderPath,
      audio_languages:
        lfilter.length > 0
          ? lfilter
          : streamDetails?.fields?.audio_languages
          ? [streamDetails?.fields?.audio_languages]
          : ["en"],
      translations:
        audio_languages.length > 0 &&
        audio_languages.map((item) => {
          delete item.type;
          return item;
        }),
      title: title,
      streamId: streamDetails?.streamId,
      videoThumbnailUrl: clip?.videoThumbnailUrl,
      clipData: clip?.clipData,
      clipId: clip?._id,
      url: videoUrl,
      csvData: clip?.csvData,
      clipIdArr: clip?.clipIdArr,
      description: description,
      nothumbnail: nothumbnail,
      userId: localStorage.getItem("__uid"),
      packageName: packageName.toString(),
      assignedCollectionId: flatten(assignedCollectionId),
      tags: compact(tags),
      digital_keywords: digitalKeywords.toString(),
      sponsoredvod: sponsoredvod,
      firstPackageName: firstPackage,
    };

    if (audio_languages.length === 0) {
      payload.translations = [];
    }
    if (lfilter.length === 0) {
      payload.translations = [
        {
          language: streamDetails?.fields?.audio_languages,
          original_title: title,
          digital_long_description_tv: title,
          digital_long_description_web: title,
          digital_short_description_mobile: title,
          short_description: title,
        },
      ];
    }
    // else {
    //   let arr = lfilter;
    //   arr.push(streamDetails?.fields?.audio_languages);
    //   let arrr = arr.filter((l) => l !== (null || undefined));
    //   payload.audio_languages = uniq(arrr);
    // }
    var data = await jsonBucketPublish(payload);
    if (data.value.success) {
      setInitialState({ ...initialState, audio_languages: [] });
      notification["success"]({ message: data.value.message });
    } else {
      setInitialState({ ...initialState, audio_languages: [] });
      notification["error"]({ message: data.value.message });
    }
    closeModal();
  };
  const fetchCustomFields = async () => {
    const { value } = await getCustomFields({
      userId,
    });
    if (value.success) {
      setPackageNameArr(value?.setting?.package_name);
      const option = value?.setting?.customFields?.filter(
        (bt) => bt?.fieldType === "select" && bt?.inputFieldName === "business_type",
      )[0]?.selectoption;
    }
  };
  useEffect(() => {
    fetchCustomFields();
  }, []);

  const closeModal = () => {
    setInitialState({ ...initialState, folderPath: "" });
    setZeeOpen(false);
  };

  const setBucketValue = (setFieldValue, value) => {
    setInitialState({ ...initialState, storageName: value, folderPath: "" });
    setFieldValue("storageName", value);
    var temp = [];
    storage.filter((obj) => {
      if (obj.storage_name === value) {
        setFieldValue("folderPath", obj?.credentials?.folder_path);
        setFieldValue("storageName", value);
        temp = obj?.multiple_folder_path || [];
        setMultipleStorageArr(temp);
        setFolderPath(temp);
      } else if (value === "") {
        setMultipleStorageArr([]);
      }
    });
  };

  const setMultiOption = (e, key) => {
    let audvar = [...audio_languages];
    let lObj = {
      language: key,
      type: key,
    };
    let previousObj = audvar.find((item) => item.type === key);
    if (previousObj) {
      lObj = previousObj;
    }
    if (e.target.name === "orignal_title") {
      lObj.original_title = e.target.value;
    } else if (e.target.name === "description") {
      lObj.digital_long_description_tv = e.target.value;
      lObj.digital_long_description_web = e.target.value;
      lObj.digital_short_description_mobile = e.target.value;
      lObj.digital_short_description_mobile = e.target.value;
      lObj.short_description = e.target.value;
    }
    if (!previousObj) {
      audvar.push(lObj);
    }
    setAudioLanguages(audvar);
  };
  useEffect(() => {
    let audvar = [...audio_languages];
    let finalArr = audvar.filter((item) => multiLanguages.includes(item.type));
    setAudioLanguages(finalArr);
  }, [multiLanguages]);

  const setFolderPath = (multifolder) => {
    let folderPath = multifolder.find((v) => {
      return v.multiple_folder_path.indexOf("short_") !== -1;
    })?.multiple_folder_path;
    if (clip?.duration > 120) {
      folderPath = multifolder.find((v) => {
        return v.multiple_folder_path.indexOf("long_") !== -1;
      })?.multiple_folder_path;
    }
    if (!folderPath && multifolder.length === 1) {
      folderPath = multifolder?.[0].multiple_folder_path;
    }
    setInitialState({
      ...initialState,
      folderPath,
    });
  };
  useEffect(() => {
    setInitialState({ ...initialState, title: clip?.episodeTitle });
  }, [clip]);

  useEffect(() => {
    setClipLanguageArr(languageArr);
  }, [streamDetails, languageArr]);

  const handlePackagesChange = (setFieldValue, e) => {
    setFirstPackage(e[0]);
    setInitialState({ ...initialState, packageName: e });
    setFieldValue("packageName", e);
    let assColl = [];
    let digKey = [];
    let tg = [];
    for (var i in e) {
      let filter = streamDetails?.fields?.packages.filter((pack) => pack.name == e[i]);
      if (filter.length > 0) {
        if (filter?.[0]?.assignedCollectionId?.[0]) {
          assColl.push(filter?.[0]?.assignedCollectionId);
        }
        if (filter?.[0]?.digital_keywords) {
          digKey.push(filter?.[0]?.digital_keywords);
        }
        if (filter?.[0]?.tags) {
          tg.push(filter?.[0]?.tags);
        }
      } else {
        let filter = packageNameArr.filter((pack) => pack.name == e[i]);
        if (filter?.[0]?.assignedCollectionId?.[0]) {
          assColl.push(filter?.[0]?.assignedCollectionId);
        }
        if (filter?.[0]?.digital_keywords) {
          digKey.push(filter?.[0]?.digital_keywords);
        }
        if (filter?.[0]?.tags) {
          tg.push(filter?.[0]?.tags);
        }
      }
    }
    setAssignedCollectionId(assColl);
    setDigitalKeywords(digKey);
    setTags(tg);
  };

  return (
    <>
      <Modal
        isOpen={zeeOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Cloud Publish"
        portalClassName="react-modal"
        overlayClassName="modal"
        ariaHideApp={false}
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Cloud Publish</h5>
            <button type="button" onClick={closeModal} className="btn btn__default">
              <CloseModal />
            </button>
          </div>
          <Formik initialValues={initialState} validationSchema={uploadToZeeValidation} onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue, values, dirty }) => (
              <Form>
                <div className="react-modal-body">
                  <div className="form-container row">
                    <div className="three-column-row">
                      <div className="form-group">
                        <label className="form-label">
                          Title <span class="required">*</span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="title"
                          onChange={(e) => setFieldValue("title", e.target.value)}
                        />
                        {touched.title && errors.title && <div className="invalid-feedback">Title required</div>}
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Description <span class="required">*</span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="description"
                          onChange={(e) => setFieldValue("description", e.target.value)}
                        />
                        {touched.description && errors.description && (
                          <div className="invalid-feedback">Description required</div>
                        )}
                      </div>
                    </div>
                    <div className="three-column-row">
                      <div className="form-group">
                        <label className="form-label">
                          Package <span class="required">*</span>
                        </label>
                        <Field
                          className="form-control"
                          name="packageName"
                          onChange={(e) => {
                            handlePackagesChange(setFieldValue, e);
                          }}
                          component={Select}
                          dropdownStyle={{ zIndex: 99999999 }}
                          mode="multiple"
                          placeholder="Package"
                          showSearch={false}
                          value={packageName}
                        >
                          {packageNameArr &&
                            packageNameArr.map((data) => {
                              return (
                                <>
                                  <Option key={data.name} value={data.name}>
                                    {data?.name}
                                  </Option>
                                </>
                              );
                            })}
                        </Field>
                        {touched.packageName && errors.packageName && (
                          <div className="invalid-feedback">Package Name required</div>
                        )}
                      </div>
                    </div>
                    {packageName.length > 0 && (
                      <>
                        <div className="three-column-row">
                          <div className="form-group">
                            <label className="form-label">Assigned Collections</label>
                            <Field
                              name={packageName}
                              type="text"
                              value={(assignedCollectionId || []).join(",")}
                              placeholder={`Enter assigned collections with comma separate like (\"0-8-456\",\"0-8-567\")`}
                              className="form-control"
                              onChange={(e) => {
                                setAssignedCollectionId((e?.target?.value || "").split(","));
                              }}
                            />
                          </div>
                        </div>
                        <div className="three-column-row">
                          <div className="form-group">
                            <label className="form-label">Digital Keywords</label>
                            <Field
                              name={packageName}
                              type="text"
                              value={digitalKeywords}
                              placeholder={`Enter digital keywords`}
                              className="form-control"
                              onChange={(e) => {
                                setDigitalKeywords(e?.target?.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="three-column-row">
                          <div className="form-group">
                            <label className="form-label">Tags</label>
                            <Field
                              name={packageName}
                              type="text"
                              value={(tags || []).join(",")}
                              placeholder={`Enter tags with comma separate like (\"0-8-456\",\"0-8-567\")`}
                              className="form-control"
                              onChange={(e) => {
                                setTags((e?.target?.value || "").split(","));
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="three-column-row">
                      <div className="form-group">
                        <label className="form-label">
                          Sponsered VOD <span class="required">*</span>
                        </label>
                        <select
                          className="custom-select form-control"
                          name="sponsoredvod"
                          placeholder="Enter Sponsored VOD"
                          value={sponsoredvod}
                          onChange={(e) => setSponseredVod(e.target.value)}
                        >
                          {touched.folderPaths && errors.folderPaths && (
                            <div className="invalid-feedback">Business type required</div>
                          )}
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </select>
                      </div>
                    </div>
                    <div className="three-column-row">
                      <div className="form-group">
                        <label className="form-label">
                          Storage <span class="required">*</span>
                        </label>
                        <select
                          className="custom-select form-control"
                          name="storageName"
                          value={values.storageName}
                          onChange={(e) => setBucketValue(setFieldValue, e.target.value)}
                        >
                          <option value="">Select Storage</option>
                          {storage.map((data) => {
                            return (
                              <option key={data._id} value={data.storage_name}>
                                {data.storage_name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.storageName && errors.storageName && (
                          <div className="invalid-feedback">Storage Name required</div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="form-group">
                          <label className="form-label">Select Folder Path</label>
                          <select
                            className="custom-select form-control"
                            name="folderPath"
                            value={folderPath}
                            disabled={true}
                          >
                            {multipleStorageArr?.map((data) => {
                              return (
                                <option key={data._id} value={data.multiple_folder_path}>
                                  {data.multiple_folder_path}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="three-column-row">
                      <div className="form-group">
                        <Checkbox
                          value={nothumbnail}
                          onChange={(e) => {
                            setNoThumbnail(e.target.checked);
                          }}
                        />
                        <label className="form-label">No Thumbnail</label>
                      </div>
                    </div>
                    <div className="three-column-row">
                      <div className="form-group">
                        <label className="form-label">Multiple Audio Languages</label>
                        <Field
                          className="form-control"
                          name="multiLanguages"
                          onChange={(e) => {
                            setMultiLanguages(e);
                          }}
                          component={Select}
                          dropdownStyle={{ zIndex: 99999999 }}
                          mode="multiple"
                          placeholder="Select Audio Languages"
                          showSearch={false}
                          value={multiLanguages}
                        >
                          {clipLanguageArr &&
                            clipLanguageArr.map((data) => {
                              return (
                                <>
                                  <Option value={data?.value}>{data?.name}</Option>
                                </>
                              );
                            })}
                        </Field>
                      </div>
                    </div>

                    {multiLanguages &&
                      multiLanguages.map((l) => {
                        let previousObj = audio_languages.find((item) => item.type === l);
                        return (
                          <>
                            {" "}
                            <div className="three-column-row">
                              <div className="form-group">
                                <label className="form-label">
                                  {l?.toUpperCase()} Title <span class="required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="orignal_title"
                                  onChange={(e) => {
                                    setMultiOption(e, l);
                                  }}
                                  required
                                  value={previousObj?.original_title}
                                  defaultValue=""
                                ></input>
                              </div>
                              <div className="form-group">
                                <label className="form-label">
                                  {l?.toUpperCase()} Description <span class="required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="description"
                                  onChange={(e) => {
                                    setMultiOption(e, l);
                                  }}
                                  required
                                  value={previousObj?.short_description}
                                  defaultValue=""
                                ></input>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="react-modal-footer">
                  <button type="button" className="btn btn-white" onClick={closeModal}>
                    Cancel
                  </button>

                  <button className="btn btn-primary" type="submit">
                    Submit
                    {loder && (
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 50,
                              color: "#000",
                              marginLeft: 50,
                            }}
                            spin
                          />
                        }
                        className="loader__full"
                      ></Spin>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default UploadToZee;
