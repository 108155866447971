import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="114" height="51" fill="none" viewBox="0 0 114 51" {...props}>
    <rect
      width="95.454"
      height="30.808"
      x="17.687"
      y="19.192"
      fill="#333"
      stroke="#fff"
      strokeDasharray="2 2"
      rx="6"
    ></rect>
    <path stroke="#fff" d="M57 10.101c5.976.337 17.778 4.95 17.172 20.707m0 0l3.282-3.283-6.565.253 3.283 3.03z"></path>
    <rect width="50" height="30" fill="#5D647B" rx="6"></rect>
    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M25 8v14M18 15h14"></path>
  </svg>
);

export default SvgComponent;
