import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { NODE_ENV, RELEASE_HASH } from "../../config/envConfig";

export const initDataDog = () => {
  initDataDogRum();
  initDataDogLogs();
};

export const initDataDogRum = () => {
  datadogRum.init({
    applicationId: "3c7af622-8840-4902-9de0-c2821ced2d1c",
    clientToken: "pubff83b73c3e2c2f6d4d9a5ee5d4b0ab3a",
    site: "datadoghq.eu",
    service: "magnifi-dashboard",
    env: NODE_ENV,
    version: RELEASE_HASH,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
};

export const initDataDogLogs = () => {
  datadogLogs.init({
    clientToken: "pubee193c16ceb65c145dfc214b78fa893d",
    site: "datadoghq.eu",
    service: "magnifi-dashboard",
    env: NODE_ENV,
    version: RELEASE_HASH,
    forwardConsoleLogs: "all",
  });
};

export const setDataDogUserProperties = (userDetails) => {
  if (userDetails) {
    datadogLogs.setGlobalContextProperty("user", {
      userid: userDetails?._id,
      name: userDetails?.name,
      email: userDetails?.email,
    });

    datadogRum.setUser({
      id: userDetails?._id,
      name: userDetails?.name,
      email: userDetails?.email,
    });
  }
};

export const addRenderingErrorToDataDogRum = (error, info) => {
  const renderingError = new Error(error.message);
  renderingError.name = `ReactRenderingError`;
  renderingError.stack = info.componentStack;
  renderingError.cause = error;

  datadogRum.addError(renderingError);
};
