import React from "react";

function minusIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="2" fill="none" viewBox="0 0 16 2" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14"></path>
    </svg>
  );
}

export default minusIcon;
