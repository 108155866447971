import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="13" fill="none" viewBox="0 0 22 13" {...props}>
    <path
      fill="currentColor"
      d="M8.14 4.399c.863.586 1.294.878 1.445 1.246a1.32 1.32 0 010 1.003c-.151.368-.582.66-1.445 1.246L3.3 11.182c-1.07.727-1.606 1.09-2.05 1.067a1.32 1.32 0 01-.98-.52C0 11.376 0 10.73 0 9.435V2.858C0 1.564 0 .918.27.563a1.32 1.32 0 01.98-.519c.444-.024.98.34 2.05 1.067l4.84 3.288zM12.877 4.356c-.862.586-1.293.878-1.444 1.246a1.32 1.32 0 000 1.003c.15.368.582.66 1.444 1.246l4.841 3.288c1.07.727 1.606 1.09 2.05 1.067.387-.02.746-.21.98-.52.27-.354.27-1 .27-2.295V2.815c0-1.294 0-1.94-.27-2.295a1.32 1.32 0 00-.98-.519c-.444-.024-.98.34-2.05 1.067l-4.84 3.288z"
    ></path>
  </svg>
);

export default SvgComponent;
